import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import BranchMigrationModal from 'components/Branch/BranchMigrationModal'
import { queryKeys } from 'constants/queryKeys'
import { branchMigrationErrors } from 'error-data/branch'
import { branchMigrationHandler, getMemberBranchMigrationDetail } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { setupDate, statusType } from 'tools/formTools'
import { getLoadingHandler } from 'tools/queryHelpers'
import { TMigration } from 'types/member'

export const BranchMigrationDetail = () => {
  const [sendType, setSendType] = useState<TMigration>()
  const [isSending, setIsSending] = useState(false)
  const { userToken, isAllAdmin, isAllOD, id, unitContext } = useAuth()
  const isAdmin = isAllAdmin()
  const queryClient = useQueryClient()
  const isValid = isAdmin || isAllOD()
  const navigate = useNavigate()

  // FETCH DATA

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.branchMigrationDetail],
    queryFn: () => getMemberBranchMigrationDetail(id),
    retry: useRetryHandler({
      resourceName: 'BranchMigrationDetail data',
      maxRetries: 1,
    }),
  })
  const name = `${data?.member?.firstName} ${data?.member?.lastName}`
  const issuer = data?.issuer?.displayName || data?.issuer?.email
  const canResolve = data?.canHandle
  const canCancel = data?.canCancel
  const memberButton =
    isAdmin || unitContext?.id === data?.branchBefore?.id
      ? [
          {
            label: 'Przejdź do pełnych danych',
            handleClick: () => navigate(`/member/${data?.member?.id}`),
          },
        ]
      : []

  // SEND HANDLER
  const mutation = useMutation({
    mutationFn: (type: TMigration) => branchMigrationHandler(id, type),
    onSuccess: (_res, data) => {
      const message = {
        accept: 'Zaakceptowano wniosek.',
        reject: 'Odrzucono wniosek.',
        cancel: 'Anulowano wniosek.',
      }
      toast.success(message[data])
      queryClient.invalidateQueries({ queryKey: [queryKeys.branchMigrations] })
      navigate('/branch/migrations')
    },
    onError: (error: AxiosError, data) => {
      setIsSending(false)
      console.error('Error', error)
      const message = {
        accept: 'Nie udało się zaakceptować wniosku.',
        reject: 'Nie udało się odrzucić wniosku.',
        cancel: 'Nie udało się anulować wniosku.',
      }
      mutationErrorHandler(error, branchMigrationErrors[data], message[data])
    },
  })

  const sendHandler = async (type: TMigration) => {
    try {
      setIsSending(true)
      mutation.mutate(type)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      const message = {
        accept: 'Nie udało się zaakceptować wniosku.',
        reject: 'Nie udało się odrzucić wniosku.',
        cancel: 'Nie udało się anulować wniosku.',
      }
      mutationErrorHandler(null, branchMigrationErrors[data], message[type])
    }
  }

  const loadingHandler = getLoadingHandler(error, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        {data?.branchAfter?.name} / Zgłoszenie zmian / Wniosek {name}
      </Typography>
      <Card label='Dane zgłoszenia'>
        <div className='grid grid-cols-5 gap-5'>
          <LabelTypography label='Data zgłoszenia' text={setupDate(data?.createdAt)} />
          <LabelTypography label='Oddział zbywający' text={data?.branchBefore?.name} />
          <LabelTypography label='Oddział nabywający' text={data?.branchAfter?.name} />
        </div>
        <div className='my-4 grid grid-cols-5 gap-5'>
          <LabelTypography label='Osoba zgłaszającva' text={issuer} />
          <LabelTypography label='Telefon' text={setupDate(data?.issuer?.phoneNumber)} />
        </div>
        <LabelTypography label='Komentarz' text={data?.comment} />
      </Card>
      <Card label='Dane członka' actionsButton={memberButton}>
        <div className='grid grid-cols-5 gap-5'>
          <LabelTypography label='Imię' text={data?.member?.firstName} />
          <LabelTypography label='Nazwisko' text={data?.member?.lastName} />
          <LabelTypography label='Nr legitymacji' text={data?.member?.card} />
          <LabelTypography label='Status' text={statusType(data?.status)} />
        </div>
      </Card>
      <div className='mt-8 flex items-center justify-center gap-5'>
        {canCancel && (
          <Button
            label='Wycofaj zgłoszenie'
            onClick={() => setSendType('cancel')}
            disabled={isSending}
          />
        )}
        {canResolve && (
          <>
            <Button label='Odrzuć' onClick={() => setSendType('reject')} disabled={isSending} />
            <Button label='Zatwierdź' onClick={() => setSendType('accept')} disabled={isSending} />
          </>
        )}
      </div>
      <BranchMigrationModal
        isSending={isSending}
        closeModal={() => setSendType(undefined)}
        sendHandler={sendHandler}
        type={sendType}
      />
    </div>
  )
}

export default BranchMigrationDetail
