import { flatten } from 'lodash'

import { useAppSelector } from 'app/hooks'

interface Roles {
  [key: string]: { role: string; name: string }[]
}
export const useRoles = () => {
  const roles = useAppSelector((state) => state.rolesReducer.roles)
  const rolesOptions = flatten(Object.values(roles))
  const rolesData: Roles = { ...roles }
  const rolesRes: any = {}
  Object.keys(rolesData).forEach((key: string) => {
    rolesData[key].forEach((role) => {
      const data = {
        name: role?.name,
        type: key,
      }
      Object.assign(rolesRes, { [role.role]: data })
    })
  })
  return { roles: rolesRes, rolesOptions }
}

export default useRoles
