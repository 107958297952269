import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getCardStatusList, getCardsList } from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearchModule from 'hooks/useSearchModule'
import { commonArrayToObject } from 'tools/dictionaryHelper'
import { getLastPage } from 'tools/queryHelpers'

export const CardList = () => {
  const { isAllAdmin, isAllOD, userLoaded, memberId } = useAuth()
  const { filters, currentPage, onPageChange } = useSearchModule({ archive: false })
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const navigate = useNavigate()

  const idBranch = isAllOD()
  const isAdmin = isAllAdmin()

  // Get legimations list
  const {
    data: legitimationsRequestList,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardsList, currentPage, memberId, filters],
    queryFn: () => getCardsList(currentPage, 10, JSON.stringify(filters)),
    retry: useRetryHandler({
      resourceName: 'CardList legitimationsRequestList',
      maxRetries: 1,
    }),
  })

  const { data: statusList, isLoading: statusLoading } = useQuery({
    queryKey: [userToken, queryKeys.cardStatus],
    queryFn: () => getCardStatusList(),
    retry: useRetryHandler({
      resourceName: 'CardList statusList',
      maxRetries: 1,
    }),
  })

  const statusListArray = statusList?.items ? commonArrayToObject(statusList.items) : {}

  const { lastPage } = getLastPage(legitimationsRequestList?.pagination)
  // End of get legimations list

  const columns = useMemo(
    () => [
      {
        Header: 'Numer legitymacji',
        accessor: 'number',
      },
      { Header: 'Imię', accessor: 'member.firstName' },
      { Header: 'Nazwisko', accessor: 'member.lastName' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (row: any) => {
          const status = row.row.original.status
          return (
            <div className='flex'>
              <p className='text-sm font-medium'>{statusListArray[status] || status}</p>
            </div>
          )
        },
      },
      { Header: 'Oddział', accessor: 'branch.name' },
      { Header: 'Termin przyjęcia', accessor: '' },
      { Header: 'Data wniosku', accessor: 'createdAt' },
      { Header: 'Data aktualizacji', accessor: 'updatedAt' },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          const declarationId = row.row.original.id
          return (
            <div className='flex'>
              <HoverIcon
                disabled={row.row.original?.archived}
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(`/card/show/${declarationId}`)}
              />
            </div>
          )
        },
      },
    ],
    [statusListArray],
  )

  const verified = isAdmin || idBranch

  if (isLoading || statusLoading || !verified || !userLoaded || !legitimationsRequestList)
    return (
      <LoadingModal
        isLoading={isLoading || !userLoaded || !legitimationsRequestList}
        forbidden={!verified}
        route={false}
      />
    )

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Wydane legitymacje
      </Typography>

      <div className='mt-5 bg-white p-5'>
        {!legitimationsRequestList?.items?.length ? (
          <p>Wczytuję...</p>
        ) : (
          <Table columns={columns} data={legitimationsRequestList.items} />
        )}

        <div className='flex justify-between gap-4'>
          <div className='ml-auto flex items-center gap-4'>
            <div className='flex items-center gap-4'>
              <p className='w-[120px] text-sm font-medium'>Wierszy na stronę</p>
              <Select
                options={[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                ]}
              />
            </div>
            <Pagination
              lastPage={lastPage}
              currentPage={currentPage}
              handlePageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
