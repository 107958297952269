import React, { useEffect, useState } from 'react'

import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import { getMembersList } from 'fetchers/membersFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsListPrefiltred } from 'fetchers/sectionFetchers'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import { getAvailableTags } from 'fetchers/tagsFetchers'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { translateCardStatus } from 'tools/cardStatus'
import { setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { errorToast } from 'tools/ToastHelpers'
import { IListElement, IOption, IOptionsObject, IPageHandler, IVoidObject } from 'types/form'
import { IMemberListing } from 'types/member'
import { IUnitSelectedUnits } from 'types/structure'

import MemberMassBranchModal from './MemberMassModals/MemberMassBranchModal'
import MemberMassCardModal from './MemberMassModals/MemberMassCardModal'
import MemberMassSectionModal from './MemberMassModals/MemberMassSectionModal'
import MemberSearchBarAll from './MemberSearchBarAll'
import MembersSectionActionCell from './MembersSectionActionCell'

type Props = {
  isRegion?: boolean
  isBranch?: boolean
  isAdmin?: boolean
  userToken: string
  unitContext: any
  userLoaded: boolean
}

const searchParams = {
  simpleParams: [
    'search',
    'searchExtended',
    'email',
    'pesel',
    'sex',
    'card',
    'institutionLimit',
    'regionLimit',
  ],
  arrayParams: [
    'institution',
    'subject',
    'region',
    'branch',
    'section',
    'position',
    'unitPosition',
    'tag',
  ],
}

const selectedUnitsInit: IUnitSelectedUnits = {
  region: [],
  branch: [],
  section: [],
  institution: [],
}

export const MemberListFull = ({
  isRegion,
  isBranch,
  isAdmin,
  userToken,
  unitContext,
  userLoaded,
}: Props) => {
  const [regionSearch, setRegionSearch] = useState('')
  const [branchSearch, setBranchSearch] = useState('')
  const [sectionSearch, setSectionSearch] = useState('')
  const [institutionSearch, setInstitutionSearch] = useState('')
  // SELECTED UNITS
  const [selectedUnits, setSelectedUnits] = useState<IUnitSelectedUnits>(selectedUnitsInit)
  // Per page states
  const [regionPerPage, setRegionPerPage] = useState(10)
  const [branchPerPage, setBranchPerPage] = useState(10)
  const [sectionPerPage, setSectionPerPage] = useState(10)
  const [inPerPage, setInPerPage] = useState(10)
  const [selectedIds, setSelectedIds] = useState<IMemberListing[]>([])
  const [typeOfRequest, setTypeOfRequest] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)

  const { filters, setParam, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const id = unitContext?.id
  const navigate = useNavigate()
  const { control } = useForm()

  const { isAllAdmin, isAllOK } = useAuth()

  // REGIONS LOADER
  // Get regions list - only enabled for admin and region users
  const canQueryRegions = React.useMemo(() => {
    return isAllAdmin() || isAllOK()
  }, [isAllAdmin, isAllOK])

  const selectedRegions = selectedUnits.region.length
  const selectedBranches = selectedUnits.branch.length

  // REGIONS LOADER
  // Get regions list
  const {
    data: regions,
    isLoading: regLoading,
    refetch: rfReg,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, regionPerPage, regionSearch],
    queryFn: () => getRegionsList(1, regionPerPage, [{ name: regionSearch }]),
    enabled: canQueryRegions,
    retry: useRetryHandler({
      resourceName: 'MemberListFull regions',
      maxRetries: 1,
    }),
  })
  // Regions list
  const regionsList = React.useMemo(() => {
    if (!canQueryRegions || !regions?.items) {
      return []
    }
    return regions.items.map((e: any) => ({ id: e.id, name: e.name }))
  }, [canQueryRegions, regions?.items])
  // END REGIONS LOADER

  // BRANCHES LOADER
  // Get branches list
  const branchFilters =
    setupSearchFilters([{ name: branchSearch }]) +
    setupSearchMultiFilters([{ region: selectedUnits.region }])
  const {
    data: branches,
    isLoading: brLoading,
    refetch: rfBr,
  } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      branchPerPage,
      branchSearch,
      selectedRegions,
      branchFilters,
    ],
    queryFn: () => {
      if (selectedRegions === 0) {
        return { items: [] }
      }
      return getBranchesListPrefiltred(1, branchPerPage, branchFilters)
    },
    retry: useRetryHandler({
      resourceName: 'MemberListFull branches',
      maxRetries: 1,
    }),
  })
  // Branches list
  const branchesList = branches?.items
    ? branches.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END BRANCHES LOADER

  // SECTIONS LOADER
  // Get sections list
  const sectionFilters =
    setupSearchFilters([{ name: sectionSearch }]) +
    setupSearchMultiFilters([{ branch: selectedUnits.branch }])
  const {
    data: sections,
    isLoading: secLoading,
    refetch: rfSec,
  } = useQuery({
    queryKey: [
      userToken,
      queryKeys.sectionsList,
      sectionPerPage,
      sectionSearch,
      selectedBranches,
      selectedRegions,
      sectionFilters,
    ],
    queryFn: () => {
      if (selectedBranches === 0) {
        return { items: [] }
      }
      return getSectionsListPrefiltred(1, sectionPerPage, sectionFilters)
    },
    retry: useRetryHandler({
      resourceName: 'MemberListFull sections',
      maxRetries: 1,
    }),
  })
  // Sections list
  const sectionsList = sections?.items
    ? sections.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END SECTIONS LOADER

  // INSTITUTIONS LOADER
  // Get institutions list
  const {
    data: institutions,
    isLoading: inLoading,
    error,
    refetch: rfIn,
  } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, inPerPage, institutionSearch],
    queryFn: () => getInstitutionsList(1, inPerPage, { name: institutionSearch }),
    retry: useRetryHandler({
      resourceName: 'MemberListFull institutions',
      maxRetries: 1,
    }),
  })

  // Set institutions list
  const institutionsList = institutions?.items
    ? institutions.items.map((e: any) => ({ id: e.id, name: e.name }))
    : []
  // END INSTITUTIONS LOADER

  // SUBJECTS LOADER
  const { data: subjects, isLoading: subLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getSubjectsList('all', 1),
    retry: useRetryHandler({
      resourceName: 'MemberListFull subjects',
      maxRetries: 1,
    }),
  })
  // Setup subjects list
  const subjectsList = subjects?.items
    ? subjects.items.map((e: any) => ({ value: e.id, label: e.name }))
    : []
  // END SUBJECTS LOADER

  // FUNCTIONS LOADER
  const { data: functions, isLoading: funLoading } = useQuery({
    queryKey: [userToken, queryKeys.unitPositionsList],
    queryFn: () => getUnitPositionsList('all', 100),
    retry: useRetryHandler({
      resourceName: 'MemberListFull functions',
      maxRetries: 1,
    }),
  })
  const functionsList = functions?.items
    ? functions.items.map((e: any) => ({ value: e.id, label: e.name }))
    : []
  // END FUNCTIONS LOADER

  // POSITIONS LOADER
  const { data: positions, isLoading: posLoading } = useQuery({
    queryKey: [userToken, queryKeys.positionsList],
    queryFn: () => getPositionsList('all', 10),
    retry: useRetryHandler({
      resourceName: 'MemberListFull positions',
      maxRetries: 1,
    }),
  })
  const positionsList = positions?.items
    ? positions.items.map((e: any) => ({ value: e.id, label: e.name }))
    : []
  // END POSITIONS LOADER

  // TAGS LOADER
  const {
    data: tags,
    isLoading: tagsLoading,
    refetch: rfTag,
  } = useQuery({
    queryKey: [userToken, queryKeys.tagsListAvailable],
    queryFn: () => getAvailableTags(),
    retry: useRetryHandler({
      resourceName: 'MemberListFull tags',
      maxRetries: 1,
    }),
  })
  const tagsList = tags?.items
    ? tags.items.map((item: IListElement) => ({ label: item.name, value: item.id }))
    : []
  // END TAGS LOADER

  // MEMBERS LOADER
  const {
    data: members,
    isLoading,
    refetch: memberRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.membersList, page, perPage, filters, id],
    queryFn: () => getMembersList(page, perPage, filters),
    retry: useRetryHandler({
      resourceName: 'MemberListFull members',
      maxRetries: 1,
    }),
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
  })

  // Setup members list
  const membersList: IMemberListing[] = members?.items || []

  // Setup member loading state
  const membersLoading = isLoading || !userLoaded
  // Setup last page
  const lastPage = useLastPage(members?.pagination, membersLoading)
  // END MEMBERS LOADER

  // CUSTOM AGE PARAM FUNCTION
  const setCustomAgeParam = (ageSearch: string) => {
    setParam.custom(ageSearch)
  }
  // OPTIONS BUNDLER
  const options: IOptionsObject = {
    regions: regionsList,
    branches: branchesList,
    sections: sectionsList,
    institutions: institutionsList,
    subjects: subjectsList,
    unitPositions: functionsList,
    positions: positionsList,
    tags: tagsList,
  }
  // END OPTIONS BUNDLER

  // LOADING BUNDLER
  const loadings: any = {
    regions: regLoading,
    branches: brLoading,
    sections: secLoading,
    institutions: inLoading,
    subjects: subLoading,
    unitPositions: funLoading,
    positions: posLoading,
    tags: tagsLoading,
  }

  // REFETCH BUNDLER
  const refetch: IVoidObject = {
    regions: rfReg,
    branches: rfBr,
    sections: rfSec,
    institutions: rfIn,
    tags: rfTag,
    members: memberRefetch,
  }
  // END REFETCH BUNDLER

  // SEARCH MORE BUNDLER
  const searchMoreHandler = (unit: any, loading: boolean, setter: React.Dispatch<any>) => {
    const count =
      unit?.pagination?.countFiltered > 0
        ? unit?.pagination?.countFiltered
        : unit?.pagination?.count
    if (!loading && unit?.items?.length < count) setter((prev: number) => prev + 10)
  }
  const searchMore: IVoidObject = {
    regions: () => searchMoreHandler(regions, regLoading, setRegionPerPage),
    branches: () => searchMoreHandler(branches, brLoading, setBranchPerPage),
    sections: () => searchMoreHandler(sections, secLoading, setSectionPerPage),
    institutions: () => searchMoreHandler(institutions, inLoading, setInPerPage),
  }
  // END SEARCH MORE BUNDLER

  // SET LIMIT BUNDLER
  const setBoxLimit: IPageHandler = {
    regions: (e: number) => setRegionPerPage(e),
    branches: (e: number) => setBranchPerPage(e),
    sections: (e: number) => setSectionPerPage(e),
    institutions: (e: number) => setInPerPage(e),
  }
  // END SET LIMIT BUNDLER

  // QUERY BUNDLER
  const query: any = {
    regions: regionSearch,
    branches: branchSearch,
    sections: sectionSearch,
    institutions: institutionSearch,
  }

  // QUERY HANDLER BUNDLER
  const queryHandler = {
    regions: (e: string) => setRegionSearch(e),
    branches: (e: string) => setBranchSearch(e),
    sections: (e: string) => setSectionSearch(e),
    institutions: (e: string) => setInstitutionSearch(e),
  }
  // END QUERY HANDLER BUNDLER

  // PAGE LIMIT HANDLER
  const onLimitHandler = (e: IOption) => {
    console.log('Current perPage:', perPage)
    setLimit(e.value)

    // Reset to the first page when the perPage value changes
    changePage(1)

    // Trigger a refetch immediately after changing perPage
    memberRefetch()

    console.log('New value:', e.value)
  }

  // END PAGE LIMIT HANDLER

  // HANDLE CHECKBOX CHANGE
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const isChecked = e.currentTarget.checked
    setSelectedIds((prevList) => {
      if (isChecked) {
        return [...prevList, row.row.original]
      } else {
        return prevList.filter((item) => item.id !== row.row.original.id)
      }
    })
  }

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Controller
            name="cardId"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={selectedIds.length > 0 && selectedIds.length === membersList.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedIds(membersList)
                  } else {
                    setSelectedIds([])
                  }
                }}
              />
            )}
          />
        ),
        accessor: 'id',
        Cell: (row: any) => (
          <Controller
            name="cardId"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={selectedIds.some((item) => item.id === row.row.original.id)}
                onChange={(e) => {
                  onChange(e.target.checked)
                  handleCheckboxChange(e, row)
                }}
              />
            )}
          />
        ),
      },
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Okręg',
        accessor: 'region',
        Cell: ({ row }: any) => row.original?.region?.name || '-',
      },
      {
        Header: 'Oddział',
        accessor: 'branch',
        Cell: ({ row }: any) => row.original?.branch?.name || '-',
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
        Cell: ({ row }: any) => row.original?.card?.number || '-',
      },
      {
        Header: 'Status legitymacji',
        accessor: 'cardStatus',
        Cell: ({ row }: any) => translateCardStatus(row.original?.card?.status) || '-',
      },
      {
        Header: 'Pełnione funkcje',
        accessor: 'functions',
        Cell: ({ row }: any) => {
          if (!row.original?.unitPositions?.length) {
            return '-'
          }
          return (
            <ul>
              {row.original?.unitPositions?.map((e: any, i: number) => (
                <li key={`${row?.original?.id}-fn-${i}-${e.unit.id}`}>
                  <p>
                    {e?.position?.name} ({e?.unit?.name})
                    {i !== row.original?.unitPositions?.length - 1 && ', '}
                  </p>
                </li>
              ))}
            </ul>
          )
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return <MembersSectionActionCell route="member" id={row.original.id} />
        },
      },
    ],
    [selectedIds, membersList, control]
  )

  const openModal = () => {
    if (selectedIds.length > 0) {
      setIsOpen(true)
    } else {
      errorToast('Zaznacz przynajmniej jednego członka')
    }
  }
  const closeModal = () => {
    setIsOpen(false)
    setTypeOfRequest('')
    setSelectedIds([])
  }

  useEffect(() => {
    if (selectedRegions === 0 && !isBranch) {
      setSelectedUnits(selectedUnitsInit)
    }
  }, [selectedRegions])

  useEffect(() => {
    if (selectedBranches === 0) {
      setSelectedUnits({ ...selectedUnits, section: [] })
    }
  }, [selectedBranches])

  useEffect(() => {
    if (isRegion) {
      setSelectedUnits({ ...selectedUnitsInit, region: [unitContext.id] })
    } else if (isBranch) {
      setSelectedUnits({ ...selectedUnitsInit, branch: [unitContext.id] })
    } else {
      setSelectedUnits(selectedUnitsInit)
    }
  }, [unitContext?.id, isRegion, isBranch, userToken])

  const listSublabel = isRegion || isBranch ? ` / ${unitContext.name}` : ''
  const actionButtons = isBranch
    ? [
        {
          label: 'Lista wniosków',
          handleClick: () => navigate(path.member.notifications),
        },
      ]
    : []

  const sectionModal = isOpen && typeOfRequest === 'section'
  const cardModal = isOpen && typeOfRequest === 'card'
  const branchModal = isOpen && typeOfRequest === 'branch'
  const allowMassActions = isAdmin || isBranch || isRegion
  const massOptionsList =
    isAdmin || isBranch
      ? [
          { label: 'Przypisz ognisko', value: 'section' },
          { label: 'Przypisz oddział', value: 'branch' },
          { label: 'Zamów legitymację', value: 'card' },
          // { label: 'Dodaj do listy', value: 'list' },
        ]
      : [{ label: 'Zamów legitymację', value: 'card' }]
  if (sectionModal) {
    return (
      <MemberMassSectionModal
        closeModal={closeModal}
        members={selectedIds}
        refetch={refetch.members}
      />
    )
  }
  if (cardModal) {
    return <MemberMassCardModal closeModal={closeModal} members={selectedIds} />
  }
  if (branchModal) {
    return (
      <MemberMassBranchModal
        closeModal={closeModal}
        members={selectedIds}
        refetch={refetch.members}
      />
    )
  }
  return (
    <div>
      <Typography size="xl" weight="semibold">
        Lista członków{listSublabel}
      </Typography>
      <MemberSearchBarAll
        isRegion={isRegion}
        isBranch={isBranch}
        selectedUnits={selectedUnits}
        setSelectedUnits={setSelectedUnits}
        setCustomAgeParam={setCustomAgeParam}
        searchParams={searchParams}
        options={options}
        loadings={loadings}
        query={query}
        refetch={refetch}
        queryHandler={queryHandler}
        searchMore={searchMore}
        limitSetter={setBoxLimit}
      />
      <Card label="Członkowie" actionsButton={actionButtons}>
        {membersList.length > 0 && allowMassActions && (
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <Select
                width="215px"
                handleSelect={(val) => setTypeOfRequest(`${val.value}` as string)}
                withEmpty
                selectLabel="Działania masowe"
                options={massOptionsList}
              />

              <Button label="Zastosuj" variant="secondary" onClick={openModal} />
            </div>
          </div>
        )}
        {!membersLoading && <Table maxColumnWidth="300px" columns={columns} data={membersList} />}
        {membersLoading && <TableSkeleton />}
        {membersList.length > 0 && lastPage > 1 && (
          <div className="flex flex-col justify-end gap-4 md:flex-row">
            <div className="flex items-center gap-4">
              <Typography weight="semibold" className="text-sm">
                Liczba wierszy na stronę
              </Typography>
              <Select
                handleSelect={onLimitHandler}
                options={[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                ]}
              />
            </div>
            {lastPage > 1 && (
              <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
            )}
          </div>
        )}
      </Card>
    </div>
  )
}

export default MemberListFull
