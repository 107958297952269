import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { xor } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { publicBaseApiURL } from 'api'
import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { RadioGroup } from 'components/Atoms/RadioGroup'
import { Spinner } from 'components/Atoms/Spinner'
import { TextField } from 'components/Atoms/TextField'
import { endpoint } from 'constants/endpoints'
import { path } from 'constants/path'
import { mainManagmentCreateSchema } from 'schemas/mainManagmentCreateSchema'
import { setupFilters, slugify } from 'tools/queryHelpers'
import { IFilters } from 'types/form'

interface ISearch {
  [key: string]: string
}

interface IFilterItem {
  id: string
  name: string
  type: string
  subtype: string
  childrenCnt: number
  parent: {
    id: string
    name: string
  }
}

interface CheckedItem {
  type: string
  value: string
  id: string
}

const initSearch = {
  region: '',
  branch: '',
  section: '',
  query: '',
}

export const MainManagmentCreate = () => {
  const [structureType, setStructureType] = useState('')
  const [searchQuery, setSearchQuery] = useState<ISearch>(initSearch)
  const [regionSelected, setRegionSelected] = useState<string[]>([])
  const [branchSelected, setBranchSelected] = useState<string[]>([])
  const [sectionSelected, setSectionSelected] = useState<string[]>([])
  const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([])

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      dateFrom: new Date(),
      dateTo: new Date(),
    },
    resolver: yupResolver(mainManagmentCreateSchema),
  })

  const { data: regionsList } = useSWR(endpoint.structure.regions(1, 100), async (url) => {
    const res = await publicBaseApiURL.get(url)

    return res?.data?.items
  })

  const regionFilterData: IFilters[] = regionSelected
    ? regionSelected.map((r) => {
        return {
          region: r,
        }
      })
    : []

  const regionFilterProps = setupFilters(regionFilterData)

  const { data: branchesList, isLoading: branchesLoading } = useSWR(
    endpoint.structure.branches(1, 1000, regionFilterProps),
    async (url) => {
      if (!regionSelected || !regionSelected.length) return Promise.resolve([])

      const res = await publicBaseApiURL.get(url)
      return res.data.items
    },
    {
      isPaused() {
        return !regionsList?.length
      },
    },
  )

  const { data: sectionsList, isLoading: sectionsLoading } = useSWR(
    endpoint.structure.sectionsUser(1, 1000, branchSelected),
    async (url) => {
      if (!branchSelected || !branchSelected.length) return Promise.resolve([])

      const res = await publicBaseApiURL.get(url)
      return res.data.items
    },
    {
      isPaused() {
        return !branchesList?.length
      },
    },
  )

  const queryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSearchQuery((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const handleCheckAll = (listType: string) => {
    let updatedList: string[] = []
    let itemType = ''

    switch (listType) {
      case 'Okręgi':
        updatedList = regionsList.map((region: { name: string }) => region.name)
        itemType = 'Okręgi'
        break
      case 'Oddziały':
        updatedList = branchesList.map((branch: { name: string }) => branch.name)
        itemType = 'Oddziały'
        break
      case 'Ogniska':
        updatedList = sectionsList.map((section: { name: string }) => section.name)
        itemType = 'Ogniska'
        break
      default:
        break
    }

    setCheckedItems((prevItems) => {
      const updatedItems: CheckedItem[] = updatedList.map((value, index) => ({
        type: itemType,
        value,
        id: `${index}`,
      }))

      return [...prevItems, ...updatedItems]
    })

    switch (listType) {
      case 'Okręgi':
        setRegionSelected(updatedList)
        break
      case 'Oddziały':
        setBranchSelected(updatedList)
        break
      case 'Ogniska':
        setSectionSelected(updatedList)
        break
      default:
        break
    }
  }

  const handleUncheckAll = (listType: string) => {
    switch (listType) {
      case 'Okręgi':
        setRegionSelected([])
        break
      case 'Oddziały':
        setBranchSelected([])
        break
      case 'Ogniska':
        setSectionSelected([])
        break
      default:
        break
    }
  }

  const filterHandler = (type: string, selectedList: string[], list?: IFilterItem[]) => {
    return (list || [])
      .filter((e) => {
        const q = slugify(searchQuery[type])

        const validQuery = slugify(e.name).includes(q)
        const selected = selectedList.includes(e.id)
        return validQuery || selected
      })
      .sort((a, b) => {
        const isSelectedA = selectedList.includes(a.id)
        const isSelectedB = selectedList.includes(b.id)

        if (isSelectedA && !isSelectedB) {
          return -1
        } else if (!isSelectedA && isSelectedB) {
          return 1
        } else {
          return 0
        }
      })
  }

  const handleInputCheck = (
    id: string,
    name: string,
    setList: React.Dispatch<any>,
    itemType: string,
  ) => {
    setList((prev: string[]) => {
      const updatedList = xor(prev, [name])
      setCheckedItems((prevItems) => {
        const updatedItems = [...prevItems, { type: itemType, value: name, id }]
        return updatedItems.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (i) => i.type === item.type && i.value === item.value && i.id === item.id,
            ),
        )
      })
      return updatedList
    })
  }

  const regionFilter = filterHandler('region', regionSelected, regionsList)
  const branchFilter = filterHandler('branch', branchSelected, branchesList)
  const sectionFilter = filterHandler('section', sectionSelected, sectionsList)

  const onSubmit = (data: any) => {
    console.info('data', data)
  }

  return (
    <Card label='Dodaj nowy obowiązek statystyczny'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='w-1/2'>
          <TextField label='Nazwa' {...register('name')} error={errors.name?.message} />
          <div className='mt-5 grid grid-cols-2 gap-5'>
            <Controller
              control={control}
              name='dateFrom'
              render={({ field: { onChange, value } }) => (
                <Calendar date={value} handleDate={(val) => onChange(val)} label='Data startu' />
              )}
            />
            <Controller
              control={control}
              name='dateTo'
              render={({ field: { onChange, value } }) => (
                <Calendar
                  date={value}
                  handleDate={(val) => onChange(val)}
                  label='Data zakończenia'
                />
              )}
            />
          </div>
          <div className='mt-5'>
            <RadioGroup
              label='Dotyczy:'
              defaultChecked='all'
              onChange={(event) => setStructureType(event.target.id)}
              data={[
                { id: 'all', title: 'Wszystkich struktur' },
                { id: 'selected', title: 'Wybranych struktur' },
              ]}
            />
          </div>
        </div>

        {structureType === 'selected' && (
          <div className='mt-6 grid grid-cols-3 gap-4'>
            <div>
              <div className='flex gap-2'>
                <div className='flex-1'>
                  <TextField label='Okręgi' type='text' name='region' onChange={queryHandler} />
                </div>
              </div>
              <div className='mb-2 mt-4 flex gap-4'>
                <Button
                  type='button'
                  label='zaznacz wszystkie'
                  onClick={() => handleCheckAll('Okręgi')}
                />

                <Button
                  type='button'
                  label='odznacz wszystkie'
                  onClick={() => handleUncheckAll('Okręgi')}
                />
              </div>
              <div>
                <ul
                  className={`h-56 rounded-md border border-solid border-gray-300 ${
                    regionFilter?.length !== 0 ? 'overflow-y-scroll' : ''
                  }`}
                >
                  {regionFilter?.length ? (
                    regionFilter.map((e: any) => {
                      return (
                        <li
                          key={e.id}
                          className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                        >
                          <Checkbox
                            label={e.name}
                            id={e.id}
                            value={e.id}
                            checked={regionSelected.includes(e.name)}
                            onChange={() => {
                              handleInputCheck(e.id, e.name, setRegionSelected, 'Okręgi')
                            }}
                          />
                        </li>
                      )
                    })
                  ) : (
                    <li className='flex gap-2 border-b-2 border-solid border-gray-300 px-3 py-2 text-sm'>
                      No data
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div>
              <div className='flex gap-2'>
                <div className='flex-1'>
                  <TextField
                    disabled={!regionSelected.length}
                    label='Oddziały'
                    type='text'
                    name='branch'
                    onChange={queryHandler}
                  />
                </div>
              </div>
              <div className='mb-2 mt-4 flex gap-4'>
                <Button
                  type='button'
                  disabled={!regionSelected.length}
                  label='zaznacz wszystkie'
                  onClick={() => handleCheckAll('Oddziały')}
                />

                <Button
                  type='button'
                  disabled={!regionSelected.length}
                  label='odznacz wszystkie'
                  onClick={() => handleUncheckAll('Oddziały')}
                />
              </div>
              <div>
                <ul
                  className={`h-56 rounded-md border border-solid border-gray-300 ${
                    branchFilter?.length !== 0 ? 'overflow-y-scroll' : ''
                  }`}
                >
                  {branchFilter?.length ? (
                    (branchFilter || []).map((e: any) => {
                      return (
                        <li
                          key={e.id}
                          className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                        >
                          <Checkbox
                            label={e.name}
                            id={e.id}
                            value={e.id}
                            checked={branchSelected.includes(e.name)}
                            onChange={() => {
                              handleInputCheck(e.id, e.name, setBranchSelected, 'Oddziały')
                            }}
                          />
                        </li>
                      )
                    })
                  ) : (
                    <li className='flex gap-2 border-b-2 border-solid border-gray-300 px-3 py-2 text-sm'>
                      No data
                    </li>
                  )}
                </ul>
                {branchesLoading && <Spinner />}
              </div>
            </div>
            <div>
              <div className='flex gap-2'>
                <div className='flex-1'>
                  <TextField
                    disabled={!branchSelected.length}
                    label='Ogniska'
                    type='text'
                    name='section'
                    onChange={queryHandler}
                  />
                </div>
              </div>
              <div className='mb-2 mt-4 flex gap-4'>
                <Button
                  type='button'
                  label='zaznacz wszystkie'
                  disabled={!branchSelected.length}
                  onClick={() => handleCheckAll('Ogniska')}
                />

                <Button
                  type='button'
                  disabled={!branchSelected.length}
                  label='odznacz wszystkie'
                  onClick={() => handleUncheckAll('Ogniska')}
                />
              </div>
              <div>
                <ul
                  className={`h-56 rounded-md border border-solid border-gray-300 ${
                    sectionFilter?.length !== 0 ? 'overflow-y-scroll' : ''
                  }`}
                >
                  {sectionFilter?.length ? (
                    sectionFilter.map((e: any) => {
                      return (
                        <li
                          key={e.id}
                          className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                        >
                          <Checkbox
                            label={e.name}
                            id={e.id}
                            value={e.id}
                            checked={sectionSelected.includes(e.name)}
                            onChange={() => {
                              handleInputCheck(e.id, e.name, setSectionSelected, 'Ogniska')
                            }}
                          />
                        </li>
                      )
                    })
                  ) : (
                    <li className='flex gap-2 border-b-2 border-solid border-gray-300 px-3 py-2 text-sm'>
                      No data
                    </li>
                  )}
                </ul>
                {sectionsLoading && <Spinner />}
              </div>
            </div>
          </div>
        )}

        <div className='mt-5 flex justify-center'>
          <div className='mr-5'>
            <Button
              label='Anuluj'
              variant='secondary'
              onClick={() => navigate(path.statisticalObligation.mainManagment.list)}
            />
          </div>
          <Button label='Utwórz' />
          <Button
            className='ml-10'
            onClick={() => console.info({ checkedItems })}
            label='Wyświetl pozycje'
          />
        </div>
      </form>
    </Card>
  )
}
