import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import LoadingModal from 'components/Atoms/LoadingModal'
import { RadioGroup } from 'components/Atoms/RadioGroup'
import SearchAndFetchMember from 'components/Atoms/SearchAndFetchMember/SearchAndFetchMember'
import { TextareaField } from 'components/Atoms/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { errorMessage } from 'constants/errorMessage'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { cardErrors } from 'error-data/card'
import { createCard, getCardReqTypeDict } from 'fetchers/cardFetchers'
import { getMembersList } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { cardForm } from 'schemas/cardSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler } from 'tools/queryHelpers'
import { errorRequires, successToast } from 'tools/ToastHelpers'

const CardCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const [member, setMember] = useState<any>(null)
  const [fullName, setFullName] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const { userToken, userLoaded, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const { setParam, param, searchQuery, filters, page } = useSearch({
    simpleParams: ['searchExtended'],
  })
  const navigate = useNavigate()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      memberId: '',
      type: 'new',
      comment: '',
    },
    resolver: yupResolver(cardForm),
  })

  // REQUEST TYPE
  const {
    data: reqType,
    isLoading: reqTypeLoading,
    error: reqTypeError,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardReqTypeDict],
    queryFn: () => getCardReqTypeDict(),
    retry: useRetryHandler({
      resourceName: 'CardCreate reqType',
      maxRetries: 1,
    }),
  })
  const reqTypeList = reqType?.items
    ? reqType?.items?.map((item: any) => {
        return { title: item.name, id: item.id }
      })
    : []

  // MEMBERS LOADER
  const setupFilters = fullName ? filters + '&unredacted=true' : filters
  const { data: members, isLoading: membersIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.membersList, page, perPage, setupFilters],
    queryFn: () => {
      if (!param.single.searchExtended) return { items: [] }
      return getMembersList(page, perPage, setupFilters)
    },
    retry: useRetryHandler({
      resourceName: 'CardCreate member',
      maxRetries: 1,
    }),
  })
  // Setup members list
  const membersList = members?.items
    ? members?.items?.map((item: any) => {
        const labelArray = [`${item?.firstName} ${item?.lastName}`]
        if (item?.card?.number) labelArray.push(item?.card?.number)
        if (item?.region?.name) labelArray.push(item?.region?.name)
        if (item?.branch?.name) labelArray.push(item?.branch?.name)
        const label = labelArray.join(' | ')
        return { label: label, value: item.id }
      })
    : []
  // Setup member loading state
  const membersLoading = membersIsLoading || !userLoaded
  // END MEMBERS LOADER

  // SEND REQUEST
  const mutate = useMutation({
    mutationFn: (data: any) => createCard(data.memberId, data.type, data.comment),
    onSuccess: () => {
      successToast('Wniosek został wysłany.')
      setMember(null)
      setFullName(false)
      navigate(path.card.request.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, cardErrors.create, 'Nie udało się wysłać wniosku')
    },
  })

  const searchMore = () => {
    const count =
      members?.pagination?.countFiltered > 0
        ? members?.pagination?.countFiltered
        : members?.pagination?.count
    if (!isLoading && members?.items?.length < count) setPerPage((prev) => prev + 10)
  }

  const setQuery = (e: string) => setParam.single('searchExtended', e)
  const searchHandler = (e: any) => {
    e.preventDefault()
    searchQuery()
  }

  const setRequestType = (e: string) => {
    setValue('type', e)
  }

  const onValidationFailure = () => {
    const data = getValues()
    console.error('errors:', errors)
    errorRequires(errors)
  }

  const onSubmitHandler = async (data: any) => {
    try {
      setIsSending(true)
      mutate.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się wysłać wniosku')
    }
  }
  const noMember = errors.memberId?.message === errorMessage.requiredField

  const isLoading = reqTypeLoading
  const loadingHandler = getLoadingHandler(reqTypeError, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Wniosek o legitymację
      </Typography>

      <div className='mt-5 bg-white p-5'>
        <div className='mx-auto flex max-w-3xl flex-col gap-4 py-12'>
          <form onSubmit={searchHandler}>
            <label htmlFor='userSearch' className='mb-1 block text-sm font-medium text-gray-700'>
              Szukaj osoby po numerze legitymacji, imieniu lub nazwisku:
            </label>
            <div className='mb-3 flex gap-4'>
              <input
                className='block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                type='search'
                placeholder='Numer legitymacji, imię lub nazwisko:'
                name='userSearch'
                id='userSearch'
                value={String(param.single.searchExtended || '')}
                onChange={(e) => setParam.single('searchExtended', e.target.value)}
              />
              <Button type='submit' label='Szukaj' />
            </div>
            <Checkbox
              label={'Pokaż pełne dane'}
              id={'fullName'}
              // value={fullName}
              checked={fullName}
              onChange={() => setFullName((f) => !f)}
            />
            <div className='my-3'>
              <Typography size='sm'>
                {member ? (
                  <>
                    <span className='font-semibold'>Wybrano członka:</span> {member}
                  </>
                ) : (
                  <span className='font-semibold'>Wybierz członka:</span>
                )}
              </Typography>
            </div>

            <SearchAndFetchMember
              setQuery={setQuery}
              externalChangeHandler={setMember}
              searchMore={searchMore}
              isLoading={membersLoading}
              name={'memberId'}
              register={register}
              options={membersList}
              filters={filters}
              control={control}
            />
          </form>
          <p className={clsx({ 'mb-2 text-sm text-red-600': noMember }, 'mb-2')}>
            {noMember ? 'Należy wskazać członka, którego dotyczy wniosek' : ''}
          </p>
          <RadioGroup
            label={'Typ wniosku'}
            defaultChecked='new'
            onChange={(e) => setRequestType(e.target.value)}
            data={reqTypeList}
          />

          <div>
            <label
              htmlFor='cardRequestReason'
              className='mb-1 block text-sm font-medium text-gray-700'
            >
              Opisz powód
            </label>
            <TextareaField error={errors.comment?.message} {...register('comment')} />
          </div>
          <div className='flex justify-center gap-4'>
            <Button
              label='Anuluj'
              variant='secondary'
              onClick={() => navigate(path.card.request.list)}
            />
            <Button
              disabled={isSending}
              onClick={handleSubmit(onSubmitHandler, onValidationFailure)}
              label='Wyślij wniosek'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardCreate
