import { useState } from 'react'

import { ArchiveModal as Modal } from 'components/Atoms/ArchiveModal'
import ArchiveModalContext from 'components/Atoms/ArchiveModal/ArchiveModalContext'
import { IListElement } from 'types/form'

type Props = {
  headerLabel: string
  executeArchive: (id: string) => Promise<void>
}

export const useArchive = ({ headerLabel, executeArchive }: Props) => {
  const [isSending, setIsSending] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [archiveData, setArchiveData] = useState({ id: '', context: <></> })

  const hideModalHandler = () => setShowModal(false)

  const showModalHandler = ({ id, name, parent }: IListElement) => {
    setArchiveData({
      id: id,
      context: (
        <ArchiveModalContext
          name={name}
          parentName={parent?.name} // Using parentNameLabel here
        />
      ),
    })
    setShowModal(true)
  }

  const archiveHandler = async () => {
    setIsSending(true)
    const res = await executeArchive(archiveData.id)
    // if (refetch) await refetch()
    // if (navigatePath) navigate(navigatePath)
    setShowModal(false)
    setIsSending(false)
  }

  const ArchiveModal = () => (
    <Modal
      isSending={isSending}
      showModal={showModal}
      data={archiveData}
      headerLabel={headerLabel}
      archiveHandler={archiveHandler}
      hideModal={hideModalHandler}
    />
  )

  return { ArchiveModal, showModalHandler }
}

export default useArchive
