import { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { exportOrdersErorrs } from 'error-data/orders'
import { archiveMember } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'

const MemberArchive = () => {
  const [memberRemoveDescription, setMemberRemoveDescription] = useState('')
  const [memberRemoveDate, setMemberRemoveDate] = useState('')
  const [memberRemoveReason, setMemberRemoveReason] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useAuth()
  const cardId = id
  const { control } = useForm()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: string
      data: { comment: string; date: string; reason: string }
    }) => archiveMember(cardId, data),
    onSuccess: () => {
      successToast('Pomyślnie usunięto członka.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberArchive] })
      navigate(path.member.list)
      setIsLoading(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, exportOrdersErorrs.archive, 'Nie udało się usunąć członka.')
      setIsLoading(false)
    },
  })

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault()

    if (!memberRemoveDescription.trim()) {
      errorToast('Komentarz jest wymagany.')
      return
    }
    if (!memberRemoveDate.trim()) {
      errorToast('Data usunięcia jest wymagana.')
      return
    }
    if (!memberRemoveReason.trim()) {
      errorToast('Powód usunięcia jest wymagany.')
      return
    }

    const exportData = {
      comment: memberRemoveDescription,
      date: memberRemoveDate,
      reason: memberRemoveReason,
    }

    setIsLoading(true)
    mutation.mutate({ id: cardId, data: exportData })
  }

  return (
    <div className='mx-auto max-w-2xl'>
      <Card label='Usuń członka'>
        <form onSubmit={submitHandler}>
          <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
            <div className='md:col-span-4'>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='memberRemoveDescription'
              >
                Komentarz do usunięcia
              </label>
              <textarea
                id='memberRemoveDescription'
                name='memberRemoveDescription'
                value={memberRemoveDescription}
                onChange={(e) => {
                  setMemberRemoveDescription(e.target.value)
                }}
                aria-rowspan={4}
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                placeholder='Opisz powód usunięcia członka'
              ></textarea>
            </div>
            <div className='flex gap-2 md:col-span-2'>
              <div className='flex-1'>
                <Controller
                  name='dateStart'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Calendar
                      date={value}
                      handleDate={(val) => {
                        onChange(val)
                        setMemberRemoveDate(new Date(val).toISOString().split('T')[0])
                      }}
                      label='Data usunięcia'
                    />
                  )}
                />
              </div>
            </div>
            <fieldset className='md:col-span-2'>
              <legend className='mb-1 block text-sm font-medium text-gray-700'>
                Powód usunięcia członka
              </legend>
              <div className='space-y-2'>
                <div className='flex items-center gap-x-3'>
                  <input
                    id='reason-death'
                    name='reason'
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    value='death'
                    onChange={(e) => setMemberRemoveReason(e.target.value)}
                  />
                  <label
                    htmlFor='reason-death'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Zgon
                  </label>
                </div>
                <div className='flex items-center gap-x-3'>
                  <input
                    id='reason-leaving'
                    name='reason'
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    value='leaving'
                    onChange={(e) => setMemberRemoveReason(e.target.value)}
                  />
                  <label
                    htmlFor='reason-leaving'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Odejście
                  </label>
                </div>
                <div className='flex items-center gap-x-3'>
                  <input
                    id='reason-other'
                    name='reason'
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    value='other'
                    onChange={(e) => setMemberRemoveReason(e.target.value)}
                  />
                  <label
                    htmlFor='reason-other'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Inny
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <hr className='my-4' />
          <div className='mt-4 flex justify-center'>
            <Button
              variant='secondary'
              label='Anuluj'
              className='mr-4'
              onClick={(e) => {
                e.preventDefault()
                navigate(`/member/${id}`)
              }}
            />
            <Button
              label={
                isLoading ? (
                  <>
                    Usuwanie członka <span className='loader ml-1'></span>
                  </>
                ) : (
                  'Usuń członka'
                )
              }
            />
          </div>
        </form>
      </Card>
    </div>
  )
}

export default MemberArchive
