import {
  BuildingOffice2Icon,
  MapPinIcon,
  PhoneIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/20/solid'
import { EnvelopeClosedIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/shadcn/ui/button'
import { Card, CardHeader, CardTitle, CardContent } from 'components/shadcn/ui/card'
import { IListElement } from 'types/form'

const iconProps = {
  className: 'min-w-4 h-4 w-4 text-gray-500',
}

interface IProps {
  data: any
  showModal: (data: IListElement) => void
}

export const InstitutionBaseInfo: React.FC<IProps> = ({ data, showModal }) => {
  // const navigate = useNavigate()
  // const { address } = data
  // const street = address
  //   ? `${address.streetName || '-'} ${address.houseNr || ''}${
  //       address.apartmentNr ? ` / ${address.apartmentNr}` : ''
  //     }`
  //   : '-'

  const navigate = useNavigate()
  const isArchived = data?.archived ?? false

  interface ActionButton {
    label: string
    handleClick?: () => void
    disabled?: boolean
    variant: 'secondary' | 'default' | 'success' | 'link' | 'destructive' | 'outline' | 'ghost'
  }

  console.log({ data })

  const actionButtons: ActionButton[] = !isArchived
    ? [
        {
          label: 'Edytuj',
          handleClick: () => navigate(`/institution/${data.id}/update`),
          variant: 'default',
        },
        {
          label: 'Archiwizuj',
          variant: 'secondary',
          handleClick: () => showModal(data),
        },
      ]
    : []

  return (
    //   <Card
    //   label='Dane ogólne'
    //   actionsButton={[
    //     {
    //       label: 'Archiwizuj',
    //       handleClick: () => showModal(data),
    //     },
    //     {
    //       label: 'Edytuj',
    //       handleClick: () => navigate('update'),
    //     },
    //   ]}
    // >
    //   <div className='mb-5 grid grid-cols-4 gap-5'>
    //     <LabelTypography label='Nazwa placówki' text={data?.name} />
    //     <LabelTypography label='Typ placówki' text={data?.type?.name} />
    //     <LabelTypography
    //       label='Prezes placówki'
    //       text={`${data?.headmaster?.firstName ? data.headmaster.firstName : ''} ${
    //         data?.headmaster?.lastName ? data?.headmaster?.lastName : ''
    //       }`}
    //     />
    //   </div>
    //   <div className='mb-5 grid grid-cols-4 gap-5'>
    //     <LabelTypography
    //       label='Telefon'
    //       text={`${data?.headmaster?.contact?.phone ? data.headmaster.contact.phone : ''}`}
    //     />
    //     <LabelTypography
    //       label='Email kontaktowy'
    //       text={`${data?.headmaster?.contact?.email ? data.headmaster.contact.email : ''}`}
    //     />
    //   </div>
    //   <Typography size='xl' className='mb-3 mt-5'>
    //     Dane adresowe
    //   </Typography>
    //   <div className='grid grid-cols-4 gap-5'>
    //     <LabelTypography label='Miejscowość' text={data?.address?.city} />
    //     <LabelTypography label='Województwo' text={data?.voivodeship?.name} />
    //     <LabelTypography label='Kod pocztowy' text={data?.address?.postalCode} />
    //     <LabelTypography label='Ulica' text={street} />
    //   </div>
    // </Card>

    <Card className="w-full">
      <CardHeader className="">
        <div className="flex items-center justify-between">
          <CardTitle className="flex items-center gap-2">
            <BuildingOffice2Icon className="h-6 w-6" />
            {data.composedName || data.name}
          </CardTitle>
          <span className="text-sm leading-6">Placówka {data.type.name}</span>
        </div>
      </CardHeader>
      <CardContent className="grid grid-cols-1 gap-4 px-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="space-y-2">
          <h3 className="pb-2 text-lg font-semibold">Adres</h3>
          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <MapPinIcon {...iconProps} />
              <span>
                {data.address.streetName} {data.address.houseNr}
                {data.address.apartmentNr && `/${data.address.apartmentNr}`}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <BuildingOffice2Icon {...iconProps} />
              <span>
                {data.address.postalCode} {data.address.city}
              </span>
            </div>
          </div>
        </div>

        {data.headmaster && (
          <div className="space-y-2">
            <h3 className="pb-2 text-lg font-semibold">Prezes</h3>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <UserIcon {...iconProps} />
                <span>
                  {data.headmaster.firstName} {data.headmaster.lastName}
                </span>
              </div>
              {data.headmaster.contact.email && (
                <div className="flex items-center gap-2">
                  <EnvelopeClosedIcon {...iconProps} />
                  <span>{data.headmaster.contact.email}</span>
                </div>
              )}
              {data.headmaster.contact.phone && (
                <div className="flex items-center gap-2">
                  <PhoneIcon {...iconProps} />
                  <span>{data.headmaster.contact.phone}</span>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="space-y-4">
          {data.groups?.length > 0 && (
            <div className="space-y-2">
              <h3 className="pb-2 text-lg font-semibold">Grupy</h3>
              <div className="grid grid-cols-1 gap-2">
                {data.groups.map((group: any) => (
                  <div className="flex items-center gap-2" key={group.id}>
                    <UsersIcon {...iconProps} />
                    {group.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {data.branch && (
          <div>
            <h3 className="pb-2 text-lg font-semibold">Oddział</h3>
            <Button
              variant="ghost"
              className="mt-2 flex w-full items-center justify-start gap-2"
              onClick={() => navigate(`/branch/${data.branch.id}`)}
            >
              <BuildingOffice2Icon {...iconProps} />
              {data.branch.name}
            </Button>
          </div>
        )}

        <div className="col-span-full">
          {actionButtons.length > 0 && (
            <div className="flex gap-2 pt-4">
              {actionButtons.map((button) => (
                <Button
                  key={button.label}
                  onClick={button.handleClick}
                  // disabled={button.disabled}
                  variant={button.variant}
                >
                  {button.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default InstitutionBaseInfo
