import { UserDropdown } from 'components/Atoms/UserDropdown'

import GlobalSearchBar from '../../GlobalSearchBar/GlobalSearchBar'
import { Notification } from '../Notification'

type Props = {
  email: string
  toggleSidebar: () => void
  isSidebarVisible: boolean
}

export const TopHeader = ({ email, toggleSidebar, isSidebarVisible }: Props) => {
  return (
    <div className="sticky top-0 z-10 flex shrink-0 items-center gap-x-2 border-b border-gray-200 bg-white p-3 px-4 shadow-sm sm:gap-x-4 lg:px-6 xl:px-8">
      <button
        type="button"
        className={`rounded-md p-2.5 text-gray-600 hover:bg-gray-100 ${
          isSidebarVisible ? 'hidden' : 'block'
        }`}
        onClick={toggleSidebar}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth={1.5}
          fill="currentColor"
          className="h-5 w-5"
          viewBox="0 0 16 16"
        >
          <path d="M3.5 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM3 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm12-1v14h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm-1 0H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h9z" />
        </svg>
      </button>
      <div className="flex flex-1 items-center justify-between gap-x-4 lg:gap-x-6">
        <GlobalSearchBar />
        <div className="flex items-center justify-center gap-2">
          <Notification />
          <UserDropdown email={email} />
        </div>
      </div>
    </div>
  )
}
