import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'

type Props = {
  isOpen: boolean
  closeHandler: () => void
  deleteHandler: () => void
}

export const MemberSectionRemoveModal = ({ isOpen, closeHandler, deleteHandler }: Props) => {
  const deleteUser = () => {
    deleteHandler()
    closeHandler()
  }
  return (
    <Modal isOpen={isOpen} handleClose={closeHandler}>
      <Card label='Usunąć użytkownika z sekcji/ ogniska?' className='p-5'>
        <div className='flex items-center justify-center gap-5'>
          <Button label='Anuluj' variant='secondary' onClick={closeHandler} />
          <Button type='button' label='Usuń' onClick={deleteUser} />
        </div>
      </Card>
    </Modal>
  )
}

export default MemberSectionRemoveModal
