import { useState } from 'react'

import { Switch } from '@headlessui/react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { path } from 'constants/path'
import { setupDate } from 'tools/formTools'
import { errorRequires } from 'tools/ToastHelpers'

type Props = {
  closeHandler?: () => void
  onSubmit: (data: any) => void
  isSending?: boolean
  label?: string
  isModal?: boolean
  initData: {
    startedAt?: Date
    endedAt?: Date
  }
}

export const BranchFunctionUpdateForm = ({
  onSubmit,
  closeHandler,
  isModal,
  label = '',
  isSending,
  initData,
}: Props) => {
  const [ended, setEnded] = useState(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: initData,
  })
  const navigate = useNavigate()

  const cancelHandler = () => {
    if (isModal && closeHandler) {
      closeHandler()
    } else {
      navigate(path.branchFunctions.list)
    }
  }
  const onValidationFailure = () => errorRequires(errors)
  const containerClass = isModal
    ? 'grid grid-cols-2 gap-5 mb-5'
    : 'grid grid-cols-3 gap-5 mb-5 w-1/2'
  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      <Card label={`Zaktualizuj kadencję ${label}`}>
        <div className={containerClass}>
          <Controller
            name='startedAt'
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <Calendar
                  date={value}
                  handleDate={(val) => onChange(val)}
                  label='Data rozpoczęcia kadencji'
                />
                <p className='mt-2 text-xs font-semibold opacity-50'>
                  Aktualna data: {setupDate(initData?.startedAt)}
                </p>
              </div>
            )}
          />

          <Controller
            name='endedAt'
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className='flex items-center gap-2'>
                <Switch
                  checked={ended}
                  onChange={(val) => onChange(setEnded((s) => !s))}
                  className={`${
                    ended ? 'bg-indigo-600' : 'bg-gray-200'
                  } relative inline-flex h-6 w-6 shrink-0 items-center rounded-full`}
                />
                <div className='flex flex-col'>
                  <Calendar
                    disabled={!ended}
                    date={value}
                    handleDate={(val) => onChange(val)}
                    label='Data zakończenia kadencji'
                  />
                  <p className='mt-2 text-xs font-semibold opacity-50'>
                    Aktualna data: {setupDate(initData?.endedAt)}
                  </p>
                </div>
              </div>
            )}
          />
        </div>
        <div className='mt-5 flex justify-center gap-5'>
          <Button label='Anuluj' variant='secondary' onClick={cancelHandler} />
          <Button disabled={isSending} label='Zatwierdź' type='submit' />
        </div>
      </Card>
    </form>
  )
}

export default BranchFunctionUpdateForm
