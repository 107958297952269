import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { Select } from 'components/Atoms/Select'
import { IOption } from 'types/form'

type Props = {
  isEdit?: boolean
  isLoading?: boolean
  setUnit: (e: string) => void
  register: any
  control: any
  options: IOption[]
  setType: (value: string) => void
  searchMore: () => void
}

export const LegalSelector = ({
  isEdit,
  setUnit,
  setType,
  searchMore,
  isLoading,
  register,
  control,
  options,
}: Props) => {
  const typeOptions = [
    { value: 'region', label: 'Okręg' },
    { value: 'branch', label: 'Oddział' },
    { value: 'section', label: 'Ognisko' },
  ]

  return (
    <div className='mb-4 grid grid-cols-5 gap-5'>
      {isEdit ? (
        <Select
          handleSelect={(value: IOption) => setType(String(value.value))}
          options={typeOptions}
          withEmpty
          selectLabel='Zmień typ jednostki'
          label='Typ'
        />
      ) : (
        <Select
          defaultOption={typeOptions[0]}
          handleSelect={(value: IOption) => setType(String(value.value))}
          options={typeOptions}
          label='Typ'
        />
      )}
      <SearchAndFetchInput
        searchMore={searchMore}
        isLoading={isLoading}
        externalChangeValue={setUnit}
        name={'division'}
        label={'Wybierz oddział docelowy'}
        register={register}
        options={options}
        control={control}
      />
    </div>
  )
}

export default LegalSelector
