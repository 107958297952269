import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Typography } from 'components/Atoms/Typography'
import ErrorCard from 'components/ErrorCard'
import UnitPositonsList from 'components/UnitPositons/UnitPositonsList'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getStructureTypesList } from 'fetchers/structureFetcher'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import { structureArrayToObject } from 'tools/dictionaryHelper'
import { wipMessage } from 'tools/ToastHelpers'

export const UnitPositions = () => {
  const navigate = useNavigate()
  const cancelHandler = () => navigate(path.dashboard)
  const archiveHandler = () => wipMessage()
  const addNewTypeHandler = () => navigate(path.structure.unitPositions.create)
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.unitPositionsList],
    queryFn: () => getUnitPositionsList(1, 100),
  })
  const {
    data: structureTypes,
    isLoading: isLoadingST,
    error: errorST,
  } = useQuery({
    queryKey: [userToken, queryKeys.baseStructures],
    queryFn: () => getStructureTypesList(1, 100),
  })

  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania listy funkcji'}
        onCancel={cancelHandler}
        buttonLabel={'Dashboard'}
      />
    )
  }
  const getStructureType = structureTypes?.structure || []
  const structureTypesData = structureArrayToObject(getStructureType)
  const unitPositions =
    data?.items && getStructureType.length > 0
      ? data.items
          .map((item: any) => ({
            ...item,
            label: structureTypesData[item.unitType],
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label))
      : []
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        Struktura ZNP / Funkcje w strukturze
      </Typography>
      <Card
        label='Lista funkcji'
        actionsButton={[
          {
            label: 'Dodaj funkcję',
            handleClick: addNewTypeHandler,
          },
          {
            label: 'Zarchiwizowane funkcje',
            handleClick: archiveHandler,
            disabled: true,
          },
        ]}
      >
        <>
          {isLoading ? (
            <LoadingSection />
          ) : (
            <UnitPositonsList
              structureTypesData={structureTypesData}
              unitPositions={unitPositions}
            />
          )}
        </>
      </Card>
    </section>
  )
}

export default UnitPositions
