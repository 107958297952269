import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import ErrorRouteModal from 'components/Atoms/ErrorRouteModal'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import UnitPositionsForm from 'components/UnitPositons/UnitPositionsForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { unitPositionErrors } from 'error-data/unit'
import { getStructureTypesList } from 'fetchers/structureFetcher'
import { getTermLength, getUnitPosition, updateUnitPosition } from 'fetchers/unitPositionFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { dataToSelect } from 'tools/mapData'
import { errorForbiddenHandler, errorNotExistsHandler, errorQuery } from 'tools/queryHelpers'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IUnitPosition, IUnitPositionData } from 'types/unitPosition'

export const UnitPositionsUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const listPath = path.structure.unitPositions.list
  const { id } = useParams()
  const pathId = id as string

  const {
    data: unitPosition,
    isLoading: isLoading,
    error: error,
  } = useQuery({
    queryKey: [userToken, queryKeys.unitPosition, pathId],
    queryFn: () => getUnitPosition(pathId),
    retry: errorQuery,
  })
  const notExists = errorNotExistsHandler(error)
  const forbiddenBranch = errorForbiddenHandler(error)

  const {
    data: termOfOffice,
    isLoading: isLoadingTOF,
    error: errorTOF,
  } = useQuery({
    queryKey: [userToken, queryKeys.termOfOffice],
    queryFn: () => getTermLength(),
  })
  const {
    data: structureTypes,
    isLoading: isLoadingST,
    error: errorST,
  } = useQuery({
    queryKey: [userToken, queryKeys.baseStructures],
    queryFn: () => getStructureTypesList(1, 100),
  })

  const termOfOfficeData = dataToSelect(termOfOffice?.items)
  const structureTypesData = dataToSelect(structureTypes?.structure)
  const defaultStructureType = structureTypesData.find(
    (item) => item.value === unitPosition?.unitType,
  )
  const defaultTermUnit = termOfOfficeData.find(
    (item) => item.value === unitPosition?.lengthOfTermUnit,
  )
  const mutation = useMutation({
    mutationFn: (data: IUnitPosition) => updateUnitPosition(pathId, data),
    onSuccess: () => {
      successToast('Zaktualizowano funkcję')
      queryClient.invalidateQueries({ queryKey: [queryKeys.unitPositionsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, unitPositionErrors.update, 'Nie udało się zaktualizować funkcji.')
    },
  })

  const onSubmit = async (data: IUnitPositionData) => {
    try {
      const { id, ...formData } = data
      setIsSending(true)
      mutation.mutate(formData)
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować funkcji.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const initData: IUnitPosition = {
    name: unitPosition?.name,
    unitType: unitPosition?.unitType,
    maxNumber: unitPosition?.maxNumber,
    lengthOfTerm: unitPosition?.lengthOfTerm,
    lengthOfTermUnit: unitPosition?.lengthOfTermUnit,
  }

  if (isLoading || isLoadingST || isLoadingTOF) return <LoadingSection />
  if (notExists) return <ErrorRouteModal />
  if (forbiddenBranch) return <ErrorRoleModal />
  return (
    <UnitPositionsForm
      defaultStructureType={defaultStructureType}
      defaultTermUnit={defaultTermUnit}
      termOfOfficeData={termOfOfficeData}
      structureTypesData={structureTypesData}
      initData={initData}
      onSubmit={onSubmit}
      isSending={isSending}
      onCancelHandler={cancelHandler}
    />
  )
}

export default UnitPositionsUpdate
