import React from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { archiveOrder, getOrdersIssuedList } from 'fetchers/ordersFetchers'
import { useLastPage } from 'hooks/useLastPage'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption } from 'types/form'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const OrdersIssued = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const { userToken, unitContext } = useAppSelector((state: any) => state.authReducer)
  const id = unitContext?.id
  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const navigate = useNavigate()

  const {
    data: ordersIssuedList,
    isLoading: ordersIssuedLoading,
    refetch: ordersIssuedRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.ordersIssuedList, filters, perPage, page, id],
    queryFn: () => getOrdersIssuedList(page, perPage, filters),
    retry: useRetryHandler({
      resourceName: 'OrdersIssued ordersIssuedList',
      maxRetries: 1,
    }),
  })

  const lastPage = useLastPage(ordersIssuedList?.pagination, ordersIssuedLoading)
  const generatedExportsList = ordersIssuedList?.items || []

  const onLimitHandler = (e: IOption) => setLimit(e.value)

  const archiveMutation = useMutation({
    mutationFn: (id: string) => archiveOrder(id),
    onSuccess: () => {
      successToast('Polecenie zostało zarchiwizowane.')
      ordersIssuedRefetch()
      setIsLoading(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      errorToast(`Nie udało się zarchiwizować polecenia. ${error}`)
      setIsLoading(false)
    },
  })

  const archive = (id: string, name: string) => {
    const isConfirmed = confirm(`Czy na pewno chcesz zarchiwizować ${name}?`)

    if (!isConfirmed) {
      return
    }
    setIsLoading(true)
    archiveMutation.mutate(id)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa polecenia',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.name
        },
      },
      {
        Header: 'Treść polecenia',
        accessor: 'content',
        Cell: ({ row }: any) => {
          return row.original?.content
        },
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          return row.original?.createdAt.slice(0, 10)
        },
      },
      {
        Header: 'Status Odczytu',
        accessor: 'readStatus',
        Cell: ({ row }: any) => {
          const { readNumber, noReadNumber } = row.original
          return `${readNumber} / ${noReadNumber}`
        },
      },
      {
        Header: 'Status Potwierdzenia',
        accessor: 'ackStatus',
        Cell: ({ row }: any) => {
          const { ackNumber, noAckNumber } = row.original
          return `${ackNumber} / ${noAckNumber}`
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz polecenie'
                onClick={() => navigate(`/orders/issued/${row.original.id}`)}
              />
              <HoverIcon
                iconName='InboxArrowDownIcon'
                title='Zarchiwizuj polecenie'
                onClick={() => archive(row.original.id, row.original.name)}
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  if (isLoading || ordersIssuedLoading) {
    return (
      <Card label='Szczegóły otrzymanego polecenia'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <Card
      label='Lista poleceń wysłanych'
      actionsButton={[
        {
          label: 'Utwórz polecenie',
          handleClick: () => navigate(path.orders.create),
        },
      ]}
    >
      <div className='flex items-center justify-between gap-4'>
        {lastPage && (
          <>
            <div className='flex flex-col justify-end gap-4 md:flex-row'>
              <div className='flex items-center gap-4'>
                <Typography weight='semibold' className='text-sm'>
                  Liczba wierszy na stronę
                </Typography>
                <Select
                  handleSelect={onLimitHandler}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20 ', value: 20 },
                  ]}
                />
              </div>
            </div>

            <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
              {lastPage > 1 && (
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              )}
            </span>
          </>
        )}
      </div>
      <Table maxColumnWidth='300px' columns={columns} data={generatedExportsList || []} />
      {ordersIssuedLoading && <TableSkeleton />}
    </Card>
  )
}

export default OrdersIssued
