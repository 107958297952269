import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import ErrorCard from 'components/ErrorCard'
import SettingsFormBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { subjectErrors } from 'error-data/subject'
import { getSubject, updateSubject } from 'fetchers/subjectFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

const MemberSubjectsUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const listPath = path.settings.subject.list
  const pathId = id as string

  const {
    data: subject,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.subject, pathId],
    queryFn: () => getSubject(pathId),
  })

  const mutation = useMutation({
    mutationFn: (data: INameForm) => updateSubject(subject?.id, data),
    onSuccess: () => {
      successToast('Zaktualizowano przedmiot')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.subjectsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, subjectErrors.create, 'Nie udało się zaktualizować przedmiotu.')
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować przedmiotu.')
    }
  }

  const cancelHandler = () => navigate(listPath)

  const breadcrumbLink = (
    <>
      <Link to={listPath}>Przedmioty</Link> / Edytuj
    </>
  )

  if (isLoading) return <LoadingSection />

  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania przedmiotu'}
        onCancel={cancelHandler}
        buttonLabel={'Lista przedmiotów'}
      />
    )
  }

  return (
    <SettingsFormBasic
      cardLabel={'Edytuj przedmiot'}
      initData={{ name: subject.name }}
      inputLabel={'Nazwa przedmiotu'}
      inputPlaceholder={'Wprowadź nowy przedmiot'}
      onSubmit={onSubmit}
      onCancelHandler={cancelHandler}
      breadcrumbLink={breadcrumbLink}
      isSending={isSending}
    />
  )
}

export default MemberSubjectsUpdate
