// GlobalSearchTable.tsx
import { useNavigate } from 'react-router-dom'

import { IMemberGlobalSearch } from 'types/member'

import { Button } from '../Button'

type Props = {
  query: string
  data: IMemberGlobalSearch[]
  total: number
  clearHandler: () => void
  closeDialog: () => void
}

export const GlobalSearchTable = ({ query, data, total, clearHandler, closeDialog }: Props) => {
  const navigate = useNavigate()

  const showUser = (id: string) => {
    navigate(`/member/${id}`)
    clearHandler()
    closeDialog()
  }

  const membersListHandler = () => {
    navigate(`/member?page=1&perPage=10&searchExtended=${query}`)
    clearHandler()
    closeDialog()
  }

  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th className='text-nowrap pb-1 pr-6 text-left text-xs uppercase'>Imię i nazwisko</th>
          <th className='text-nowrap pb-1 pr-6 text-left text-xs uppercase'>Oddział (okręg)</th>
          <th className='text-nowrap pb-1 pr-6 text-left text-xs uppercase'>Legitymacja</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} onClick={() => showUser(item.id)} className='cursor-pointer'>
            <td className='text-nowrap py-1 pr-6 text-base'>{item.name}</td>
            <td className='max-w-48 truncate text-nowrap py-1 pr-6 text-base'>{item.unit}</td>
            <td className='text-nowrap py-1 text-base'>{item.card}</td>
          </tr>
        ))}
        {total > 4 && (
          <tr>
            <td colSpan={3} className='py-1 text-center'>
              <Button
                type='button'
                label='Pokaż wszystkie'
                onClick={membersListHandler}
                className='mt-2'
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default GlobalSearchTable
