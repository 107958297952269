import { useState } from 'react'

import { Button } from 'components/Atoms/Button'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { IOption } from 'types/form'
import { ILegalHook } from 'types/legal'

import LegalSearchForm from './LegalSearchForm'

type Props = {
  control: any
  register: any
  legalData: ILegalHook
  defaultOption?: IOption
  isRegion?: boolean | undefined
}

export const LegalSearch = ({ control, register, legalData, defaultOption, isRegion }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { legal, unit } = legalData
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const initData = {
    name: '',
    identifier: '',
    dateEnactment: new Date(),
    dateEffective: new Date(),
    unit: unit,
  }
  if (!unit) {
    return <></>
  }
  return (
    <div className='w-full max-w-[299px]'>
      <SearchAndFetchInput
        defaultOption={defaultOption}
        searchMore={legal.search}
        isLoading={legal.loading}
        name={'document'}
        label={'Wyszukaj identyfikator uchwały'}
        register={register}
        options={legal.list}
        control={control}
      >
        <Button
          disabled={!unit && !isRegion}
          className='my-2'
          type='button'
          onClick={openModal}
          label='Dodaj uchwałę'
        />
      </SearchAndFetchInput>
      <LegalSearchForm
        refetch={legal.refetch}
        isOpen={isOpen}
        data={initData}
        handleClose={closeModal}
      />
    </div>
  )
}

export default LegalSearch
