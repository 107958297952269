import { IBasicSettingForm } from 'types/form'

import { Button } from '../Button'
import { Card } from '../Card'
import { Modal } from '../Modal'

type Props = {
  showModal: boolean
  isSending?: boolean
  name: string
  handleClose: () => void
  confirmSend: (data: IBasicSettingForm) => void
}

export const TagAlertModal = ({ showModal, isSending, name, handleClose, confirmSend }: Props) => {
  const sendHandler = () => confirmSend({ name })
  return (
    <Modal isOpen={showModal} handleClose={handleClose}>
      <Card label='Duplikat'>
        <div className='w- relative mb-4 w-80 bg-white px-4 py-4'>
          <p className='mx-auto mb-2 text-center text-lg'>
            Tag <b>{name}</b> został już dodany na danym poziomie struktury.
          </p>
          <p className='mx-auto text-center text-lg'>Czy chcesz dodać jego duplikat?</p>
        </div>
        <div className='mx-2 flex justify-center gap-10'>
          <Button label={'Anuluj'} variant='secondary' onClick={handleClose} />
          <Button disabled={isSending} label={'Potwierdź'} onClick={sendHandler} />
        </div>
      </Card>
    </Modal>
  )
}

export default TagAlertModal
