import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'

export const legalForm = yup.object({
  unit: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  identifier: yup.string().required(errorMessage.requiredField).min(2, errorMessage.min2Chars),
  name: yup.string().optional(),
  dateEnactment: yup.date().required(errorMessage.requiredField),
  dateEffective: yup.date().optional(),
})
