import React from 'react'

import { BuildingOffice2Icon, MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { EnvelopeClosedIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/shadcn/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { path } from 'constants/path'
import { IRegion } from 'types/region'

interface IProps {
  data: IRegion
}

const iconProps = {
  className: 'min-w-4 h-4 w-4 text-gray-500',
}

export const RegionBaseInfo: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate()

  const actionButtons = data
    ? [
        {
          label: 'Edytuj',
          variant: 'default',
          handleClick: () => navigate(`${path.region.update}${data?.id}`),
        },
      ]
    : []

  console.log({ data })

  return (
    // <Card
    //   label="Dane ogólne"
    //   actionsButton={[
    //     {
    //       label: 'Edytuj',
    //       handleClick: () => navigate(`${path.region.update}${data?.id}`),
    //     },
    //   ]}
    // >
    //   <div className="mb-5 grid grid-cols-4 gap-5">
    //     <LabelTypography label="Nazwa okręgu" text={data?.name} />
    //     <LabelTypography label="Prezes okręgu" text={data?.regionPresident} />
    //     <LabelTypography label="Telefon" text={data?.contact.phone} />
    //     {data?.contact.phoneSecondary && (
    //       <LabelTypography label="Telefon dodatkowy" text={data?.contact?.phoneSecondary} />
    //     )}
    //     <LabelTypography label="Email" text={data?.contact?.email} />
    //     {data?.contact.emailSecondary && (
    //       <LabelTypography label="Telefon dodatkowy" text={data?.contact?.emailSecondary} />
    //     )}
    //   </div>
    //   <div className="grid grid-cols-4 gap-5">
    //     <LabelTypography label="Adres" text={address} />
    //     <LabelTypography label="Lokalizacja" text={data?.locationDescription} />
    //     <LabelTypography label="Godziny otwarcia" text={data?.workHours} />
    //   </div>
    // </Card>

    <>
      <div className="w-full">
        <Card className="w-full">
          <CardHeader className="flex justify-between">
            <div className="flex items-center justify-between">
              <CardTitle className="flex items-center gap-2">
                <BuildingOffice2Icon className="h-6 w-6" />
                {data.name}
              </CardTitle>
              <span className="text-sm leading-6">{data.type && 'Okręg'}</span>
            </div>
          </CardHeader>

          <CardContent className="grid grid-cols-1 gap-4 px-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <h3 className="pb-2 text-lg font-semibold">Informacje kontaktowe</h3>
                <div className="space-y-2">
                  {data.contact.email && (
                    <div className="flex items-center gap-2">
                      <EnvelopeClosedIcon {...iconProps} />
                      <span>{data.contact.email}</span>
                    </div>
                  )}
                  {data.contact.emailSecondary && (
                    <div className="flex items-center gap-2">
                      <EnvelopeClosedIcon {...iconProps} />
                      <span>{data.contact.emailSecondary}</span>
                    </div>
                  )}
                  {data.contact.phone && (
                    <div className="flex items-center gap-2">
                      <PhoneIcon {...iconProps} />
                      <span>{data.contact.phone}</span>
                    </div>
                  )}
                  {data.contact.phoneSecondary && (
                    <div className="flex items-center gap-2">
                      <PhoneIcon {...iconProps} />
                      <span>{data.contact.phoneSecondary}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {data.address && (
                <div className="space-y-2">
                  <h3 className="pb-2 text-lg font-semibold">Adres</h3>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <MapPinIcon {...iconProps} />
                      <span>
                        {data.address.streetName} {data.address.houseNr}
                        {data.address.apartmentNr && `/${data.address.apartmentNr}`}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <BuildingOffice2Icon {...iconProps} />
                      <span>
                        {data.address.postalCode} {data.address.city}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {data.locationDescription && (
              <div className="space-y-4 xl:col-span-2">
                <div className="space-y-2">
                  <h3 className="pb-2 text-lg font-semibold">Informacje dodatkowe</h3>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <span>{data.locationDescription}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-span-full">
              {actionButtons.length > 0 && (
                <div className="flex gap-2 pt-4">
                  {actionButtons.map((button) => (
                    <Button key={button.label} onClick={button.handleClick}>
                      {button.label}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}
