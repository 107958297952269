import { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import { TextField } from 'components/Atoms/TextField'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { rodoRequestErrors } from 'error-data/rodo'
import { postResourceDeclarationReq, postResourceMemberReq } from 'fetchers/rodoFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorRequires, successToast } from 'tools/ToastHelpers'
import { IDict, IRodoActive, IRodoDict, IRodoReq } from 'types/dictionary'

type Props = {
  criteriaList: IDict[]
  initValues: IRodoDict
  initActive: IRodoActive
  type: 'members' | 'declarations'
}

export const RodoResourceForm = ({ initValues, initActive, criteriaList, type }: Props) => {
  const [isSending, setIsSending] = useState(false)
  const [activeCriteria, setActiveCriteria] = useState<IRodoActive>(initActive)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const resourceType = {
    members: {
      key: queryKeys.rodoResourceMembers,
      path: path.rodo_resource.members.list,
      api: postResourceMemberReq,
    },
    declarations: {
      key: queryKeys.rodoResourceDeclarations,
      path: path.rodo_resource.declarations.list,
      api: postResourceDeclarationReq,
    },
  }

  const resolve = (data: any) => {
    const errors = []
    const values = {}
    for (const active in activeCriteria) {
      if (activeCriteria[active]) {
        if (!data[active]) {
          errors.push(active)
        } else {
          Object.assign(values, { [active]: data[active] })
        }
      }
    }
    if (errors.length > 0) {
      const errorData = {}
      errors.forEach((error) => {
        setError(error, { type: 'required', message: 'required' })
        Object.assign(errorData, { [error]: 'Pole jest wymagane' })
      })
      return { values: {}, errors: errorData }
    }
    return { values: data, errors: {} }
  }

  const mutation = useMutation({
    mutationFn: (data: IRodoReq[]) => resourceType[type].api(data),
    onSuccess: () => {
      setIsSending(false)
      queryClient.invalidateQueries({ queryKey: [resourceType[type].key] })
      successToast('Zapytanie zostało wysłane')
      navigate(resourceType[type].path)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, rodoRequestErrors.get.message)
    },
  })

  const onSubmit = (data: any) => {
    try {
      const filteredData = []
      for (const key in data) {
        if (activeCriteria[key]) {
          filteredData.push({
            name: key,
            value: data[key],
          })
        }
      }
      mutation.mutate(filteredData)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, rodoRequestErrors.get.message)
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    setValue,
    clearErrors,
    setError,
  } = useForm<IRodoDict>({
    defaultValues: initValues,
    resolver: resolve,
  })
  const onValidationFailure = () => errorRequires(errors)
  const buttonIsActive =
    Object.keys(activeCriteria).some((key) => activeCriteria[key]) && !isSending
  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      <p className={'mb-4'}>Wybierz kryteria wyszukiwania</p>
      <div className='mb-5 grid grid-cols-4 gap-5'>
        {criteriaList.map((item, idx) => {
          const id = item.id || String(idx)
          return (
            <div key={item.id}>
              <label className='ml-6'>{item.name}</label>
              <div className='flex gap-2'>
                <div className='mt-4'>
                  <Checkbox
                    onChange={() => {
                      setValue(id, '')
                      clearErrors(id)
                      setActiveCriteria({ ...activeCriteria, [id]: !activeCriteria[id] })
                    }}
                  />
                </div>
                <TextField
                  disabled={!activeCriteria[id]}
                  {...register(item.id)}
                  type='text'
                  placeholder={item.name}
                  error={errors[id] ? 'Wypełnij zaznaczone pole' : undefined}
                  className='w-full rounded-md border border-gray-300 p-2 disabled:bg-gray-200'
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className='mt-5 flex justify-center'>
        <Button disabled={!buttonIsActive} label='Wyślij zapytanie' />
      </div>
    </form>
  )
}

export default RodoResourceForm
