import { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import SettingsFormBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { subjectErrors } from 'error-data/subject'
import { createSubject } from 'fetchers/subjectFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

export const MemberSubjectsCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const listPath = path.settings.subject.list

  const mutation = useMutation({
    mutationFn: (data: INameForm) => createSubject(data),
    onSuccess: () => {
      successToast('Dodano nowy przedmiot')
      queryClient.invalidateQueries({ queryKey: [queryKeys.subjectsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, subjectErrors.create, 'Nie udało się dodać nowego przedmiotu.')
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać nowego przedmiotu.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const breadcrumbLink = (
    <>
      <Link to={listPath}>Przedmioty</Link> / Dodaj
    </>
  )

  return (
    <SettingsFormBasic
      cardLabel={'Dodaj nowy przedmiot'}
      initData={{ name: '' }}
      inputLabel={'Nazwa przedmiotu'}
      inputPlaceholder={'Wprowadź nowy przedmiot'}
      onSubmit={onSubmit}
      onCancelHandler={cancelHandler}
      breadcrumbLink={breadcrumbLink}
      isSending={isSending}
    />
  )
}

export default MemberSubjectsCreate
