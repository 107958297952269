import { useNavigate } from 'react-router-dom'

import ErrorCard from 'components/ErrorCard'

export const ErrorRouteModal = () => {
  const navigate = useNavigate()
  return (
    <ErrorCard
      roleError
      label={'Nie znaleziono strony'}
      message={'Nie znaleziono strony, do której próbujesz się dostać.'}
      onCancel={() => navigate('/')}
      buttonLabel={'Strona główna'}
    />
  )
}

export default ErrorRouteModal
