import { useState } from 'react'

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { NavLink } from 'react-router-dom'

type Props = {
  item: any
  isSidebarVisible: boolean
  onPress: () => void
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const SidebarTab = ({ item, isSidebarVisible, onPress }: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  return (
    <Popover
      className='relative'
      onMouseEnter={() => setIsPopoverVisible(true)}
      onMouseLeave={() => setIsPopoverVisible(false)}
    >
      <PopoverButton className='block w-full text-left'>
        <NavLink
          to={`${item.href}`}
          className={({ isActive }) =>
            isActive
              ? 'group flex items-center rounded-md bg-gray-200 px-2 py-2 text-sm font-medium text-gray-600'
              : 'group flex items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200'
          }
          onClick={onPress}
        >
          <item.icon
            className={classNames(
              item.current ? 'text-gray-400' : 'text-gray-400',
              'h-6 w-6 flex-shrink-0',
            )}
            aria-hidden='true'
          />
          {isSidebarVisible && <span className='ml-3 flex-1'>{item.name}</span>}
        </NavLink>
      </PopoverButton>

      {!isSidebarVisible && (
        <Transition
          show={isPopoverVisible}
          enter='transition ease-out duration-200'
          enterFrom='opacity-0 translate-y-1'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-150'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-1'
        >
          <PopoverPanel
            static
            className='absolute left-10 top-[2px] z-50 rounded border border-gray-900 bg-gray-900 text-white shadow-lg'
          >
            <div className='z-50 whitespace-nowrap p-2 px-3'>
              <p className='text-xs font-medium'>{item.name}</p>
            </div>
          </PopoverPanel>
        </Transition>
      )}
    </Popover>
  )
}

export default SidebarTab
