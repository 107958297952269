import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Typography } from 'components/Atoms/Typography'
import ErrorCard from 'components/ErrorCard'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getPositionsList } from 'fetchers/positionFetchers'

import SettingsBaseList from '../../../components/Settings/SettingsBaseList'

export const MemberPositions = () => {
  const navigate = useNavigate()
  const cancelHandler = () => navigate(path.settings.main)
  const addNewTypeHandler = () => navigate(path.settings.position.create)
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { data, isLoading, error } = useQuery({
    queryKey: [userToken, queryKeys.positionsList],
    queryFn: () => getPositionsList(1, 100),
  })
  const positionTypes = data?.items || []
  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania listy stanowisk'}
        onCancel={cancelHandler}
        buttonLabel={'Ustawienia'}
      />
    )
  }
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        <Link to={path.settings.main}>Ustawienia</Link> / Stanowiska
      </Typography>
      <Card
        label='Lista stanowisk'
        actionsButton={[
          {
            label: 'Dodaj nowy typ',
            handleClick: addNewTypeHandler,
          },
        ]}
      >
        <>
          {isLoading ? (
            <LoadingSection />
          ) : (
            <SettingsBaseList baseList={positionTypes} type='position' />
          )}
        </>
      </Card>
    </section>
  )
}

export default MemberPositions
