import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import pl from 'date-fns/locale/pl'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'

import AutofetchInput from 'components/Atoms/AutofetchInput/AutofetchInput'
import { Button } from 'components/Atoms/Button'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { rodoRequestErrors } from 'error-data/rodo'
import { getDeclarationsListWithPlace } from 'fetchers/declarationFetcher'
import { postAccessDeclarationReq, postAccessMemberReq } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import { rodoMemberSchema } from 'schemas/rodoSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IRodoAccess, IRodoAccessDeclaration, IRodoDict } from 'types/dictionary'
import { IOption } from 'types/form'
registerLocale('pl', pl)

type Props = {
  criteriaList: IOption[]
  type: 'members' | 'declarations'
}

export const RodoAccessDeclarationForm = ({ criteriaList, type }: Props) => {
  const [operations, setOperations] = useState<IOption[]>([])
  const [datetimeFrom, setDatetimeFrom] = useState<Date | undefined>()
  const [datetimeTo, setDatetimeTo] = useState<Date | undefined>()
  const [isSending, setIsSending] = useState(false)
  const [perPage, setPerPage] = useState(10)

  const { userToken } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const filters = 'filter[status][]=awaiting&filter[status][]=rejected&filter[status][]=accepted'
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.declarationList, perPage, filters],
    queryFn: () => getDeclarationsListWithPlace(1, perPage, filters),
  })

  const getStatus = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekująca'
      case 'accepted':
        return 'Zaakceptowana'
      case 'rejected':
        return 'Odrzucone'
      default:
        return ''
    }
  }

  const declarationsList = data?.items
    ? data?.items.map((item: IRodoDict) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName} | ${getStatus(item.status)} | refId: ${
          item.referenceId
        } | ${item.basePlace} `,
      }))
    : []

  const loadMore = () => {
    const count =
      data?.pagination?.countFiltered > 0
        ? data?.pagination?.countFiltered
        : data?.pagination?.count
    if (!isLoading && data?.items?.length < count) setPerPage((prev) => prev + 10)
  }

  const resourceType = {
    members: {
      key: queryKeys.rodoAccessMembers,
      path: path.rodo_access.members.list,
      api: postAccessMemberReq,
    },
    declarations: {
      key: queryKeys.rodoAccessDeclarations,
      path: path.rodo_access.declarations.list,
      api: postAccessDeclarationReq,
    },
  }

  const mutation = useMutation({
    mutationFn: (data: IRodoAccess) => resourceType[type].api(data),
    onSuccess: () => {
      setIsSending(false)
      queryClient.invalidateQueries({ queryKey: [resourceType[type].key] })
      successToast('Zapytanie zostało wysłane')
      navigate(resourceType[type].path)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, rodoRequestErrors.get.message)
    },
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IRodoAccessDeclaration>({
    defaultValues: {
      resourceId: '',
      datetimeFrom: undefined,
      datetimeTo: undefined,
    },
    resolver: yupResolver(rodoMemberSchema),
  })

  const handleDate = (type: 'datetimeFrom' | 'datetimeTo', date: Date) => {
    setValue(type, date)
    if (type === 'datetimeFrom') {
      setDatetimeFrom(date)
    } else {
      setDatetimeTo(date)
    }
  }

  const onValidationFailure = (d: any) => {
    const values = getValues()
    const { resourceId, datetimeFrom, datetimeTo } = values
    if (!resourceId) {
      errorToast('Wybierz deklarację')
    }
    if (!datetimeFrom || !datetimeTo) {
      errorToast('Podaj zakres dat wyszukiwania')
    }
    if (datetimeFrom && datetimeTo && datetimeFrom > datetimeTo) {
      errorToast('Data początkowa nie może być większa niż data końcowa')
    }
  }

  const onSubmit = (data: any) => {
    try {
      const dataForm = {
        resourceId: data.resourceId,
        datetimeFrom: data.datetimeFrom,
        datetimeTo: data.datetimeTo,
        operationType: operations.map((item) => String(item.value)),
      }
      mutation.mutate(dataForm)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, rodoRequestErrors.get.message)
    }
  }
  const oneYearAgo = new Date()
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

  return (
    <>
      <p className={'mb-4'}>Wybierz kryteria wyszukiwania</p>
      <section className='grid grid-cols-4 gap-5 align-top'>
        <div>
          <p className='mb-1 block text-sm font-medium text-gray-700'>Rodzaj operacji</p>

          <MultiSelect
            options={criteriaList}
            value={operations}
            valueRenderer={(selected, _options) => selected.map((item) => item.label).join(', ')}
            hasSelectAll={true}
            className='rodo-selector text-sm'
            onChange={setOperations}
            overrideStrings={{
              search: 'Wyszukaj',
              selectAll: 'Zaznacz wszystko',
              allItemsAreSelected: 'Wszystkie są zaznaczone',
              selectSomeItems: 'Wybierz...',
            }}
            labelledBy='Wyszukaj'
          />
        </div>
      </section>

      <section className='my-4 flex gap-5'>
        <div>
          <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='baseSearchDate'>
            Od:
          </label>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            {...register('datetimeFrom')}
            locale='pl'
            minDate={oneYearAgo}
            maxDate={new Date()}
            selected={datetimeFrom}
            onChange={(date: Date) => handleDate('datetimeFrom', date)}
            className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
          />
        </div>
        <div>
          <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='baseSearchDate'>
            Do:
          </label>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            locale='pl'
            {...register('datetimeTo')}
            minDate={oneYearAgo}
            maxDate={new Date()}
            selected={datetimeTo}
            onChange={(date: Date) => handleDate('datetimeTo', date)}
            className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
          />
        </div>
      </section>

      <div className='mb-5 max-w-3xl'>
        <p className='mb-1 block text-sm font-medium text-gray-700'>Deklaracja</p>

        <AutofetchInput
          control={control}
          name={'resourceId'}
          label={'Deklaracje'}
          register={register}
          options={declarationsList}
          loadMore={loadMore}
          isLoading={isLoading}
        />
      </div>

      <div className='mt-5 flex justify-center'>
        <Button
          label='Wyślij zapytanie'
          disabled={isSending}
          onClick={handleSubmit(onSubmit, onValidationFailure)}
        />
      </div>
    </>
  )
}

export default RodoAccessDeclarationForm
