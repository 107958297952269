import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import MemberFunctionModal from 'components/Atoms/MemberFunctionModal'
import { Table } from 'components/Atoms/Table'
import useAuth from 'hooks/useAuth'
import { IMemberUnits } from 'types/member'
import { IUnitPosition, IUnitPositionUpdate } from 'types/structure'

import MemberSectionAddModal from './MemberSectionAddModal'
import MemberSectionRemoveModal from './MemberSectionRemoveModal'

type Props = {
  member: IMemberUnits
  functions: any
  isSending?: boolean
  generalIsSending?: boolean
  withArchived: boolean
  isRoleEditor: boolean
  functionsModalData?: IUnitPosition
  setFunctionsModalData: React.Dispatch<React.SetStateAction<IUnitPosition | undefined>>
  submitHandler: (data: IUnitPositionUpdate) => void
  toggleArchivedFunctions: () => void
  sectionRemover: () => void
  sectionAdder: (id: string) => void
  sectionChanger: (unitId: string, comment?: string) => void
}

export const MemberFunctions = ({
  member,
  functions,
  isSending,
  generalIsSending,
  functionsModalData,
  withArchived,
  sectionRemover,
  sectionAdder,
  sectionChanger,
  setFunctionsModalData,
  submitHandler,
  toggleArchivedFunctions,
}: Props) => {
  const [moveSectionModal, setMoveSectionModal] = useState(false)
  const [addSectionModal, setAddSectionModal] = useState(false)
  const [removeSectionModal, setRemoveSectionModal] = useState(false)
  const { isAllAdmin, isAllOD, id } = useAuth()
  const openModal = (data: IUnitPosition) => setFunctionsModalData(data)
  const closeModal = () => setFunctionsModalData(undefined)
  const isAllow = isAllAdmin() || isAllOD()
  const navigate = useNavigate()
  const unitChangeHandler = () => {
    navigate('migration')
  }
  const memberSections = member?.sections.map((item: any) => item?.name).join(', ')
  const showAddSection = !memberSections && isAllow
  const showRemoveSection = memberSections && isAllow
  const disableAddButton = generalIsSending || !!memberSections
  const disableRemoveButton = generalIsSending || !memberSections

  return (
    <>
      <Card label='Przynależność'>
        <div className='grid grid-cols-4'>
          <LabelTypography label='Okręg' text={member?.region?.name || '-'} />
          <div className='flex flex-col'>
            <LabelTypography label='Oddział' text={member?.branch?.name || '-'} />
            {isAllow && (
              <div className='flex gap-5'>
                <Button
                  type='button'
                  label='Zmień przynależność'
                  onClick={unitChangeHandler}
                  className='mt-2'
                />
              </div>
            )}
          </div>
          <div className='col-span-2 flex flex-col'>
            <LabelTypography label='Sekcja / Ognisko' text={memberSections || '-'} />

            <div className='flex gap-5'>
              {showAddSection && (
                <Button
                  type='button'
                  disabled={disableAddButton}
                  label='Przypisz'
                  onClick={() => setAddSectionModal(true)}
                  className='mt-2'
                />
              )}
              {showRemoveSection && (
                <>
                  <Button
                    type='button'
                    disabled={disableRemoveButton}
                    onClick={() => setRemoveSectionModal(true)}
                    label='Usuń'
                    className='mt-2'
                  />
                  <Button
                    type='button'
                    disabled={disableRemoveButton}
                    onClick={() => setMoveSectionModal(true)}
                    label='Przenieś'
                    className='mt-2'
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
      <Card label='Funkcje'>
        <div>
          {functions?.length === 0 && (
            <p className='mb-1 block text-sm font-medium text-gray-700'>
              Brak przypisanych funkcji.
            </p>
          )}

          {functions?.length > 0 && (
            <Table
              columns={[
                {
                  Header: 'Nazwa funkcji',
                  accessor: 'unitPosition.name',
                },
                {
                  Header: 'Nazwa oddziału',
                  accessor: 'unit.name',
                },
                {
                  Header: 'Początek kadencji',
                  accessor: 'startedAt',
                },
                {
                  Header: 'Koniec kadencji',
                  accessor: 'endedAt',
                  Cell: ({ row }: any) => row.original.endedAt || '-',
                },
                {
                  Header: 'Akcje',
                  accessor: 'action',
                  Cell: ({ row }: any) => {
                    const editPath = `${row.original.unitId}/${row.original.memberId}/${row.original.id}`
                    return (
                      <div className='flex'>
                        <HoverIcon
                          iconName='PencilIcon'
                          title='Edytuj'
                          onClick={() => openModal(row.original)}
                        />
                      </div>
                    )
                  },
                },
              ]}
              data={functions}
            />
          )}
          <div className='mt-2 flex justify-center'>
            <Button
              type='button'
              label={withArchived ? 'Ukryj archiwalne' : 'Pokaż archiwalne'}
              onClick={toggleArchivedFunctions}
            />
          </div>
        </div>
        {functionsModalData && (
          <MemberFunctionModal
            functionData={functionsModalData}
            isOpen={!!functionsModalData}
            isSending={isSending}
            submitHandler={submitHandler}
            closeModal={closeModal}
          />
        )}
      </Card>
      <MemberSectionRemoveModal
        isOpen={removeSectionModal}
        closeHandler={() => setRemoveSectionModal(false)}
        deleteHandler={sectionRemover}
      />
      <MemberSectionAddModal
        branchId={member?.branch?.id}
        isOpen={addSectionModal}
        closeHandler={() => setAddSectionModal(false)}
        sendHandler={sectionAdder}
      />
      <MemberSectionAddModal
        isMove
        branchId={member?.branch?.id}
        isOpen={moveSectionModal}
        closeHandler={() => setMoveSectionModal(false)}
        sendHandler={sectionChanger}
      />
    </>
  )
}

export default MemberFunctions
