import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/shadcn/ui/card'
import { ChartContainer } from 'components/shadcn/ui/chart'
import { Separator } from 'components/shadcn/ui/separator'

interface ChartProps {
  isSectionSummary?: boolean
  data: {
    balanceChangeIn: number
    balanceChangeOut: number
  }
}

const ChartHorizontal = ({ data, isSectionSummary }: ChartProps) => {
  const chartData = [
    {
      activity: 'Dodani',
      value: data.balanceChangeIn,
      label: `${data.balanceChangeIn}`,
      fill: 'var(--color-balanceChangeIn)',
    },
    {
      activity: 'Usunięci',
      value: data.balanceChangeOut,
      label: `${data.balanceChangeOut}`,
      fill: 'var(--color-balanceChangeOut)',
    },
  ]

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value, height } = props
    return (
      <text
        x={x + width - 10}
        y={y + height / 2}
        fill='white'
        textAnchor='end'
        dominantBaseline='middle'
        fontSize={12}
      >
        {value !== 0 ? value : ''}
      </text>
    )
  }

  return (
    <Card className={`w-full max-w-md p-4 ${isSectionSummary ? 'border-none shadow-none' : ''}`}>
      <CardHeader className='px-2 py-2'>
        <CardTitle>Podsumowanie zmian</CardTitle>
        <CardDescription>Bilans liczby członków</CardDescription>
      </CardHeader>
      <CardContent className='flex gap-4 p-0 px-2 pb-2'>
        <ChartContainer
          config={{
            balanceChangeIn: {
              label: 'Dodani',
              color: 'hsl(var(--chart-2))',
            },
            balanceChangeOut: {
              label: 'Usunięci',
              color: 'hsl(var(--chart-1))',
            },
          }}
          className='h-[140px] w-full'
        >
          <BarChart
            data={chartData}
            layout='vertical'
            barSize={32}
            barGap={2}
            margin={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <XAxis type='number' dataKey='value' hide />
            <YAxis
              dataKey='activity'
              type='category'
              tickLine={false}
              axisLine={false}
              className='capitalize'
            />
            <Bar dataKey='value' radius={5}>
              <LabelList dataKey='value' content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className='flex flex-row border-t p-4'>
        <div className='flex w-full items-center gap-2'>
          <div className='grid flex-1 auto-rows-min gap-0.5'>
            <div className='text-xs text-muted-foreground'>Dodani</div>
            <div className='flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none'>
              {data.balanceChangeIn}
            </div>
          </div>
          <Separator orientation='vertical' className='mx-2 h-10 w-px' />
          <div className='grid flex-1 auto-rows-min gap-0.5'>
            <div className='text-xs text-muted-foreground'>Usunięci</div>
            <div className='flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none'>
              {data.balanceChangeOut}
            </div>
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}

export default ChartHorizontal
