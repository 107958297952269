// GlobalSearchBar.tsx
import { useState } from 'react'

import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'

import GlobalSearchTable from 'components/Atoms/GlobalSearchTable/GlobalSearchTable'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/ui/dialog'
import { Input } from 'components/shadcn/ui/input'
import { queryKeys } from 'constants/queryKeys'
import { getMembersList } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { errorQuery } from 'tools/queryHelpers'
import { IMemberGlobalSearch, IMemberListing } from 'types/member'

let searchTimeout: NodeJS.Timeout | null = null

export const GlobalSearchBar = () => {
  const [searchValue, setSearchValue] = useState('')
  const [query, setQuery] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const { userToken } = useAuth()

  // FETCHER
  const filter = query ? `&filter[searchExtended]=${query}` : ''
  const { data, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.membersList, 1, 4, filter],
    queryFn: () => {
      if (filter.length === 0) return null
      return getMembersList(1, 4, filter)
    },
    retry: errorQuery,
  })

  const list: IMemberGlobalSearch[] = data?.items
    ? data.items.map((item: IMemberListing) => {
        const branch = item?.branch?.name || ''
        const region = item?.region?.name ? ` (${item.region.name})` : ''
        return {
          id: item.id,
          name: `${item.firstName} ${item.lastName}`,
          unit: branch + region,
          card: item.card?.number || '',
        }
      })
    : []
  const total = data?.pagination?.countFiltered || 0

  const initSearch = () => {
    setQuery(searchValue)
  }

  const clearHandler = () => {
    setQuery('')
    setSearchValue('')
    setDialogOpen(false)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      initSearch()
    } else {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(initSearch, 500)
    }
  }

  const isLoadingData = query && isLoading
  const noResults = query && !isLoadingData && list.length === 0
  const findResults = query && !isLoadingData && list.length > 0

  const handleSearchIconClick = () => {
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  return (
    <div>
      <span
        className='relative flex w-80 cursor-pointer items-center justify-center bg-muted/50'
        onClick={handleSearchIconClick}
      >
        <MagnifyingGlassIcon
          className='absolute left-2 top-2 h-5 w-5 text-gray-400'
          aria-hidden='true'
        />
        <input
          type='text'
          className='w-full cursor-pointer rounded-lg border border-zinc-950/10 bg-transparent pl-8 text-base text-zinc-950 sm:text-sm'
          placeholder='Wyszukaj zasobów...'
          readOnly
        />
      </span>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className='gap-0 p-0'>
          <DialogHeader className='space-y-0'>
            <DialogTitle></DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className='flex items-center border-b px-3'>
            <MagnifyingGlassIcon
              className='absolute left-2 top-3 h-6 w-6 text-gray-400'
              aria-hidden='true'
            />
            <Input
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyPress}
              className='flex h-12 w-full rounded-md border-0 bg-transparent py-3 pl-8 text-sm focus:outline-none focus-visible:outline-none focus-visible:outline-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-opacity-0 focus-visible:ring-offset-0'
              placeholder='Wpisz imię, nazwisko, numer legitymacji lub nazwę oddziału...'
            />
          </div>

          <div className='max-h-[400px] overflow-y-auto overflow-x-hidden'>
            <div className='p-4'>
              {isLoadingData && <p className='text-base'>Wyszukiwanie...</p>}
              {noResults && <p className='text-base'>Brak wyników</p>}
              {findResults && (
                <GlobalSearchTable
                  query={query}
                  total={total}
                  data={list}
                  clearHandler={clearHandler}
                  closeDialog={closeDialog}
                />
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default GlobalSearchBar
