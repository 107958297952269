import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { education } from 'constants/education'
import { sex } from 'constants/sex'
import { createMemberSchema } from 'schemas/memberSchema'

interface IProps {
  onSubmit: (data: any) => void
}

const defaultValues = {
  firstName: '',
  secondName: '',
  lastName: '',
  pesel: '',
  sex: '',
  phoneNumber: '',
  email: '',
  zipCode: '',
  city: '',
  street: '',
  hauseNr: '',
  apartmentNr: '',
  education: '',
  primaryJob: '',
  position: '',
  file: '',
}

export const CreateMemberForm: React.FC<IProps> = ({ onSubmit }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(createMemberSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mb-5 flex'>
        <div className='mr-10 w-2/12'>
          <TextField label='Imię' {...register('firstName')} error={errors.firstName?.message} />
        </div>
        <div className='w-2/12'>
          <TextField label='Drugie imię (opcjonalne)' {...register('secondName')} />
        </div>
      </div>
      <div className='w-2/12'>
        <div className='mb-5'>
          <TextField label='Nazwisko' {...register('lastName')} error={errors.lastName?.message} />
        </div>
        <div className='mb-5'>
          <TextField label='PESEL' {...register('pesel')} error={errors.pesel?.message} />
        </div>
        <div className='mb-5'>
          <Controller
            name='sex'
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Select
                handleSelect={(val) => onChange(val.value)}
                options={sex}
                label='Płeć'
                error={error?.message}
              />
            )}
          />
        </div>
        <div className='mb-5'>
          <TextField
            label='Numer telefonu'
            {...register('phoneNumber')}
            error={errors.phoneNumber?.message}
          />
        </div>
        <div className='mb-5'>
          <TextField label='Email' {...register('email')} />
        </div>
      </div>

      <div className='mb-5 mt-10'>
        <Typography size='xl'>Adres korespondencyjny</Typography>
      </div>

      <div className='mb-5 flex'>
        <div className='mr-5 w-2/12'>
          <TextField
            label='Kod pocztowy'
            {...register('zipCode')}
            error={errors.zipCode?.message}
          />
        </div>
        <div className='mr-5 w-2/12'>
          <TextField label='Miasto' {...register('city')} error={errors.city?.message} />
        </div>
        <div className='mr-5 w-2/12'>
          <TextField label='Ulica' {...register('street')} error={errors.street?.message} />
        </div>
        <div className='mr-5 w-2/12'>
          <TextField label='Nr. domu' {...register('hauseNr')} error={errors.hauseNr?.message} />
        </div>
        <div className='mr-5 w-2/12'>
          <TextField label='Nr. mieszkania' {...register('apartmentNr')} />
        </div>
      </div>

      <div className='mb-5 w-2/12'>
        <Controller
          name='education'
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              handleSelect={(val) => onChange(val.value)}
              options={education}
              label='Wykształcenie'
            />
          )}
        />
      </div>

      <div className='mb-5 flex'>
        <div className='mr-5 w-2/12'>
          <Controller
            name='primaryJob'
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                handleSelect={(val) => onChange(val.value)}
                options={[
                  { value: 1, label: 'Bezrobotny' },
                  { value: 2, label: 'Emeryt' },
                  { value: 3, label: 'Rencista' },
                ]}
                label='Główne miejsce pracy'
              />
            )}
          />
        </div>
        <div className='w-2/12'>
          <Controller
            name='position'
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                handleSelect={(val) => onChange(val.value)}
                options={[
                  { value: 1, label: 'Pedagogiczny' },
                  { value: 2, label: 'Niepedagogiczny' },
                ]}
                label='Stanowisko'
              />
            )}
          />
        </div>
      </div>

      <div className='flex items-end justify-between'>
        <div>
          <Typography>Deklaracja członkowska</Typography>
          <input type='file' {...register('file')} />
        </div>

        <div>
          <Button label='Dodaj' />
        </div>
      </div>
    </form>
  )
}
