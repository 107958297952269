import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { RegionBaseInfo } from 'components/Region/RegionBaseInfo'
import { RegionBranches } from 'components/Region/RegionBranches'
import { RegionFunctions } from 'components/Region/RegionFunctions'
import { queryKeys } from 'constants/queryKeys'
import { getRegion, getRegionBranches } from 'fetchers/regionFetchers'
import { getStructureFunctionList } from 'fetchers/structureFetcher'
import { errorQuery, getLastPage } from 'tools/queryHelpers'
import { IOption } from 'types/form'

const data = [
  {
    id: 1,
    changing: 'Anna Joma',
    date: '01.01.2022 01.15',
    changedFiles: ['email', 'telefon'],
    beforeChange: 'przed@wp.pl',
    afterChange: 'po@wp.pl',
  },
  {
    id: 2,
    changing: 'Marek Baraszek',
    date: '15.03.2021 21.27',
    changedFiles: ['email', 'telefon'],
    beforeChange: '131 141 534',
    afterChange: '111 222 333',
  },
]

export const RegionDetails = () => {
  const [current, setCurrent] = useState('true')
  const [page, setPage] = useState({
    current: 1,
    limit: 10,
  })
  const navigate = useNavigate()
  const { id } = useParams()
  const regionId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const onItemsPerPageChange = (data: IOption) => {
    setPage((prev) => ({ ...prev, limit: Number(data.value) }))
  }
  const changePage = (newPage: number) => {
    setPage((prev) => ({ ...prev, current: newPage }))
  }
  const toggleArchivedFunctions = () => {
    setCurrent((prev) => (prev === 'true' ? 'false' : 'true'))
  }
  const { data: region, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.region, regionId],
    queryFn: () => getRegion(regionId),
  })

  const { data: branchesInRegion, isLoading: branchesIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionBranchesList, regionId],
    queryFn: () => getRegionBranches(regionId),
  })

  // const { data: usersInRegion, isLoading: usersIsLoading } = useQuery({
  //   queryKey: [userToken, queryKeys.regionUsersList, regionId],
  //  queryFn: () => getRegionUsers(regionId, page, Number(itemsPerPage.value)),
  // })
  const functionFilters = `?page=${page.current}&perPage=${page.limit}&filter[onlyCurrent]=${current}`

  const { data: members, isLoading: subLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberSectionList, id, functionFilters],
    queryFn: () => {
      if (!id) return { items: [], pagination: {} }
      return getStructureFunctionList(id, functionFilters)
    },
    retry: errorQuery,
  })
  const { lastPage } = getLastPage(members?.pagination)

  const usersList: any[] = []

  if (members?.items) {
    members.items.forEach((user: any) => {
      user.unitPositions.forEach((position: any) => {
        usersList.push({
          id: user.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          numberID: user?.numberID,
          functions: position?.unitPosition?.name,
          section: user?.section?.name,
        })
      })
    })
  }

  if (isLoading || branchesIsLoading) return <LoadingSection />

  return (
    <div>
      <RegionBaseInfo data={region} />
      <RegionBranches data={branchesInRegion} />
      <RegionFunctions
        isLoading={subLoading}
        withArchived={current === 'false'}
        data={usersList}
        onPageChange={changePage}
        lastPage={lastPage}
        currentPage={page.current}
        toggleArchivedFunctions={toggleArchivedFunctions}
        onItemsPerPageChange={onItemsPerPageChange}
      />

      <Card label='Historia zmian'>
        <p>Work in progress...</p>
        {/* {data.map((item) => (
          <div className='mb-2' key={item.id}>
            <HistoryList handleVisibleDetails={() => console.info('test')} {...item} />
          </div>
        ))} */}
      </Card>
    </div>
  )
}
