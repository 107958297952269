import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import ErrorCard from 'components/ErrorCard'
import SettingsFormBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { institutionErrors } from 'error-data/institution'
import { getInstitutionGroup, updateInstitutionGroup } from 'fetchers/institutionFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

export const InstitutionGroupsUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const listPath = path.settings.institutionGroup.list
  const pathId = id as string

  const {
    data: institutionGroup,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.institutionGroups, pathId],
    queryFn: () => getInstitutionGroup(pathId),
  })

  const mutation = useMutation({
    mutationFn: (data: INameForm) => updateInstitutionGroup(institutionGroup?.id, data),
    onSuccess: () => {
      successToast('Zaktualizowano grupę placówek')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.institutionGroupsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        institutionErrors.updateGroup,
        'Nie udało się dodać nowej grupy placówek.',
      )
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać nowej grupy placówek.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const breadcrumbLink = (
    <>
      <Link to={listPath}>Grupy placówek</Link> / Edytuj
    </>
  )
  if (isLoading) return <LoadingSection />
  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania grupy placówek'}
        onCancel={cancelHandler}
        buttonLabel={'Lista grup placówek'}
      />
    )
  }
  return (
    <SettingsFormBasic
      cardLabel={'Edytuj grupę placówek'}
      initData={{ name: institutionGroup.name }}
      inputLabel={'Nazwa grupy placówek'}
      inputPlaceholder={'Wprowadź nową grupę'}
      onSubmit={onSubmit}
      onCancelHandler={cancelHandler}
      breadcrumbLink={breadcrumbLink}
      isSending={isSending}
    />
  )
}

export default InstitutionGroupsUpdate
