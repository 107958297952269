import { Card, CardContent, CardFooter, CardHeader } from 'components/shadcn/ui/card'

export function SkeletonTimeLeftChart() {
  return (
    <Card className='flex flex-col'>
      <CardHeader className='items-center pb-0'>
        <div className='h-4 w-full max-w-[200px] animate-pulse rounded-md bg-slate-300'></div>
      </CardHeader>
      <CardContent className='my-4 flex flex-1 items-center justify-center pb-0'>
        <div className='h-[200px] w-full max-w-[200px] animate-pulse rounded-md bg-slate-200'></div>
      </CardContent>
      <CardFooter className='flex-col gap-2 text-sm'>
        <div className='h-8 w-full max-w-[120px] animate-pulse rounded-md bg-slate-300'></div>
      </CardFooter>
    </Card>
  )
}
