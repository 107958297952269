import { useState } from 'react'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ConfirmData } from 'components/StatisticalObligation/ConfirmData'

interface IProps {
  setDisplaySummary: () => void
}

const newMember = [
  {
    name: 'Lindsay Walton',
  },
  {
    name: 'Emma Dorsey',
  },
  {
    name: 'Tom Cook',
  },
]
const leftMember = [
  {
    name: 'Whitney Francis',
  },
  {
    name: 'Courtney Henry',
  },
]

export const ReportSummary = ({ setDisplaySummary }: IProps) => {
  const [isOK, setIsOK] = useState(false)

  const membersLength = (): any[] => {
    if (newMember.length > leftMember.length) return newMember

    return leftMember
  }

  if (isOK) return <ConfirmData />

  return (
    <Card label='Podsumowanie zgłoszenia'>
      <div className='grid w-2/6 grid-cols-5'>
        <div className='col-span-1'></div>
        <p className='col-span-2'>+ 4 osoby</p>
        <p className='col-span-2'>- 1 osoby</p>
      </div>
      <div className='mt-10'>
        <div className='grid w-2/6 grid-cols-5 border-b'>
          <div className='col-span-1'>Nr</div>
          <div className='col-span-2 border-l p-2'>Doszły osoby</div>
          <div className='col-span-2 border-l p-2'>Odeszły Y:</div>
        </div>
        <div className='grid w-2/6 grid-cols-5'>
          <div className='col-span-1'>
            {membersLength().map((item, index) => (
              <div className='border-b p-2' key={index}>
                {index + 1}
              </div>
            ))}
          </div>
          <div className='col-span-2 border-l'>
            {newMember.map((item) => (
              <div className='border-b p-2' key={item.name}>
                {item.name}
              </div>
            ))}
          </div>
          <div className='col-span-2 border-l'>
            {leftMember.map((item) => (
              <div className='border-b p-2' key={item.name}>
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='flex justify-center'>
        <Button
          label='Wróć i popraw'
          variant='secondary'
          className='mr-5'
          onClick={setDisplaySummary}
        />
        <Button label='Zatwierdź' onClick={() => setIsOK(true)} />
      </div>
    </Card>
  )
}
