import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { wipMessage } from 'tools/ToastHelpers'
import { IUserData } from 'types/userlist'
interface IOption {
  label: string
  value: number | string
}
interface IProps {
  data: IUserData[]
  lastPage: number
  currentPage: number
  isLoading: boolean
  withArchived: boolean
  toggleArchivedFunctions: () => void
  onPageChange: (currentPage: number) => void
  onItemsPerPageChange: (selectItem: IOption) => void
}
export const RegionFunctions: React.FC<IProps> = ({
  data,
  lastPage,
  currentPage,
  isLoading,
  withArchived,
  onPageChange,
  toggleArchivedFunctions,
  onItemsPerPageChange,
}) => {
  // const functionsList = data?.items ? data?.items : []\
  const navigate = useNavigate()
  const columns = React.useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'address',
      },
      {
        Header: 'Pełnione funkcje',
        accessor: 'func',
      },
      // {
      //   Header: 'Oddział',
      //   accessor: 'unit',
      // },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(`/member/${row.row.original.id}`)}
              />
              <HoverIcon iconName='TrashIcon' title='Usuń' onClick={() => wipMessage()} />
            </div>
          )
        },
      },
    ],
    [],
  )
  if (data.length === 0 && !isLoading) {
    return (
      <Card label='Funkcje przypisane do okręgu'>
        {/* <Table columns={columns} data={data} /> */}

        {/* <p>Work in progress...</p> */}
        <p>Brak funkcji przypisanych do okręgu</p>
      </Card>
    )
  }

  return (
    <Card label='Funkcje przypisane do okręgu'>
      <div className='flex justify-between'>
        <div className='flex items-center'>
          <Select
            handleSelect={onItemsPerPageChange}
            options={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
            ]}
          />
        </div>
        <Pagination lastPage={lastPage} currentPage={currentPage} handlePageChange={onPageChange} />
      </div>
      {isLoading ? <Spinner /> : <Table data={data} columns={columns} />}
      <div className='mt-2 flex justify-center'>
        <Button
          type='button'
          label={withArchived ? 'Ukryj archiwalne' : 'Pokaż archiwalne'}
          onClick={toggleArchivedFunctions}
        />
      </div>
    </Card>
  )
}
