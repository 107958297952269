import { useEffect, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { xor } from 'lodash'
import DatePicker from 'react-datepicker'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { sex } from 'constants/sex'
import { declarationErrors } from 'error-data/declaration'
import { createExport, getMemberExportDictionary } from 'fetchers/exportFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import useSearchModule from 'hooks/useSearchModule'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import {
  IBasicSettingForm,
  IBasicSettingObject,
  IBooleans,
  IOption,
  IPageHandler,
  IQuery,
  IQueryHandler,
  IVoidObject,
} from 'types/form'
import { IQueryParamsState } from 'types/searchModule'
import { IUnitSelectedUnits } from 'types/structure'

type Props = {
  userToken: string
  isRegion?: boolean
  unitContext: any
  isBranch?: boolean
  searchParams: IQueryParamsState
  selectedUnits: IUnitSelectedUnits
  setSelectedUnits: React.Dispatch<React.SetStateAction<IUnitSelectedUnits>>
  setCustomAgeParam: (param: string) => void
  options: IBasicSettingObject
  loadings: IBooleans
  query: IQuery
  refetch: IVoidObject
  queryHandler: IQueryHandler
  searchMore: IVoidObject
  limitSetter: IPageHandler
  archive?: boolean
}

const MemberExportCreate = ({
  selectedUnits,
  searchParams,
  userToken,
  unitContext,
  options,
  archive,
}: Props) => {
  const [fileName, setFileName] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [tags, setTags] = useState([])
  const [ageFrom, setAgeFrom] = useState<string | undefined>()
  const [ageTo, setAgeTo] = useState<string | undefined>()
  const [baseSearchDate, setBaseSearchDate] = useState(new Date())
  const [positions, setPositions] = useState([])
  const [sections, setSections] = useState<IOption[]>([])
  const [sectionValues, setSectionValues] = useState<string[]>([])
  const [isSending, setIsSending] = useState(false)
  const [chosenSex, setChosenSex] = useState('')
  const [chosenSections, setChosenSections] = useState<string[]>([])
  const [chosenTags, setChosenTags] = useState<string[]>([])
  const [chosenUntis, setChosenUnits] = useState<string[]>([])

  const { filters, currentPage } = useSearchModule({ archive })

  const handleCheckboxChange = (id: string) => {
    setChosenSections((prev: any) => {
      if (prev.includes(id)) {
        return prev.filter((item: any) => item !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  const handleCheckboxChangeTags = (ids: string[]) => {
    setChosenTags((prev: any) => {
      const updatedTags = prev.filter((item: any) => !ids.includes(item))
      return [...updatedTags, ...ids]
    })
  }

  const handleCheckboxChangeUnits = (ids: string[]) => {
    setChosenUnits((prev: any) => {
      const updatedUnits = prev.filter((item: any) => !ids.includes(item))
      return [...updatedUnits, ...ids]
    })
  }

  const auth = useAppSelector((state) => state.authReducer)

  const { param, setParam, searchQuery } = useSearch(searchParams)
  const { isAllAdmin, isAllOK, isAllOD } = useAuth()
  const queryClient = useQueryClient()
  const id = unitContext?.id
  const navigate = useNavigate()

  const handleInputCheck = (
    option: IBasicSettingForm,
    setter: React.Dispatch<React.SetStateAction<IUnitSelectedUnits>>,
    paramType: keyof IUnitSelectedUnits,
  ) => {
    if (option?.id) {
      setParam.array(paramType, xor(param.array[paramType] as string[], [option?.id]))
      setter((prev: IUnitSelectedUnits) => ({
        ...prev,
        [paramType]: xor(prev[paramType], [option?.id]),
      }))
    }
  }

  useEffect(() => {
    setParam.array('institution', selectedUnits.institution)
  }, [selectedUnits.institution])

  const updateAgeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.currentTarget.value ? e.currentTarget.value : undefined
    setAgeFrom(data)
  }
  const updateAgeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.currentTarget.value ? e.currentTarget.value : undefined
    setAgeTo(data)
  }

  const singleSelector = (type: string, value: string | number) => {
    setParam.single(type, value)
  }
  const optionsPositions: any = options.positions || []
  const optionsTags: any = options.tags || []

  useEffect(() => {
    const values = positions.map((e: IOption) => String(e.value))
    setParam.array('position', values)
    const pickPositions = positions.map((e: IOption) => String(e.value))
    handleCheckboxChangeUnits(pickPositions)
  }, [positions])

  useEffect(() => {
    setParam.array('institution', selectedUnits.institution)
  }, [selectedUnits.institution])

  useEffect(() => {
    const values = tags.map((e: IOption) => String(e.value))
    setParam.array('tag', values)
    const pickTags = tags.map((e: IOption) => String(e.value))
    handleCheckboxChangeTags(pickTags)
  }, [tags])

  useEffect(() => {
    return setSectionValues(sections.map((section) => section.value.toString()))
  }, [sections])

  const { data: exportDictionary, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberExportDictionary, id],
    queryFn: () => {
      return getMemberExportDictionary()
    },
    retry: useRetryHandler({
      resourceName: 'MemberExportCreate exportDictionary',
      maxRetries: 1,
    }),
  })

  const {
    data: sectionData,
    isLoading: sectionLoading,
    error,
    refetch: sectionRefetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, currentPage, filters],
    queryFn: () => getSectionsList(Number(0), 1000, filters),
    retry: useRetryHandler({
      resourceName: 'MemberExportCreate sectionData',
      maxRetries: 1,
    }),
  })

  const mutation = useMutation({
    mutationFn: (data: any) => {
      const { redacted, status, ...formData } = data
      return createExport(formData)
    },
    onSuccess: () => {
      setIsSending(false)
      successToast('Dodano żądanie eksportu danych.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.export.list)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsSending(false)
      const errorMessages = error.response?.data.errors
        .map((item: any) => `${item.property}: ${item.error}`)
        .join('\n')

      error.response?.data.errors.forEach((item: any) => {
        toast.warning(errorMessages)
      })
      mutationErrorHandler(error, declarationErrors.create, errorMessages)
    },
  })

  const optionsSelect =
    exportDictionary?.items?.map((item: any) => {
      item.label = item.name
      item.value = item.id
      return item
    }) || []

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSending(true)
    e.preventDefault()
    if (!fileName.trim()) {
      setIsSending(false)
      errorToast('Nazwa pliku jest wymagana.')
      return
    }

    if (selectedOptions.length === 0) {
      setIsSending(false)
      errorToast('Musisz wybrać co najmniej jedno pole do eksportu.')
      return
    }

    const formattedOptions = selectedOptions.map((option) => option.value)

    const exportData = {
      name: fileName,
      fields: formattedOptions,
      units: sectionValues,
      sex: chosenSex,
      positions: chosenUntis,
      tags: chosenTags,
      ageGt: Number(ageFrom),
      ageLt: Number(ageTo),
      ageDate: baseSearchDate,
    }

    mutation.mutate(exportData)
  }

  const filteredItems =
    sectionData?.items.map((item: { id: string; name: string }) => ({
      value: item.id,
      label: item.name,
    })) || []

  return (
    <Card label='Wybierz dane do eksportu'>
      <form onSubmit={submitHandler}>
        <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-3'>
          <TextField
            onChange={(e) => {
              setFileName(e.target.value)
            }}
            label='Nazwa pliku'
            placeholder='Wpisz nazwę pliku'
            name='card'
            type='text'
            value={fileName}
          />
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Pola do eksportu</p>
            <MultiSelect
              options={optionsSelect || []}
              value={selectedOptions}
              className='text-sm'
              onChange={setSelectedOptions}
              labelledBy='Wybierz'
              hasSelectAll={false}
              overrideStrings={{
                selectSomeItems: 'Zaznacz pola',
                allItemsAreSelected: 'Wszystkie pola są zaznaczone',
                selectAll: 'Zaznacz wszystkie',
                search: 'Wyszukaj',
              }}
            />
          </div>
        </div>

        <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4'></div>
        <hr className='my-4' />
        <div className=''>
          <div className='grid grid-cols-1 gap-4 py-4 sm:grid-cols-3'>
            <span className='flex flex-wrap gap-x-2 sm:col-span-1 sm:row-span-1'>
              <div className='inline-block w-full text-sm font-medium text-gray-700'>
                Szukaj wg wieku
              </div>
              <TextField
                min={18}
                onChange={updateAgeFrom}
                placeholder='od'
                type='number'
                name='ageFrom'
              />
              <TextField
                min={18}
                onChange={updateAgeTo}
                addClass=''
                placeholder='do'
                type='number'
                name='ageTo'
              />
            </span>
            <div className='sm:col-span-1 sm:row-span-1'>
              <label
                className='mb-1 block text-sm font-medium text-gray-700'
                htmlFor='baseSearchDate'
              >
                Wiek obliczony na dzień:
              </label>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                id='baseSearchDate'
                locale='pl'
                selected={baseSearchDate}
                onChange={(date: Date) => setBaseSearchDate(date)}
                className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
              />
            </div>
            <div className='sm:col-span-1 sm:row-span-1'>
              <Select
                handleSelect={(e) => {
                  singleSelector('sex', e.value)
                  setChosenSex(e.value.toString())
                }}
                placeholder='Wybierz płeć'
                options={[{ value: '', label: '---' }, ...sex]}
                label='Płeć'
              />
            </div>
            <div className='sm:col-span-1 sm:row-span-1'>
              <div>
                <p className='mb-1 block text-sm font-medium text-gray-700'>Tagi</p>
                <MultiSelect
                  options={optionsTags}
                  value={tags}
                  hasSelectAll={false}
                  className='text-sm'
                  onChange={setTags}
                  overrideStrings={{
                    selectSomeItems: 'Zaznacz tagi',
                    allItemsAreSelected: 'Wszystkie tagi są zaznaczone',
                    selectAll: 'Zaznacz wszystkie',
                    search: 'Wyszukaj',
                  }}
                  labelledBy='Wyszukaj'
                />
              </div>
            </div>
            <div className='sm:col-span-1 sm:row-span-1'>
              <div>
                <p className='mb-1 block text-sm font-medium text-gray-700'>Stanowisko</p>
                <MultiSelect
                  options={optionsPositions}
                  value={positions}
                  hasSelectAll={false}
                  className='text-sm'
                  onChange={setPositions}
                  overrideStrings={{
                    selectSomeItems: 'Zaznacz stanowisko',
                    allItemsAreSelected: 'Wszystkie stanowiska są zaznaczone',
                    selectAll: 'Zaznacz wszystkie',
                    search: 'Wyszukaj',
                  }}
                  labelledBy='Wyszukaj'
                />
              </div>
            </div>
            <div className='sm:col-span-1 sm:row-span-1'>
              <div>
                <p className='mb-1 block text-sm font-medium text-gray-700'>Sekcje</p>
                <MultiSelect
                  options={filteredItems}
                  value={sections}
                  onChange={setSections}
                  hasSelectAll={false}
                  className='text-sm'
                  overrideStrings={{
                    selectSomeItems: 'Zaznacz sekcje',
                    allItemsAreSelected: 'Wszystkie sekcje są zaznaczone',
                    selectAll: 'Zaznacz wszystkie',
                    search: 'Wyszukaj',
                  }}
                  labelledBy='Wyszukaj'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='my-4 flex justify-center gap-4'>
          <Button
            variant='secondary'
            label='Anuluj'
            onClick={(e) => {
              e.preventDefault()
              navigate(path.export.list)
            }}
          />
          <Button
            label={
              isSending ? (
                <>
                  Eksportuję dane
                  <span className='loader ml-1'></span>
                </>
              ) : (
                'Eksportuj dane'
              )
            }
            disabled={isSending}
          />
        </div>
      </form>
    </Card>
  )
}

export default MemberExportCreate
