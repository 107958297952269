import { Fragment, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'sonner'

import CensusBreadcrumbs from 'components/Atoms/Breadcrumbs/CensusBreadcrumbs'
import { Button as AtomButton } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { Table } from 'components/Atoms/Table'
import { Button } from 'components/shadcn/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/ui/dialog'
import { queryKeys } from 'constants/queryKeys'
import { censusCreate, censusErrors } from 'error-data/census'
import { getBranch } from 'fetchers/branchFetchers'
import {
  closeCensusBranch,
  getCensusBranches,
  rejectCensusSectionResults,
  reopenCensusBranch,
  reopenCensusSection,
} from 'fetchers/censusFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { BarChartComponent } from 'views/Census/charts/BarChartComponent'
import ChartHorizontal from 'views/Census/charts/ChartHorizontal'
import { ProgressChart } from 'views/Census/charts/ProgressChart'
import { SkeletonAreaChartComponent } from 'views/Census/charts/SkeletonAreaChartComponent'
import { SkeletonProgressChart } from 'views/Census/charts/SkeletonProgressChart'
import { SkeletonTimeLeftChart } from 'views/Census/charts/SkeletonTimeLeftChart'
import { TimeLeftChart } from 'views/Census/charts/TimeLeftChart'

const CensusDashboardBranch = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [censusChangeNotificationDescription, setCensusChangeNotificationDescription] = useState('')
  const [unitChangeId, setUnitChangeId] = useState('')
  const [isRejectLoading, setIsRejectLoading] = useState(false)

  const { userToken } = useAuth()
  const { censusId, branchId } = useParams()

  const {
    data: censusBranchData,
    isPending: isCensusBranchDataPending,
    isLoading: isCensusBranchDataLoading,
    error: censusBranchDataError,
    refetch: refetchCensusBranchData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDashboard, censusId],
    queryFn: () => getCensusBranches(censusId ?? '', branchId ?? ''),
    retry: (failureCount: number, error: any) => {
      if (error?.response?.status === 403) {
        toast.error('Brak uprawnień do wykonania tej operacji: censusBranchData')
        return false
      }
      if (error?.response?.status === 404) {
        toast.error('Nie znaleziono zasobu: censusBranchData')
        return false
      }
      return failureCount < 1
    },
  })

  const { data: branchData, isLoading: isBranchDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.region, branchId],
    queryFn: () => getBranch(branchId ?? ''),
    retry: (failureCount: number, error: any) => {
      if (error?.response?.status === 403) {
        toast.error('Brak uprawnień do wykonania tej operacji: branchData')
        return false
      }
      if (error?.response?.status === 404) {
        toast.error('Nie znaleziono zasobu: branchData')
        return false
      }
      return failureCount < 1
    },
    enabled: !!branchId,
  })

  const closeCensusBranchMutation = useMutation({
    mutationFn: async ({ censusID, branchId }: { censusID: string; branchId: string }) =>
      closeCensusBranch(censusID, branchId),
    onSuccess: () => {
      successToast('Spis oddziału został zamknięty.')
      refetchCensusBranchData()
      setIsLoading(false)
    },
    onError: (error: any) => {
      mutationErrorHandler(
        error,
        censusErrors.update,
        'Nie udało się zamknąć spisu dla tego oddziału.',
      )
      setIsLoading(false)
    },
  })

  const reopenCensusBranchMutation = useMutation({
    mutationFn: async ({ censusID, branchId }: { censusID: string; branchId: string }) =>
      reopenCensusBranch(censusID, branchId),
    onSuccess: () => {
      successToast('Spis oddziału został otwarty.')
      refetchCensusBranchData()
      setIsLoading(false)
    },
    onError: (error: any) => {
      mutationErrorHandler(
        error,
        censusErrors.update,
        'Nie udało się otworzyć spisu dla tego oddziału.',
      )
      setIsLoading(false)
    },
  })

  const reopenCensusSectionMutation = useMutation({
    mutationFn: async ({ censusID, sectionId }: { censusID: string; sectionId: string }) =>
      reopenCensusSection(censusID, sectionId),
    onSuccess: () => {
      successToast('Spis sekcji został otwarty.')
      setIsLoading(false)
    },
    onError: (error: any) => {
      mutationErrorHandler(error, censusErrors.update, 'Nie udało się otworzyć spisu sekcji.')
      setIsLoading(false)
    },
  })

  const handleBranchMutation = async () => {
    setIsLoading(true)
    try {
      const progressValue =
        (censusBranchData?.completedUnitsNum / censusBranchData?.totalUnitsNum) * 100

      if (!censusBranchData?.isCompleted && progressValue < 100) {
        const incompleteConfirmed = window.confirm(
          'Czy na pewno chcesz zamknąć spis oddziału, kiedy nie wszystkie sekcje ukończyły swój spis?',
        )
        if (!incompleteConfirmed) {
          setIsLoading(false)
          return
        }
      }

      const action = censusBranchData?.isCompleted ? 'otworzyć' : 'zamknąć'
      const finalConfirmed = window.confirm(`Czy jesteś pewien, że chcesz ${action} spis oddziału?`)
      if (!finalConfirmed) {
        setIsLoading(false)
        return
      }

      if (censusBranchData?.isCompleted) {
        reopenCensusBranchMutation.mutate(
          { censusID: censusId ?? '', branchId: branchId ?? '' },
          {
            onSuccess: () => {
              refetchCensusBranchData()
              setIsLoading(false)
            },
          },
        )
      } else {
        closeCensusBranchMutation.mutate(
          { censusID: censusId ?? '', branchId: branchId ?? '' },
          {
            onSuccess: () => {
              refetchCensusBranchData()
              setIsLoading(false)
            },
          },
        )
      }
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zmienić statusu oddziału.')
      setIsLoading(false)
    }
  }

  const rejectSectionMutation = useMutation({
    mutationFn: (data: any) => rejectCensusSectionResults(data.censusId, data.sectionId, data.data),
    onSuccess: () => {
      successToast('Wyniki spisu zostały odrzucone')
      setIsRejectLoading(false)
      refetchCensusBranchData()
      setOpen(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setIsRejectLoading(false)
      mutationErrorHandler(error, censusCreate.create, 'Nie udało się odrzucić wyników spisu')
    },
  })

  const reportingModalHandler = (unitId: string) => () => {
    setOpen(true)
    setUnitChangeId(unitId)
  }

  const submitHandler = (e: any) => {
    e.preventDefault()
    setIsRejectLoading(true)
    if (censusChangeNotificationDescription.length < 5) {
      mutationErrorHandler(null, null, 'Komentarz musi mieć co najmniej 5 znaków.')
      setIsRejectLoading(false)
      return
    }

    rejectSectionMutation.mutate({
      censusId: censusId ?? '',
      sectionId: unitChangeId,
      data: {
        description: censusChangeNotificationDescription,
      },
    })

    reopenCensusSectionMutation.mutate({ censusID: censusId ?? '', sectionId: unitChangeId })
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Sekcja',
        accessor: 'name',
        Cell: ({ row }: any) => {
          const unitId = row.original?.unit?.id
          if (unitId === branchId) {
            return <span>Członkowie nieprzypisani do ogniska</span>
          }
          return row.original?.unit?.name
        },
      },
      {
        Header: 'Czy potwierdzone?',
        accessor: 'isCompleted',
        Cell: ({ row }: any) => {
          const unitId = row.original?.unit?.id
          if (unitId === branchId) {
            return <span>-</span>
          }
          return row.original?.isCompleted ? 'Tak' : 'Nie'
        },
      },
      {
        Header: 'Bilans liczby członków (+/-)',
        accessor: 'balanceChange',
        Cell: ({ row }: any) => {
          return (
            <span
              className={`${
                row.original?.balanceChangeIn > row.original?.balanceChangeOut
                  ? 'text-green-500'
                  : row.original?.balanceChangeIn < row.original?.balanceChangeOut
                    ? 'text-red-500'
                    : ''
              }`}
            >{`${row.original?.balanceChangeIn - row.original?.balanceChangeOut} (${
              row.original?.balanceChangeIn
            }/${row.original?.balanceChangeOut})`}</span>
          )
        },
      },
      {
        Header: 'Weryfikacja listy',
        accessor: 'verify-unit',
        Cell: ({ row }: any) => {
          const unitId = row.original?.unit?.id
          return (
            <Link
              to={`/census/dashboard/${censusId}/section/${row.original?.unit?.id}${
                unitId === branchId ? '/branch-as-section' : ''
              }`}
            >
              <AtomButton variant='secondary' label='Weryfikacja listy członków' />
            </Link>
          )
        },
      },
      {
        Header: 'Szczegóły',
        accessor: 'show-list',
        Cell: ({ row }: any) => {
          const unitId = row.original?.unit?.id
          return (
            <Link
              to={`/census/dashboard/${censusId}/section/${row.original?.unit?.id}/${
                unitId === branchId ? 'branch-as-section/' : ''
              }summary`}
            >
              <AtomButton variant='secondary' label='Zobacz podsumowanie' />
            </Link>
          )
        },
      },
      {
        Header: 'Odrzuć zamknięcie spisu',
        accessor: 'unit-notification',
        Cell: ({ row }: any) => {
          const unitId = row.original?.unit?.id
          if (unitId === branchId) {
            return <span>-</span>
          }
          return (
            <AtomButton
              disabled={row.original?.isCompleted === false}
              variant='secondary'
              label='Odrzuć zamknięcie spisu'
              onClick={reportingModalHandler(row.original?.unit?.id)}
            />
          )
        },
      },
    ]
  }, [branchId, censusId])

  if (isCensusBranchDataLoading || isBranchDataLoading) {
    return (
      <div>
        <div className='chart-wrapper mx-auto grid items-start gap-6 pb-2 xl:grid-cols-2'>
          <div className='flex h-full flex-col flex-wrap gap-6 md:flex-row'>
            <div className='grid flex-1 gap-6 md:grid-cols-2'>
              <SkeletonTimeLeftChart />
              <SkeletonTimeLeftChart />
            </div>
            <div className='inline-block w-full'>
              <SkeletonProgressChart />
            </div>
          </div>
          <SkeletonAreaChartComponent />
        </div>
        <Card className='flex flex-col'>
          <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
            {[...Array(6)].map((_, index) => (
              <Fragment key={index}>
                <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                  <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
                </dt>
                <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                  <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
                </dd>
              </Fragment>
            ))}
          </dl>
        </Card>
      </div>
    )
  }

  if (errorForbiddenHandler(censusBranchDataError)) return <ErrorRoleModal />

  return (
    <div>
      <div className='mb-6 flex items-center gap-4'>
        <CensusBreadcrumbs
          censusId={censusBranchData?.id}
          regionId={branchData?.parent?.id}
          censusName={censusBranchData?.name}
          regionName={branchData?.parent?.name}
          branchId={branchData?.id}
          branchName={branchData?.name}
        />
      </div>
      <div className='chart-wrapper mx-auto grid items-start gap-6 pb-2 xl:grid-cols-2'>
        <div className='flex h-full flex-col flex-wrap gap-6 md:flex-row'>
          <div className='grid flex-1 gap-6 md:grid-cols-2'>
            <TimeLeftChart
              data={{
                dataKey: 'allDays',
                dateTo: censusBranchData?.dateTo,
                colorFill: '#FFC107',
                dateFrom: censusBranchData?.dateFrom,
              }}
              action={handleBranchMutation}
              actionText={
                censusBranchData?.isCompleted ? 'Otwórz spis Oddziału' : 'Zamknij spis Oddziału'
              }
              isCompleted={censusBranchData?.isCompleted}
              isPending={isLoading}
              isEditable={censusBranchData?.isEditable}
              isReopenable={censusBranchData?.isReopenable}
            />
            <ChartHorizontal
              data={{
                balanceChangeIn: censusBranchData?.balanceChangeIn,
                balanceChangeOut: censusBranchData?.balanceChangeOut,
              }}
            />
          </div>
          <div className='inline-block w-full'>
            <ProgressChart
              data={{
                total: censusBranchData?.totalUnitsNum,
                done: censusBranchData?.completedUnitsNum,
                value:
                  (censusBranchData?.completedUnitsNum / censusBranchData?.totalUnitsNum) * 100,
              }}
            />
          </div>
        </div>
        <BarChartComponent censusBranchData={censusBranchData} dashbordType='sekcji' />
      </div>
      <Table data={censusBranchData?.subresults || []} columns={columns} />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className='pb-4'>
              Zgłoś wyniki dla{' '}
              {censusBranchData?.subresults?.find(
                (subresult: any) => subresult?.unit?.id === unitChangeId,
              )?.unit?.id === branchId
                ? 'Członkowie nieprzypisani do ogniska'
                : censusBranchData?.subresults?.find(
                    (subresult: any) => subresult?.unit?.id === unitChangeId,
                  )?.unit?.name || 'Unknown Unit'}
            </DialogTitle>
            <DialogDescription></DialogDescription>
            <form onSubmit={submitHandler}>
              <div className='space-y-4'>
                <div className='md:col-span-4'>
                  <label
                    className='mb-1 block text-sm font-medium text-gray-700'
                    htmlFor='censusChangeNotificationDescription'
                  >
                    Komentarz
                  </label>
                  <textarea
                    id='censusChangeNotificationDescription'
                    name='censusChangeNotificationDescription'
                    value={censusChangeNotificationDescription}
                    onChange={(e) => {
                      setCensusChangeNotificationDescription(e.target.value)
                    }}
                    aria-rowspan={4}
                    className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                    placeholder='Dodaj komentarz'
                    minLength={5}
                  ></textarea>
                </div>
                <Button variant='default' disabled={isLoading || isRejectLoading}>
                  {isLoading || (isRejectLoading && <div className='loader'></div>)}
                  Zgłoś sekcję
                </Button>
              </div>
            </form>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CensusDashboardBranch
