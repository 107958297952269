import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'components/shadcn/ui/pagination'

interface PaginationExampleProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

export function PaginationExample({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationExampleProps) {
  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            href='#'
            onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          />
        </PaginationItem>
        {[...Array(totalPages).keys()].map((pageIndex) => (
          <PaginationItem key={pageIndex}>
            <PaginationLink
              href='#'
              isActive={currentPage === pageIndex + 1}
              onClick={() => onPageChange(pageIndex + 1)}
            >
              {pageIndex + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            href='#'
            onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
