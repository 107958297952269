import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button } from 'components/Atoms/Button'
import { TextField } from 'components/Atoms/TextField'
import { errorMessage } from 'constants/errorMessage'
import { slugify } from 'tools/queryHelpers'
import { errorToast } from 'tools/ToastHelpers'
import { IOption } from 'types/form'


export const schema = yup.object({
  removeId: yup.string().required(errorMessage.requiredField),
  targetId: yup.string().required(errorMessage.requiredField),
  newSection: yup.string().required(errorMessage.requiredField),
})

interface IFormData {
  removeId: string
  targetId: string
  newSection: string
}

interface IProps {
  isSending?: boolean
  onSubmit: (data: any) => void
  navigator: (url: string) => void
  defaultValues: {
    removeId: string
    targetId: string
    newSection: string
  }
  initRemoveData?: IOption
  sections: IOption[]
}

export const SectionMergeForm: React.FC<IProps> = ({
  isSending,
  defaultValues,
  initRemoveData,
  sections,
  onSubmit,
  navigator,
}) => {
  const [removeSelected, setRemoveSelected] = useState<IOption | undefined>(initRemoveData)
  const [toRemoveQuery, setToRemoveQuery] = useState<string>('')
  const [targetQuery, setTargetQuery] = useState<string>('')
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  })
  type IChange = (value: string) => void

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission on Enter key press
    }
  }

  const removeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setToRemoveQuery(e.target.value)
  }

  const targetHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setTargetQuery(e.target.value)
  }

  const setupTarget = (e: any) => {
    const name = getValues('newSection')
    const target = e.target.value
    if (name === '') {
      const searchName = sections.find((e) => e.value === target)?.label
      if (searchName) {
        setValue('newSection', searchName)
      }
    }
  }

  const validate = (e: React.FormEvent) => {
    e.preventDefault()
    const { removeId, targetId } = getValues()
    if (removeId === targetId) {
      errorToast('Wybrane ogniska są takie same.')
      return
    }
    handleSubmit(onSubmit)()
  }

  const removeList = sections.filter((e) => slugify(e.label).includes(slugify(toRemoveQuery)))
  const targetList = removeSelected
    ? sections.filter((e) => {
        const slugMatch = slugify(e.label).includes(slugify(targetQuery))
        const parentMatch = e.parent === removeSelected.parent
        return slugMatch && parentMatch
      })
    : []
  return (
    <form onSubmit={validate}>
      {/* COMBO SEARCH SECTION */}
      <div className='mb-4 grid grid-cols-4 gap-5'>
        {/* SECTION TO REMOVE */}
        <div className='flex gap-2'>
          <div className='flex-1'>
            <TextField
              onKeyDown={handleKeyPress}
              label={'Wybierz usuwane ognisko'}
              type='text'
              onChange={removeHandler}
            />

            <ul
              className={`h-56 rounded-md border border-solid border-gray-300 ${
                removeList?.length !== 0 ? 'overflow-y-scroll' : ''
              }`}
            >
              {removeList.map((e: any) => {
                const rKey = `r-${e.value}`
                return (
                  <li
                    key={rKey}
                    className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                  >
                    <label htmlFor={rKey} className='cursor-pointer'>
                      <input
                        {...register('removeId')}
                        type='radio'
                        id={rKey}
                        name={'removeId'}
                        onChange={() => setRemoveSelected(e)}
                        value={e.value}
                      />
                      <span className='pl-2'>{e.label}</span>
                    </label>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        {/* TARGET SECTION */}
        <div className='flex gap-2'>
          <div className='flex-1'>
            <TextField
              disabled={!removeSelected}
              onKeyDown={handleKeyPress}
              label={'Wybierz ognisko docelowe'}
              type='text'
              onChange={targetHandler}
            />

            <ul
              className={`h-56 rounded-md border border-solid border-gray-300 ${
                targetList?.length !== 0 ? 'overflow-y-scroll' : ''
              }`}
            >
              {targetList.map((e: any) => {
                const tKey = `t-${e.value}`
                return (
                  <li
                    key={tKey}
                    className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                  >
                    <label htmlFor={tKey}>
                      <input
                        {...register('targetId')}
                        type='radio'
                        id={tKey}
                        name={'targetId'}
                        onChange={setupTarget}
                        value={e.value}
                      />
                      <span className='pl-2'>{e.label}</span>
                    </label>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* NEW SECTION NAME */}
      <div className='grid grid-cols-2 gap-5'>
        <div className='cols-2'>
          <TextField
            label='Nazwa nowego ogniska'
            {...register('newSection')}
            error={errors.newSection?.message}
          />
        </div>
      </div>
      <div></div>

      <div className='flex justify-between'>
        {/* <div className='w-2/6'>
              <Controller
                name='newBoss'
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    handleSelect={(val) => onChange(val.value)}
                    options={[
                      { value: 1, label: 'Prezes nr 1' },
                      { value: 2, label: 'Prezes nr 2' },
                    ]}
                    label='Prezes nowego ogniska'
                  />
                )}
              />
            </div> */}

        <div className='w-full'>{/* NOWA NAZWA */}</div>
        {/* <Checkbox label='Archiwizuj' /> */}
      </div>
      <div className='mt-10 flex justify-center'>
        <div className='mr-5'>
          <Button variant='secondary' label='Anuluj' onClick={() => navigator('/section')} />
        </div>
        <Button disabled={isSending} label='Zatwierdź' />
      </div>
    </form>
  )
}
