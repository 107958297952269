import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ControllerGroup } from 'components/Atoms/ControllerGroup'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { createUnitPositionSchema } from 'schemas/unitPositionSchema'
import { errorRequires } from 'tools/ToastHelpers'
import { IOption } from 'types/form'
import { IUnitPositionData } from 'types/unitPosition'

type Props = {
  initData: IUnitPositionData
  isSending?: boolean
  termOfOfficeData: IOption[]
  structureTypesData: IOption[]
  onSubmit: (data: IUnitPositionData) => void
  onCancelHandler: () => void
  defaultStructureType?: IOption
  defaultTermUnit?: IOption
}

export const UnitPositionsForm = ({
  initData,
  isSending,
  onSubmit,
  onCancelHandler,
  termOfOfficeData,
  structureTypesData,
  defaultStructureType,
  defaultTermUnit,
}: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<IUnitPositionData>({
    defaultValues: initData,
    resolver: yupResolver(createUnitPositionSchema),
  })
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        Struktura ZNP / <Link to={path.structure.unitPositions.list}>Funkcje w strukturze</Link> /
        Dodaj
      </Typography>
      <Card label={'Dodaj funkcję'}>
        <form onSubmit={handleSubmit(onSubmit, () => errorRequires(errors))}>
          <div className='mb-5 grid grid-cols-4 gap-5'>
            <TextField
              label={'Nazwa funkcji'}
              placeholder={'Wpisz nazwę funkcji'}
              error={errors.name?.message}
              {...register('name')}
            />
            <ControllerGroup
              defaultOption={defaultStructureType}
              name='unitType'
              label='Jednostka macierzysta'
              control={control}
              options={structureTypesData}
              error={errors?.unitType?.message}
              setError={setError}
            />
            <TextField
              label={'Limit osób'}
              type={'number'}
              min={0}
              placeholder={'Wpisz maksymalną liczebność'}
              error={errors?.maxNumber?.message}
              {...register('maxNumber')}
            />
          </div>
          <div className='mb-5 grid grid-cols-4 gap-5'>
            <TextField
              label={'Długość kadencji'}
              type={'number'}
              min={1}
              placeholder={'Wprowadź czas'}
              error={errors.lengthOfTerm?.message}
              {...register('lengthOfTerm')}
            />
            <ControllerGroup
              defaultOption={defaultTermUnit}
              name='lengthOfTermUnit'
              label='Jednostka czasu'
              control={control}
              options={termOfOfficeData}
              error={errors.lengthOfTermUnit?.message}
              setError={setError}
            />
          </div>
          <div className='mt-10 flex justify-center'>
            <Button label='Anuluj' variant='secondary' className='mr-5' onClick={onCancelHandler} />
            <Button disabled={isSending} label='Zatwierdź' />
          </div>
        </form>
      </Card>
    </section>
  )
}

export default UnitPositionsForm
