import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
const postalCodeRegExp = /^[0-9]{2}-[0-9]{3}$/

export const branchFunctionsForm = yup.object({
  startedAt: yup.string(),
  endedAt: yup.string(),
  memberId: yup.string().required(errorMessage.requiredField),
  unitPositionId: yup.string().required(errorMessage.requiredField),
})
