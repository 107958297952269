import 'react-datepicker/dist/react-datepicker.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Toaster } from 'sonner'
import { SWRConfig } from 'swr'

import { path } from 'constants/path'
import CheckSecureConnection from 'globals/checkSecureConnection'
import { ProtectedRoute } from 'templates/ProtectedRoutes'
import AuthWrapper from 'views/AuthWrapper'
import { BranchCreate } from 'views/Branch/BranchCreate'
import { BranchDetails } from 'views/Branch/BranchDetails'
import { BranchList } from 'views/Branch/BranchList'
import { BranchMerge } from 'views/Branch/BranchMerge'
import BranchMigrationDetail from 'views/Branch/BranchMigrationDetail'
import BranchMigrations from 'views/Branch/BranchMigrations'
import { BranchUpdate } from 'views/Branch/BranchUpdate'
import { BranchFunctions } from 'views/BranchFunctions/BranchFunctions'
import { BranchFunctionsCreate } from 'views/BranchFunctions/BranchFunctionsCreate'
import { BranchFunctionsUpdate } from 'views/BranchFunctions/BranchFunctionsUpdate'
import CardCreate from 'views/Cards/CardCreate'
import CardDetails from 'views/Cards/CardDetails'
import CardReqDetails from 'views/Cards/CardReqDetails'
import { CardList } from 'views/Cards/CardsList'
import { CardsListRequest } from 'views/Cards/CardsListRequest'
import CardVerify from 'views/Cards/CardVerify'
import CensusCreate from 'views/Census/create/CensusCreate'
import CensusDashboardAdmin from 'views/Census/dashboard/admin/CensusDashboardAdmin'
import CensusDashboardBranch from 'views/Census/dashboard/branch/CensusDashboardBranch'
import CensusDashboardRegion from 'views/Census/dashboard/region/CensusDashboardRegion'
import CensusDashboardSection from 'views/Census/dashboard/section-list/CensusDashboardSection'
import CensusDashboardSectionAsBranch from 'views/Census/dashboard/section-list/CensusDashboardSectionAsBranch'
import CensusDashboardSectionSummary from 'views/Census/dashboard/section-summary/CensusDashboardSectionSummary'
import CensusDashboardSectionSummaryAsBranch from 'views/Census/dashboard/section-summary/CensusDashboardSectionSummaryAsBranch'
import CensusDetails from 'views/Census/details/CensusDetails'
import CensusList from 'views/Census/list/CensusList'
import CensusListMe from 'views/Census/list/CensusListMe'
import CensusUpdate from 'views/Census/update/CensusUpdate'
import { Dashboard } from 'views/Dashboard'
import { DeclarationCreate } from 'views/Declarations/DeclarationCreate'
import { DeclarationDetails } from 'views/Declarations/DeclarationDetails'
import { Declarations } from 'views/Declarations/Declarations'
import { DeclarationUpdate } from 'views/Declarations/DeclarationUpdate'
import DocumentCreate from 'views/Documents/DocumentCreate'
import DocumentDetails from 'views/Documents/DocumentDetails'
import DocumentList from 'views/Documents/DocumentList'
import { InstitutionCreate } from 'views/Institution/InstitutionCreate'
import { InstitutionDetails } from 'views/Institution/InstitutionDetails'
import { InstitutionList } from 'views/Institution/InstitutionList'
import { InstitutionUpdate } from 'views/Institution/InstitutionUpdate'
import LegalCreate from 'views/Legal/LegalCreate'
import { LegaList } from 'views/Legal/LegalList'
import LegalUpdate from 'views/Legal/LegalUpdate'
import { LoadingLoginPage } from 'views/LoadingLoginPage'
import MemberExportDetails from 'views/MemberExport/MemberExportDetails'
import { MemberExportList } from 'views/MemberExport/MemberExportList'
import { MemberExportView } from 'views/MemberExport/MemberExportView'
import CopyMember from 'views/Members/CopyMember'
import { HistoryDetails } from 'views/Members/HistoryDetails'
import MemberArchive from 'views/Members/MemberArchive'
import { MemberDetails } from 'views/Members/MemberDetails'
import { MemberHistoryDetails } from 'views/Members/MemberHistoryDetails'
import MemberMigration from 'views/Members/MemberMigration'
import MemberNotifications from 'views/Members/MemberNotifications'
import MemberNotificationsDetail from 'views/Members/MemberNotificationsDetail'
import MemberNotifyChange from 'views/Members/MemberNotifyChange'
import { MembersList } from 'views/Members/MembersList'
import OrderCreate from 'views/Orders/OrderCreate'
import OrderIssuedDetails from 'views/Orders/OrderIssuedDetails'
import OrderReceivedDetails from 'views/Orders/OrderReceivedDetails'
import OrdersIssued from 'views/Orders/OrdersIssued'
import OrdersReceived from 'views/Orders/OrdersReceived'
import { RegionCreate } from 'views/Region/RegionCreate'
import { RegionDetails } from 'views/Region/RegionDetails'
import { RegionList } from 'views/Region/RegionList'
import { RegionUpdate } from 'views/Region/RegionUpdate'
import { RegionFunctions } from 'views/RegionFunctions/RegionFunctions'
import { RegionFunctionsCreate } from 'views/RegionFunctions/RegionFunctionsCreate'
import AccessDeclarationReq from 'views/Rodo/Access/AccessDeclarationReq'
import AccessDeclarationsList from 'views/Rodo/Access/AccessDeclarationsList'
import AccessMemberReq from 'views/Rodo/Access/AccessMemberReq'
import AccessMembersList from 'views/Rodo/Access/AccessMembersList'
import ResourceDeclarationReq from 'views/Rodo/Resources/ResourceDeclarationReq'
import ResourceDeclarationsList from 'views/Rodo/Resources/ResourceDeclarationsList'
import ResourceMemberReq from 'views/Rodo/Resources/ResourceMemberReq'
import ResourceMembersList from 'views/Rodo/Resources/ResourceMembersList'
import { SectionCreateClub } from 'views/Section/SectionCreateClub'
import { SectionCreateGroup } from 'views/Section/SectionCreateGroup'
import { SectionDetails } from 'views/Section/SectionDetails'
import { SectionList } from 'views/Section/SectionList'
import { SectionMerge } from 'views/Section/SectionMerge'
import { SectionUpdateClub } from 'views/Section/SectionUpdateClub'
import { SectionUpdateGroup } from 'views/Section/SectionUpdateGroup'
import SecureConnectionRedirect from 'views/SecureConnection/SecureConnectionRedirect'
import InstitutionGroups from 'views/Settings/InstitutionGroups/InstitutionGroups'
import InstitutionGroupsCreate from 'views/Settings/InstitutionGroups/InstitutionGroupsCreate'
import InstitutionGroupsUpdate from 'views/Settings/InstitutionGroups/InstitutionGroupsUpdate'
import InstitutionTypes from 'views/Settings/InstitutionTypes/InstitutionTypes'
import InstitutionTypesCreate from 'views/Settings/InstitutionTypes/InstitutionTypesCreate'
import InstitutionTypesUpdate from 'views/Settings/InstitutionTypes/InstitutionTypesUpdate'
import MemberEducations from 'views/Settings/MemberEducations/MemberEducations'
import MemberEducationsCreate from 'views/Settings/MemberEducations/MemberEducationsCreate'
import MemberEducationsUpdate from 'views/Settings/MemberEducations/MemberEducationsUpdate'
import MemberPositions from 'views/Settings/MemberPositions/MemberPositions'
import MemberPositionsCreate from 'views/Settings/MemberPositions/MemberPositionsCreate'
import MemberPositionsUpdate from 'views/Settings/MemberPositions/MemberPositionsUpdate'
import MemberSubjects from 'views/Settings/MemberSubjects/MemberSubjects'
import MemberSubjectsCreate from 'views/Settings/MemberSubjects/MemberSubjectsCreate'
import MemberSubjectsUpdate from 'views/Settings/MemberSubjects/MemberSubjectsUpdate'
import Settings from 'views/Settings/Settings'
import TagCreate from 'views/Settings/Tags/TagCreate'
import TagsList from 'views/Settings/Tags/TagsList'
import TagUpdate from 'views/Settings/Tags/TagUpdate'
import { StatisticalObligationConfirm } from 'views/StatisticalObligation/branch/StatisticalObligationConfirm'
import { StatisticalObligationMain } from 'views/StatisticalObligation/branch/StatisticalObligationMain'
import { MainManagment } from 'views/StatisticalObligation/MainManagment/MainManagment'
import { MainManagmentCreate } from 'views/StatisticalObligation/MainManagment/MainManagmentCreate'
import { MainManagmentList } from 'views/StatisticalObligation/MainManagment/MainManagmentList'
import { StatisticalObligationRegionMain } from 'views/StatisticalObligation/region/StatisticalObligationMain'
import { StatisticalObligationSectionList } from 'views/StatisticalObligation/section/StatisticalObligationSectionList'
import { StatisticalObligation } from 'views/StatisticalObligation/StatisticalObligation'
import UnitPositions from 'views/Structure/UnitPosition/UnitPositions'
import UnitPositionsCreate from 'views/Structure/UnitPosition/UnitPositionsCreate'
import UnitPositionsUpdate from 'views/Structure/UnitPosition/UnitPositionsUpdate'
import TrainingAddMember from 'views/Training/TrainingAddMember'
import TrainingCreate from 'views/Training/TrainingCreate'
import TrainingDetails from 'views/Training/TrainingDetails'
import TrainingList from 'views/Training/TrainingList'
import TrainingMemberList from 'views/Training/TrainingMemberList'
import TrainingRemoveMember from 'views/Training/TrainingRemoveMember'
import TrainingUpdate from 'views/Training/TrainingUpdate'
import { UserCreate } from 'views/Users/UserCreate'
import { UserDetails } from 'views/Users/UserDetails'
import { UserHistory } from 'views/Users/UserHistory'
import { UserHistoryDetails } from 'views/Users/UserHistoryDetails'
import { UsersList } from 'views/Users/UsersList'

const queryClient = new QueryClient()

function App() {
  // const isSecure = useSelector((state: any) => state.secureConnectionReducer.secure)
  const isSecure = true
  return (
    <CheckSecureConnection>
      <SWRConfig>
        <QueryClientProvider client={queryClient}>
          <AuthWrapper>
            <Toaster richColors />
            <BrowserRouter>
              <Routes>
                <Route path='/postlogin' element={<LoadingLoginPage />} />
                {/* Domyślne przekierowanie na dashboard: */}
                <Route path='/' element={<Navigate to={path.dashboard} replace />} />
                <Route path='/' element={<ProtectedRoute />}>
                  <Route path={path.dashboard} element={<Dashboard />} />
                  {/* Użytkownicy */}
                  <Route path={path.user.list} element={<UsersList />} />
                  <Route path={path.user.details} element={<UserDetails />} />
                  <Route path={path.user.history} element={<UserHistory />} />
                  <Route path={path.user.historyDetails} element={<UserHistoryDetails />} />
                  <Route path={path.user.create} element={<UserCreate />} />
                  <Route path={path.user.create} element={<UserCreate />} />
                  <Route path={path.user.update} element={<CopyMember />} />

                  {/* Członkowie */}
                  <Route path={path.member.list} element={<MembersList />} />
                  <Route path={path.member.details} element={<MemberDetails />} />
                  <Route path={path.member.archive} element={<MemberArchive />} />
                  <Route path={path.member.notifications} element={<MemberNotifications />} />
                  <Route
                    path={path.member.notificationsDetails}
                    element={<MemberNotificationsDetail />}
                  />
                  <Route path={path.member.notify} element={<MemberNotifyChange />} />
                  <Route path={path.member.migration} element={<MemberMigration />} />
                  <Route path={path.member.history} element={<MemberHistoryDetails />} />
                  <Route path={path.member.historyDetails} element={<HistoryDetails />} />
                  {/* <Route path={path.member.create} element={<AddMember />} />
                <Route path={path.member.copy} element={<CopyMember />} /> */}

                  {/* Eksport danych członka */}
                  <Route path={path.export.list} element={<MemberExportList />} />
                  <Route path={path.export.request} element={<MemberExportView />} />
                  <Route path={path.export.details} element={<MemberExportDetails />} />

                  {/* Szkolenia */}
                  <Route path={path.training.list} element={<TrainingList />} />
                  <Route path={path.training.details} element={<TrainingDetails />} />
                  <Route path={path.training.detailsUpdate} element={<TrainingUpdate />} />
                  <Route path={path.training.create} element={<TrainingCreate />} />
                  <Route path={path.training.addMember} element={<TrainingAddMember />} />
                  <Route path={path.training.removeMember} element={<TrainingRemoveMember />} />
                  <Route path={path.training.listMembers} element={<TrainingMemberList />} />

                  {/* Polecenia */}
                  <Route path={path.orders.create} element={<OrderCreate />} />
                  <Route path={path.orders.receive} element={<OrdersReceived />} />
                  <Route path={path.orders.receiveDetails} element={<OrderReceivedDetails />} />
                  <Route path={path.orders.issued} element={<OrdersIssued />} />
                  <Route path={path.orders.issuedDetails} element={<OrderIssuedDetails />} />

                  {/* Dokumenty */}
                  <Route path={path.document.list} element={<DocumentList />} />
                  <Route path={path.document.create} element={<DocumentCreate />} />
                  <Route path={path.document.details} element={<DocumentDetails />} />

                  {/* Obowiązek statystyczny - spis */}
                  <Route path={path.census.list} element={<CensusList />} />
                  <Route path={path.census.listMe} element={<CensusListMe />} />
                  <Route path={path.census.create} element={<CensusCreate />} />
                  <Route path={path.census.details} element={<CensusDetails />} />
                  <Route path={path.census.edit} element={<CensusUpdate />} />
                  <Route path={path.census.dashboard} element={<CensusDashboardAdmin />} />
                  <Route path={path.census.region} element={<CensusDashboardRegion />} />
                  <Route path={path.census.branch} element={<CensusDashboardBranch />} />
                  <Route path={path.census.section} element={<CensusDashboardSection />} />
                  <Route
                    path={path.census.sectionAsBranch}
                    element={<CensusDashboardSectionAsBranch />}
                  />
                  <Route
                    path={path.census.sectionSummary}
                    element={<CensusDashboardSectionSummary />}
                  />
                  <Route
                    path={path.census.sectionSummaryAsBranch}
                    element={<CensusDashboardSectionSummaryAsBranch />}
                  />

                  {/* Declarations */}
                  {isSecure ? (
                    <>
                      <Route path={path.declaration.list} element={<Declarations />} />
                      <Route path={path.declaration.details} element={<DeclarationDetails />} />
                      <Route path={path.declaration.create} element={<DeclarationCreate />} />
                      <Route path={path.declaration.update} element={<DeclarationUpdate />} />
                    </>
                  ) : (
                    <Route
                      path={path.declaration.base}
                      element={<Navigate to={path.vpn.redirect} />}
                    />
                  )}

                  {/* Okręg */}
                  <Route path={path.region.list} element={<RegionList />} />
                  <Route path={path.region.create} element={<RegionCreate />} />
                  <Route path={`${path.region.details}:id`} element={<RegionDetails />} />
                  <Route path={`${path.region.update}:id`} element={<RegionUpdate />} />

                  {/* Placówka */}
                  <Route path={path.institution.list} element={<InstitutionList />} />
                  <Route path={path.institution.details} element={<InstitutionDetails />} />
                  <Route path={path.institution.create} element={<InstitutionCreate />} />
                  <Route path={path.institution.update} element={<InstitutionUpdate />} />

                  {/* Oddział */}
                  <Route path={path.branch.list} element={<BranchList />} />
                  <Route path={path.branch.archive} element={<BranchList archive />} />
                  <Route path={path.branch.create} element={<BranchCreate />} />
                  <Route path={path.branch.merge} element={<BranchMerge />} />
                  <Route path={path.branch.details} element={<BranchDetails />} />
                  <Route path={path.branch.update} element={<BranchUpdate />} />
                  <Route path={path.branch.migrations} element={<BranchMigrations />} />
                  <Route path={path.branch.migration} element={<BranchMigrationDetail />} />

                  {/* Ognisko */}
                  <Route path={path.section.list} element={<SectionList />} />
                  <Route path={path.section.archive} element={<SectionList archive />} />
                  <Route path={path.section.createGroup} element={<SectionCreateGroup />} />
                  <Route path={path.section.createClub} element={<SectionCreateClub />} />
                  <Route path={path.section.merge} element={<SectionMerge />} />
                  <Route path={path.section.updateClub} element={<SectionUpdateClub />} />
                  <Route path={path.section.updateGroup} element={<SectionUpdateGroup />} />
                  <Route path={path.section.details} element={<SectionDetails />} />

                  {/* Funkcje w oddziale (główny sidebar) */}
                  <Route path={path.branchFunctions.list} element={<BranchFunctions />} />
                  <Route path={path.branchFunctions.create} element={<BranchFunctionsCreate />} />
                  <Route path={path.branchFunctions.update} element={<BranchFunctionsUpdate />} />

                  {/* Funkcje w okręgu (główny sidebar) */}
                  <Route path={path.regionFunctions.list} element={<RegionFunctions />} />
                  <Route path={path.regionFunctions.create} element={<RegionFunctionsCreate />} />
                  {/* Obowiązek statystyczny - ognisko */}
                  <Route
                    path={path.statisticalObligation.list}
                    element={<StatisticalObligation />}
                  />
                  <Route
                    path={path.statisticalObligation.section.list}
                    element={<StatisticalObligationSectionList />}
                  />
                  <Route
                    path={path.statisticalObligation.mainManagment.main}
                    element={<MainManagment />}
                  />
                  <Route
                    path={path.statisticalObligation.mainManagment.create}
                    element={<MainManagmentCreate />}
                  />
                  <Route
                    path={path.statisticalObligation.branch.main}
                    element={<StatisticalObligationMain />}
                  />
                  <Route
                    path={path.statisticalObligation.branch.confirm}
                    element={<StatisticalObligationConfirm />}
                  />
                  <Route
                    path={path.statisticalObligation.region.main}
                    element={<StatisticalObligationRegionMain />}
                  />

                  {/* Zarządzanie (główny sidebar) */}
                  <Route
                    path={path.statisticalObligation.mainManagment.list}
                    element={<MainManagmentList />}
                  />
                  {/* Ustawienia */}
                  <Route path={path.settings.main} element={<Settings />} />

                  <Route path={path.settings.institution.list} element={<InstitutionTypes />} />
                  <Route
                    path={path.settings.institution.create}
                    element={<InstitutionTypesCreate />}
                  />
                  <Route
                    path={path.settings.institution.update}
                    element={<InstitutionTypesUpdate />}
                  />

                  <Route
                    path={path.settings.institutionGroup.list}
                    element={<InstitutionGroups />}
                  />
                  <Route
                    path={path.settings.institutionGroup.create}
                    element={<InstitutionGroupsCreate />}
                  />
                  <Route
                    path={path.settings.institutionGroup.update}
                    element={<InstitutionGroupsUpdate />}
                  />

                  {/* TAGI */}
                  <Route path={path.settings.tags.list} element={<TagsList />} />
                  <Route path={path.settings.tags.create} element={<TagCreate />} />
                  <Route path={path.settings.tags.update} element={<TagUpdate />} />

                  <Route path={path.settings.position.list} element={<MemberPositions />} />
                  <Route path={path.settings.position.create} element={<MemberPositionsCreate />} />
                  <Route path={path.settings.position.update} element={<MemberPositionsUpdate />} />

                  <Route path={path.settings.education.list} element={<MemberEducations />} />
                  <Route
                    path={path.settings.education.create}
                    element={<MemberEducationsCreate />}
                  />
                  <Route
                    path={path.settings.education.update}
                    element={<MemberEducationsUpdate />}
                  />

                  <Route path={path.structure.unitPositions.list} element={<UnitPositions />} />
                  <Route
                    path={path.structure.unitPositions.create}
                    element={<UnitPositionsCreate />}
                  />
                  <Route
                    path={path.structure.unitPositions.update}
                    element={<UnitPositionsUpdate />}
                  />

                  <Route path={path.settings.subject.list} element={<MemberSubjects />} />
                  <Route path={path.settings.subject.create} element={<MemberSubjectsCreate />} />
                  <Route path={path.settings.subject.update} element={<MemberSubjectsUpdate />} />
                  {/* RODO */}
                  <Route path={path.rodo_resource.members.list} element={<ResourceMembersList />} />
                  <Route path={path.rodo_resource.members.ask} element={<ResourceMemberReq />} />
                  <Route
                    path={path.rodo_resource.declarations.list}
                    element={<ResourceDeclarationsList />}
                  />
                  <Route
                    path={path.rodo_resource.declarations.ask}
                    element={<ResourceDeclarationReq />}
                  />
                  {/* RODO OPERATIONS */}
                  {/* RESOLUTIONS */}
                  <Route path={path.rodo_access.members.list} element={<AccessMembersList />} />
                  <Route path={path.rodo_access.members.ask} element={<AccessMemberReq />} />
                  <Route
                    path={path.rodo_access.declarations.list}
                    element={<AccessDeclarationsList />}
                  />
                  <Route
                    path={path.rodo_access.declarations.ask}
                    element={<AccessDeclarationReq />}
                  />
                  <Route path={path.resolution.list} element={<LegaList />} />
                  <Route path={path.resolution.create} element={<LegalCreate />} />
                  <Route path={path.resolution.update} element={<LegalUpdate />} />
                  {/* <Route path={path.resolution.list} element={<UnitPositions/>} />
                <Route path={path.resolution.create} element={<UnitPositionsCreate/>} />
                <Route path={path.resolution.update} element={<UnitPositionsUpdate/>} /> */}

                  {/* LEGITIMATIONS */}
                  <Route path={path.card.showCard} element={<CardDetails />} />
                  <Route path={path.card.list} element={<CardList />} />
                  <Route path={path.card.verify} element={<CardVerify />} />
                  <Route path={path.card.request.list} element={<CardsListRequest />} />
                  <Route path={path.card.request.create} element={<CardCreate />} />
                  <Route path={path.card.request.details} element={<CardReqDetails />} />

                  {/* VPN */}
                  <Route path={path.vpn.redirect} element={<SecureConnectionRedirect />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthWrapper>
        </QueryClientProvider>
      </SWRConfig>
    </CheckSecureConnection>
  )
}

export default App
