import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import { LoadingSection } from 'components/Atoms/LoadingSection'
import { TagAlertModal } from 'components/Atoms/TagAlertModal'
import ErrorCard from 'components/ErrorCard'
import SettingsFormBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { tagErrors } from 'error-data/tags'
import { getTag, getTagsList, updateTag } from 'fetchers/tagsFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery } from 'tools/queryHelpers'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

export const TagUpdate = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [current, setCurrent] = useState('')
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, id } = useAuth()
  const listPath = path.settings.tags.list

  const {
    data: tag,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.tag, id],
    queryFn: () => getTag(id),
  })

  const { data, isLoading: listIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.tagsList],
    queryFn: () => getTagsList(),
    retry: errorQuery,
  })

  const tagsList = data?.items ? data?.items.map((item: any) => item.name) : []

  const mutation = useMutation({
    mutationFn: (data: INameForm) => updateTag(tag?.id, data),
    onSuccess: () => {
      successToast('Zaktualizowano tag')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.tagsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, tagErrors.update, 'Nie udało się zaktualizować tagu.')
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować tagu.')
    }
  }

  const checkTag = async (data: IBasicSettingForm) => {
    if (tagsList.includes(data.name)) {
      setCurrent(data.name)
      setShowModal(true)
    } else {
      await onSubmit({ name: data.name })
    }
  }

  const cancelHandler = () => navigate(listPath)
  const handleClose = () => setShowModal(false)

  const breadcrumbLink = (
    <>
      <Link to={listPath}>Tagi</Link> / Edytuj
    </>
  )
  if (isLoading || listIsLoading) return <LoadingSection />
  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania tagu'}
        onCancel={cancelHandler}
        buttonLabel={'Lista tagów'}
      />
    )
  }
  return (
    <>
      <SettingsFormBasic
        cardLabel={'Zaktualizuj tag'}
        initData={{ name: tag?.name }}
        inputLabel={'Nazwa tagu'}
        inputPlaceholder={'Zaktualizuj tag'}
        onSubmit={checkTag}
        onCancelHandler={cancelHandler}
        breadcrumbLink={breadcrumbLink}
        isSending={isSending}
      />
      <TagAlertModal
        showModal={showModal}
        isSending={isSending}
        name={current}
        handleClose={handleClose}
        confirmSend={onSubmit}
      />
    </>
  )
}

export default TagUpdate
