import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

import { generatePagination } from 'tools/paginator'

type IPaginate = {
  value: number
  label?: string
}
type Props = {
  // pagination: IPagination ,
  handlePageChange: (currentPage: number) => void
  lastPage: number
  currentPage: number
}

export const Pagination = ({ currentPage, handlePageChange, lastPage }: Props) => {
  const scrollToTop = () => {
    const element = document.querySelector('table')
    element?.scrollIntoView({ behavior: 'smooth' })
  }
  const pageButtons = generatePagination(currentPage, lastPage)
  const changePage = (page: number) => {
    handlePageChange(page)
    scrollToTop()
  }

  return (
    <div>
      <nav className='isolate inline-flex -space-x-px rounded-md shadow-sm' aria-label='Pagination'>
        <button
          onClick={() => {
            handlePageChange(currentPage - 1)
            scrollToTop()
          }}
          disabled={currentPage === 1 || currentPage === 0}
          className='relative z-30 inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:cursor-default disabled:bg-white'
        >
          <span className='sr-only'>Previous</span>
          <ChevronLeftIcon className='h-4 w-4' aria-hidden='true' />
        </button>
        {pageButtons.map((e: IPaginate, index) => (
          <button
            key={index + 1}
            onClick={() => {
              if (!e?.label) changePage(e.value)
            }}
            className={`relative inline-flex items-center border ${
              currentPage === e.value
                ? 'z-40 border-indigo-500 bg-indigo-50 text-indigo-600'
                : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
            } px-4 py-1.5 text-sm font-medium focus:z-20`}
          >
            {e?.label || e.value}
          </button>
        ))}
        <button
          onClick={() => {
            handlePageChange(currentPage + 1)
            scrollToTop()
          }}
          disabled={currentPage === lastPage}
          className='relative z-30 inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:cursor-default disabled:bg-white'
        >
          <span className='sr-only'>Next</span>
          <ChevronRightIcon className='h-4 w-4' aria-hidden='true' />
        </button>
      </nav>
    </div>
  )
}
