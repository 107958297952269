import { useState, useMemo } from 'react'

import { useQueries } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Table } from 'components/Atoms/Table'
import { queryKeys } from 'constants/queryKeys'
import { getMembersSectionList } from 'fetchers/membersFetchers'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { translateCardStatus } from 'tools/cardStatus'

interface ISection {
  id: string
  name: string
}

interface IBranchMembersProps {
  sections?: ISection[]
}

export interface IMemberData {
  Header?: string
  id: string
  firstName: string
  lastName: string
  branchName: string
  positions: Array<{ id: string; name: string }>
  card?: {
    id: string
    number: string
    status: string
  }
  sectionName?: string
}

const BranchMembers: React.FC<IBranchMembersProps> = ({ sections = [] }) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const memberQueries = useQueries({
    queries: sections.map((section) => ({
      queryKey: [userToken, queryKeys.memberBranchList, section.id, page],
      queryFn: () => getMembersSectionList(section.id, page, 10),
      retry: useRetryHandler({
        resourceName: 'BranchMembers memberList',
        maxRetries: 1,
      }),
    })),
  })

  const isLoading = memberQueries.some((query) => query.isLoading)
  const isError = memberQueries.some((query) => query.isError)

  const allMembers = useMemo(() => {
    const members: IMemberData[] = []
    memberQueries.forEach((query, index) => {
      if (query.data?.items) {
        const sectionName = sections[index].name
        const sectionMembers = query.data.items.map((member: any) => ({
          ...member,
          sectionName,
        }))
        members.push(...sectionMembers)
      }
    })
    return members
  }, [memberQueries, sections])

  const showMemberDetails = (id: string) => {
    navigate(`/member/${id}`)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Imię i nazwisko członka',
        accessor: (row: IMemberData) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: 'Ognisko',
        accessor: (row: IMemberData) => row.sectionName || '-',
      },
      {
        Header: 'Nr legitymacji',
        accessor: (row: IMemberData) => row.card?.number || '-',
      },
      {
        Header: 'Status legitymacji',
        accessor: (row: IMemberData) => translateCardStatus(row?.card?.status ?? '-') || '-',
      },
      {
        Header: 'Akcje',
        Cell: ({ row }: { row: { original: IMemberData } }) => (
          <div className="flex gap-2">
            <HoverIcon
              iconName="EyeIcon"
              title="Zobacz"
              onClick={() => showMemberDetails(row.original.id)}
            />
          </div>
        ),
      },
    ],
    []
  )

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-8 text-gray-500">
      <p className="text-lg">Brak powiązanych członków w oddziale</p>
    </div>
  )

  if (isLoading) {
    return <LoadingSection />
  }

  if (isError) {
    return (
      <Card label="Powiązani członkowie">
        <p className="text-red-500">Wystąpił błąd podczas ładowania danych</p>
      </Card>
    )
  }

  return (
    <Card label="Powiązani członkowie">
      {allMembers.length === 0 ? (
        <EmptyState />
      ) : (
        <Table
          data={allMembers}
          columns={columns}
          pagination={{
            currentPage: page,
            lastPage: memberQueries[0]?.data?.pagination?.lastPage || 1,
            onPageChange: setPage,
          }}
        />
      )}
    </Card>
  )
}

export default BranchMembers
