import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import SearchableDropdown from 'components/Atoms/SearchableDropdown/SearchableDropdown'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { errorMessage } from 'constants/errorMessage'
import { path } from 'constants/path'
import { sex } from 'constants/sex'
import { createDeclarationUserSchema } from 'schemas/declarationsSchema'
import { setupPostal } from 'tools/formTools'
import { IDeclaration, IDeclarationFileType } from 'types/declaration'
import { IOption } from 'types/form'

type Props = {
  initData: IDeclaration
  onSubmit: (data: any) => void
  onFileChange: (file: any) => void
  isEditView?: boolean
  isAdmin?: boolean
  isSending?: boolean
  branchList: IOption[]
  educationList: IOption[]
  institutionList: IOption[]
  positionList: IOption[]
  initJobs?: any[]
  subjectList: IOption[]
}

export const DeclarationForm = ({
  isAdmin,
  branchList,
  educationList,
  institutionList,
  positionList,
  subjectList,
  initData,
  isSending,
  onSubmit,
  onFileChange,
  isEditView,
  initJobs = [],
}: Props) => {
  const [postalCode, setPostalCode] = useState(initData.zipCode)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: initData,
    resolver: yupResolver(createDeclarationUserSchema),
  })
  const { fields, append, remove } = useFieldArray({ control, name: 'jobs' })
  const {
    fields: jobsSimpleFields,
    append: appendJobsSimple,
    remove: removeJobsSimple,
  } = useFieldArray({
    control,
    name: 'jobsSimple',
  })

  const handleCancel = () => {
    navigate(path.declaration.list)
  }
  const addWork = () => {
    append({
      institution: { value: '', label: '' },
      position: { value: '', label: '' },
      isMain: false,
      subject: { value: '', label: '' },
    })
  }

  const addSimpleWork = () => {
    appendJobsSimple({
      institution: '',
      position: '',
      isMain: false,
      subject: '',
    })
  }

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0]
    onFileChange(selectedFile)
  }

  const showBranchSelector = isAdmin && branchList && !isEditView
  const declarationFile: IDeclarationFileType | null =
    typeof initData.declarationFile === 'string'
      ? null
      : (initData.declarationFile as IDeclarationFileType)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showBranchSelector && (
        <Card label='Wybierz oddział'>
          <div className='mb-5 grid grid-cols-4'>
            <div className='mr-5'>
              <Controller
                name='branch'
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    handleSelect={({ value }) => onChange(value)}
                    label='Oddział'
                    options={branchList}
                    selectLabel='---'
                    withEmpty
                    error={errors?.branch?.message}
                  />
                )}
              />
            </div>
          </div>
        </Card>
      )}
      <div className='grid grid-cols-5 gap-5'>
        <div className='col-span-3'>
          <Card label='Dane osobowe'>
            <div className='grid grid-cols-3 gap-5'>
              <TextField
                {...register('firstName')}
                error={errors.firstName?.message}
                label='Imię'
                placeholder='Imię'
              />
              <TextField
                {...register('secondName')}
                label='Drugie imię (opcjonalne)'
                placeholder='Drugie imię'
              />
              <TextField
                {...register('lastName')}
                error={errors.lastName?.message}
                label='Nazwisko'
                placeholder='Nazwisko'
              />
              <TextField
                {...register('pesel')}
                error={errors.pesel?.message}
                label='PESEL'
                placeholder='PESEL'
              />
              <Controller
                name='sex'
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    handleSelect={({ value }) => onChange(value)}
                    label='Płeć'
                    options={sex}
                  />
                )}
              />
            </div>
          </Card>
        </div>

        <div className='col-span-2'>
          <Card label='Adres korespondencyjny'>
            <div className='grid grid-cols-3 gap-5'>
              <TextField
                {...register('zipCode')}
                error={errors.zipCode?.message}
                label='Kod pocztowy'
                placeholder='np. 01-123'
                value={postalCode}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setupPostal(e, setPostalCode)
                }}
              />
              <TextField
                {...register('city')}
                error={errors.city?.message}
                label='Miasto'
                placeholder='np. Warszawa'
              />
              <TextField
                {...register('street')}
                error={errors.street?.message}
                label='Ulica'
                placeholder='Ulica'
              />
              <TextField
                {...register('houseNr')}
                error={errors.houseNr?.message}
                label='Nr domu'
                placeholder='nr domu'
              />
              <TextField
                {...register('apartmentNr')}
                label='Nr mieszkania'
                placeholder='nr mieszkania'
              />
            </div>
          </Card>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-5'>
        <Card label='Dane kontaktowe'>
          <div className='grid grid-cols-2 gap-5'>
            <TextField
              {...register('phoneNumber')}
              error={errors.phoneNumber?.message}
              label='Nr telefonu'
              placeholder='Nr telefonu'
            />
            <TextField
              {...register('email')}
              label='Adres email'
              placeholder='np. jan.kowalski@email.com'
            />
          </div>
        </Card>

        <Card label='Wykształcenie'>
          <div className='grid grid-cols-2 gap-5'>
            <Controller
              name='education'
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => onChange(value)}
                  label='Wykształcenie'
                  selectLabel='---'
                  withEmpty
                  options={educationList}
                />
              )}
            />
            <TextField
              {...register('educationSimple')}
              label='Wykształcenie dodatkowe'
              placeholder='opcjonalne'
            />
          </div>
        </Card>
      </div>

      <Card label='Zatrudnienie'>
        <ul className='mt-2'>
          {fields.map((field, index) => (
            <li className={index > 0 ? 'mt-6' : ''} key={`job-${index}-${field.id}`}>
              <div className='grid grid-cols-3'>
                <div className='mr-5'>
                  <SearchableDropdown
                    clearError={() => setError(`jobs.${index}`, { message: '' })}
                    name={`jobs.${index}.institution`}
                    label={index === 0 ? 'Główne miejsce pracy' : ''}
                    register={register}
                    defaultOption={initJobs[index]?.institution}
                    options={institutionList}
                    control={control}
                  />

                  <p className='mt-2 text-sm text-red-600'>
                    {errors?.jobs?.[index]?.message && errorMessage.requiredField}
                    {errors?.jobs?.[index]?.institution?.message && errorMessage.requiredField}
                  </p>
                </div>
                <div className='mr-5'>
                  <SearchableDropdown
                    clearError={() => setError(`jobs.${index}`, { message: '' })}
                    name={`jobs.${index}.position`}
                    label={index === 0 ? 'Stanowisko' : ''}
                    register={register}
                    defaultOption={initJobs[index]?.position}
                    options={positionList}
                    control={control}
                  />

                  <p className='mt-2 text-sm text-red-600'>
                    {errors?.jobs?.[index]?.message && errorMessage.requiredField}
                    {errors?.jobs?.[index]?.position?.message && errorMessage.requiredField}
                  </p>
                </div>

                <div className='mr-5'>
                  <SearchableDropdown
                    clearError={() => setError(`jobs.${index}`, { message: '' })}
                    name={`jobs.${index}.subject`}
                    label={index === 0 ? 'Przedmiot' : ''}
                    register={register}
                    defaultOption={initJobs[index]?.subject}
                    options={subjectList}
                    control={control}
                  />

                  <p className='mt-2 text-sm text-red-600'>
                    {errors?.jobs?.[index]?.message && errorMessage.requiredField}
                    {errors?.jobs?.[index]?.subject?.message && errorMessage.requiredField}
                  </p>
                </div>

                {index > 0 && (
                  <div>
                    <Button
                      disabled={index === 0}
                      type='button'
                      label='usuń'
                      onClick={() => remove(index)}
                    />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        <div className='grid grid-cols-3'>
          <div className='col-span-3 mx-auto my-6'>
            <Button label='Dodaj kolejne' type='button' onClick={addWork} />
          </div>
        </div>
      </Card>

      <div className='grid grid-cols-5 gap-5'>
        <div className='col-span-3'>
          <Card label='Zatrudnienie dodatkowe'>
            <ul className='mt-2'>
              {jobsSimpleFields.map((field, index) => (
                <li className={index > 0 ? 'mt-6' : ''} key={`job-${index}-${field.id}`}>
                  <div className='grid grid-cols-1 items-end gap-4 sm:grid-cols-2 md:grid-cols-4'>
                    {/* Inputs for job details */}
                    <div className=''>
                      <TextField
                        error={errors?.jobsSimple?.[index]?.message}
                        label={index === 0 ? 'Miejsce pracy' : ''}
                        {...register(`jobsSimple.${index}.institution`)}
                      />
                    </div>
                    <div className=''>
                      <TextField
                        error={errors?.jobsSimple?.[index]?.message}
                        label={index === 0 ? 'Stanowisko' : ''}
                        {...register(`jobsSimple.${index}.position`)}
                      />
                    </div>
                    <div className=''>
                      <TextField
                        error={errors?.jobsSimple?.[index]?.message}
                        label={index === 0 ? 'Przedmiot' : ''}
                        {...register(`jobsSimple.${index}.subject`)}
                      />
                    </div>
                    <div className={index === 0 ? 'mt-5' : ''}>
                      <Button type='button' label='usuń' onClick={() => removeJobsSimple(index)} />
                    </div>
                  </div>
                  {errors?.jobsSimple?.[index]?.message && (
                    <p className='mt-2 text-sm text-red-600'>{errors?.jobsSimple?.[index]?.type}</p>
                  )}
                </li>
              ))}
            </ul>

            <div className='grid grid-cols-1'>
              <div className='col-span-1 mx-auto my-6'>
                <Button label='Dodaj' type='button' onClick={addSimpleWork} />
              </div>
            </div>
          </Card>
        </div>

        <div className='col-span-2'>
          <Card label='Załącznik'>
            <div className='mb-5 flex flex-col text-sm'>
              {declarationFile && (
                <div className='mb-6 flex items-center gap-5'>
                  <p>Wgrano plik: {declarationFile.filename}</p>
                  {/* <Button
                    label='Pobierz plik'
                    variant='secondary'
                    onClick={() => getFile(declarationFile?.id)}/> */}
                </div>
              )}
              <label className='mb-3' htmlFor='declarationDoc'>
                {initData.declarationFile
                  ? 'Podmień skan deklaracji członkowskiej'
                  : 'Dodaj skan deklaracji członkowskiej'}
              </label>
              <input onChange={handleFileChange} id='declarationDoc' type='file' />
            </div>
          </Card>
        </div>
      </div>

      <div className='flex justify-center p-5'>
        <div className='mr-5'>
          <Button label='Anuluj' variant='secondary' onClick={handleCancel} />
        </div>
        <Button
          disabled={isSending}
          label={isEditView ? 'Zaktualizuj deklarację' : 'Utwórz deklarację'}
        />
      </div>
    </form>
  )
}
