import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { BranchBaseInfo } from 'components/Branch/BranchBaseInfo'
import BranchDecorations from 'components/Branch/BranchDecorations'
import { BranchPerformedFunctions } from 'components/Branch/BranchPerformedFunctions'
import { BranchSections } from 'components/Branch/BranchSections'
import { History } from 'components/Utilites/History'
import { queryKeys } from 'constants/queryKeys'
import { getBranch } from 'fetchers/branchFetchers'
import { useArchiveSection } from 'fetchers/hooks/useArchiveSection'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { getStructureFunctionList } from 'fetchers/structureFetcher'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { IOption } from 'types/form'

import BranchMembers from './BranchMembers'

export const BranchDetails = () => {
  const [current, setCurrent] = useState('true')
  const [page, setPage] = useState({
    current: 1,
    limit: 10,
  })
  const { id } = useParams()
  const branchId = id as string
  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const onItemsPerPageChange = (data: IOption) => {
    setPage((prev) => ({ ...prev, limit: Number(data.value) }))
  }
  const changePage = (newPage: number) => {
    setPage((prev) => ({ ...prev, current: newPage }))
  }
  const {
    data: branch,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.branch, branchId],
    queryFn: () => getBranch(branchId),
    retry: useRetryHandler({
      resourceName: 'BranchDetails branch',
      maxRetries: 1,
    }),
  })

  const { data: branchSections, isLoading: branchSectionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, branchId],
    queryFn: () => getSectionsList(1, 100, [{ branch: branchId }]),
    retry: useRetryHandler({
      resourceName: 'BranchDetails branchSections',
      maxRetries: 1,
    }),
  })

  const functionFilters = `?page=${page.current}&perPage=${page.limit}&filter[onlyCurrent]=${current}`

  const { data: members, isLoading: membersIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberSectionList, id, functionFilters],
    queryFn: () => {
      if (!id) return { items: [], pagination: {} }
      return getStructureFunctionList(id, functionFilters)
    },
    retry: useRetryHandler({
      resourceName: 'BranchDetails members',
      maxRetries: 1,
    }),
  })
  const toggleArchivedFunctions = () => {
    setCurrent((prev) => (prev === 'true' ? 'false' : 'true'))
  }

  const lastPage = members?.pagination?.lastPage || 1

  const usersList = members?.items
    ? members.items
        .map((user: any) => {
          return user.unitPositions.map((position: any) => ({
            id: user.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            numberID: user?.numberID,
            functions: position.unitPosition.name,
            section: user?.section?.name,
            card: user.card, // Add card information
            sectionName: user?.section?.name, // Add section name
          }))
        })
        .flat()
    : []

  const sections =
    branchSections?.items?.map((section: { id: string; name: string }) => ({
      id: section.id,
      name: section.name,
    })) || []

  // MODAL HANDLER
  const { showModalHandler, ArchiveModal } = useArchiveSection({ refetch })

  const allowArchive = usersList?.length === 0 && branchSections?.items?.length === 0

  if (isLoading || branchSectionsLoading) {
    return <LoadingSection />
  }
  
  return (
    <>
      <BranchBaseInfo
        data={branch}
        showModalHandler={showModalHandler}
        allowArchive={allowArchive}
      />
      <BranchSections data={branchSections} />
      <BranchMembers sections={sections} />

      <BranchDecorations />
      <BranchPerformedFunctions
        isLoading={membersIsLoading}
        withArchived={current === 'false'}
        toggleArchivedFunctions={toggleArchivedFunctions}
        data={usersList}
        onPageChange={changePage}
        lastPage={lastPage}
        currentPage={page.current}
        onItemsPerPageChange={onItemsPerPageChange}
      />
      <Card label="Historia zmian">
        <History />
      </Card>

      <ArchiveModal />
    </>
  )
}
