import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Typography } from 'components/Atoms/Typography'
import { DeclarationForm } from 'components/Declaration/DeclarationForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { declarationErrors } from 'error-data/declaration'
import { addDeclarationDoc, getDeclaration, updateDeclaration } from 'fetchers/declarationFetcher'
import { getEducationsList } from 'fetchers/educationFetchers'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { getSubjectsList } from 'fetchers/subjectFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { IDeclaration, IDeclarationForm } from 'types/declaration'

import { declarationInit } from './initData'

type Props = {
  initData: IDeclaration
}

export const DeclarationUpdate = () => {
  const [file, setFile] = useState<File>()
  const [isSending, setIsSending] = useState(false)
  const { id: declarationId, userLoaded, userToken, isAdmins, isAllOD } = useAuth()

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const admin = isAdmins()
  const verified = admin || isAllOD()
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.declaration, declarationId, isAdmins],
    queryFn: () => {
      if (!isAdmins) return declarationInit
      return getDeclaration(declarationId)
    },
  })

  const errorStatus = (error as AxiosError)?.response?.status

  // EDUCATIONS
  const { data: education, isLoading: loadingEducation } = useQuery({
    queryKey: [userToken, queryKeys.educationList],
    queryFn: () => getEducationsList(1, 100),
    retry: useRetryHandler({
      resourceName: 'DeclarationsUpdate education',
      maxRetries: 1,
    }),
  })
  // INSTITUTIONS
  const { data: institution, isLoading: loadingInstitution } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, 1, 1000],
    queryFn: () => getInstitutionsList(1, 1000),
    retry: useRetryHandler({
      resourceName: 'DeclarationsUpdate institution',
      maxRetries: 1,
    }),
  })
  // POSITIONS
  const { data: position, isLoading: positionInstitution } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, 1, 1000],
    queryFn: () => getPositionsList(1, 1000),
    retry: useRetryHandler({
      resourceName: 'DeclarationsUpdate subject',
      maxRetries: 1,
    }),
  })
  // SUBJECTS
  const { data: subject, isLoading: subjectInstitution } = useQuery({
    queryKey: [userToken, queryKeys.subjectsList, 1, 1000],
    queryFn: () => getSubjectsList(1, 1000),
    retry: useRetryHandler({
      resourceName: 'DeclarationsUpdate subject',
      maxRetries: 1,
    }),
  })

  const educationList = education?.items
    ? education?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const institutionList = institution?.items
    ? institution?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const positionList = position?.items
    ? position?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const subjectList = subject?.items
    ? subject?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const mutation = useMutation({
    mutationFn: (data: IDeclarationForm) => {
      const fileId: string =
        typeof initData.declarationFile === 'string'
          ? initData.declarationFile
          : initData.declarationFile?.id
      const formData = {
        ...data,
        declarationFile: fileId,
      }
      return updateDeclaration(declarationId, formData)
    },
    onSuccess: () => {
      successToast('Dodano nową deklarację.')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.declarationList] })
      navigate(path.declaration.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        declarationErrors.update,
        'Nie udało się zaktualizować deklaracji.',
      )
    },
  })

  const fileUpload = useMutation({
    mutationFn: (data: IDeclarationForm) => {
      const { redacted, status, ...formData } = data
      return addDeclarationDoc(file)
    },
    onSuccess: (res, data) => {
      mutation.mutate({ ...data, declarationFile: res?.id })
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        declarationErrors.file,
        'Nie udało się zaktualizować deklaracji. Załącznik odrzucony.',
      )
    },
  })

  const onSubmit = async (data: any) => {
    try {
      const hasFile = !!file
      const { declarationDoc, validationResult, ...formData } = data
      const prepData = {
        ...formData,
        jobs: formData.jobs?.map((item: any) => {
          return {
            ...item,
            subject: typeof item?.subject !== 'string' ? '' : item?.subject,
          }
        }),
      }
      if (hasFile) {
        fileUpload.mutate(prepData)
      } else {
        mutation.mutate(prepData)
      }
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować deklaracji.')
    }
  }

  const handleCancel = () => {
    navigate(path.declaration.list)
  }

  if (
    !userLoaded ||
    isLoading ||
    loadingEducation ||
    loadingInstitution ||
    positionInstitution ||
    subjectInstitution
  ) {
    return <LoadingSection />
  }

  const initJobs = data?.jobs
    ? data?.jobs.map(
        (j: {
          institution: { id: string }
          position: { id: string }
          isMain: string
          subject: { id: string }
        }) => {
          return {
            institution: {
              value: j.institution,
              label: institutionList.find((i: any) => i.value === j.institution)?.label || '',
            },
            position: {
              value: j.position,
              label: positionList.find((p: any) => p.value === j.position)?.label || '',
            },
            subject: {
              value: j.subject,
              label: subjectList.find((s: any) => s.value === s.subject)?.label || '',
            },
            isMain: j.isMain,
          }
        },
      )
    : []

  const initData = {
    firstName: data?.firstName || '',
    secondName: data?.secondName || '',
    lastName: data?.lastName || '',
    pesel: data?.pesel || '',
    sex: data?.sex || '',
    phoneNumber: data?.phoneNumber || '',
    email: data?.email || '',
    zipCode: data?.zipCode || '',
    city: data?.city || '',
    street: data?.street || '',
    houseNr: data?.houseNr || '',
    apartmentNr: data?.apartmentNr || '',
    education: data?.education || '',
    educationSimple: data?.educationSimple || '',
    jobs: initJobs,
    jobsSimple: data?.jobsSimple || [],
    branch: data?.branch,
    declarationFile: data?.declarationFile || '',
  }
  if (!verified || errorStatus === 403) return <ErrorRoleModal />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Deklaracja członka: {data?.firstName} {data?.lastName}
      </Typography>
      <DeclarationForm
        onFileChange={setFile}
        branchList={[]}
        educationList={educationList}
        institutionList={institutionList}
        positionList={positionList}
        subjectList={subjectList}
        isAdmin={admin}
        initData={initData}
        onSubmit={onSubmit}
        isEditView={true}
        isSending={isSending}
        initJobs={initJobs}
      />
    </div>
  )
}
