import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { queryKeys } from 'constants/queryKeys'
import { getMemberChangelog } from 'fetchers/membersFetchers'
import { getUserById } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { setupDate, setupLatestUpdate } from 'tools/formTools'

type Props = {
  admittedAt: string
}

export const RecordData = ({ admittedAt }: Props) => {
  const [userId, setUserId] = useState<string>('')
  const { id, userToken } = useAuth()

  // CHANGES
  const { data: secure, isLoading: secureLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberChangelog, id, 'secure'],
    queryFn: () => getMemberChangelog(1, 10, id, '', 'secure'),
    retry: useRetryHandler({
      resourceName: 'RecordData secure',
      maxRetries: 1,
    }),
  })

  const { data: simple, isLoading: simpleLoading } = useQuery({
    queryKey: [userToken, queryKeys.memberChangelog, id, 'simple'],
    queryFn: () => getMemberChangelog(1, 10, id, '', 'simple'),
    retry: useRetryHandler({
      resourceName: 'RecordData simple',
      maxRetries: 1,
    }),
  })

  // ISSUER
  const { data: user, isLoading: userIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.member, userId],
    queryFn: () => {
      if (userId) return getUserById(userId)
      return null
    },
    // enabled: !!userId && typeof userId === 'string',
    retry: useRetryHandler({
      resourceName: 'RecordData user',
      maxRetries: 1,
    }),
  })
  const issuer = user?.displayName || user?.email || '-'

  const secureLast = secure?.items?.[0]?.createdAt
  const simpleLast = simple?.items?.[0]?.createdAt

  const latestChange = secureLast && simpleLast ? setupLatestUpdate(secureLast, simpleLast) : null

  const lastDate = latestChange ? setupDate(new Date(latestChange)) : '-'

  const navigate = useNavigate()

  useEffect(() => {
    if (secureLast && simpleLast && latestChange) {
      const changeUserId =
        latestChange === secureLast ? secure?.items?.[0]?.user : simple?.items?.[0]?.user

      if (typeof changeUserId === 'string' && changeUserId !== userId) {
        setUserId(changeUserId)
      }
    }
  }, [secureLast, simpleLast, latestChange, secure?.items, simple?.items])

  return (
    <Card
      label='Dane rekordu'
      actionsButton={[
        {
          label: 'Pokaż historię',
          handleClick: () => navigate('history'),
        },
      ]}
    >
      <div className='flex'>
        <div className='mr-6'>
          <LabelTypography label='Ostatnia zmiana' text={lastDate} />
        </div>
        <div className='mx-6'>
          <LabelTypography label='Zmienione przez' text={issuer} />
        </div>
        <div className='mx-6'>
          <LabelTypography label='Data utworzenia' text={admittedAt} />
        </div>
        <div className='mx-6'>
          <LabelTypography label='Zmienione przez' text='Import danych - System' />
        </div>
      </div>
    </Card>
  )
}
