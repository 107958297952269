import { Dispatch, SetStateAction } from 'react'

import { IStatusTypes } from 'types/form'

export const setupPostal = (
  e: React.ChangeEvent<HTMLInputElement>,
  setter: Dispatch<SetStateAction<string>>,
) => {
  const input = e.target.value.replace(/\D/g, '')
  if (input.length > 2) {
    setter(input.slice(0, 2) + '-' + input.slice(2, 5))
  } else {
    setter(input)
  }
}

export const setupDate = (data?: Date) => {
  if (!data) {
    return ''
  }
  const date = new Date(data)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const setupTime = (data?: Date) => {
  if (!data) {
    return ''
  }
  const date = new Date(data)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${hours}:${minutes}`
}

export const setupFullDate = (date?: Date) => {
  return `${setupDate(date)} ${setupTime(date)}`
}

export const setupLatestUpdate = (first: string, second: string) => {
  const firstDate = new Date(first)
  const secondDate = new Date(second)
  return firstDate > secondDate ? first : second
}

export const buildFilters = (unredacted: boolean, status: string) => {
  let additionalFilters = unredacted ? 'unredacted=true' : ''

  if (status) {
    additionalFilters += `${additionalFilters ? '&' : ''}filter[status][]=${status}`
  }

  return `&${additionalFilters}`
}

export const statusType = (status: IStatusTypes) => {
  const types = {
    awaiting: 'Oczekujące',
    accepted: 'Zaakceptowane',
    rejected: 'Odrzucone',
  }
  return types[status]
}
