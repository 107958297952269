import { Fragment } from 'react'

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import DOMPurify from 'dompurify'

import { useAppSelector } from 'app/hooks'
import { verifyRole } from 'tools/roleHelpers'

type NotificationType = {
  id: string
  header: string
  content: string
  readAt: string | null
  type: string
  metadata: {
    censusId?: string
    memberId?: string
    migrationRequestId?: string
    exportId?: string
    orderId?: string
    documentId?: string
    changeRequestId?: string
  }
}

type ToastProps = {
  notification: NotificationType
  onMarkAsRead: (id: string) => void
  isOpen: boolean
  handleClose: () => void
}

const sanitizeHtml = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

export const NotificationModal = ({ isOpen, handleClose, notification }: ToastProps) => {
  const auth = useAppSelector((state) => state.authReducer)
  const userContext = auth.userInfo?.context
  const currentRole = userContext?.role
  const currentUnitId = userContext?.unit?.id

  const getCensusUrl = (censusId: string) => {
    if (currentRole && verifyRole.isAllOD(currentRole)) {
      return `/census/dashboard/${censusId}/branch/${currentUnitId}`
    } else if (currentRole && verifyRole.isAllOK(currentRole)) {
      return `/census/dashboard/${censusId}/region/${currentUnitId}`
    } else if (currentRole && verifyRole.isPOG(currentRole)) {
      return `/census/dashboard/${censusId}/section/${currentUnitId}`
    } else {
      return `/census/${censusId}/show`
    }
  }

  const renderActionButton = () => {
    switch (notification.type) {
      case 'changerequest_new':
      case 'changerequest_handled':
        return (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={`/member/notifications/${notification.metadata.changeRequestId}`}
          >
            Przejdź do zgłoszenia
          </a>
        )

      case 'member_addedtounit':
      case 'member_removedfromunit':
        return (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={`/member/${notification.metadata.memberId}`}
          >
            Przejdź do profilu
          </a>
        )

      case 'migrationrequest_rejected':
      case 'migrationrequest_accepted':
      case 'migrationrequest_new':
        return (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={`/branch/migrations/${notification.metadata.migrationRequestId}`}
          >
            Przejdź do wniosku
          </a>
        )

      case 'census_completed':
      case 'census_started':
      case 'census_rejected':
        return notification.metadata.censusId ? (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={getCensusUrl(notification.metadata.censusId)}
          >
            Przejdź do spisu
          </a>
        ) : null

      case 'export_generated':
      case 'export_tomanyresults':
        return (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={`/export/${notification.metadata.exportId}`}
          >
            Przejdź do eksportu
          </a>
        )

      case 'order_ack':
      case 'order_new':
        return (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={`/orders/issued/${notification.metadata.orderId}`}
          >
            Przejdź do polecenia
          </a>
        )

      case 'document_generated':
        return (
          <a
            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            href={`/document/${notification.metadata.documentId}`}
          >
            Przejdź do dokumentu
          </a>
        )

      default:
        return null
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/25' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <DialogPanel className='relative w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <DialogTitle as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {notification.header}
                </DialogTitle>
                <button
                  type='button'
                  className='absolute right-2 top-2 text-gray-400 hover:text-gray-500'
                  onClick={handleClose}
                >
                  <span className='sr-only'>Close</span>
                  <XMarkIcon className='h-6 w-6' />
                </button>

                <div
                  className='mt-2 text-sm text-gray-500'
                  dangerouslySetInnerHTML={sanitizeHtml(notification.content)}
                />

                <div className='mt-4'>{renderActionButton()}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
