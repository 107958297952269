import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { Card } from 'components/Atoms/Card'
import { TextField } from 'components/Atoms/TextField'
import { editMemberSchema } from 'schemas/memberSchema'
import { errorRequires } from 'tools/ToastHelpers'
import { IMemberNaming } from 'types/member'

type Props = {
  closeHandler: () => void
  onSubmit: (data: IMemberNaming) => void
  isSending?: boolean
  initData?: IMemberNaming
}

export const MemberNameEdit = ({ closeHandler, onSubmit, isSending, initData }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMemberNaming>({
    defaultValues: initData,
    resolver: yupResolver(editMemberSchema),
  })
  // const updateHandler = () => {
  //     onSubmit('updateHandler')
  // }
  const onValidationFailure = () => errorRequires(errors)

  return (
    <Card
      label='Zmiana imienia i nazwiska'
      actionsButton={[
        { disabled: isSending, label: 'Anuluj', handleClick: closeHandler },
        {
          disabled: isSending,
          label: 'Zapisz',
          handleClick: handleSubmit(onSubmit, onValidationFailure),
        },
      ]}
    >
      <form
        onSubmit={handleSubmit(onSubmit, onValidationFailure)}
        className='grid grid-cols-4 gap-5'
      >
        <TextField
          {...register('firstName')}
          label='Podaj nowe imię'
          error={errors.firstName?.message}
          placeholder='Pierwsze imię'
        />
        <div className='self-end'>
          <TextField {...register('secondName')} placeholder='Drugie imię (opcjonalnie)' />
          {(errors.firstName?.message || errors.lastName?.message) && <div className='mt-2 h-5' />}
        </div>
        <TextField
          {...register('lastName')}
          error={errors.lastName?.message}
          label='Podaj nowe nazwisko'
          placeholder='Nazwisko'
        />
      </form>
    </Card>
  )
}

export default MemberNameEdit
