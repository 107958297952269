import { InputHTMLAttributes, useEffect, useState } from 'react'

import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import { IOption } from 'types/form'

import { Spinner } from '../Spinner'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface IProps extends InputHTMLAttributes<HTMLSelectElement> {
  options: IOption[]
  label?: string
  selectLabel?: string
  handleSelect?: (selectItem: IOption) => void
  error?: string
  withEmpty?: boolean
  defaultOption?: IOption // Rename defaultValue to defaultOption
  token?: string
  disabled?: boolean
  value?: string
  width?: string
  isLoading?: boolean
}

export const Select: React.FC<IProps> = ({
  value,
  token,
  options,
  label,
  selectLabel,
  handleSelect,
  error,
  disabled,
  withEmpty,
  defaultOption, // Rename defaultValue to defaultOption
  width,
  isLoading,
}) => {
  const [selected, setSelected] = useState<IOption | undefined>(
    defaultOption || (withEmpty ? { label: selectLabel || 'Wybierz', value: '' } : options[0]),
  )

  const onSelect = () => {
    if (selected) {
      handleSelect && handleSelect(selected)
    }
  }

  useEffect(() => {
    onSelect()
  }, [selected])

  useEffect(() => {
    setSelected(
      defaultOption || (withEmpty ? { label: selectLabel || 'Wybierz', value: '' } : options[0]),
    )
  }, [token])

  useEffect(() => {
    if (!value) {
      setSelected(
        defaultOption || (withEmpty ? { label: selectLabel || 'Wybierz', value: '' } : options[0]),
      )
    }
  }, [value])

  return (
    <div style={{ width: width }}>
      <Listbox value={selected} disabled={disabled} onChange={setSelected}>
        {label && <Label className='mb-1 block text-sm font-medium text-gray-700'>{label}</Label>}
        <ListboxButton
          className={clsx(
            'relative block w-full min-w-16 rounded-md border-0 py-1.5 pl-3 pr-8 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600',
            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
            error &&
              'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500',
          )}
        >
          {selected ? (
            <div className='flex items-center gap-2'>
              <span>{selected.label}</span>

              {isLoading && <Spinner />}
            </div>
          ) : (
            <span className='text-gray-500'>{selectLabel}</span>
          )}
          <ChevronDownIcon
            className='group pointer-events-none absolute right-2.5 top-2.5 size-4'
            aria-hidden='true'
          />
        </ListboxButton>
        <ListboxOptions
          anchor='bottom'
          transition
          className={clsx(
            'w-[var(--button-width)] rounded-xl border bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
            error && 'border-red-300',
          )}
        >
          {options?.map((option, index) => (
            <ListboxOption
              key={`${option.value}-${index}`}
              value={option}
              className='group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-slate-200'
            >
              {selected?.value === option.value ? (
                <CheckIcon className='aspect-square size-4 min-w-4 group-data-[selected]:visible' />
              ) : (
                <CheckIcon className='invisible aspect-square size-4 min-w-4 group-data-[selected]:visible' />
              )}
              <div className='text-sm/6'>{option.label}</div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  )
}
