import { useState } from 'react'

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { NavLink } from 'react-router-dom'

import { SidebarTab } from '../SidebarTab'

type Props = {
  item: any
  isSidebarVisible: boolean
  onPress: () => void
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const SidebarGroup = ({ item, isSidebarVisible, onPress }: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div>
      <Popover
        className='relative'
        onMouseEnter={() => setIsPopoverVisible(true)}
        onMouseLeave={() => setIsPopoverVisible(false)}
      >
        <PopoverButton className='block w-full text-left focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0'>
          <div
            className={`group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0 ${
              isSidebarVisible && 'cursor-default'
            }`}
            title={item.name}
            onClick={() => {
              if (isSidebarVisible) {
                setIsExpanded((s) => !s)
              }
            }}
          >
            {isSidebarVisible ? (
              <>
                <item.icon
                  className={classNames(
                    item.current ? 'text-gray-400' : 'text-gray-400',
                    'mr-3 h-6 w-6 flex-shrink-0',
                  )}
                  aria-hidden='true'
                />
                <span className='flex-1'>{item.name}</span>
              </>
            ) : (
              <>
                <item.icon
                  className={classNames(
                    item.current ? 'text-gray-400' : 'text-gray-400',
                    'mr-3 h-6 w-6 flex-shrink-0',
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </div>
        </PopoverButton>

        {!isSidebarVisible && (
          <Transition
            show={isPopoverVisible}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <PopoverPanel
              static
              className='absolute left-10 top-[2px] z-50 rounded border border-gray-300 bg-zinc-50 text-gray-600 shadow-lg'
            >
              <div className='z-50 whitespace-nowrap p-2'>
                {/* <p className='text-xs font-medium'>{item.name}</p> */}
                <ul className={classNames(isExpanded ? 'active' : '', 'sidebarGroup')}>
                  {item.children.map((child: any) => (
                    <li
                      key={child.name}
                      className='flex cursor-pointer items-center border-l-2 border-zinc-50 px-3 pb-2 hover:border-indigo-600 hover:text-indigo-600'
                    >
                      <NavLink to={`${child.href}`}>
                        <div
                          className='pt-2 text-xs font-bold'
                          onClick={() => {
                            setIsExpanded(false)
                          }}
                        >
                          {child.name}
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </PopoverPanel>
          </Transition>
        )}

        <ul className={classNames(isExpanded ? 'active' : '', 'sidebarGroup pl-8')}>
          {item.children.map((child: any) => {
            return (
              !!isExpanded &&
              !!isSidebarVisible && (
                <SidebarTab
                  key={child.name}
                  item={child}
                  isSidebarVisible={isSidebarVisible}
                  onPress={() => {
                    onPress()
                  }}
                />
              )
            )
          })}
        </ul>
      </Popover>
    </div>
  )
}

export default SidebarGroup
