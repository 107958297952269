import { useEffect, useRef, useState } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { IBasicSettingForm } from 'types/form'

import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Spinner } from '../Spinner'
import { TextField } from '../TextField'

type Props = {
  type: string
  label: string
  isLoading: boolean
  options: IBasicSettingForm[]
  inputCheck: string[]
  handleInputCheck: (e: IBasicSettingForm) => void
  queryHandler: (e: any) => void
  query: string
  changeLimit?: (value: number) => void
  refetch?: () => void
  searchMore?: () => void
  resultLabel?: string
}

export const SearchMultiselect = ({
  type,
  label,
  options,
  isLoading,
  query,
  inputCheck,
  handleInputCheck,
  queryHandler,
  changeLimit,
  refetch,
  searchMore,
  resultLabel = 'Brak danych',
}: Props) => {
  const [currentList, setCurrentList] = useState<IBasicSettingForm[]>([])
  const [search, setSearch] = useState('')
  const ulRef = useRef<HTMLUListElement>(null)
  const forceReload = () => {
    changeLimit && changeLimit(10)
    queryHandler(search)
    refetch && refetch()
  }
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission on Enter key press
      forceReload()
    }
  }
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const element = e.target as HTMLUListElement
    if (element.scrollHeight - element.scrollTop - 10 < element.clientHeight) {
      searchMore && searchMore()
    }
  }
  useEffect(() => {
    if (options?.length) {
      setCurrentList(options)
    } else if (!isLoading) {
      setCurrentList([])
    }
  }, [options, isLoading])
  useEffect(() => {
    if (options) setCurrentList(options)
  }, [query])
  return (
    <div>
      <div className='mb-2 flex items-end justify-end gap-2'>
        <TextField
          onKeyDown={handleKeyPress}
          label={label}
          type='text'
          name={type}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='flex gap-2'>
          <Button
            variant='secondary'
            type='button'
            onClick={forceReload}
            label={<MagnifyingGlassIcon className='h-4 w-4' />}
          />
          {isLoading && (
            <div className='flex items-center justify-center'>
              <Spinner />
            </div>
          )}
        </div>
      </div>
      <div>
        <ul
          ref={ulRef}
          onScroll={handleScroll}
          className={`h-56 rounded-md border border-solid border-gray-300 ${
            currentList?.length !== 0 ? 'overflow-y-scroll' : ''
          }`}
        >
          {currentList.map((e: any, idx) => {
            return (
              <li
                key={`${e.id}-${idx}-${type}`}
                className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm last:border-b-0'
              >
                <Checkbox
                  label={e.name}
                  id={e.id}
                  value={e.id}
                  checked={inputCheck.includes(e.id)}
                  onChange={() => handleInputCheck(e)}
                />
              </li>
            )
          })}

          {currentList?.length === 0 && (
            <li className='flex gap-2 border-b px-3 py-2 text-sm'>{resultLabel}</li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default SearchMultiselect
