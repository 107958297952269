import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const data = [
  {
    name: 'Styczeń',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Luty',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Marzec',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Kwiecień',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Maj',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Czerwiec',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Lipiec',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]

export const CharArea = () => {
  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer width='100%' height={200}>
        <AreaChart
          width={500}
          height={200}
          data={data}
          syncId='anyId'
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Area type='monotone' dataKey='pv' stroke='#82ca9d' fill='#82ca9d' />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
