import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import LegalSearch from 'components/Legal/LegalSearch'
import {
  IAcceptDeclarationRequest,
  IDeclaration,
} from 'features/Member/declaration/declarationSlice'
import { createSectionSchemaClub } from 'schemas/sectionSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { ICreateSectionClub } from 'types/section'

interface IProps {
  choosenDeclaration: IDeclaration[]
  handleAcceptDeclaration: (data: IAcceptDeclarationRequest) => void
  handleCloseList: () => void
  legalData: any
  branchId: string
}
interface IAcceptedList {
  id: string
}

export const DeclarationAccept = ({
  choosenDeclaration,
  handleAcceptDeclaration,
  handleCloseList,
  legalData,
  branchId,
}: IProps) => {
  const [resolutionError, setResolutionError] = useState(false)
  const [acceptedList, setAcceptedList] = useState<IAcceptedList[]>([])
  const [requestData, setRequestData] = useState<IAcceptDeclarationRequest>({
    items: acceptedList,
    resolution: '',
    date: new Date(),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ICreateSectionClub>({
    resolver: yupResolver(createSectionSchemaClub),
  })

  const setLegal = (e: string) => {
    setValue('document', e)
  }

  const handleSelect = (event: React.FormEvent<HTMLInputElement>, id: string) => {
    if (event.currentTarget.checked) {
      setAcceptedList((prev) => [...prev, { id }])
    } else {
      const updatedList = acceptedList.filter((item) => item.id !== id)
      setAcceptedList(updatedList)
    }
  }

  const handleSelectAll = () => {
    const allIds = choosenDeclaration.map((declaration) => declaration.id)
    setAcceptedList(allIds.map((id) => ({ id })))
  }

  const handleDeselectAll = () => {
    setAcceptedList([])
  }

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'accepted':
        return 'Zaakceptowano'
      case 'rejected':
        return 'Konflikt'
      default:
        return ''
    }
  }
  const sendHandler = async () => {
    try {
      const resolution = getValues('document')
      setResolutionError(typeof resolution !== 'string')
      handleAcceptDeclaration({ ...requestData, resolution })
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaakceptować deklaracji')
    }
  }

  useEffect(() => {
    if (branchId) {
      legalData.setUnit(branchId)
    }
  }, [branchId])

  useEffect(() => {
    setRequestData((prevData) => ({
      ...prevData,
      items: acceptedList,
    }))
  }, [acceptedList])

  return (
    <>
      <Card label='Akceptacja deklaracji członkowskich'>
        <Button
          label='Zaznacz wszystkie'
          onClick={() => {
            handleSelectAll()
            const data = getValues()
          }}
        />
        <Button label='Odznacz wszystkie' onClick={handleDeselectAll} className='ml-2' />

        <div className='mt-6 flow-root'>
          <ul role='list'>
            {choosenDeclaration.map((declaration) => (
              <li
                key={declaration.id}
                className='mb-4 grid w-full max-w-[992px] grid-cols-5 items-start gap-4'
              >
                <span className='col-span-1 mt-1'>
                  <Checkbox
                    id={declaration.id}
                    checked={acceptedList.some((item) => item.id === declaration.id)}
                    onChange={(event) => handleSelect(event, declaration.id)}
                    label={`${declaration.firstName} ${declaration.lastName}`}
                  />
                </span>
                <span className='col-span-4 flex w-full items-start gap-4'>
                  <span
                    className={`border-grey-500 rounded border border-neutral-600 p-1 px-2 text-center text-sm text-neutral-900 ${
                      declaration.status === 'awaiting'
                        ? 'bg-yellow-500'
                        : declaration.status === 'accepted'
                          ? 'bg-green-500'
                          : 'bg-red-500'
                    }`}
                  >
                    {getStatusText(declaration.status)}
                  </span>
                  {declaration.validationResult && !declaration.validationResult.valid && (
                    <span className='border-grey-500 whitespace-pre-wrap rounded border border-neutral-600 p-1 px-2 text-sm text-neutral-900'>
                      <>
                        {declaration.validationResult.errorValidation.map((error, index) => (
                          <span key={index}>
                            {error.property}: {error.error}{' '}
                          </span>
                        ))}
                      </>
                    </span>
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Card>
      <Card label='Dokumenty'>
        <div className='grid grid-cols-5 gap-4'>
          <div>
            <LegalSearch control={control} register={register} legalData={legalData} />
            {resolutionError && <span className='text-red-500'>Wybierz dokument</span>}
          </div>
          <Calendar
            label='Wybierz date przyjęcia'
            date={requestData.date}
            handleDate={(e) => setRequestData({ ...requestData, date: e })}
          />
        </div>
      </Card>
      <div className='mt-6 flex justify-center'>
        <div className='mr-2'>
          <Button label='Anuluj' variant='secondary' onClick={handleCloseList} />
        </div>
        <Button label='Przyjmij do zatwierdzenia' onClick={sendHandler} />
      </div>
    </>
  )
}
