import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'components/shadcn/ui/breadcrumb'
import useAuth from 'hooks/useAuth'

interface BreadcrumbProps {
  censusId?: string
  censusName?: string
  regionId?: string
  regionName?: string
  branchId?: string
  branchName?: string
  sectionId?: string
  sectionName?: string
}

const CensusBreadcrumbs: React.FC<BreadcrumbProps> = ({
  censusId,
  censusName,
  regionId,
  regionName,
  branchId,
  branchName,
  sectionId,
  sectionName,
}) => {
  const { getHighestRoleLevel } = useAuth()
  const highestRole = getHighestRoleLevel()

  const isAdmin = highestRole === 'admin'
  const isRegion = highestRole === 'region'
  const isBranch = highestRole === 'branch'

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {/* Always show census list for admins */}
        {isAdmin ? (
          <BreadcrumbItem>
            <BreadcrumbLink href='/census'>Lista spisów</BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem>
            <BreadcrumbLink href='/census/me'>Lista spisów</BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {censusId && censusName && (
          <>
            <BreadcrumbSeparator />
            {isAdmin ? (
              <BreadcrumbItem>
                <BreadcrumbLink href={`/census/dashboard/${censusId}`}>{censusName}</BreadcrumbLink>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>{censusName}</BreadcrumbItem>
            )}
          </>
        )}

        {regionId && regionName && (
          <>
            <BreadcrumbSeparator />
            {isAdmin || isRegion ? (
              <BreadcrumbItem>
                <BreadcrumbLink href={`/census/dashboard/${censusId}/region/${regionId}`}>
                  {regionName}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>{regionName}</BreadcrumbItem>
            )}
          </>
        )}

        {branchId && branchName && (
          <>
            <BreadcrumbSeparator />
            {isAdmin || isBranch ? (
              <BreadcrumbItem>
                <BreadcrumbLink href={`/census/dashboard/${censusId}/branch/${branchId}`}>
                  {branchName}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>{branchName}</BreadcrumbItem>
            )}
          </>
        )}

        {sectionId && sectionName && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>{sectionName}</BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default CensusBreadcrumbs
