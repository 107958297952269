import { useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { RadioGroup } from 'components/Atoms/RadioGroup'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { TextareaField } from 'components/Atoms/TextareaField'
import { queryKeys } from 'constants/queryKeys'
import { cardErrors } from 'error-data/card'
import { changeCardReqType, checkCardReq, getCardReqTypeDict } from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { cardTypeReqUpdate } from 'schemas/cardSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { ICardReqError, ICardReqSingle, IError } from 'types/cardRequest'

interface IData {
  comment: string
  type: string
}

type Props = {
  selectedId: ICardReqSingle
  closeModal: () => void
  refetch: () => void
  deselectAll: () => void
}

export const CardReqUpdateType = ({ closeModal, selectedId, refetch, deselectAll }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const { userToken, isAllAdmin, isAllOK, isAllOD } = useAuth()

  const isAllow = isAllAdmin || isAllOD() || isAllOK()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { type: 'new', comment: '' },
    resolver: yupResolver(cardTypeReqUpdate),
  })
  const checkListData = [{ id: selectedId.id }]

  // CHECK VALIDIATION
  const {
    data: check,
    isLoading,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardReqCheck, [selectedId]],
    queryFn: () => checkCardReq([{ id: selectedId.id }], 'new'),
  })
  const checkList: ICardReqError[] = check?.items || []
  // END CHECK VALIDIATION

  // REQUEST TYPE
  const {
    data: reqType,
    isLoading: reqTypeLoading,
    error: reqTypeError,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardReqTypeDict],
    queryFn: () => getCardReqTypeDict(),
  })
  const reqTypeList = reqType?.items
    ? reqType?.items?.map((item: any) => {
        return { title: item.name, id: item.id }
      })
    : []
  // END REQUEST TYPE

  // SEND HANDLER
  const mutation = useMutation({
    mutationFn: (data: IData) => changeCardReqType(data.type, data.comment, selectedId.id),
    onSuccess: () => {
      successToast('Zmieniono typ wniosku')
      refetch()
      deselectAll()
      closeModal()
    },
    onError: (error: AxiosError) => {
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(error, cardErrors.type, cardErrors.type.message)
    },
  })

  const onSubmit = async (data: any) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      console.error('Error', error)
      setIsSending(false)
      mutationErrorHandler(null, cardErrors.type, cardErrors.type.message)
    }
  }
  // END SEND HANDLER

  const setRequestType = (e: string) => {
    setValue('type', e)
  }

  // DATA LIST
  const list = [
    {
      ...selectedId,
      errors: checkList?.[0]?.errors || null,
    },
  ]
  // END DATA LIST

  const closeHandler = () => {
    closeModal()
  }

  const listData = list.map((item) => {
    return {
      name: `${item?.member?.firstName} ${item?.member?.lastName}`,
      card: item?.member?.card,
      branch: item?.branch?.name,
      status: item?.status,
      comments: item?.errors,
      id: item?.id,
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Imię i nazwisko',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
      },
      {
        Header: 'Oddział',
        accessor: 'branch',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Walidacja',
        accessor: 'valid',
        Cell: ({ row }: any) => {
          return (
            <span
              className={clsx(
                'border-grey-500 rounded border border-neutral-600 p-1 px-2 text-center text-sm text-neutral-900',
                { 'bg-green-500': !row.original.comments },
                { 'bg-red-500': row.original.comments },
              )}
            >
              {!row.original.comments ? 'OK' : 'Konflikt'}
            </span>
          )
        },
      },
      {
        Header: 'Uwagi',
        accessor: 'comment',
        Cell: ({ row }: any) => {
          return (
            <ul className='list-inside list-disc'>
              {row.original.comments?.map((error: IError, index: number) => (
                <li key={index}>{error?.error}</li>
              ))}
            </ul>
          )
        },
      },
    ],
    [],
  )

  const loadingHandler = getLoadingHandler(error, isLoading || reqTypeLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <section>
      <Card label={'Zmień typ'}>
        <Table columns={columns} data={listData} />
        <RadioGroup
          label={'Typ wniosku'}
          defaultChecked='new'
          onChange={(e) => setRequestType(e.target.value)}
          data={reqTypeList}
        />
        <TextareaField
          placeholder='Komentarz'
          error={errors?.comment?.message}
          {...register('comment')}
        />
        <div className='mt-4 flex justify-center gap-4'>
          <Button
            disabled={isSending}
            label={'Zmień'}
            onClick={handleSubmit(onSubmit)}
            className='ml-2'
          />
          <Button label='Anuluj' variant='secondary' onClick={closeHandler} className='ml-2' />
        </div>
      </Card>
    </section>
  )
}

export default CardReqUpdateType
