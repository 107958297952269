import { useEffect, useMemo } from 'react'

import { flatten } from 'lodash'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Spinner } from 'components/Atoms/Spinner'
import { changeContexts } from 'features/auth/authActions'
import { getRolesAsync } from 'features/Role/roleSlice'

export const ContextSwitcher = (): JSX.Element => {
  const auth = useAppSelector((state) => state.authReducer)
  const dispatch = useAppDispatch()
  const roles = useAppSelector((state) => state.rolesReducer.roles)

  // Fetch roles only if not already loaded
  useEffect(() => {
    if (!roles || flatten(Object.values(roles)).length === 0) {
      dispatch(getRolesAsync())
    }
  }, [roles, dispatch])

  const availableContexts = auth.availableContexts || []

  // Compute role names with useMemo outside of the loop
  const roleNamesMap = useMemo(() => {
    const flatRoles = flatten(Object.values(roles))
    return flatRoles.reduce(
      (acc, role) => {
        acc[role.role] = role.name
        return acc
      },
      {} as Record<string, string>,
    )
  }, [roles])

  if (auth?.contextsLoading) return <Spinner />

  if (auth.contextsError) return <div>error</div>

  return (
    <>
      <hr className='mt-2' />
      <ul className='pt-2'>
        {availableContexts.map((item: any, idx: number) => {
          const isActive =
            auth.userInfo?.context?.role === item.role &&
            auth.userInfo?.context?.unit?.id === item.unit?.id

          const roleName = roleNamesMap[item.role] || item.role // Get roleName from memoized map

          return (
            <li style={{ fontWeight: isActive ? '700' : '400' }} key={`${item.role}-${idx}`}>
              <button
                className='flex w-full flex-col px-4 py-2 text-left text-sm text-gray-700 hover:bg-slate-100'
                onClick={() => {
                  dispatch(
                    changeContexts({
                      context: { role: item.role, unit: item?.unit ? item.unit.id : null },
                      authToken: localStorage.getItem('token') || '',
                    }),
                  )
                }}
              >
                <span>{roleName}</span>
                {!!item?.unit?.name && (
                  <>
                    <span style={{ fontSize: '10px', lineHeight: 1.5 }}>({item.unit.name})</span>
                  </>
                )}
              </button>
            </li>
          )
        })}
      </ul>
    </>
  )
}
