import { reduce } from 'lodash'

const base = {
  declaration: 'member/membership-declaration',
  branch: 'structure/unit/branch',
  region: 'structure/unit/region',
  section: 'structure/unit/section',
}
const getPagination = (page: number, count: number, filters?: string) => {
  return `?page=${page}&perPage=${count}` + filters
}

export const endpoint = {
  // USER DECLARATION
  user: {
    me: 'me',
    availableContexts: 'me/available-contexts',
    changeContext: 'me/change-context',
    role: 'role/list/me',
    roleGlobal: 'role/list',
    create: (id: string) => `user/create/${id}`,
    deleteUserRole: (id: string) => `user/remove-context/${id}`,
  },
  // USER DECLARATION
  users: {
    list: 'user/list',
    listPaginated: (page: number, perPage: number, filters?: string) =>
      'user/list' + getPagination(page, perPage, filters),
    remoteSearch: 'user/remote/search',
    singleUserById: (id?: string) => `user/show/${id}`,
  },
  // LEGAL DECLARATION
  legal: {
    create: 'legal/document/create',
    list: (page: number, perPage: number, filters?: string) =>
      'legal/document/list' + getPagination(page, perPage, filters),
    byId: (id: string) => `legal/document/show/${id}`,
    update: (id: string) => `legal/document/update/${id}`,
  },
  // MEMBERS DECLARATION
  members: {
    archive: (id: string) => `member/archive/${id}`,
    byId: (id: string) => `member/show/${id}/general`,
    decorationList: 'member/decoration/list',
    userAvialableDecorations: 'member/decoration/list/me',
    memberDecorations: (memberId: string) => `member/decoration/list/member/${memberId}`,
    addDecoration: (memberId: string) => `member/decoration/add/${memberId}`,
    sensitive: (id: string) => `member/show/${id}/sensitive`,
    updateSensitive: (id: string) => `member/update/${id}/sensitive`,
    contact: (id: string) => `member/show/${id}/contact`,
    section: (id: string, page: number, perPage: number, filters: string) =>
      `member/list/section/${id}` + getPagination(page, perPage, filters),
    list: (page: number, perPage: number, filters: string) =>
      'member/list' + getPagination(page, perPage, filters),
    functions: (id: string) => `member/unit-term/list/${id}`,
    functionById: (memberId: string, termId: string) =>
      `member/unit-term/show/${memberId}/${termId}`,
    functionCreate: (memberId: string, unitId: string, unitPositionId: string) =>
      `member/unit-term/create/${memberId}/${unitId}/${unitPositionId}`,
    functionUpdate: (memberId: string, termId: string) =>
      `member/unit-term/update/${memberId}/${termId}`,
    updateGeneral: (id: string) => `member/update/${id}/general`,
    updateContact: (id: string) => `member/update/${id}/contact`,
    updateEmployment: (id: string, employmentId: string) =>
      `member/employment/update/${id}/${employmentId}`,
    addEmployment: (id: string) => `member/employment/create/${id}`,
    removeFromSection: (memberId: string, sectionId: string) =>
      `member/remove-from-section/${memberId}/${sectionId}`,
    addToSection: (memberId: string, sectionId: string) =>
      `member/add-to-section/${memberId}/${sectionId}`,
    moveToSection: (memberId: string, sectionId: string) =>
      `member/move-to-section/${memberId}/${sectionId}`,
    changelog: (page: number, perPage: number, memberId: string, filters: string) =>
      `member/changelog/list/${memberId}` + getPagination(page, perPage, filters),
    changelogSingle: (memberId: string, changeId: string) =>
      `member/changelog/show/${memberId}/${changeId}`,
    changelogRevert: (memberId: string, changeId: string) =>
      `member/changelog/revert/${memberId}/${changeId}`,
    tags: (memberId: string) => `member/show/${memberId}/tags`,
  },
  // MIGRATION DECLARATION
  migration: {
    branch: {
      list: (page: number, perPage: number, filters: string) =>
        'member/migration/list' + getPagination(page, perPage, filters),
      create: (memberId: string, branchId: string) =>
        `member/migration/create/${memberId}/${branchId}`,
      details: (migrationId: string) => `member/migration/show/${migrationId}?unredacted=true`,
      cancel: (migrationId: string) => `member/migration/cancel/${migrationId}`,
      accept: (migrationId: string) => `member/migration/accept/${migrationId}`,
      reject: (migrationId: string) => `member/migration/reject/${migrationId}`,
      mass: {
        create: (branchId: string) => `member/migration/create/mass/${branchId}`,
        cancel: 'member/migration/cancel/mass',
        accept: 'member/migration/accept/mass',
        reject: 'member/migration/reject/mass',
      },
    },
    section: {
      mass: (sectionId: string) => `member/move-to-section/mass/${sectionId}`,
    },
  },
  // NOTIFY DECLARATION
  notify: {
    reasons: 'member/change-request/reason/list',
    list: (page: number, perPage: number, filters: string) =>
      'member/change-request/list' + getPagination(page, perPage, filters),
    byId: (requestId: string) => `member/change-request/show/${requestId}`,
    create: (memberId: string) => `member/change-request/create/${memberId}`,
    handle: (requestId: string) => `member/change-request/handle/${requestId}`,
    remove: (requestId: string) => `member/change-request/remove/${requestId}`,
  },
  // MEMBERSHIP DECLARATION
  membership: {
    declarationList: (page: number, perPage: number, status: string) =>
      `member/membership-declaration/list?page=${page}&perPage=${perPage}&${status}`,
    declarationById: (id: string) => `member/membership-declaration/${id}/show`,
    declarationCreate: 'member/membership-declaration/create',
    declarationUpdate: (id: string) => `member/membership-declaration/${id}/update`,
    employmentList: (id: string) => `member/employment/list/${id}`,
    employmentListUpdate: (id: string) => `member/employment/update/${id}/contact`,
    accept: 'member/membership-declaration/accept',
    reject: 'member/membership-declaration/reject',
    positionDictionary: 'member/position/dict',
    institutionDictionary: 'member/institution/dict',
    educationDictionary: 'member/education/dict',
    subjectDictionary: 'member/subject/dict',
    position: {
      create: 'member/position/create',
      list: 'member/position/list',
      show: (id?: string) => `member/position/show/${id}`,
      update: (id?: string) => `member/position/update/${id}`,
    },
    education: {
      create: 'member/education/create',
      list: 'member/education/list',
      show: (id?: string) => `member/education/show/${id}`,
      update: (id?: string) => `member/education/update/${id}`,
    },
    subject: {
      create: 'member/subject/create',
      list: 'member/subject/list',
      show: (id?: string) => `member/subject/show/${id}`,
      update: (id?: string) => `member/subject/update/${id}`,
    },
    unitPosition: {
      create: 'member/unit-position/create',
      list: 'member/unit-position/list',
      show: (id?: string) => `member/unit-position/show/${id}`,
      update: (id?: string) => `member/unit-position/update/${id}`,
    },
    tags: {
      create: 'member/tag/create',
      list: 'member/tag/list',
      available: 'member/tag/list/available',
      byId: (id?: string) => `member/tag/show/${id}`,
      show: (id?: string) => `member/show/${id}/tags`,
      update: (id?: string) => `member/tag/update/${id}`,
      add: (tagId: string, memberId: string) => `member/tag/add/${tagId}/${memberId}`,
      remove: (tagId: string, memberId: string) => `member/tag/remove/${tagId}/${memberId}`,
    },
    termLength: 'member/length-of-term/dict',
  },
  // DECLARATION (WNIOSKI)
  declaration: {
    list: `${base.declaration}/list`,
    create: `${base.declaration}/create`,
    byId: (id?: string) => `${base.declaration}/${id}/show`,
    update: (id?: string) => `${base.declaration}/${id}/update`,
    accept: `${base.declaration}/accept`,
    reject: `${base.declaration}/reject`,
    valid: `${base.declaration}/check-validity`,
    file: {
      upload: `${base.declaration}/file/create`,
      byId: (id?: string) => `${base.declaration}/file/${id}/get`,
      password: (id?: string) => `${base.declaration}/file/${id}/password`,
    },
  },
  // CARD DECLARATION
  card: {
    list: 'card/list',
    getCard: (id: string, filters: string) => `/card/show/${id}` + filters,
    print: 'card/print',
    statusTypes: 'card/dict/status',
    request: {
      list: 'card/request/list',
      create: (memberId: string, type: string) => `card/request/create/${memberId}/${type}`,
      createMass: (type: string) => `card/request/create/mass/${type}`,
      show: (id: string, filters: string) => `card/request/show/${id}` + filters,
      check: (status: string) => `card/request/check/${status}`,
      update: (type: string) => `card/request/${type}`,
      changeType: (cardRequestId: string) => `card/request/change/${cardRequestId}`,
      dict: {
        status: 'card/request/dict/status',
        type: 'card/request/dict/type',
        event: 'card/request/dict/event/type',
      },
    },
  },
  // STRUCTURE DECLARATION
  structure: {
    voivodeship: 'structure/voivodeship/dict',
    baseRegions: 'structure/unit/list/regions/me',
    baseBranches: 'structure/unit/branch/list/me',
    baseSections: 'structure/unit/section/list/me',
    baseStructures: 'structure/unit/structure',
    baseInstitutions: 'institution/list',
    baseInstitutionsMe: 'institution/list/me',
    functions: (id: string, filters: string) => `structure/unit/unit-term/list/${id}` + filters,
    functionStatus: (id: string) => `structure/unit/unit-term/status/${id}`,
    regions: (page: number, count: number) =>
      'structure/unit/list/regions/me?page=' + page + '&perPage=' + count,
    branches: (page: number, count: number, filters?: string) => {
      return 'structure/unit/branch/list/me?page=' + page + '&perPage=' + count + filters
    },
    sectionsUser: (page: number, count: number, filters?: string[]) => {
      if (filters && filters.length > 0) {
        const filterString = reduce(
          filters,
          (result, value) => {
            return `${result}&filter[${value}]`
          },
          '',
        )

        return 'structure/unit/section/list/me?page=' + page + '&perPage=' + count + filterString
      }

      return 'structure/unit/section/list/me?page=' + page + '&perPage=' + count
    },
    list: '',

    // Institutions
    singleInstitutionById: (id?: string) => `structure/unit/institution/show/${id}`,

    // Regions
    singleRegionById: (id?: string) => `structure/unit/region/show/${id}`,
    branchesListInRegion: (id?: string) =>
      `structure/unit/branch/list/me?filter%5Bregion%5D%5B%5D=${id}`,

    // Branches
    singleBranchById: (id?: string) => `structure/unit/branch/show/${id}`,
    sectionsListInBranch: (id?: string) =>
      `structure/unit/list/sections/me?filter%5Bbranch%5D%5B%5D=${id}`,
    branchArchive: (id?: string) => `structure/unit/branch/archive/${id}`,
    // Sections
    sectionsListUser: (page: number, count: number) =>
      'structure/unit/section/list/me?page=' + page + '&perPage=' + count,

    sectionGroups: 'structure/unit/section/subtype/dict/group',

    singleSectionById: (id?: string) => `structure/unit/section/show/${id}`,

    singleSectionClubCreate: 'structure/unit/section/club/create',
    singleSectionClubUpdate: (id?: string) => `structure/unit/section/club/update/${id}`,

    singleSectionGroupCreate: 'structure/unit/section/group/create',
    singleSectionGroupUpdate: (id?: string) => `structure/unit/section/group/update/${id}`,

    sectionArchive: (id?: string) => `structure/unit/section/archive/${id}`,
  },
  role: {
    list: 'role/list',
  },

  // BRANCH DECLARATION
  branch: {
    list: (page: number, count: number, filters?: string) =>
      `${base.branch}/list/me` + getPagination(page, count, filters),
    listGlobal: (page: number, count: number, filters?: string) =>
      `${base.branch}/list` + getPagination(page, count, filters),
    byId: (id?: string) => `${base.branch}/show/${id}`,
    create: `${base.branch}/create`,
    update: (id?: string) => `${base.branch}/update/${id}`,
    archive: (id?: string) => `${base.branch}/archive/${id}`,
    merge: (targetId: string, removeId: string) => `${base.branch}/merge/${targetId}/${removeId}`,
    listDecorations: (id: string) => `member/decoration/list/unit/${id}`,
  },

  // INSTITUTION DECLARATION
  institution: {
    byID: (id?: string) => `institution/show/${id}`,
    singleInstitutionCreate: 'institution/create',
    archive: (id?: string) => `institution/archive/${id}`,
    singleInstitutionUpdate: (id?: string) => `institution/update/${id}`,
    types: {
      create: 'institution/type/create',
      list: 'institution/type/list',
      show: (id?: string) => `institution/type/show/${id}`,
      update: (id?: string) => `institution/type/update/${id}`,
    },
    groups: {
      create: 'institution/group/create',
      list: 'institution/group/list',
      show: (id?: string) => `institution/group/show/${id}`,
      update: (id?: string) => `institution/group/update/${id}`,
    },
  },

  // REGION DECLARATION
  region: {
    list: (page: number, count: number, filters?: string) =>
      `${base.region}/list/me` + getPagination(page, count, filters),
    listGlobal: (page: number, count: number, filters?: string) =>
      `${base.region}/list` + getPagination(page, count, filters),
    byId: (id?: string) => `${base.region}/show/${id}`,
    create: `${base.region}/create`,
    update: (id?: string) => `${base.region}/update/${id}`,
    archive: (id?: string) => `${base.region}/archive/${id}`,
  },

  // SECTION DECLARATION
  section: {
    list: (page: number, count: number, filters?: string) =>
      `${base.section}/list/me` + getPagination(page, count, filters),
    listGlobal: (page: number, count: number, filters?: string) =>
      `${base.section}/list` + getPagination(page, count, filters),
    byId: (id?: string) => `${base.section}/show/${id}`,
    archive: (id?: string) => `${base.section}/archive/${id}`,
    subtype: {
      all: `${base.section}/subtype/dict/all`,
      group: `${base.section}/subtype/dict/group`,
    },
    // SUBTYPES
    club: {
      merge: (targetId: string, removeId: string) =>
        `${base.section}/club/merge/${targetId}/${removeId}`,
      create: `${base.section}/club/create`,
      update: (id?: string) => `${base.section}/club/update/${id}`,
    },
    group: {
      create: `${base.section}/group/create`,
      update: (id?: string) => `${base.section}/group/update/${id}`,
    },
  },
  // RODO
  rodo_resource: {
    password: (id: string) => `rodo/search/resource/file/${id}/password`,
    file: (id: string) => `rodo/search/resource/file/${id}/get`,
    declarations: {
      ask: 'rodo/search/resource/declaration',
      criteria: 'rodo/search/resource/declaration/identity-criteria/dict',
      list: 'rodo/search/resource/declaration/list',
    },
    members: {
      ask: 'rodo/search/resource/member',
      criteria: 'rodo/search/resource/member/identity-criteria/dict',
      list: 'rodo/search/resource/member/list',
    },
  },
  rodo_access: {
    password: (id: string) => `rodo/search/data-access/file/${id}/password`,
    file: (id: string) => `rodo/search/data-access/file/${id}/get`,
    declarations: {
      ask: 'rodo/search/data-access/declaration',
      criteria: 'rodo/search/data-access/declaration/operation-type/dict',
      list: 'rodo/search/data-access/declaration/list',
    },
    members: {
      ask: 'rodo/search/data-access/member',
      criteria: 'rodo/search/data-access/member/operation-type/dict',
      list: 'rodo/search/data-access/member/list',
    },
  },
  // EXPORT MEMBER DATA
  export: {
    member: {
      exportFieldsDict: 'member-export/fields/dict',
      createExport: 'member-export/create',
      exportSingleDetails: (id: string) => `member-export/${id}/show`,
      exportList: (page: number, count: number, filters?: string) =>
        `member-export/list${getPagination(page, count, filters)}`,
      exportById: (id: string) => `member-export/${id}/show`,
    },
    exportPassword: (id: string) => `member-export/file/${id}/password`,
    exportFile: (id: string) => `member-export/file/${id}/get`,
  },

  // TRAINING
  training: {
    list: (page: number, count: number, filters?: string) =>
      `training/list?page=${page}&perPage=${count}${filters}`,
    details: (id: string) => `training/show/${id}`,
    archive: (id: string) => `training/archive/${id}`,
    updateDetails: (id: string) => `training/update/${id}`,
    create: 'training/create',
    addMember: (id: string, memberId: string) => `training/add-member/${id}/${memberId}`,
    trainingMemberList: (id: string) => `training/list-members/${id}`,
    removeMember: (id: string, memberId: string) => `training/remove-member/${id}/${memberId}`,
    addFile: 'training/file/create',
    getFile: (id: string) => `training/file/${id}/get`,
  },

  // ORDERS
  orders: {
    create: 'order/create',
    issuedList: (page: number, count: number, filters?: string) =>
      `order/list/issued?page=${page}&perPage=${count}${filters}`,
    receivedList: (page: number, count: number, filters?: string) =>
      `order/list/received?page=${page}&perPage=${count}${filters}`,
    createFile: 'order/file/create',
    getFile: (id: string) => `order/file/${id}/get`,
    receivedDetails: (id: string) => `order/show/received/${id}`,
    issuedDetails: (id: string) => `order/show/issued/${id}`,
    confirmOrder: (id: string) => `order/ack/${id}`,
    archive: (id: string) => `order/archive/${id}`,
  },

  // DOCUMENTS
  document: {
    create: 'document/create',
    list: (page: number, count: number, filters?: string) =>
      `document/list?page=${page}&perPage=${count}${filters}`,
    details: (id: string) => `document/${id}/show`,
    templateList: (page: number, count: number, filters?: string) =>
      `document/template/list?page=${page}&perPage=${count}${filters}`,
    templateDetails: (id: string) => `document/template/show/${id}`,
    update: (id: string) => `document/update/${id}`,
    archive: (id: string) => `document/archive/${id}`,
    createFile: 'document/file/create',
    getFile: (id: string) => `document/file/${id}/get`,
    getPassword: (id: string) => `document/file/${id}/password`,
  },

  // obowiązek stytystyczny - spis
  census: {
    list: 'census/list',
    listActiveMe: 'census/list/active/me',
    censusListUnit: (id: string, page: number, count: number, filters?: string) =>
      `census/list/${id}?page=${page}&perPage=${count}${filters}`,
    listBranches: (branchId: string) => `census/list/${branchId}`,
    dashboard: (id: string) => `census/dashboard/${id}`,
    dashboardRegion: (censusId: string, regionId: string) =>
      `census/dashboard/${censusId}/region/${regionId}`,
    dashboardBranch: (censusId: string, branchId: string) =>
      `census/dashboard/${censusId}/branch/${branchId}`,
    byId: (id: string) => `census/show/${id}`,
    create: 'census/create',
    update: (id: string) => `census/${id}`,
    archive: (id: string) => `census/archive/${id}`,
    membersSectionList: (censusId: string, sectionId: string) =>
      `census/members/${censusId}/section/${sectionId}`,
    membersBranchList: (censusId: string, branchId: string) =>
      `census/members/${censusId}/branch/${branchId}`,
    markMemberAsConfirmed: (censusId: string, censusMemberId: string) =>
      `census/members/${censusId}/member/${censusMemberId}/mark-as-confirmed`,
    markMemberAsUnconfirmed: (censusId: string, censusMemberId: string) =>
      `census/members/${censusId}/member/${censusMemberId}/mark-as-unconfirmed`,
    markMemberAsRemoved: (censusId: string, censusMemberId: string) =>
      `census/members/${censusId}/member/${censusMemberId}/mark-as-removed`,
    markMemberAsNotRemoved: (censusId: string, censusMemberId: string) =>
      `census/members/${censusId}/member/${censusMemberId}/mark-as-not-removed`,
    addMemberToSection: (censusId: string, sectionId: string) =>
      `census/members/${censusId}/section/${sectionId}/add-member`,
    addMemberToBranch: (censusId: string, branchId: string) =>
      `census/members/${censusId}/branch/${branchId}/add-member`,
    addedMembersSectionList: (censusId: string, sectionId: string) =>
      `census/members/${censusId}/section/${sectionId}/add-member/list`,
    addedMembersBranchList: (censusId: string, branchId: string) =>
      `census/members/${censusId}/branch/${branchId}/add-member/list`,
    branchResult: (censusId: string, branchId: string) =>
      `census/result/${censusId}/branch/${branchId}`,
    sectionResult: (censusId: string, sectionId: string) =>
      `census/result/${censusId}/section/${sectionId}`,
    sectionResultDetails: (censusId: string, sectionId: string) =>
      `census/dashboard/${censusId}/section/${sectionId}/summary-for-section`,

    closeSection: (censusId: string, sectionId: string) =>
      `census/action/${censusId}/section/${sectionId}/close`,
    reopenSection: (censusId: string, sectionId: string) =>
      `census/action/${censusId}/section/${sectionId}/reopen`,
    closeBranch: (censusId: string, branchId: string) =>
      `census/action/${censusId}/branch/${branchId}/close`,
    reopenBranch: (censusId: string, branchId: string) =>
      `census/action/${censusId}/branch/${branchId}/reopen`,
    closeRegion: (censusId: string, regionId: string) =>
      `census/action/${censusId}/region/${regionId}/close`,
    reopenRegion: (censusId: string, regionId: string) =>
      `census/action/${censusId}/region/${regionId}/reopen`,
    close: (censusId: string) => `census/action/${censusId}/close`,
    rejectSectionResults: (censusId: string, sectionId: string) =>
      `census/members/${censusId}/section/${sectionId}/reject`,
    sectionResultsRejections: (censusId: string, sectionId: string) =>
      `census/members/${censusId}/section/${sectionId}/reject`,
    acknowledgeSectionResultsRejection: (
      censusId: string,
      sectionId: string,
      rejectionId: string,
    ) => `census/members/${censusId}/section/${sectionId}/reject/${rejectionId}/ack`,
    survey: (censusId: string) => `census/survey/${censusId}`,
    setCensusSurveyValue: (censusId: string, censusMemberId: string) =>
      `census/members/${censusId}/member/${censusMemberId}/set-survey-value`,
  },

  // notifications
  notifications: {
    list: (page: number, perPage: number, filter?: string) =>
      `notification/list?page=${page}&perPage=${perPage}${filter}`,
    read: (id: string) => `notification/read/${id}`,
    readAll: 'notification/mark-as-read',
  },
}
