import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import { LoadingSection } from 'components/Atoms/LoadingSection'
import { TagAlertModal } from 'components/Atoms/TagAlertModal'
import SettingsFormBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { tagErrors } from 'error-data/tags'
import { createTag, getTagsList } from 'fetchers/tagsFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery } from 'tools/queryHelpers'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

export const TagCreate = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [current, setCurrent] = useState('')
  const navigate = useNavigate()
  const { userToken } = useAuth()
  const queryClient = useQueryClient()
  const listPath = path.settings.tags.list

  const { data, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.tagsList],
    queryFn: () => getTagsList(),
    retry: errorQuery,
  })

  const tagsList = data?.items ? data?.items.map((item: any) => item.name) : []

  const mutation = useMutation({
    mutationFn: (data: INameForm) => createTag(data),
    onSuccess: () => {
      successToast('Dodano nowy tag')
      queryClient.invalidateQueries({ queryKey: [queryKeys.tagsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, tagErrors.create, 'Nie udało się dodać nowego tagu.')
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać nowego tagu.')
    }
  }
  const checkTag = async (data: IBasicSettingForm) => {
    if (tagsList.includes(data.name)) {
      setCurrent(data.name)
      setShowModal(true)
    } else {
      await onSubmit({ name: data.name })
    }
  }

  const cancelHandler = () => navigate(listPath)
  const handleClose = () => setShowModal(false)
  const breadcrumbLink = (
    <>
      <Link to={listPath}>Tagi</Link> / Dodaj
    </>
  )
  if (isLoading) return <LoadingSection />
  return (
    <>
      <SettingsFormBasic
        cardLabel={'Dodaj nowy tag'}
        initData={{ name: '' }}
        inputLabel={'Nazwa tagu'}
        inputPlaceholder={'Wprowadź nowy tag'}
        onSubmit={checkTag}
        onCancelHandler={cancelHandler}
        breadcrumbLink={breadcrumbLink}
        isSending={isSending}
      />
      <TagAlertModal
        showModal={showModal}
        isSending={isSending}
        name={current}
        handleClose={handleClose}
        confirmSend={onSubmit}
      />
    </>
  )
}

export default TagCreate
