import { useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Pagination } from 'components/Atoms/Pagination'
import TableSkeleton from 'components/Atoms/Skeleton/TableSkeleton'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { Typography } from 'components/Atoms/Typography'
import { InstitutionActionCell } from 'components/Institution/InstitutionActionCell'
import InstitutionSearch from 'components/Institution/InstitutionSearch'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { useArchiveInstitution } from 'fetchers/hooks/useArchiveInstitution'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { getLastPage } from 'tools/queryHelpers'
import { IInstitutionListElement, IInstitutionSearchQuery } from 'types/institution'

type IArchiveData = {
  id: string
  name: string
}

export const InstitutionList = () => {
  const [query, setQuery] = useState<IInstitutionSearchQuery>({
    name: '',
    city: '',
  })
  const [queryParams, setQueryParams] = useSearchParams({ page: '1' })
  const page = Number(queryParams.get('page'))
  const navigate = useNavigate()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const [currentPage, setCurrentPage] = useState(1)

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, page, query.city, query.name],
    queryFn: () => getInstitutionsList(Number(page), 100, query),
    retry: useRetryHandler({
      resourceName: 'InstitutionList data',
      maxRetries: 1,
    }),
  })

  const { lastPage } = getLastPage(data?.pagination)

  const institutionData = (data?.items || []).map((item: any): IInstitutionListElement => {
    const firstName = item?.headmaster?.firstName || null
    const lastName = item?.headmaster?.lastName || null
    const name = [firstName, lastName].filter(Boolean).join(' ')
    return {
      id: item.id,
      name: item.name,
      headmaster: name,
      type: item?.type?.name || null,
      memberCount: item?.memberCount,
    }
  })

  const { showModalHandler, ArchiveModal } = useArchiveInstitution({
    refetch,
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Nazwa placówki',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Dyrektor placówki',
        accessor: 'headmaster',
      },
      {
        Header: 'Typ placówki',
        accessor: 'type',
      },
      {
        Header: 'Liczba członków',
        accessor: 'memberCount',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <InstitutionActionCell
              showModalHandler={showModalHandler}
              data={row.row.original}
              route={'institution'}
            />
          )
        },
      },
    ],
    [isAllow],
  )

  const onPageChange = (newPage: any) => {
    newPage === 1 ? setQueryParams() : setQueryParams({ page: newPage })
    setCurrentPage(newPage)
  }

  const queryHandler = (query: IInstitutionSearchQuery) => {
    setQuery(query)
  }
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Lista placówek
      </Typography>
      <InstitutionSearch queryHandler={queryHandler} />
      <div className='mt-5 flex justify-between'>
        <Button
          label='Utwórz placówkę'
          className='justify-center'
          onClick={() => navigate(path.institution.create)}
        />
        <Button disabled label='Zarchiwizowane' />
      </div>
      {isLoading ? <TableSkeleton /> : <Table columns={columns} data={institutionData} />}
      <div className='flex justify-end'>
        <Pagination lastPage={lastPage} currentPage={currentPage} handlePageChange={onPageChange} />
      </div>
      <ArchiveModal />
    </div>
  )
}
