import { AxiosError } from 'axios'

import { IFilters, IMultiFilters, IPagination } from 'types/form'

export const slugify = (text: string) => {
  return text
    .toString()
    .toLocaleLowerCase()
    .normalize('NFD')
    .trim()
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

export const setupFilters = (filters?: IFilters[]) => {
  if (!filters) return ''
  return filters.reduce((acc, filter) => {
    const key = Object.keys(filter)[0]
    const value = Object.values(filter)[0]
    return `${acc}&filter[${key}]=${value}`
  }, '')
}

export const setupSearchFilters = (filters: IFilters[]) => {
  if (!filters || filters.length === 0) return ''
  return filters.reduce((acc, filter) => {
    const key = Object.keys(filter)[0]
    const value = Object.values(filter)[0]
    return `${acc}&filter[${key}]=${value}`
  }, '')
}

export const setupSearchMultiFilters = (filters: IMultiFilters[]) => {
  if (!filters || filters.length === 0) return ''
  const filterData = filters.reduce((acc, filter) => {
    const key = Object.keys(filter)[0]
    const value = filter[key].map((v: string | number) => `filter[${key}][]=${v}`).join('&')
    return acc ? `${acc}&${value}` : value
  }, '')
  if (!filterData) return ''
  return '&' + filterData
}

export const getLastPage = (pagination?: IPagination) => {
  const lastPageUnfiltered = pagination?.lastPage || 1
  const lastPageFiltered = pagination?.lastPageFiltered
  const lastPage = lastPageFiltered || lastPageUnfiltered
  return {
    lastPage,
    totalCount: pagination?.count || 0,
    count: pagination?.countFiltered || pagination?.count || 0,
  }
}

interface ISearchParams {
  region?: string[] | null
  branch?: string[] | null
  section?: string[] | null
  query?: string | null
}

export const userFilters = (data?: ISearchParams | null) => {
  const filters = []
  if (data?.region) filters.push({ region: data.region })
  if (data?.branch) filters.push({ branch: data.branch })
  if (data?.section) filters.push({ section: data.section })
  if (data?.query) filters.push({ search: data.query })
  return filters
}

export const errorQuery = (failureCount: number, error: AxiosError) => {
  const status = Number(error?.response?.status)
  if ([403, 404].includes(status)) return false
  return failureCount < 1
}

export const errorForbiddenHandler = (error: Error | null) => {
  if ((error as AxiosError)?.response?.status === 403) {
    return true
  }
  return false
}

export const errorNotExistsHandler = (error: Error | null) => {
  if ((error as AxiosError)?.response?.status === 404) {
    return true
  }
  return false
}

export const getLoadingHandler = (error: Error | null, isLoading: boolean, invalidRole = false) => {
  const status = (error as AxiosError)?.response?.status
  const route = status === 404
  const forbidden = status === 403 || invalidRole
  return {
    route,
    forbidden,
    isLoading: isLoading || false,
    show: route || forbidden || isLoading,
  }
}
