import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'

export const createMemberNotify = yup.object({
  type: yup.string().required(errorMessage.requiredField),
  reason: yup.string().required(errorMessage.requiredField),
  description: yup.string().min(5, errorMessage.min5Chars).required(errorMessage.requiredField),
  changeDate: yup.string().required(errorMessage.requiredField),
})
