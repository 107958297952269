import { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

import { publicBaseApiURL } from 'api'
import { useAppDispatch } from 'app/hooks'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Button } from 'components/shadcn/ui/button'
import { Card } from 'components/shadcn/ui/card'
import { queryKeys } from 'constants/queryKeys'
import { getRoles, getRolesAsync } from 'features/Role/roleSlice'
import { getBranchesList } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { IOption } from 'types/form'
import { IContext, ISingleUserData } from 'types/userlist'

interface IProps {
  handleVisibleEditField: () => void
  userData: ISingleUserData
  mutate: (id: string) => void
}

interface IRoles {
  id: string | number
  role: IOption
  unit: IOption
  displayLabel?: string
}

const defaultValueSelector: IOption = {
  label: 'Wybierz',
  value: '',
}

const AssignedRolesList: React.FC<{
  roles: IRoles[]
  onRoleDel: (role: IOption, unit: IOption, id: string | number) => void
}> = ({ roles, onRoleDel }) => {
  if (roles.length === 0) {
    return <p className='text-gray-500'>Brak przypisanych ról</p>
  }

  return (
    <div className='space-y-2'>
      {roles.map((role) => (
        <div key={role.id} className='flex items-center justify-between rounded-lg bg-gray-50 p-3'>
          <div>
            <p className='font-medium'>{role.role.label}</p>
            <p className='text-sm text-gray-600'>{role.unit.label}</p>
          </div>
          <Button
            variant='ghost'
            size='lg'
            onClick={() => onRoleDel(role.role, role.unit, role.id)}
            className='text-red-600 hover:text-red-700'
          >
            Usuń
          </Button>
        </div>
      ))}
    </div>
  )
}

export const EditUser: React.FC<IProps> = ({ userData, handleVisibleEditField, mutate }) => {
  const [roles, setRoles] = useState<IRoles[]>([])
  const [selectedRole, setSelectedRole] = useState<IOption>(defaultValueSelector)
  const [selectedUnit, setSelectedUnit] = useState<IOption>(defaultValueSelector)
  const [resetKey, setResetKey] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const r = useSelector(getRoles)
  const dispatch = useAppDispatch()
  const { userToken } = useAuth()

  useEffect(() => {
    dispatch(getRolesAsync())
  }, [])

  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
  }, [selectedRole])

  const selectedRoleUnit = useMemo(() => {
    if (!selectedRole.value || !r) return null

    for (const [key, value] of Object.entries(r)) {
      const role = value.find((i: { role: string; name: string }) => i.role === selectedRole.value)
      if (role) return key
    }

    return null
  }, [selectedRole, r])

  const { data: division, isLoading: isLoadingDivisions } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      queryKeys.regionList,
      queryKeys.sectionsList,
      selectedRole,
    ],
    queryFn: () => {
      if (selectedRoleUnit === 'branch') return getBranchesList(1, 1000, [])
      if (selectedRoleUnit === 'region') return getRegionsList(1, 1000, [])
      if (selectedRoleUnit === 'section') return getSectionsList(1, 1000, [])
      return { items: [] }
    },
  })

  const rolesList = useMemo(() => {
    if (!Array.isArray(Object.values(r))) return []

    return Object.values(r)
      .flat()
      .map((i: { role: string; name: string }) => ({ label: i.name, value: i.role }))
  }, [r])

  const resetSelects = () => {
    setSelectedRole(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
    setResetKey((prev) => prev + 1)
  }

  useEffect(() => {
    if (!userData?.contexts) {
      setRoles([])
      return
    }

    const updatedRoles = userData.contexts.map((e: IContext, index: number) => {
      const matchingRole = rolesList.find((role) => role.value === e?.role)
      return {
        id: index,
        role: {
          label: matchingRole?.label || e?.role,
          value: e?.role,
        },
        unit: {
          label: e?.unit?.name,
          value: e?.unit?.id,
        },
      }
    })

    setRoles(updatedRoles)
  }, [userData, rolesList])

  const handleSubmitRole = async () => {
    if (!selectedRole.value || !selectedUnit.value) return

    setIsSubmitting(true)
    setLoading(true)

    try {
      // Check if role already exists
      const hasExistingRole = roles.some(
        (role) => role.role.value === selectedRole.value && role.unit.value === selectedUnit.value,
      )

      if (hasExistingRole) {
        toast.error('Użytkownik ma już przypisaną tę rolę w tej jednostce')
        return
      }

      const payload = {
        role: selectedRole.value,
        unit: selectedUnit.value,
      }

      await publicBaseApiURL.put(`user/add-context/${userData?.id}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      const newRole: IRoles = {
        id: String(roles.length + 1),
        role: {
          label: selectedRole.label,
          value: selectedRole.value,
        },
        unit: selectedUnit,
      }

      setRoles((prevRoles) => [...prevRoles, newRole])
      toast.success('Pomyślnie dodano rolę')

      resetSelects()
      mutate(userData.id)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          toast.error('Użytkownik ma już przypisaną tę rolę w tej jednostce')
        } else {
          toast.error('Nie udało się dodać roli')
        }
      } else {
        toast.error('Wystąpił nieoczekiwany błąd')
      }
      console.error(error)
    } finally {
      setLoading(false)
      setIsSubmitting(false)
    }
  }

  const handleRoleDel = async (role: IOption, unit: IOption, id: string | number) => {
    setLoading(true)

    try {
      await publicBaseApiURL.delete(`user/remove-context/${userData?.id}`, {
        data: {
          role: role.value,
          unit: unit.value,
        },
        headers: {
          'X-Auth-Token': `Bearer ${localStorage.getItem('token')}`,
        },
      })

      toast.success('Pomyślnie usunięto rolę')
      setRoles((prevRoles) => prevRoles.filter((r) => String(r.id) !== String(id)))
      mutate(userData?.id)
    } catch (error) {
      toast.error('Nie udało się usunąć roli')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const isSubmitDisabled = !selectedRole.value || !selectedUnit.value || isSubmitting

  return (
    <>
      <div className='mt-4 space-y-6'>
        {/* Section 1: Current Roles */}
        <Card className='p-6'>
          <h2 className='mb-4 text-lg font-semibold'>Przypisane role</h2>
          <AssignedRolesList roles={roles} onRoleDel={handleRoleDel} />
        </Card>

        {/* Section 2: Assign New Role */}
        <Card className='p-6'>
          <h2 className='mb-4 text-lg font-semibold'>Przypisz nową rolę</h2>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <Select
                key={`role-${resetKey}`}
                handleSelect={(option) => setSelectedRole(option)}
                options={rolesList}
                label='Rola'
                withEmpty
                defaultOption={defaultValueSelector}
              />
            </div>
            <div>
              {isLoadingDivisions ? (
                <div className='flex h-full items-center justify-center'>
                  <Spinner />
                </div>
              ) : (
                <Select
                  key={`unit-${resetKey}`}
                  handleSelect={(option) => setSelectedUnit(option)}
                  options={(division?.items || []).map((i: any) => ({
                    label: i.name,
                    value: i.id,
                  }))}
                  label='Jednostka struktury'
                  withEmpty
                  defaultOption={defaultValueSelector}
                />
              )}
            </div>
          </div>
          <div className='mt-4 flex justify-end space-x-3'>
            <Button variant='outline' onClick={handleVisibleEditField}>
              Powrót
            </Button>
            <Button onClick={handleSubmitRole} disabled={isSubmitDisabled}>
              {loading ? <Spinner /> : null}
              Przypisz rolę
            </Button>
          </div>
        </Card>
      </div>
    </>
  )
}
