import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'

export const createUnitPositionSchema = yup.object({
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  // unitType: yup.string().required(errorMessage.unitType),
  maxNumber: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  lengthOfTerm: yup.number().required(errorMessage.requiredField),
  lengthOfTermUnit: yup.string().required(errorMessage.requiredField),
})
