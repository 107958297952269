import { useMemo } from 'react'

import clsx from 'clsx'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Modal } from 'components/Atoms/Modal'
import { Table } from 'components/Atoms/Table'
import { ICardReqError, IError } from 'types/cardRequest'
import { TMigration } from 'types/member'
import { IMemberMigrationItem } from 'types/migration'

type Props = {
  sendHandler: (type: TMigration) => void
  closeModal: () => void
  isSending?: boolean
  type?: TMigration
  migrations: IMemberMigrationItem[]
  isOpen: boolean
  fetchErrors: ICardReqError[]
}

export const BranchMigrationMassModal = ({
  sendHandler,
  closeModal,
  isSending,
  type,
  migrations,
  isOpen,
  fetchErrors,
}: Props) => {
  const headerData = {
    accept: 'Zatwierdź zgłoszenia',
    reject: 'Odrzuć zgłoszenia',
    cancel: 'Wycofaj zgłoszenia',
  }

  const header = type ? headerData[type] : ''

  const columns = useMemo(
    () => [
      {
        Header: 'Imię',
        accessor: 'firstName',
      },
      {
        Header: 'Nazwisko',
        accessor: 'lastName',
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
        Cell: ({ row }: any) => {
          const card = row.original.card || '-'
          return <p>{card} </p>
        },
      },
      {
        Header: 'Odddział przekazujący',
        accessor: 'before',
      },
      {
        Header: 'Odddział przejmujący',
        accessor: 'after',
      },
      {
        Header: 'Walidacja',
        accessor: 'valid',
        Cell: ({ row }: any) => {
          const currentError = fetchErrors.find((error) => error.id === row.original.id)
          const waiting = fetchErrors.length === 0
          return (
            <span
              className={clsx(
                'border-grey-500 rounded border border-neutral-600 p-1 px-2 text-center text-sm text-neutral-900',
                { 'bg-green-500': !currentError && !waiting },
                { 'bg-red-500': currentError },
                { 'bg-yellow-500': waiting },
              )}
            >
              {!currentError ? (waiting ? 'Oczekuje' : 'OK') : 'Konflikt'}
            </span>
          )
        },
      },
      {
        Header: 'Uwagi',
        accessor: 'comment',
        Cell: ({ row }: any) => {
          const currentError = fetchErrors.find((error) => error.id === row.original.id)
          if (!currentError) return null
          return (
            <ul className='list-inside list-disc'>
              {currentError.errors?.map((error: IError, index: number) => (
                <li key={`${row.original.id}-${index}`}>{error.error}</li>
              ))}
            </ul>
          )
        },
      },
    ],
    [fetchErrors],
  )

  const sendDataHandler = () => {
    if (type) {
      sendHandler(type)
    }
  }
  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <Card label={header}>
        <div className='bg-white px-4 py-2 text-center'>
          <Table columns={columns} data={migrations} />

          <div className='mt-4 flex justify-center gap-5'>
            <Button label={'Anuluj'} variant='secondary' onClick={closeModal} />
            <Button disabled={isSending} label={'Potwierdź'} onClick={sendDataHandler} />
          </div>
        </div>
      </Card>
    </Modal>
  )
}

export default BranchMigrationMassModal
