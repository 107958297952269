import React, { InputHTMLAttributes } from 'react'

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
  label?: string
  type?: 'email' | 'text' | 'number' | 'password'
  disabled?: boolean
  error?: string
  width?: string
  name?: string
  addClass?: string
  maxLength?: number
}

const errorFieldClass =
  'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 text-sm'
const normalFieldClass =
  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6'
const disabledFieldClass =
  'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 text-sm'
export const TextField = React.forwardRef<HTMLInputElement, IProps>(
  (
    { id, label, addClass, type = 'text', name = 'email', disabled, error, width, ...props },
    ref,
  ) => (
    <div className={`flex-1 ${width || ''} ${addClass || ''}`}>
      <label htmlFor={label} className='mb-1 block text-sm font-medium text-gray-700'>
        {label}
      </label>
      <div className='rounded-md shadow-sm'>
        <input
          id={label}
          disabled={disabled}
          ref={ref}
          type={type}
          name={name} 
          maxLength={props.maxLength}
          className={error ? errorFieldClass : disabled ? disabledFieldClass : normalFieldClass}
          aria-invalid='true'
          {...props}
        />
      </div>

      {error && (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {error}
        </p>
      )}
    </div>
  ),
)
