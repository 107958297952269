import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import SettingGroup from 'components/SettingGroup'

export const Settings = () => {
  const navigate = useNavigate()

  return (
    <Card label='Ustawienia'>
      <div className='mb-4 grid grid-cols-6'>
        <SettingGroup label='Typy placówek' type='institution-type' />
        <SettingGroup label='Grupy placówek' type='institution-group' />
        <SettingGroup label='Tagi' type='tags' />
      </div>
      <div className='mb-4 grid grid-cols-6'>
        <SettingGroup label='Wykształcenie' type='education' />
        <SettingGroup label='Stanowiska' type='position' />
        <SettingGroup label='Przedmioty' type='subject' />
      </div>
    </Card>
  )
}

export default Settings
