import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import MemberListFull from 'components/Members/MemberListFull'
import { useAuth } from 'hooks/useAuth'

export const MembersList = () => {
  const { userToken, unitContext, userLoaded, isPOG, isAllOD, isAllOK, isAllAdmin } = useAuth()
  const isRegion = isAllOK()
  const isBranch = isAllOD()
  const isSection = isPOG()
  const isAdmin = isAllAdmin()

  if (!userLoaded) return <LoadingSection />
  if (isSection) return <ErrorRoleModal />

  return (
    <MemberListFull
      isRegion={isRegion}
      isBranch={isBranch}
      isAdmin={isAdmin}
      userToken={userToken}
      unitContext={unitContext}
      userLoaded={userLoaded}
    />
  )
}
