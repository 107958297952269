import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Modal } from 'components/Atoms/Modal'
import { TextField } from 'components/Atoms/TextField'
import { queryKeys } from 'constants/queryKeys'
import { legalErrors } from 'error-data/legal'
import { createLegal } from 'fetchers/legatFetchers'
import { legalForm } from 'schemas/legalSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { ILegal } from 'types/legal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  data: ILegal
  refetch: () => void
}

export const LegalSearchForm = ({ refetch, isOpen, data, handleClose }: Props) => {
  const [isSending, setIsSending] = useState(false)
  const [docDate, setDocDate] = useState(data.dateEnactment)
  const [startDate, setStartDate] = useState(data.dateEffective)
  const { register, handleSubmit, setValue } = useForm<ILegal>({
    resolver: yupResolver(legalForm),
    defaultValues: data,
  })
  const queryClient = useQueryClient()

  const handleDate = (type: 'dateEnactment' | 'dateEffective', date: Date) => {
    setValue(type, date)
    if (type === 'dateEnactment') {
      setDocDate(date)
    } else {
      setStartDate(date)
    }
  }

  const mutation = useMutation({
    mutationFn: (data: ILegal) => createLegal(data),
    onSuccess: () => {
      successToast('Dodano dokument.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.legalList] })
      refetch()
      closeHandler()
    },
    onError: (error: AxiosError) => {
      const errorStatus = (error as AxiosError).response?.status
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(error, legalErrors.create, 'Nie udało się dodać dokumentu.')
    },
  })

  const onSubmit = async (data: ILegal) => {
    try {
      const dataForm = {
        dateEffective: data.dateEffective,
        dateEnactment: data.dateEnactment,
        identifier: data.identifier,
        name: data.name,
        unit: data.unit,
      }
      setIsSending(true)
      mutation.mutate(dataForm)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, legalErrors.create, 'Nie udało się dodać dokumentu.')
    }
  }
  const closeHandler = () => {
    handleClose()
    setIsSending(false)
    setValue('name', '')
    setValue('identifier', '')
    setValue('dateEnactment', new Date())
    setValue('dateEffective', new Date())
  }
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div
        // onSubmit={handleSubmit(onSubmit)}
        className='rounded-md bg-white p-6 px-8 shadow'
      >
        <div className='mb-6 grid grid-cols-2 gap-5'>
          <TextField {...register('identifier')} label='Numer dokumentu' />
          <TextField {...register('name')} label='Nazwa dokumentu' />
        </div>
        <div className='grid grid-cols-2 gap-5'>
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Data publikacji</p>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              {...register('dateEnactment')}
              selected={docDate}
              onChange={(date: Date) => handleDate('dateEnactment', date)}
              className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
            />
          </div>
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Data wejścia w życie</p>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              {...register('dateEffective')}
              selected={startDate}
              onChange={(date: Date) => handleDate('dateEffective', date)}
              className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
            />
          </div>
        </div>
        <div className='flex justify-center gap-5'>
          <Button
            onClick={closeHandler}
            type='button'
            variant='secondary'
            label='Anuluj'
            className='mt-4'
          />
          <Button
            onClick={handleSubmit(onSubmit)}
            label='Wyślij'
            disabled={isSending}
            className='mt-4'
          />
        </div>
      </div>
    </Modal>
  )
}

export default LegalSearchForm
