import { Fragment, useEffect, useMemo, useState } from 'react'

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/20/solid'
import { ArrowLeftOnRectangleIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { flatten } from 'lodash'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ContextSwitcher } from 'components/Atoms/ContextSwitcher'
import { getRolesAsync } from 'features/Role/roleSlice'
import { logout } from 'hooks/logout'

type Props = {
  email: string
}

enum UpperMenu {
  LOGOUT = 'Wyloguj',
  CHANGE_ROLE = 'Zmiana roli',
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const UserDropdown = ({ email }: Props) => {
  const [activateChangeRole, setActivateChangeRole] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const roles = useAppSelector((state) => state.rolesReducer.roles)
  const userInfo = useAppSelector((state) => state.authReducer.userInfo)

  // Fetch roles only if not already loaded
  useEffect(() => {
    if (!roles || flatten(Object.values(roles)).length === 0) {
      dispatch(getRolesAsync())
    }
  }, [roles, dispatch])

  const roleName = useMemo(() => {
    return flatten(Object.values(roles)).find((role) => role.role === userInfo?.context.role)?.name
  }, [roles, userInfo?.context.role])

  const unitName = userInfo?.context?.unit?.name

  const name = userInfo?.firstName ? `${userInfo?.firstName} ${userInfo?.lastName}` : email

  const menu = [
    { name: UpperMenu.LOGOUT, onClick: logout, icon: ArrowLeftOnRectangleIcon },
    {
      name: UpperMenu.CHANGE_ROLE,
      onClick: () => setActivateChangeRole((prev) => !prev),
      icon: ArrowsRightLeftIcon,
    },
  ]

  return (
    <Popover className='relative z-0'>
      {({ open }) => (
        <>
          <div className='relative z-10 flex items-center bg-white'>
            <PopoverButton
              className={classNames(
                open ? 'text-gray-900' : 'text-gray-500',
                'group inline-flex items-center rounded-md bg-white px-1 py-0.5 text-base font-medium transition-colors hover:bg-gray-100 focus-visible:ring-0 focus-visible:ring-offset-0',
              )}
            >
              <span className='flex items-center gap-1'>
                {userInfo?.firstName && userInfo?.lastName ? (
                  <span className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 p-1 text-sm'>
                    {userInfo?.firstName[0]}
                    {userInfo?.lastName[0]}
                  </span>
                ) : (
                  <UserCircleIcon className='h-8 w-8 text-gray-500' />
                )}
                <div className='hidden text-sm sm:block'>
                  ({roleName}
                  {unitName && ` / ${unitName}`})
                </div>
              </span>
            </PopoverButton>
          </div>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 -translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 -translate-y-1'
          >
            <PopoverPanel className='absolute right-0 mt-2 w-56 origin-top-right scale-100 transform divide-y divide-gray-100 rounded-md bg-white opacity-100 shadow-lg ring-1 ring-black/5 focus:outline-none'>
              <div className='px-1 py-1'>
                <ul role='list'>
                  {menu.map((item, idx) => (
                    <li key={`${item.name}-${idx}`}>
                      <button
                        onClick={item.onClick}
                        className='flex w-full items-center gap-2 p-2 px-3 text-sm text-gray-700 hover:bg-slate-100'
                      >
                        <item.icon
                          className='h-5 w-5 flex-shrink-0 text-gray-400'
                          aria-hidden='true'
                        />
                        <span>{item.name}</span>
                      </button>
                      {item.name === UpperMenu.CHANGE_ROLE && activateChangeRole && (
                        <ContextSwitcher />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
