import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'

const persons = ['Apolonia Jarmuszewicz', 'Monika Cimoszewicz']

export const EditMemberData = () => {
  return (
    <>
      <Card label='Wypełnianie statystyk'>
        <div className='flex items-center justify-between'>
          <div className='flex'>
            <div className='mr-5'>
              <Checkbox label='Pokaż wszystkich' />
            </div>
            <Checkbox label='Pokaż niepotwierdzonych' />
          </div>
          {/* <Pagination /> */}
        </div>
      </Card>

      <Typography size='lg' weight='semibold' className='my-5 text-center'>
        Opisz powody usunięcia wskazanych osób
      </Typography>

      {persons.map((item, index) => (
        <div key={item} className='mb-5 bg-white p-5'>
          <div className='flex items-center justify-between'>
            <Typography>
              {index + 1}. {item}
            </Typography>
            <div className='flex'>
              <div className='mr-5'>
                <Select
                  label='Powód usunięcia'
                  options={[
                    { label: 'powód1', value: '1' },
                    { label: 'powód2', value: '2' },
                  ]}
                />
              </div>
              <TextField label='Komentarz' />
            </div>
          </div>
        </div>
      ))}

      <Typography size='lg' weight='semibold' className='my-5 text-center'>
        Zaznacz osoby które zmieniły dane
      </Typography>

      <div className='my-5 flex items-end'>
        <TextField label='Filtruj' placeholder='np. e-mail' />
        <Button label='Szukaj' className='ml-5' style={{ height: '38px' }} />
      </div>

      {persons.map((item, index) => (
        <div key={item} className='mb-5 bg-white p-5'>
          <div className='flex items-center justify-between'>
            <Typography>
              {index + 1}. {item}
            </Typography>
            <div className='flex'>
              <div className='mr-5'>
                <TextField label='Telefon' />
              </div>
              <TextField label='E-mail' />
            </div>
          </div>
        </div>
      ))}

      <Card>
        <div className='mt-5 flex flex-col justify-center'>
          <div className='mx-auto mb-5'>
            <Button label='Zatwierdź' />
          </div>
          <Typography size='xl' weight='semibold' className='text-center'>
            Zgłoszone zmiany 2 z 15 osób
          </Typography>
        </div>
      </Card>
    </>
  )
}
