import { AxiosError } from 'axios'

import { universalErrorData } from 'error-data/universal'
import { IMutationErrorRoutes } from 'types/httpStatus.enum'

import { errorToast } from './ToastHelpers'

export const mutationErrorHandler = (
  error: AxiosError | null,
  mutationType: IMutationErrorRoutes | null | undefined,
  message?: string,
) => {
  const errorStatus = error?.response?.status
  const errorText = error?.response?.statusText
  const errorCode = (errorStatus ? `u${errorStatus}` : 'u500') as string
  const errorMessage =
    mutationType && errorCode in mutationType
      ? mutationType[errorCode]
      : universalErrorData[errorCode] || errorText || 'Błąd serwera'
  const toast = message ? `${errorMessage} ${message}` : errorMessage
  errorToast(toast)
  return toast
}
