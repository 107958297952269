import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { TextareaField } from 'components/Atoms/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { migrationErrors } from 'error-data/member-migration'
import { getBranchesListPrefiltredGlobal } from 'fetchers/branchFetchers'
import { getMembersDetails, moveMemberToBranch } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import useSearch from 'hooks/useSearch'
import { moveSectionSchema } from 'schemas/unitChangeSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { setupSearchFilters, setupSearchMultiFilters } from 'tools/queryHelpers'
import { errorRequires, errorToast, successToast } from 'tools/ToastHelpers'
import { IMemberMoveUnit } from 'types/member'

export const MemberMigration = () => {
  const [selected, setSelected] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [branchPerPage, setBranchPerPage] = useState(10)
  const { userToken, id, isAllAdmin } = useAuth()
  const isAdmin = isAllAdmin()
  const navigate = useNavigate()
  const { param } = useSearch({ simpleParams: ['name'] })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      comment: '',
      document: '',
    },
    resolver: yupResolver(moveSectionSchema),
  })
  // MEMBER DATA
  const {
    data: member,
    isLoading: memberIsLoading,
    error: memberError,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.member, id],
    queryFn: () => getMembersDetails(id),
    retry: useRetryHandler({
      resourceName: 'MemberMigration member',
      maxRetries: 1,
    }),
  })

  const memberName = `${member?.firstName} ${member?.lastName}`
  const regionId = member?.region?.id
  const branchesFilters =
    setupSearchFilters([{ name: param?.single?.name || '' }]) +
    setupSearchMultiFilters([{ region: [regionId] }])
  const {
    data: branches,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList, branchesFilters],
    queryFn: () => getBranchesListPrefiltredGlobal(1, branchPerPage, branchesFilters),
    retry: useRetryHandler({
      resourceName: 'MemberMigration branches',
      maxRetries: 1,
    }),
  })
  const branchesList = (branches?.items || [])
    .map((e: { name: string; id: string }) => {
      return {
        label: e.name,
        value: e.id,
      }
    })
    .filter((e: { label: string; value: string }) => e.value !== member?.branch?.id)

  const searchMoreHandler = () => {
    const count =
      branches?.pagination?.countFiltered > 0
        ? branches?.pagination?.countFiltered
        : branches?.pagination?.count
    if (!branchesLoading && branches?.items?.length < count)
      setBranchPerPage((prev: number) => prev + 10)
  }
  const onValidationFailure = () => errorRequires(errors)
  const backHandler = () => navigate(-1)

  // MUTATE
  const moveBranch = useMutation({
    mutationFn: (data: IMemberMoveUnit) => {
      const { comment, unitId } = data
      return moveMemberToBranch(id, unitId, comment)
    },
    onSuccess: () => {
      const message = isAdmin
        ? 'Przeniesiono członka do nowego oddziału.'
        : 'Zgłoszenie zostało wysłane do oddziału docelowego.'
      successToast(message)
      backHandler()
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        migrationErrors.moveBranch,
        'Nie udało się przenieść członka do oddziału',
      )
    },
  })
  const onSubmit = async (data: any) => {
    try {
      setIsSending(true)
      const formData = {
        unitId: data.document,
        comment: data.comment || '',
      }
      moveBranch.mutate(formData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      errorToast('Nie udało się zmienić sekcji.')
      mutationErrorHandler(null, null, 'Nie udało się przenieść członka do oddziału')
    }
  }
  return (
    <section>
      <Typography size='xl' weight='semibold'>
        {memberName} / Zmień oddział
      </Typography>
      <Card label='Przenieś członka do innego oddziału'>
        <form className='grid grid-cols-4' onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
          <div className='col-start-2 col-end-4 mb-4'>
            <SearchAndFetchInput
              externalChangeValue={setSelected}
              searchMore={searchMoreHandler}
              isLoading={branchesLoading}
              name={'document'}
              label={'Wyszukaj oddział'}
              register={register}
              options={branchesList}
              control={control}
            />
          </div>
          <div className='col-start-2 col-end-4'>
            <TextareaField
              error={errors.comment?.message}
              {...register('comment')}
              label='Powód przeniesienia'
              name='comment'
            />
          </div>
          <div className='col-span-4 mt-5 flex items-center justify-center gap-5'>
            <Button label='Wróć' variant='secondary' onClick={backHandler} />
            <Button disabled={isSending || !selected} type='submit' label='Przenieś' />
          </div>
        </form>
      </Card>
    </section>
  )
}

export default MemberMigration
