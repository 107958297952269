import { useState } from 'react'

import { Controller } from 'react-hook-form'

import { IOption } from 'types/form'

import { Select } from '../Select'
type Props = {
  isAdmin: boolean
  regionOptions: IOption[]
  branchOptions: IOption[]
  regionsLoading?: boolean
  branchesLoading?: boolean
  selectedBranch?: string
  setSelectedBranch: React.Dispatch<string>
  setSelectedRegion: React.Dispatch<string>
  control: any
}
export const BranchFunctionSearch = ({
  isAdmin,
  regionOptions,
  branchOptions,
  regionsLoading,
  branchesLoading,
  selectedBranch,
  setSelectedBranch,
  setSelectedRegion,
  control,
}: Props) => {
  const [isRegionSelected, setIsRegionSelected] = useState<boolean>(false)

  return (
    <div>
      {isAdmin ? (
        <div className='grid grid-cols-2 gap-2'>
          <div>
            <Controller
              name='regionId'
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value, label }) => {
                    onChange(value)
                    setSelectedRegion(String(value))
                    setIsRegionSelected(label !== '---')
                    setSelectedBranch('')
                  }}
                  label='Wskaż okręg'
                  withEmpty
                  selectLabel='---'
                  options={regionOptions}
                  isLoading={regionsLoading}
                />
              )}
            />
          </div>
          {isRegionSelected && regionOptions.length > 0 && (
            <div>
              <Controller
                name='branchId'
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    value={selectedBranch}
                    handleSelect={({ value }) => {
                      onChange(value)
                      setSelectedBranch(String(value))
                    }}
                    label='Wskaż oddział'
                    withEmpty
                    selectLabel='---'
                    options={branchOptions}
                    isLoading={branchesLoading}
                  />
                )}
              />
            </div>
          )}
        </div>
      ) : (
        <Controller
          name='unitId'
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              handleSelect={({ value }) => {
                onChange(value)
                setSelectedBranch(String(value))
              }}
              label='Wskaż oddział'
              withEmpty
              selectLabel='---'
              options={branchOptions}
              isLoading={branchesLoading}
            />
          )}
        />
      )}
    </div>
  )
}
export default BranchFunctionSearch
