import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
// import type { RootState } from 'app/store'

// Define a type for the slice state
interface SecureConnectionState {
  secure: boolean
}

// Define the initial state using that type
const initialState: SecureConnectionState = {
  secure: false,
}

export const fetchData = createAsyncThunk('counter/fetchData', async () => {
  try {
    const response = await axios.get('')
    return [...response.data]
  } catch (err) {
    return err
  }
})

export const secureConnectionSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    isSecure: (state, action) => {
      state.secure = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { isSecure } = secureConnectionSlice.actions

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default secureConnectionSlice.reducer
