import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { wipMessage } from 'tools/ToastHelpers'
import { IBranchList } from 'types/branch'
interface IProps {
  data: IBranchList
}
export const BranchSections: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate()
  const showHandler = (id: string) => navigate(`/section/${id}`)
  const sectionsList = data?.items ? data?.items : []
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa ogniska',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Lokalizacja ogniska',
        accessor: 'location',
      },
      {
        Header: 'Adres ogniska',
        accessor: 'address',
      },
      {
        Header: 'Prezes',
        accessor: 'president',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <div className="flex">
              <HoverIcon
                iconName="EyeIcon"
                title="Zobacz"
                onClick={() => showHandler(row.row.original.id)}
              />
              <HoverIcon iconName="TrashIcon" title="Usuń" onClick={() => wipMessage()} />
            </div>
          )
        },
      },
    ],
    []
  )

  if (sectionsList.length === 0) {
    return (
      <Card label="Powiązane ogniska">
        <div className="flex flex-col items-center justify-center py-8 text-gray-500">
          <p className="text-lg">Brak powiązanych ognisk w oddziale</p>
        </div>
      </Card>
    )
  }

  return (
    <Card label="Powiązane ogniska">
      <Table data={data?.items ? data?.items : []} columns={columns} />
    </Card>
  )
}
