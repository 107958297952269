import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { houseNrRegExp } from 'constants/regexs'

export const institutionCreateSchema = yup.object({
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  branch: yup.string().required(errorMessage.requiredField),
  type: yup.string().required(errorMessage.requiredField),
  // voivodeship: yup.string().required(errorMessage.requiredField),
  headmasterFirstName: yup
    .string()
    .test('headmasterFirstName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterLastName !== '' ? value !== '' : true
    }),
  headmasterLastName: yup
    .string()
    .test('headmasterLastName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterFirstName !== '' ? value !== '' : true
    }),
  headmasterPhone: yup.string(),
  headmasterEmail: yup.string().email(),
  postalCode: yup.string().required(errorMessage.requiredField),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => {
      // Custom validation function
      return !/\d/.test(String(value))
    })
    .required(errorMessage.requiredField)
    .min(2, errorMessage.min2Chars),
  streetName: yup.string(),
  houseNr: yup.string().matches(houseNrRegExp, errorMessage.invalidHouseNr),
  apartmentNr: yup.string(),
  groups: yup.array().of(yup.string()),
})

export const institutionCreateSchemaWithBranch = yup.object({
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  branch: yup.string().required(errorMessage.requiredField), // Branch is required
  type: yup.string().required(errorMessage.requiredField),
  headmasterFirstName: yup
    .string()
    .test('headmasterFirstName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterLastName !== '' ? value !== '' : true
    }),
  headmasterLastName: yup
    .string()
    .test('headmasterLastName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterFirstName !== '' ? value !== '' : true
    }),
  headmasterPhone: yup.string(),
  headmasterEmail: yup.string().email(),
  postalCode: yup.string().required(errorMessage.requiredField),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => !/\d/.test(String(value)))
    .required(errorMessage.requiredField)
    .min(2, errorMessage.min2Chars),
  streetName: yup.string(),
  houseNr: yup.string().matches(houseNrRegExp, errorMessage.invalidHouseNr),
  apartmentNr: yup.string(),
  groups: yup.array().of(yup.string()),
})

export const institutionCreateSchemaNoBranch = yup.object({
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  // branch: yup.string().required(errorMessage.requiredField),
  type: yup.string().required(errorMessage.requiredField),
  // voivodeship: yup.string().required(errorMessage.requiredField),
  headmasterFirstName: yup
    .string()
    .test('headmasterFirstName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterLastName !== '' ? value !== '' : true
    }),
  headmasterLastName: yup
    .string()
    .test('headmasterLastName', errorMessage.requiredField, function (value) {
      return this.parent.headmasterFirstName !== '' ? value !== '' : true
    }),
  headmasterPhone: yup.string(),
  headmasterEmail: yup.string().email(),
  postalCode: yup.string().required(errorMessage.requiredField),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => {
      // Custom validation function
      return !/\d/.test(String(value))
    })
    .required(errorMessage.requiredField)
    .min(2, errorMessage.min2Chars),
  streetName: yup.string(),
  houseNr: yup.string().matches(houseNrRegExp, errorMessage.invalidHouseNr),
  apartmentNr: yup.string(),
  groups: yup.array().of(yup.string()),
})
