import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ControllerGroup } from 'components/Atoms/ControllerGroup'
import LoadingModal from 'components/Atoms/LoadingModal'
import { TextareaField } from 'components/Atoms/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { notificationErrors } from 'error-data/notification'
import { getMembersDetails } from 'fetchers/membersFetchers'
import { createNotification, getReasonsList } from 'fetchers/notifyFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { createMemberNotify } from 'schemas/notifySchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { setupDate } from 'tools/formTools'
import { getLoadingHandler } from 'tools/queryHelpers'
import { errorRequires, successToast } from 'tools/ToastHelpers'
import { INotify } from 'types/notify'

export const MemberNotifyChange = () => {
  const [isSending, setIsSending] = useState(false)
  const [date, setDate] = useState(new Date())
  const { userToken, id, isPOG, isPOD } = useAuth()
  const isValid = isPOG() || isPOD()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    control,
  } = useForm<INotify>({
    resolver: yupResolver(createMemberNotify),
  })

  // MEMBER DATA
  const {
    data: member,
    isLoading: memberIsLoading,
    error: memberError,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.member, id],
    queryFn: () => getMembersDetails(id),
    retry: useRetryHandler({
      resourceName: 'MemberNotifyChange member',
      maxRetries: 1,
    }),
  })
  const memberName = `${member?.firstName} ${member?.lastName}`

  // REASON DATA
  const {
    data: reasons,
    isLoading: reasonsLoading,
    error: reasonsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.reasonsList],
    queryFn: () => getReasonsList(),
    retry: useRetryHandler({
      resourceName: 'MemberNotifyChange reasons',
      maxRetries: 1,
    }),
  })

  // OPTIONS DATA
  const reasonsList = reasons?.items
    ? reasons.items.map((reason: any) => ({ value: reason.id, label: reason.name }))
    : []

  const typeOptions = [
    { value: 'change', label: 'Edycja' },
    { value: 'remove', label: 'Usunięcie' },
  ]

  // MUTATION
  const mutate = useMutation({
    mutationFn: async (data: INotify) => createNotification(id, data),
    onSuccess: () => {
      successToast('Wysłano zgłoszenie.')
      navigate(-1)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, notificationErrors.create, 'Nie udało się wysłać zgłoszenia.')
    },
  })

  // HANDLERS
  const setDateHandler = (date: Date) => {
    setDate(date)
    setValue('changeDate', setupDate(date))
  }
  const onValidationFailure = () => errorRequires(errors)
  const onSubmit = async (data: INotify) => {
    try {
      setIsSending(true)
      mutate.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(null, null, 'Nie udało się wysłać zgłoszenia.')
    }
  }

  // SET DEFAULT VALUES
  useEffect(() => {
    setValue('changeDate', setupDate(date))
  }, [setValue])

  // LOADIND HANDLER
  const isLoading = memberIsLoading || reasonsLoading
  const loadingHandler = getLoadingHandler(memberError, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        {member?.sections[0]?.name} / Lista członków / {memberName} / Zgłoś zmianę danych
      </Typography>
      <Card label='Zgłoś zmianę danych'>
        <form
          className='mb-5 grid grid-cols-5 gap-5'
          onSubmit={handleSubmit(onSubmit, onValidationFailure)}
        >
          <ControllerGroup
            name='type'
            label='Rodzaj zgłoszenia'
            control={control}
            options={typeOptions}
            error={errors?.type?.message}
            setError={setError}
          />
          <ControllerGroup
            name='reason'
            label='Powód zgłoszenia'
            control={control}
            options={reasonsList}
            error={errors?.reason?.message}
            setError={setError}
          />
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Data zmiany danych</p>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              {...register('changeDate')}
              selected={date}
              onChange={(date: Date) => setDateHandler(date)}
              className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
            />
          </div>
          <div className='col-span-3'>
            <TextareaField
              {...register('description')}
              label='Komentarz do zmiany'
              error={errors?.description?.message}
            />
          </div>

          <div className='col-span-5 flex justify-center gap-5'>
            <Button label='Anuluj' type='button' variant='secondary' onClick={() => navigate(-1)} />
            <Button disabled={isSending} label='Zatwierdź' />
          </div>
        </form>
      </Card>
    </div>
  )
}

export default MemberNotifyChange
