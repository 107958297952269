import { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import pl from 'date-fns/locale/pl'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Checkbox } from 'components/Atoms/Checkbox'
import SearchAndFetchMember from 'components/Atoms/SearchAndFetchMember/SearchAndFetchMember'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { rodoRequestErrors } from 'error-data/rodo'
import { getMembersList } from 'fetchers/membersFetchers'
import { postAccessDeclarationReq, postAccessMemberReq } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import useSearch from 'hooks/useSearch'
import { rodoMemberSchema } from 'schemas/rodoSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery } from 'tools/queryHelpers'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IRodoAccess, IRodoAccessMember } from 'types/dictionary'
import { IOption } from 'types/form'
registerLocale('pl', pl)

type Props = {
  criteriaList: IOption[]
  type: 'members' | 'declarations'
}

export const RodoAccessMemberForm = ({ criteriaList, type }: Props) => {
  const [operations, setOperations] = useState<IOption[]>([])
  const [datetimeFrom, setDatetimeFrom] = useState<Date | undefined>()
  const [datetimeTo, setDatetimeTo] = useState<Date | undefined>()
  const [member, setMember] = useState<any>(null)
  const [perPage, setPerPage] = useState(10)

  const [fullName, setFullName] = useState(false)

  const [isSending, setIsSending] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { userToken, userLoaded, isAllAdmin, isAllOD, isAllOK } = useAuth()

  // MEMBER FETCHER
  const { setParam, param, searchQuery, filters, page } = useSearch({
    simpleParams: ['searchExtended'],
  })
  const setupFilters = fullName ? filters + '&unredacted=true' : filters
  const { data: members, isLoading: membersIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.membersList, page, perPage, setupFilters],
    queryFn: () => {
      if (!param.single.searchExtended) return { items: [] }
      return getMembersList(page, perPage, setupFilters)
    },
    retry: errorQuery,
  })
  const membersList = members?.items
    ? members?.items?.map((item: any) => {
        const labelArray = [`${item?.firstName} ${item?.lastName}`]
        if (item?.card?.number) labelArray.push(item?.card?.number)
        if (item?.region?.name) labelArray.push(item?.region?.name)
        if (item?.branch?.name) labelArray.push(item?.branch?.name)
        const label = labelArray.join(' | ')
        return { label: label, value: item.id }
      })
    : []
  const membersLoading = membersIsLoading || !userLoaded

  const setQuery = (e: string) => setParam.single('searchExtended', e)
  const searchMore = () => {
    const count =
      members?.pagination?.countFiltered > 0
        ? members?.pagination?.countFiltered
        : members?.pagination?.count
    if (members?.items?.length < count) setPerPage((prev) => prev + 10)
  }

  const searchHandler = (e: any) => {
    e.preventDefault()
    searchQuery()
  }

  // END MEMBER FETCHER

  const resourceType = {
    members: {
      key: queryKeys.rodoAccessMembers,
      path: path.rodo_access.members.list,
      api: postAccessMemberReq,
    },
    declarations: {
      key: queryKeys.rodoAccessDeclarations,
      path: path.rodo_access.declarations.list,
      api: postAccessDeclarationReq,
    },
  }

  const mutation = useMutation({
    mutationFn: (data: IRodoAccess) => resourceType[type].api(data),
    onSuccess: () => {
      setIsSending(false)
      queryClient.invalidateQueries({ queryKey: [resourceType[type].key] })
      successToast('Zapytanie zostało wysłane')
      navigate(resourceType[type].path)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, rodoRequestErrors.get.message)
    },
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IRodoAccessMember>({
    defaultValues: {
      memberId: '',
      datetimeFrom: undefined,
      datetimeTo: undefined,
    },
    resolver: yupResolver(rodoMemberSchema),
  })

  const handleDate = (type: 'datetimeFrom' | 'datetimeTo', date: Date) => {
    setValue(type, date)
    if (type === 'datetimeFrom') {
      setDatetimeFrom(date)
    } else {
      setDatetimeTo(date)
    }
  }

  const onValidationFailure = (d: any) => {
    const values = getValues()
    const { memberId, datetimeFrom, datetimeTo } = values
    if (!memberId) {
      errorToast('Wybierz członka')
    }
    if (!datetimeFrom || !datetimeTo) {
      errorToast('Podaj zakres dat wyszukiwania')
    }
    if (datetimeFrom && datetimeTo && datetimeFrom > datetimeTo) {
      errorToast('Data początkowa nie może być większa niż data końcowa')
    }
  }

  const onSubmit = (data: any) => {
    try {
      const filteredData = ['s']
      const dataForm = {
        resourceId: data.memberId,
        datetimeFrom: data.datetimeFrom,
        datetimeTo: data.datetimeTo,
        operationType: operations.map((item) => String(item.value)),
      }
      mutation.mutate(dataForm)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, null, rodoRequestErrors.get.message)
    }
  }
  const oneYearAgo = new Date()
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

  return (
    <>
      <p className={'mb-4'}>Wybierz kryteria wyszukiwania</p>
      <div className='my-4'>
        <p className='mb-1 block text-sm font-medium text-gray-700'>Rodzaj operacji</p>

        <MultiSelect
          options={criteriaList}
          value={operations}
          valueRenderer={(selected, _options) => selected.map((item) => item.label).join(', ')}
          hasSelectAll={true}
          className='rodo-selector text-sm'
          onChange={setOperations}
          overrideStrings={{
            search: 'Wyszukaj',
            selectAll: 'Zaznacz wszystko',
            allItemsAreSelected: 'Wszystkie są zaznaczone',
            selectSomeItems: 'Wybierz...',
          }}
          labelledBy='Wyszukaj'
        />
      </div>
      <div className='mb-5 max-w-3xl'>
        <form onSubmit={searchHandler}>
          <label htmlFor='userSearch' className='mb-1 block text-sm font-medium text-gray-700'>
            Szukaj osoby po numerze legitymacji, imieniu lub nazwisku:
          </label>
          <div className='mb-3 flex gap-4'>
            <input
              className='block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
              type='search'
              placeholder='Numer legitymacji, imię lub nazwisko:'
              name='userSearch'
              id='userSearch'
              value={String(param.single.searchExtended || '')}
              onChange={(e) => setParam.single('searchExtended', e.target.value)}
            />
            <Button type='submit' label='Szukaj' />
          </div>
          <Checkbox
            label={'Pokaż pełne dane'}
            id={'fullName'}
            // value={fullName}
            checked={fullName}
            onChange={() => setFullName((f) => !f)}
          />
          <div className='my-3'>
            <Typography size='sm'>
              {member ? (
                <>
                  <span className='font-semibold'>Wybrano członka:</span> {member}
                </>
              ) : (
                <span className='font-semibold'>Wybierz członka:</span>
              )}
            </Typography>
          </div>
          <div className='w-100 mt-2'>
            <SearchAndFetchMember
              setQuery={setQuery}
              externalChangeHandler={setMember}
              searchMore={searchMore}
              isLoading={membersLoading}
              name={'memberId'}
              register={register}
              options={membersList}
              filters={filters}
              control={control}
            />
          </div>
        </form>
      </div>
      <div className='grid grid-cols-4 gap-5 align-top'>
        <div>
          <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='baseSearchDate'>
            Od:
          </label>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            {...register('datetimeFrom')}
            locale='pl'
            minDate={oneYearAgo}
            maxDate={new Date()}
            selected={datetimeFrom}
            onChange={(date: Date) => handleDate('datetimeFrom', date)}
            className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
          />
        </div>
        <div>
          <label className='mb-1 block text-sm font-medium text-gray-700' htmlFor='baseSearchDate'>
            Do:
          </label>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            locale='pl'
            {...register('datetimeTo')}
            minDate={oneYearAgo}
            maxDate={new Date()}
            selected={datetimeTo}
            onChange={(date: Date) => handleDate('datetimeTo', date)}
            className='block w-full rounded-md border-0 py-1.5 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600'
          />
        </div>
      </div>

      <div className='mt-5 flex justify-center'>
        <Button
          label='Wyślij zapytanie'
          disabled={isSending}
          onClick={handleSubmit(onSubmit, onValidationFailure)}
        />
      </div>
    </>
  )
}

export default RodoAccessMemberForm
