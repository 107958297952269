import { useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import ErrorRouteModal from 'components/Atoms/ErrorRouteModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getStructureFunctionList, getStructureFunctionStatus } from 'fetchers/structureFetcher'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import useAuth from 'hooks/useAuth'
import { getTermFlex } from 'tools/dictionaryHelper'
import { getStatusObject } from 'tools/mapData'
import { errorForbiddenHandler, errorNotExistsHandler, errorQuery } from 'tools/queryHelpers'
import { wipMessage } from 'tools/ToastHelpers'
import { IMemberFunctionsFull, IStructureFunction } from 'types/structure'

interface PositionLimits {
  [key: string]: number
}

export const RegionFunctions = () => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const navigate = useNavigate()
  const { userToken, userLoaded, isAllAdmin, unitContext, user } = useAuth()
  const { control } = useForm()
  const isAdmin = isAllAdmin()
  const unitId = unitContext?.id
  // GET REGIONS
  const {
    data: regions,
    isLoading: regionsLoading,
    error: regionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, userLoaded],
    queryFn: () => {
      if (!userLoaded || !isAdmin) return { items: [] }
      return getRegionsList(1, 1000)
    },
  })
  const regionOptions = regions?.items
    ? regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
    : []
  // END GET REGIONS

  // GET POSITIONS
  const {
    data: positions,
    isLoading: positionsLoading,
    error: positionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, userLoaded],
    queryFn: () => {
      if (!userLoaded) return { items: [] }
      return getUnitPositionsList(1, 1000)
    },
  })

  const { data: functionStatus } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctionsStatus, userLoaded, selectedRegion],
    queryFn: () => {
      if (!selectedRegion) return { items: [] }
      return getStructureFunctionStatus(selectedRegion)
    },
  })

  const statusList = functionStatus?.items ? functionStatus?.items : []
  const positionLimits: PositionLimits = getStatusObject(statusList)

  const positionList = positions?.items
    ? positions?.items?.filter((item: IStructureFunction) => item.unitType === 'region')
    : []
  // END GET POSITIONS

  // GET FUNCTIONS
  const {
    data: functions,
    isLoading: functionsLoading,
    error: functionError,
  } = useQuery({
    queryKey: [userToken, queryKeys.structureFunctions, selectedRegion, unitId],
    queryFn: () => {
      if (unitId) {
        return getStructureFunctionList(unitId)
      } else if (selectedRegion && !unitId) {
        return getStructureFunctionList(selectedRegion)
      }
      return { items: [] }
    },
    retry: errorQuery,
  })
  const notExists = errorNotExistsHandler(functionError)
  const forbiddenBranch = errorForbiddenHandler(functionError)

  const functionsFullList = functions?.items ? functions?.items : []
  const functionsList = functionsFullList.flatMap((item: IMemberFunctionsFull) =>
    item.unitPositions.map((position: any) => ({
      member: `${item.firstName} ${item.lastName}`,
      memberId: item.id,
      startedAt: position.startedAt,
      endedAt: position.endedAt,
      calculatedTermEndAt: position.calculatedTermEndAt,
      name: position.unitPosition.name,
      id: position.id,
    })),
  )
  // END GET FUNCTIONS

  const schemeColumns = useMemo(
    () => [
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Przypisanych członków',
        accessor: 'count',
        Cell: ({ row }: any) => {
          return row.original.id ? positionLimits[row.original.id] : '-'
        },
      },
      {
        Header: 'Limit',
        accessor: 'maxNumber',
        Cell: ({ row }: any) => {
          return <p>{!row.original.maxNumber ? '-' : row.original.maxNumber}</p>
        },
      },
      {
        Header: 'Kadencja',
        accessor: 'lengthOfTermUnit',
        Cell: ({ row }: any) => {
          const { lengthOfTermUnit, lengthOfTerm } = row.original
          return <p>{getTermFlex(lengthOfTerm, lengthOfTermUnit)}</p>
        },
      },
    ],
    [positionList],
  )
  const dataColumns = useMemo(
    () => [
      // {
      //   Header: '',
      //   accessor: 'Selected',
      //   Cell: (row: any) => <Checkbox />,
      // },
      {
        Header: 'Nazwa funkcji',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Członek',
        accessor: 'member',
      },
      {
        Header: 'Kadencja od',
        accessor: 'startedAt',
      },
      {
        Header: 'Kadencja do',
        accessor: 'endedAt',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          const editPath = `${selectedRegion || unitId}/${row.original.memberId}/${row.original.id}`
          return (
            <div className='flex'>
              <HoverIcon
                iconName='PencilIcon'
                title='Edytuj'
                // onClick={() => navigate(`/branch-functions/${editPath}/update`)}
                onClick={() => wipMessage()}
              />
            </div>
          )
        },
      },
    ],
    [selectedRegion, unitId],
  )

  const createHandler = () => {
    if (isAdmin) navigate(`${selectedRegion}/create`)
    if (unitId) navigate(`${unitId}/create`)
  }

  const addButtonDisabled = !selectedRegion && isAdmin
  const showSelector = regionOptions.length > 0 && isAdmin
  const showSelectorLoader = regionsLoading && isAdmin
  const functionsLoaded =
    (selectedRegion && !functionsLoading && functionsList.length > 0) ||
    (unitId && !functionsLoading && functionsList.length > 0 && !isAdmin)
  const functionsIsLoading = selectedRegion && functionsLoading
  const noRegionSelected = !selectedRegion && isAdmin
  const noFunctions =
    (selectedRegion && !functionsLoading && functionsList.length === 0 && isAdmin) ||
    (!functionsLoading && functionsList.length === 0 && !isAdmin)

  if (!userLoaded) return <LoadingSection />
  if (notExists) return <ErrorRouteModal />
  if (forbiddenBranch) return <ErrorRoleModal />
  if (!isAdmin) return <ErrorRoleModal />

  return (
    <div>
      <Typography size='xl' weight='semibold'>
        Funkcje pełnione w okręgu
      </Typography>
      <div className='mt-2 w-80'>
        {showSelectorLoader && <Spinner />}
        {showSelector && (
          <Controller
            name='unitId'
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                handleSelect={({ value }) => {
                  onChange(value)
                  setSelectedRegion(String(value))
                }}
                label='Wskaż okręg'
                withEmpty
                selectLabel='---'
                options={regionOptions}
                isLoading={regionsLoading}
              />
            )}
          />
        )}
      </div>
      <div className='my-5 flex'>
        <div className='mr-5'>
          <Button disabled={addButtonDisabled} label='Dodaj funkcję' onClick={createHandler} />
        </div>
      </div>
      <Card label='Funkcje dostępne w okręgu'>
        {positionsLoading ? (
          <Spinner />
        ) : (
          <section>
            <Table columns={schemeColumns} data={positionList} />
          </section>
        )}
      </Card>
      <Card label='Funkcje członków okręgu'>
        <section>
          {noRegionSelected && <p className='text-center'>Wybierz okręg</p>}
          {noFunctions && <p className='text-center'>Nie przypisano żadnych funkcji</p>}
          {functionsIsLoading && (
            <div className='flex justify-center'>
              <Spinner />
            </div>
          )}
          {functionsLoaded && (
            <>
              <Table columns={dataColumns} data={functionsList} />
              {/* <Pagination /> */}
            </>
          )}
        </section>
      </Card>
    </div>
  )
}
