import { ReactNode } from 'react'

import { ThreeCircles } from 'react-loader-spinner'

import { Modal } from 'components/Atoms/Modal'

import { Button } from '../Button'
import { Card } from '../Card'

type IProps = {
  isSending: boolean
  showModal: boolean
  headerLabel: string
  data: {
    id?: string
    context: ReactNode
  }
  hideModal: () => void
  archiveHandler: () => void
}

export const ArchiveModal: React.FC<IProps> = ({
  isSending,
  showModal,
  headerLabel,
  data,
  hideModal,
  archiveHandler,
}) => {
  const onArchive = () => {
    archiveHandler()
  }
  return (
    <Modal isOpen={showModal} handleClose={hideModal}>
      <Card label={headerLabel}>
        <div className='relative mb-8 bg-white px-4 py-12'>
          <div className='mx-auto'>{data.context}</div>
          <div className='absolute bottom-2 left-1/2 -translate-x-3'>
            {isSending && (
              <ThreeCircles
                height='24'
                width='24'
                color='blue'
                wrapperStyle={{}}
                wrapperClass=''
                visible={true}
                ariaLabel='three-circles-rotating'
                outerCircleColor=''
                innerCircleColor=''
                middleCircleColor=''
              />
            )}
          </div>
        </div>
        <div className='mx-2 flex justify-center gap-10'>
          <Button label={'Anuluj'} variant='secondary' onClick={hideModal} />
          <Button disabled={isSending} label={'Archiwizuj'} onClick={onArchive} />
        </div>
      </Card>
    </Modal>
  )
}
