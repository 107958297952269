export const translateCardStatus = (status: string) => {
  switch (status) {
    case 'created':
      return 'Utworzona'
    case 'issued':
      return 'Wydana'
    case 'printed':
      return 'Wydrukowana'
    case 'in_branch':
      return 'Przekazana do oddziału'
    case 'invalidated':
      return 'Unieważniona'
    default:
      return '-'
  }
}
