import { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import SettingsFormIBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { institutionErrors } from 'error-data/institution'
import { createInstitutionType } from 'fetchers/institutionFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

export const InstitutionTypesCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const listPath = path.settings.institution.list

  const mutation = useMutation({
    mutationFn: (data: INameForm) => createInstitutionType(data),
    onSuccess: () => {
      successToast('Dodano nowy typ placówek')
      queryClient.invalidateQueries({ queryKey: [queryKeys.institutionTypesList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(
        error,
        institutionErrors.create,
        'Nie udało się dodać nowego typu placówek.',
      )
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać nowego typu placówek.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const breadcrumbLink = (
    <>
      <Link to={listPath}>Typy placówek</Link> / Dodaj
    </>
  )
  return (
    <SettingsFormIBasic
      cardLabel={'Dodaj nowy typ placówki'}
      initData={{ name: '' }}
      inputLabel={'Nazwa typu placówki'}
      inputPlaceholder={'Wprowadź nowy typ'}
      onSubmit={onSubmit}
      onCancelHandler={cancelHandler}
      breadcrumbLink={breadcrumbLink}
      isSending={isSending}
    />
  )
}

export default InstitutionTypesCreate
