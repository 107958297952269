import { Fragment, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'

import CensusBreadcrumbs from 'components/Atoms/Breadcrumbs/CensusBreadcrumbs'
import { Button as AtomButton } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { Table } from 'components/Atoms/Table'
import { Button } from 'components/shadcn/ui/button'
import { ProgressInside } from 'components/shadcn/ui/progress-inside'
import { queryKeys } from 'constants/queryKeys'
import { notificationErrors } from 'error-data/notification'
import { closeCensus, getCensusAdminDashboardData } from 'fetchers/censusFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'

import { BarChartComponent } from '../../charts/BarChartComponent'
import ChartHorizontal from '../../charts/ChartHorizontal'
import { ProgressChart } from '../../charts/ProgressChart'
import { SkeletonAreaChartComponent } from '../../charts/SkeletonAreaChartComponent'
import { SkeletonProgressChart } from '../../charts/SkeletonProgressChart'
import { SkeletonTimeLeftChart } from '../../charts/SkeletonTimeLeftChart'
import { TimeLeftChart } from '../../charts/TimeLeftChart'

const CensusDashboardAdmin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { userToken, id } = useAuth()
  const navigate = useNavigate()

  const {
    data: censusDashboardData,
    isPending: isCensusDashboardDataPending,
    isLoading: isCensusDashboardDataLoading,
    error: censusDashboardDataError,
    refetch: refetchCensusDashboardData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDashboard, id],
    queryFn: () => getCensusAdminDashboardData(id),
    retry: useRetryHandler({
      resourceName: 'censusDashboardData',
      maxRetries: 1,
    }),
  })

  const closeCensusCensusMutation = useMutation({
    mutationFn: async ({ censusID }: { censusID: string }) => closeCensus(censusID),
    onSuccess: () => {
      successToast('Spis został zamknięty.')
      setIsLoading(false)
      refetchCensusDashboardData()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się zamknąć spisu.')
      setIsLoading(false)
    },
  })

  const handleCensusMutation = async () => {
    setIsLoading(true)
    try {
      if (!confirm('Czy na pewno chcesz zamknąć spis?')) return
      closeCensusCensusMutation.mutate(
        {
          censusID: id,
        },
        {
          onSuccess: () => {
            refetchCensusDashboardData()
            setIsLoading(false)
          },
        },
      )
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zmienić statusu spisu.')
      setIsLoading(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Okręg',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.unit?.name
        },
      },
      {
        Header: 'Czy potwierdzone?',
        accessor: 'isCompleted',
        Cell: ({ row }: any) => {
          return row.original?.isCompleted ? 'Tak' : 'Nie'
        },
      },
      {
        Header: 'Bilans liczby członków (+/-)',
        accessor: 'balanceChange',
        Cell: ({ row }: any) => {
          return (
            <span
              className={`${
                row.original?.balanceChangeIn > row.original?.balanceChangeOut
                  ? 'text-green-500'
                  : row.original?.balanceChangeIn < row.original?.balanceChangeOut
                    ? 'text-red-500'
                    : ''
              }`}
            >{`${row.original?.balanceChangeIn - row.original?.balanceChangeOut} (${
              row.original?.balanceChangeIn
            }/${row.original?.balanceChangeOut})`}</span>
          )
        },
      },
      {
        Header: 'Ukończone jednostki',
        accessor: 'progress',
        Cell: ({ row }: any) => {
          const { completedUnitsNum, totalUnitsNum } = row.original

          if (isNaN(completedUnitsNum) || isNaN(totalUnitsNum))
            return <ProgressInside value={0} completed={0} total={0} />
          const progressValue = (completedUnitsNum / totalUnitsNum) * 100

          return (
            <ProgressInside
              value={progressValue}
              completed={completedUnitsNum}
              total={totalUnitsNum}
            />
          )
        },
      },
      {
        Header: 'Szczegóły',
        accessor: 'details',
        Cell: ({ row }: any) => {
          return (
            <Link to={`/census/dashboard/${id}/region/${row.original?.unit?.id}`}>
              <AtomButton variant='secondary' label='Zobacz dashboard okręgu' />
            </Link>
          )
        },
      },
    ]
  }, [])

  const totalUnits = censusDashboardData?.totalUnitsNum
  const completedUnits = censusDashboardData?.completedUnitsNum

  if (errorForbiddenHandler(censusDashboardDataError)) return <ErrorRoleModal />

  if (isCensusDashboardDataLoading) {
    return (
      <div>
        <div className='chart-wrapper mx-auto grid items-start gap-6 pb-2 xl:grid-cols-2'>
          <div className='flex h-full flex-col flex-wrap gap-6 md:flex-row'>
            <div className='grid flex-1 gap-6 md:grid-cols-2'>
              <SkeletonTimeLeftChart />
              <SkeletonTimeLeftChart />
            </div>
            <div className='inline-block w-full'>
              <SkeletonProgressChart />
            </div>
          </div>
          <SkeletonAreaChartComponent />
        </div>
        <Card className='flex flex-col'>
          <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
            {[...Array(6)].map((_, index) => (
              <Fragment key={index}>
                <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                  <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
                </dt>
                <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                  <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
                </dd>
              </Fragment>
            ))}
          </dl>
        </Card>
      </div>
    )
  }

  return (
    <div>
      <div className='mb-6 flex items-center justify-between gap-4'>
        <CensusBreadcrumbs
          censusId={censusDashboardData?.id}
          censusName={censusDashboardData?.name}
        />
        <Button
          disabled={
            censusDashboardData?.isCompleted === true || censusDashboardData?.isEditable === false
          }
          variant='outline'
          onClick={() => navigate(`/census/${id}/edit`)}
        >
          Edytuj spis
        </Button>
      </div>
      <div className='chart-wrapper mx-auto grid items-start gap-6 pb-2 xl:grid-cols-2'>
        <div className='flex h-full flex-col flex-wrap gap-6 md:flex-row'>
          <div className='grid flex-1 gap-6 md:grid-cols-2'>
            <TimeLeftChart
              data={{
                dataKey: 'allDays',
                dateTo: censusDashboardData?.dateTo,
                colorFill: '#FFC107',
                dateFrom: censusDashboardData?.dateFrom,
              }}
              action={handleCensusMutation}
              actionText={censusDashboardData?.isCompleted ? 'Spis zamknięty' : 'Zamknij spis'}
              isCompleted={censusDashboardData?.isCompleted}
              isPending={isLoading}
              isEditable={censusDashboardData?.isEditable}
              isReopenable={censusDashboardData?.isReopenable}
            />
            <ChartHorizontal
              data={{
                balanceChangeIn: censusDashboardData?.balanceChangeIn,
                balanceChangeOut: censusDashboardData?.balanceChangeOut,
              }}
            />
          </div>
          <div className='inline-block w-full'>
            <ProgressChart
              data={{
                total: totalUnits,
                done: completedUnits,
                value: (completedUnits / totalUnits) * 100,
              }}
            />
          </div>
        </div>
        <BarChartComponent censusBranchData={censusDashboardData} dashbordType='' />
      </div>
      <Table data={censusDashboardData?.subresults || []} columns={columns} />
    </div>
  )
}

export default CensusDashboardAdmin
