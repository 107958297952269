import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import DashboardSection from 'components/DashboardSection'
import { path } from 'constants/path'
import useAuth from 'hooks/useAuth'

export const Dashboard = () => {
  const navigate = useNavigate()
  const { isAllOD, isAllOK, isAllAdmin } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  return (
    <div>
      <DashboardSection title='ZESPÓŁ'>
        <Card
          label={'Użytkownicy'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.user.list)}
          />
          <Button
            label='Przypisz'
            className='justify-center'
            onClick={() => navigate(path.user.update)}
          />
        </Card>
        <Card
          label={'Członkowie'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.member.list)}
          />
          {/* <Button 
            disabled 
            label='Dodaj' 
            className="justify-center" 
            onClick={() => navigate(path.user.list)} /> */}
        </Card>
      </DashboardSection>

      <DashboardSection title='STRUKTURA'>
        {/* OKRĘGI */}
        <Card
          label={'Okręgi'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            disabled={!isAllow}
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.region.list)}
          />
          <Button
            label='Dodaj'
            className='justify-center'
            onClick={() => navigate(path.region.create)}
          />
        </Card>

        {/* ODDZIAŁY */}
        <Card
          label={'Oddziały'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            disabled={!isAllow}
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.branch.list)}
          />
          <Button
            disabled={!isAllow}
            label='Dodaj'
            className='justify-center'
            onClick={() => navigate(path.branch.create)}
          />
          <Button
            disabled={!isAllow}
            label='Zarchiwizowane'
            className='justify-center'
            onClick={() => navigate(path.branch.archive)}
          />
          <Button
            label='Wnioski o przeniesienie'
            className='justify-center'
            onClick={() => navigate(path.branch.migrations)}
          />
        </Card>

        {/* SEKCJE */}
        <Card
          label={'Ogniska'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.section.list)}
          />
          <Button
            label='Dodaj ognisko'
            className='justify-center'
            onClick={() => navigate(path.section.createClub)}
          />
          <Button
            label='Dodaj sekcję'
            className='justify-center'
            onClick={() => navigate(path.section.createGroup)}
          />
          <Button
            label='Zarchiwizowane'
            className='justify-center'
            onClick={() => navigate(path.section.archive)}
          />
          <Button
            label='Połącz'
            className='justify-center'
            onClick={() => navigate(path.section.merge)}
          />
        </Card>

        {/* PLACÓWKI */}
        <Card
          label={'Placówki'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.institution.list)}
          />
          <Button
            disabled={!isAllow}
            label='Dodaj'
            className='justify-center'
            onClick={() => navigate(path.institution.create)}
          />
        </Card>
      </DashboardSection>

      <DashboardSection title='DOKUMENTY'>
        <Card
          label={'Deklaracja'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.declaration.list)}
          />
          <Button
            label='Utwórz deklarację'
            className='justify-center'
            onClick={() => navigate(path.declaration.create)}
          />
        </Card>
        <Card
          label={'Obowiązek statystyczny i lista spisów'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          {/* <Button
            label='Dashboard'
            className='justify-center'
            onClick={() => navigate(path.statisticalObligation.list)}
          /> */}
          <Button
            label='Lista spisów'
            className='justify-center'
            onClick={() => navigate(path.census.list)}
          />
          <Button
            label='Utwórz spis'
            className='justify-center'
            onClick={() => navigate(path.census.create)}
          />
          <Button
            label='Lista moich spisów'
            className='justify-center'
            onClick={() => navigate(path.census.listMe)}
          />
        </Card>
        <Card
          label={'Uchwały'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista'
            className='justify-center'
            onClick={() => navigate(path.resolution.list)}
          />
          <Button
            label='Dodaj uchwałę'
            className='justify-center'
            onClick={() => navigate(path.resolution.create)}
          />
        </Card>
        <Card
          label='Legitymacje'
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista wniosków'
            className='justify-center'
            onClick={() => navigate(path.card.request.list)}
          />
          <Button
            label='Lista legitymacji'
            className='justify-center'
            onClick={() => navigate(path.card.list)}
          />
          <Button
            label='Sprawdzenie ważności legitymacji'
            className='justify-center'
            onClick={() => navigate(path.card.verify)}
          />
        </Card>
        <Card
          label={'Wzory dokumentów'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista dokumentów'
            className='justify-center'
            onClick={() => {
              navigate(path.document.list)
              window.scrollTo({ top: 0 })
            }}
          />
          <Button
            label='Utwórz dokument'
            className='justify-center'
            onClick={() => {
              navigate(path.document.create)
              window.scrollTo({ top: 0 })
            }}
          />
        </Card>
      </DashboardSection>
      <DashboardSection title='RODO - wyszukiwanie zasobów'>
        <Card
          label={'Deklaracje członkowskie'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista żądań'
            className='justify-center'
            onClick={() => navigate(path.rodo_resource.declarations.list)}
          />
          <Button
            label='Nowe zapytanie'
            className='justify-center'
            onClick={() => navigate(path.rodo_resource.declarations.ask)}
          />
        </Card>
        <Card
          label={'Członkowie'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista żądań'
            className='justify-center'
            onClick={() => navigate(path.rodo_resource.members.list)}
          />
          <Button
            label='Nowe zapytanie'
            className='justify-center'
            onClick={() => navigate(path.rodo_resource.members.ask)}
          />
        </Card>
      </DashboardSection>

      <DashboardSection title='RODO - operacje na danych'>
        <Card
          label={'Deklaracje członkowskie'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista żądań'
            className='justify-center'
            onClick={() => navigate(path.rodo_access.declarations.list)}
          />
          <Button
            label='Nowe zapytanie'
            className='justify-center'
            onClick={() => navigate(path.rodo_access.declarations.ask)}
          />
        </Card>
        <Card
          label={'Członkowie'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista żądań'
            className='justify-center'
            onClick={() => navigate(path.rodo_access.members.list)}
          />
          <Button
            label='Nowe zapytanie'
            className='justify-center'
            onClick={() => navigate(path.rodo_access.members.ask)}
          />
        </Card>
      </DashboardSection>
      <DashboardSection title='Eksport danych'>
        <Card
          label={'Eksport danych członka'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista żądań'
            className='justify-center'
            onClick={() => {
              navigate(path.export.list)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
          <Button
            label='Nowe zapytanie'
            className='justify-center'
            onClick={() => {
              navigate(path.export.request)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        </Card>
      </DashboardSection>
      <DashboardSection title='Szkolenia'>
        <Card
          label={'Szkolenia'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Lista szkoleń'
            className='justify-center'
            onClick={() => {
              navigate(path.training.list)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
          <Button
            label='Nowe szkolenie'
            className='justify-center'
            onClick={() => {
              navigate(path.training.create)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        </Card>
      </DashboardSection>
      <DashboardSection title='Polecenia'>
        <Card
          label={'Polecenia'}
          className='mb-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'
        >
          <Button
            label='Nowe polecenie'
            className='justify-center'
            onClick={() => {
              navigate(path.orders.create)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
          <Button
            label='Lista poleceń otrzymanych'
            className='justify-center'
            onClick={() => {
              navigate(path.orders.receive)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
          <Button
            label='Lista poleceń wysłanych'
            className='justify-center'
            onClick={() => {
              navigate(path.orders.issued)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        </Card>
      </DashboardSection>
    </div>
  )
}
