import { useEffect, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { flatten } from 'lodash'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { TextField } from 'components/Atoms/TextField'
import { fetchErrors } from 'constants/fetchErrors'
import { queryKeys } from 'constants/queryKeys'
import { Roles, getRolesAsync } from 'features/Role/roleSlice'
import { getRemoteSearch, getRoleList } from 'fetchers/authFetchers'
import { getBranchesList } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { createUser, getUsersList } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import useSearchModule from 'hooks/useSearchModule'
import { roleById } from 'tools/dictionaryHelper'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast } from 'tools/ToastHelpers'

interface IRole {
  label: string
  value: string | number
}
interface IUnit {
  label: string
  value: string | number
}
interface IRoles {
  id: string
  role: IRole
  unit: IUnit
}
interface IForm {
  fullName: string
  phoneNumber: string
  email: string
  division?: string
}
const defaultValueSelector = {
  label: 'Wybierz',
  value: '',
}
const defaultValues = {
  division: '',
  fullName: '',
  phoneNumber: '',
  email: '',
}

export const CopyMemberForm: React.FC = () => {
  const { perPage, setPerPage, filters } = useSearchModule({})
  const [isSending, setIsSending] = useState(false)
  const [roles, setRoles] = useState<IRoles[]>([])

  const dispatch = useAppDispatch()
  const rolesData = useAppSelector((state) => state.rolesReducer.roles)
  const fRoles = flatten(Object.values(rolesData))

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useState<IForm | null>(null)
  const [selected, setSelected] = useState('')
  const [selectedEmail, setSelectedEmail] = useState('')
  const [selectedRole, setSelectedRole] = useState<IRole>(defaultValueSelector)
  const [selectedUnit, setSelectedUnit] = useState<IUnit>(defaultValueSelector)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [localFilter, setLocalFilter] = useState('')

  const { userToken, isAllAdmin, isAllOK, isAllOD } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }, // dodana destrukturyzacja errors
  } = useForm<IForm>({
    defaultValues,
  })

  // Role list query
  const { data: roleList } = useQuery({
    queryKey: [userToken, 'roleList'],
    queryFn: () => getRoleList(),
  })

  // Remote search query
  const { data: remoteUsers, isLoading: remoteUsersIsLoading } = useQuery({
    queryKey: [userToken, 'remoteSearch', searchParams],
    queryFn: () => {
      if (!searchParams) return { items: [] }
      return getRemoteSearch({
        name: searchParams.fullName,
        phone: searchParams.phoneNumber,
        email: searchParams.email,
      })
    },
  })

  // Current user roles query
  const { data: userData } = useQuery({
    queryKey: [userToken, 'usersList', selectedEmail],
    queryFn: () => {
      if (!selectedEmail) return { items: [] }
      return getUsersList(1, 100)
    },
  })

  // Get unit type based on selected role
  const selectedRoleUnit = useMemo(() => {
    if (!selectedRole.value || !roleList) return null

    for (const [key, value] of Object.entries(roleList)) {
      const role = (value as { role: string; name: string }[]).find(
        (i) => i.role === selectedRole.value,
      )
      if (role) return key
    }
    return null
  }, [selectedRole, roleList])

  // Units query
  const { data: units, isLoading: unitsLoading } = useQuery({
    queryKey: [userToken, 'unitsList', selectedRoleUnit],
    queryFn: async () => {
      if (!selectedRoleUnit) return null

      const getUnits = {
        branch: getBranchesList,
        region: getRegionsList,
        section: getSectionsList,
      }[selectedRoleUnit]

      if (!getUnits) return null
      return getUnits(1, 100, [])
    },
    enabled: !!selectedRoleUnit && selectedRoleUnit !== 'systemwide',
  })

  // Filter units locally
  const filteredUnits = useMemo(() => {
    if (!units?.items) return []
    return units.items
      .filter((unit: { name: string }) =>
        unit.name.toLowerCase().includes(localFilter.toLowerCase()),
      )
      .map((unit: { name: any; id: any }) => ({
        label: unit.name,
        value: unit.id,
      }))
  }, [units?.items, localFilter])

  // Create user mutation
  const createUserMutation = useMutation({
    mutationFn: (data: any) => createUser(selected, data),
    onSuccess: () => {
      setSuccess('Pomyślnie dodano rolę')
      setIsSending(false)
      setLoading(false)

      if (selectedRole.value && selectedUnit.value) {
        const newRole: IRoles = {
          id: String(roles.length + 1),
          role: selectedRole,
          unit: selectedUnit,
        }
        setRoles((prevRoles) => [...prevRoles, newRole])
      }

      // Reset form state
      setSelected('')
      setSelectedEmail('')
      setSelectedRole(defaultValueSelector)
      setSelectedUnit(defaultValueSelector)
      setLocalFilter('')
      reset()

      // Navigate back to users list
      navigate('/user')
      toast.success('Pomyślnie dodano rolę')
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      setLoading(false)
      const message = mutationErrorHandler(error, fetchErrors.users)
      setError(message)
      errorToast(message)
    },
  })

  const handleSubmitRole = async () => {
    setError(null)
    setSuccess(null)
    setLoading(true)
    setIsSending(true)

    if (!selectedRole.value || (!selectedUnit.value && selectedRoleUnit !== 'systemwide')) {
      setError('Wybierz rolę i jednostkę')
      setLoading(false)
      setIsSending(false)
      return
    }

    const payload =
      selectedRoleUnit === 'systemwide'
        ? { systemwideRoles: [selectedRole.value] }
        : { unitRoles: [{ role: selectedRole.value, unit: selectedUnit.value }] }

    createUserMutation.mutate(payload)
  }

  const r: Roles = roleList || { systemwide: [], region: [], branch: [], section: [] }
  useEffect(() => {
    dispatch(getRolesAsync())
  }, [])

  useEffect(() => {
    setSelectedUnit(defaultValueSelector)
  }, [selectedRole])

  const onSubmit = (data: IForm) => {
    setSearchParams(data)
  }

  const currentUser = userData?.items
    ? userData?.items.find((i: any) => i.email === selectedEmail)
    : null
  const currentUserRoles = currentUser?.contexts
    ? currentUser?.contexts.map((i: any) => ({
        id: i.role,
        role: {
          label: roleById(i.role, fRoles),
          value: i.role,
        },
        unit: {
          label: i?.unit?.name,
          value: i?.unit?.id,
        },
      }))
    : []

  const { data: division, isLoading } = useQuery({
    queryKey: [
      userToken,
      queryKeys.branchesList,
      queryKeys.regionList,
      queryKeys.sectionsList,
      filters,
      perPage,
      selectedRoleUnit,
    ],
    queryFn: () => {
      if (selectedRoleUnit === 'branch') {
        return getBranchesList(1, perPage, filters)
      }
      if (selectedRoleUnit === 'region') {
        return getRegionsList(1, perPage, filters)
      }
      if (selectedRoleUnit === 'section') {
        return getSectionsList(1, perPage, filters)
      }
      return null
    },
  })

  const divisionList = (division?.items || []).map((e: { name: string; id: string }) => {
    return { label: e.name, value: e.id }
  })

  const searchMore = () => {
    const count =
      division?.pagination?.countFiltered > 0
        ? division?.pagination?.countFiltered
        : division?.pagination?.count
    if (!isLoading && division?.items?.length < count) {
      setPerPage((prev) => prev + 10)
    }
  }

  // Add handler for when SearchAndFetchInput is cleared
  const handleSearchReset = () => {
    setSelectedRole(defaultValueSelector)
    setSelectedUnit(defaultValueSelector)
    setLocalFilter('')
    setError(null)
    setSuccess(null)
  }

  const rolesList = useMemo(() => {
    const isArray = Array.isArray(Object.values(r))

    if (!isArray) return []

    return Object.values(r)
      .flat()
      .map((i: { role: string; name: string }) => ({ label: i.name, value: i.role }))
  }, [r])

  useEffect(() => {
    setSelectedRole({ label: 'Wybierz', value: '' })
  }, [userToken])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card label='Przypisz nowego użytkownika'>
          <div className='grid grid-cols-4 gap-5'>
            <div>
              <TextField
                {...register('fullName')}
                label='Imię i nazwisko'
                placeholder='Imię i nazwisko'
                error={errors.fullName?.message}
              />
            </div>
            <div>
              <TextField
                label='Numer telefonu'
                {...register('phoneNumber')}
                error={errors.phoneNumber?.message}
              />
            </div>
            <div className='mb-5'>
              <TextField label='Email' {...register('email')} />
            </div>
            <div className='flex items-center'>
              <Button
                onClick={() => {
                  setSelected('')
                  setRoles([])
                  setSearchParams(null)
                  setError(null)
                  setSuccess(null)
                }}
                label='Wyszukaj użytkownika'
              />
            </div>
            {remoteUsersIsLoading && (
              <div className='mb-4 flex flex-col gap-4'>
                <div className='h-4 w-full animate-pulse rounded-md bg-slate-300'></div>
                <div className='h-4 w-full max-w-[90%] animate-pulse rounded-md bg-slate-300'></div>
              </div>
            )}
          </div>
          {remoteUsers?.items && (
            <table className='min-w-full divide-y-reverse divide-slate-300'>
              <thead>
                <tr>
                  <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                    Imię i nazwisko
                  </th>
                  <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Email:
                  </th>
                  <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Telefon:
                  </th>
                  <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                    Akcje
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y border-t border-gray-300'>
                {(
                  remoteUsers?.items?.filter((i: any) =>
                    selected ? i.remoteId === selected : i,
                  ) || []
                ).map((item: any) => {
                  return (
                    <tr key={item.remoteId}>
                      <td
                        className={
                          'whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 sm:pl-0'
                        }
                      >
                        {item.displayName}
                      </td>
                      <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>
                        {item.email}
                      </td>
                      <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>
                        {item.phone}
                      </td>
                      <td className={'whitespace-nowrap px-3 py-4 text-sm text-gray-500'}>
                        {!selected && (
                          <Button
                            label='Wybierz'
                            className='flex justify-center'
                            onClick={() => {
                              setSelected(item.remoteId)
                              setSelectedEmail(item.email)
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          {selected && (
            <div className='my-4 grid grid-cols-2 gap-4'>
              <div>
                <Select
                  defaultOption={defaultValueSelector}
                  token={userToken}
                  handleSelect={(val) => setSelectedRole(val)}
                  options={rolesList}
                  label='Przypisz nową rolę'
                  withEmpty
                />
              </div>
              {selectedRoleUnit && selectedRoleUnit !== 'systemwide' ? (
                <div>
                  <SearchAndFetchInput
                    key={selectedRole.value} // Force re-render when role changes
                    externalChangeValue={(val) => setSelectedUnit({ label: val, value: val })}
                    searchMore={searchMore}
                    isLoading={isLoading}
                    name={'division'}
                    label={'Wybierz jednostkę docelową'}
                    register={register}
                    options={divisionList}
                    control={control}
                    onReset={handleSearchReset}
                  />
                </div>
              ) : (
                isLoading && (
                  <div className='flex items-center justify-center'>
                    <Spinner />
                  </div>
                )
              )}

              <div>
                <p className='mb-1 block text-sm font-medium text-gray-700'>{'Przypisane role:'}</p>
                <p className='text-sm text-gray-500'>
                  {currentUserRoles
                    .map((i: any) => {
                      return `${i?.role?.label}${i?.unit?.label ? ` (${i?.unit?.label})` : ''}`
                    })
                    .join(', ')}
                </p>
              </div>
            </div>
          )}
          {selected && (
            <div className='mb-4 flex justify-center gap-5'>
              <Button label='Anuluj' variant='secondary' onClick={() => navigate('/user')} />
              <Button
                label={
                  isSending
                    ? 'Przypisuję...'
                    : selectedRole.value && selectedUnit.value
                      ? 'Przypisz rolę'
                      : 'Wybierz rolę i jednostkę'
                }
                disabled={
                  !selectedRole.value ||
                  (!selectedUnit.value && selectedRoleUnit !== 'systemwide') ||
                  isSending
                }
                onClick={handleSubmitRole}
              />
            </div>
          )}
          {/* {error && <Alert description={error} headline={'Błąd!!!'} level='red' />}
          {success && <Alert description={success} headline={'Info'} level='blue' />} */}
          {/* {loading && <Spinner />} */}
        </Card>
      </form>
      <div className='mt-4 flex items-center justify-center'>
        <Button
          className='mx-auto'
          label='Powrót do listy'
          onClick={() => navigate('/user')}
          variant='secondary'
        />
      </div>
    </>
  )
}
