import { useEffect, useRef, useState } from 'react'

import { Controller } from 'react-hook-form'

import { IOption } from 'types/form'

import { Spinner } from '../Spinner'

type Props = {
  control: any
  name: any
  label: string
  register: any
  options: IOption[]
  defaultOption?: IOption
  externalChangeHandler?: (e: string) => void
  externalChangeValue?: (e: string) => void
  isLoading?: boolean
  loadMore?: () => void
}

export const AutofetchInput = ({
  control,
  name,
  register,
  options,
  defaultOption,
  externalChangeHandler,
  externalChangeValue,
  isLoading,
  loadMore,
}: Props) => {
  const [currentList, setCurrentList] = useState<IOption[]>([])
  const ulRef = useRef<HTMLUListElement>(null)
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const element = e.target as HTMLUListElement
    if (element.scrollHeight - element.scrollTop - 10 < element.clientHeight) {
      loadMore && loadMore()
    }
  }

  useEffect(() => {
    if (options.length) {
      setCurrentList(options)
    }
  }, [options])

  return (
    <div className='grid gap-2'>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultOption || {}}
        render={({ field: { onChange, value } }) => (
          <ul
            ref={ulRef}
            onScroll={handleScroll}
            className='relative h-56 overflow-y-scroll rounded-md border-2 border-solid border-gray-300'
          >
            {isLoading && currentList.length === 0 ? (
              <div className='flex h-full items-center justify-center'>
                <Spinner />
              </div>
            ) : (
              <>
                {currentList.map((e: any) => {
                  const rKey = `r-${e.value}-${name}`
                  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange({ value: e })
                  }
                  return (
                    <li
                      key={rKey}
                      className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
                    >
                      <label htmlFor={rKey} className='cursor-pointer'>
                        <input
                          {...register(name)}
                          type='radio'
                          id={rKey}
                          name={name}
                          onChange={() => {
                            handleRadioChange(e.value)
                            externalChangeValue && externalChangeValue(e?.value)
                            externalChangeHandler && externalChangeHandler(e?.label)
                          }}
                          checked={value?.value === e.value}
                          value={e.value}
                        />
                        <span className='pl-2'>{e.label}</span>
                      </label>
                    </li>
                  )
                })}
                {isLoading && (
                  <div className='flex items-center justify-center py-5'>
                    <Spinner />
                  </div>
                )}
              </>
            )}
          </ul>
        )}
      />
    </div>
  )
}

export default AutofetchInput
