import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'

export const moveSectionSchema = yup.object({
  comment: yup.string().min(5, errorMessage.min5Chars).required(errorMessage.requiredField),
  document: yup.string().required(errorMessage.requiredField),
})

export const moveMassBranchSchema = yup.object({
  items: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(errorMessage.requiredField),
      }),
    )
    .required(errorMessage.requiredField),
  comment: yup.string().min(5, errorMessage.min5Chars).required(errorMessage.requiredField),
  branchId: yup.string().required(errorMessage.requiredField),
})

export const moveMassSectionSchema = yup.object({
  items: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(errorMessage.requiredField),
      }),
    )
    .required(errorMessage.requiredField),
  comment: yup.string().min(5, errorMessage.min5Chars).required(errorMessage.requiredField),
  sectionId: yup.string().required(errorMessage.requiredField),
})
