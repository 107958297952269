import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { houseNrRegExp, phoneRegExp } from 'constants/regexs'

export const createDeclarationUserSchema = yup.object({
  firstName: yup.string().required(errorMessage.requiredField).min(2),
  branch: yup.string().required(errorMessage.requiredField).min(2),
  secondName: yup.string().nullable().optional(),
  lastName: yup.string().required(errorMessage.requiredField).min(2),
  pesel: yup.string().required(errorMessage.requiredField),
  sex: yup.string().required(errorMessage.requiredField),
  phoneNumber: yup
    .string()
    .required(errorMessage.requiredField)
    .matches(phoneRegExp, errorMessage.invalidPhone),

  email: yup.string().nullable().optional(),
  zipCode: yup
    .string()
    .required(errorMessage.requiredField)
    .min(6, errorMessage.invalidFormat)
    .max(6, errorMessage.invalidFormat),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => {
      // Custom validation function
      return !/\d/.test(String(value))
    })
    .required(errorMessage.requiredField),
  street: yup.string().nullable().optional(),
  houseNr: yup
    .string()
    .matches(houseNrRegExp, errorMessage.invalidHouseNr)
    .required(errorMessage.requiredField),
  apartmentNr: yup.string().nullable().optional(),
  education: yup.string().nullable().optional(),
  educationSimple: yup.string().nullable().optional(),
  jobs: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          institution: yup.string(),
          position: yup.string(),
          isMain: yup.boolean(),
        })
        .test('institutionRequired', errorMessage.jobBoth, function (value) {
          return !!value.institution && !!value.position // Checks if either institution or position has any value
        }),
    )
    .min(1, errorMessage.job), // At least one job is required
  jobsSimple: yup.array().of(
    yup
      .object()
      .shape({
        institution: yup.string().nullable().default(''), // Validation for institution value
        position: yup.string().nullable().default(''), // Validation for position value
        isMain: yup.boolean(),
      })
      .test(
        'Wypełnij przynajmniej jedno pole', // Test name
        ' ', // Error message
        function (value) {
          return Object.values(value).some((field) => field && field !== '')

          // return !!value.institution || !!value.position; // Checks if either institution or position has any value
        },
      ),
  ),
})
