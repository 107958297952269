import { useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Controller, useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { Select } from 'components/Atoms/Select'
import { queryKeys } from 'constants/queryKeys'
import { getEducationsList } from 'fetchers/educationFetchers'
import { updateMemberGeneralInfo } from 'fetchers/membersFetchers'
import useAuth from 'hooks/useAuth'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { IMemberGeneral } from 'types/member'

type Props = {
  initData?: IMemberGeneral
  isEditAllow?: boolean
  refetch: () => void
}

export const MembershipEducationSection = ({ initData, refetch, isEditAllow }: Props) => {
  const [isSelected, setIsSelected] = useState<string>('')
  const [isSending, setIsSending] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { userToken, id } = useAuth()
  const { control, handleSubmit } = useForm({
    defaultValues: initData,
  })
  // EDUCATIONS LIST
  const { data: education, isLoading: loadingEducation } = useQuery({
    queryKey: [userToken, queryKeys.educationList],
    queryFn: () => getEducationsList(1, 100),
  })
  const educationList = education?.items
    ? education?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []
  // END OF EDUCATIONS LIST

  // UPDATE GENERAL INFO
  const mutateGeneral = useMutation({
    mutationFn: (data: IMemberGeneral) => updateMemberGeneralInfo(id, data),
    onSuccess: () => {
      successToast('Zaktualizowano wykształcenie.')
      setIsSending(false)
      setIsEdit(false)
      setIsSelected('')
      refetch()
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, null, 'Nie udało się zaktualizować wykształcenia.')
    },
  })
  const onSubmit = async (data: IMemberGeneral) => {
    try {
      const { educationLabel, ...formData } = data
      setIsSending(true)
      mutateGeneral.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować wykształcenia.')
    }
  }
  // END OF UPDATE GENERAL INFO
  if (isEdit)
    return (
      <form className='w-80' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='education'
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              handleSelect={({ value }) => {
                onChange(value)
                setIsSelected(String(value))
              }}
              label='Wykształcenie'
              selectLabel='---'
              withEmpty
              options={educationList}
            />
          )}
        />
        <div className='my-4 flex items-center justify-center gap-5'>
          <Button
            disabled={isSending}
            label={'Anuluj'}
            variant='secondary'
            onClick={() => setIsEdit(false)}
          />
          <Button
            disabled={isSending || !isSelected}
            label={'Zapisz'}
            onClick={() => setIsEdit(true)}
          />
        </div>
      </form>
    )
  return (
    <div className='mb-4 flex items-center gap-5'>
      <LabelTypography label='Wykształcenie' text={initData?.educationLabel || '-'} />
      {isEditAllow && (
        <Button disabled={isSending} label={'Edytuj'} onClick={() => setIsEdit(true)} />
      )}
    </div>
  )
}

export default MembershipEducationSection
