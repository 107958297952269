import { ChangeEventHandler, useEffect } from 'react'

import { xor } from 'lodash'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import SearchMultiselect from 'components/Atoms/SearchMultiselect/SearchMultiselect'
import { TextField } from 'components/Atoms/TextField'
import useAuth from 'hooks/useAuth'
import { useSearch } from 'hooks/useSearch'
import {
  IBasicSettingForm,
  IBasicSettingObject,
  IBooleans,
  IPageHandler,
  IQuery,
  IQueryHandler,
  IVoidObject,
} from 'types/form'
import { IQueryParamsState } from 'types/searchModule'
import { IUnitSelectedUnits } from 'types/structure'

type Props = {
  isRegion?: boolean
  isBranch?: boolean
  searchParams: IQueryParamsState
  selectedUnits: IUnitSelectedUnits
  setSelectedUnits: React.Dispatch<React.SetStateAction<IUnitSelectedUnits>>
  options: IBasicSettingObject
  loadings: IBooleans
  query: IQuery
  refetch: IVoidObject
  queryHandler: IQueryHandler
  searchMore: IVoidObject
  limitSetter: IPageHandler
  // React.Dispatch<React.SetStateAction<number>>
}

export const UserSearchBar = ({
  selectedUnits,
  setSelectedUnits,
  searchParams,
  options,
  loadings,
  query,
  refetch,
  queryHandler,
  searchMore,
  limitSetter,
}: Props) => {
  const { param, setParam, searchQuery } = useSearch(searchParams)
  const { isAllAdmin, isAllOK, isAllOD } = useAuth()
  const isAdmin = isAllAdmin()
  const isRegion = isAllOK() || isAdmin
  const isBranch = isAllOD()

  const submitHandler = (e: any) => {
    e.preventDefault()
    searchQuery()
  }
  const singleSetter: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.currentTarget
    setParam.single(name, value)
  }
  const handleInputCheck = (
    option: IBasicSettingForm,
    setter: React.Dispatch<React.SetStateAction<IUnitSelectedUnits>>,
    paramType: keyof IUnitSelectedUnits,
  ) => {
    if (option?.id) {
      setParam.array(paramType, xor(param.array[paramType] as string[], [option?.id]))
      setter((prev: IUnitSelectedUnits) => ({
        ...prev,
        [paramType]: xor(prev[paramType], [option?.id]),
      }))
    }
  }

  useEffect(() => {
    setParam.array('institution', selectedUnits.institution)
  }, [selectedUnits.institution])

  const branchSubLabel = !selectedUnits?.region?.length ? 'Zaznacz przynajmniej jeden okręg' : '---'
  const sectionSubLabel = !selectedUnits?.branch?.length
    ? 'Zaznacz przynajmniej jeden oddział'
    : '---'
  return (
    <Card label='Szukaj'>
      <form onSubmit={submitHandler}>
        <div className='grid grid-cols-4 gap-5'>
          <TextField label='Wyszukaj' type='text' name='search' onChange={singleSetter} />
        </div>
        <div className='mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4'>
          {isAdmin && (
            <SearchMultiselect
              type='region'
              label={'Okręg'}
              options={options.regions}
              isLoading={loadings.regions}
              query={query.regions}
              searchMore={searchMore.regions}
              queryHandler={queryHandler.regions}
              changeLimit={limitSetter.regions}
              refetch={refetch.regions}
              inputCheck={selectedUnits.region}
              handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'region')}
            />
          )}
          {(isRegion || isAdmin) && (
            <SearchMultiselect
              type='branch'
              label={'Oddział'}
              resultLabel={branchSubLabel}
              options={options.branches}
              isLoading={loadings.branches}
              query={query.branches}
              searchMore={searchMore.branches}
              queryHandler={queryHandler.branches}
              changeLimit={limitSetter.branches}
              refetch={refetch.branches}
              inputCheck={selectedUnits.branch}
              handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'branch')}
            />
          )}
          <SearchMultiselect
            type='section'
            label={'Sekcja'}
            resultLabel={sectionSubLabel}
            options={options.sections}
            isLoading={loadings.sections}
            query={query.sections}
            searchMore={searchMore.sections}
            queryHandler={queryHandler.sections}
            changeLimit={limitSetter.sections}
            refetch={refetch.sections}
            inputCheck={selectedUnits.section}
            handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'section')}
          />
          <SearchMultiselect
            type='role'
            label={'Rola'}
            options={options.role}
            isLoading={loadings.role}
            query={query.role}
            queryHandler={queryHandler.role}
            inputCheck={selectedUnits.role || []}
            handleInputCheck={(e) => handleInputCheck(e, setSelectedUnits, 'role')}
          />
        </div>

        <div className='mt-8 flex justify-center'>
          <Button label='Szukaj' />
        </div>
      </form>
    </Card>
  )
}

export default UserSearchBar
