import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import ErrorCard from 'components/ErrorCard'
import SettingsFormBasic from 'components/Settings/Forms/SettingsFormBasic'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { positionErrors } from 'error-data/position'
import { getPosition, updatePosition } from 'fetchers/positionFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, universalError } from 'tools/ToastHelpers'
import { IBasicSettingForm, INameForm } from 'types/form'

export const MemberPositionsUpdate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const listPath = path.settings.position.list
  const pathId = id as string

  const {
    data: position,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.position, pathId],
    queryFn: () => getPosition(pathId),
  })

  const mutation = useMutation({
    mutationFn: (data: INameForm) => updatePosition(position?.id, data),
    onSuccess: () => {
      successToast('Zaktualizowano stanowisko')
      refetch()
      queryClient.invalidateQueries({ queryKey: [queryKeys.positionsList] })
      navigate(listPath)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, positionErrors.update, 'Nie udało się zaktualizować stanowiska.')
    },
  })

  const onSubmit = async (data: IBasicSettingForm) => {
    try {
      setIsSending(true)
      mutation.mutate({ name: data.name })
    } catch (error) {
      setIsSending(false)
      universalError(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować stanowiska.')
    }
  }

  const cancelHandler = () => navigate(listPath)
  const breadcrumbLink = (
    <>
      <Link to={listPath}>Stanowiska</Link> / Edytuj
    </>
  )
  if (isLoading) return <LoadingSection />
  if (error) {
    return (
      <ErrorCard
        error={error}
        label={'Błąd pobierania danych'}
        message={'Wystąpił błąd podczas pobierania stanowiska'}
        onCancel={cancelHandler}
        buttonLabel={'Lista stanowisk'}
      />
    )
  }
  return (
    <SettingsFormBasic
      cardLabel={'Edytuj stanowisko'}
      initData={{ name: position.name }}
      inputLabel={'Nazwa stanowiska'}
      inputPlaceholder={'Wprowadź nowe stanowisko'}
      onSubmit={onSubmit}
      onCancelHandler={cancelHandler}
      breadcrumbLink={breadcrumbLink}
      isSending={isSending}
    />
  )
}

export default MemberPositionsUpdate
