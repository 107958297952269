import { useState } from 'react'

import { Field, Label, Radio, RadioGroup } from '@headlessui/react'

import { Card } from 'components/Atoms/Card'
import { AddMemberForm } from 'components/StatisticalObligation/AddMemberForm'
import { ApproveLeftInfo } from 'components/StatisticalObligation/ApproveLeftInfo'
import { Item } from 'components/StatisticalObligation/Item'
import { ReadyToApprove } from 'components/StatisticalObligation/ReadyToApprove'
import { ReportSummary } from 'components/StatisticalObligation/ReportSummary'

const UserItem = [
  {
    firstName: 'Wisława',
    lastName: 'Moczulska',
    institution: 'Szkoła podstawowa nr 2',
    position: 'Nauczyciel miarowany',
    job: 'Pół etatu',
    isExists: false,
  },
  {
    firstName: 'Krystyna',
    lastName: 'Wielicka',
    institution: 'LO VII',
    position: 'Dyrektor',
    job: 'Full time',
    isExists: true,
  },
]

const plans = ['Pokaż wszystkich', 'Pokaż niepotwierdzonych']

export const StatisticalObligationSectionList = () => {
  const [displaySummary, setDisplaySummary] = useState(false)
  const [selected, setSelected] = useState(plans[0])

  if (displaySummary) return <ReportSummary setDisplaySummary={() => setDisplaySummary(false)} />
  return (
    <>
      <Card label='Wypełnianie statystyk'>
        <div className='flex items-center'>
          <RadioGroup
            value={selected}
            onChange={setSelected}
            aria-label='Server size'
            className='flex items-center gap-4'
          >
            {plans.map((plan) => (
              <Field key={plan} className='flex items-center gap-1'>
                <Radio
                  value={plan}
                  className='group flex size-5 flex-row items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-400'
                >
                  <span className='invisible size-2 rounded-full bg-white group-data-[checked]:visible' />
                </Radio>
                <Label className='text-sm'>{plan}</Label>
              </Field>
            ))}
          </RadioGroup>
        </div>
      </Card>

      {UserItem.map((item, index) => (
        <div key={index}>
          <Item {...item} />
        </div>
      ))}

      <AddMemberForm />
      <ApproveLeftInfo />
      <ReadyToApprove setDisplaySummary={() => setDisplaySummary(true)} />
    </>
  )
}
