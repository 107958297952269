import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { TextareaField } from 'components/Atoms/TextareaField'
import { TextField } from 'components/Atoms/TextField'
import LegalSearch from 'components/Legal/LegalSearch'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { createForm } from 'schemas/regionsSchema'
import { ICreateRegion } from 'types/region'

interface IProps {
  isSending?: boolean
  onSubmit: (formData: any) => void
  data: any
  label: string
}

export const UpdateForm: React.FC<IProps> = ({ isSending, onSubmit, data, label }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ICreateRegion>({
    defaultValues: data,
    resolver: yupResolver(createForm),
  })
  const legalData = useLegalFetcher(true)

  const navigate = useNavigate()

  const document = data?.document
    ? { label: data.document.name, value: data.document.id }
    : undefined

  return (
    <Card label={label}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-5 grid grid-cols-4 gap-5'>
          <TextField
            id='name'
            {...register('name')}
            error={errors.name?.message}
            label='Nazwa okręgu'
            placeholder='Nazwa'
          />
          <TextField
            {...register('email')}
            label='E-mail'
            error={errors.email?.message}
            placeholder='Podaj e-mail'
          />
          <TextField
            {...register('phonePrimary')}
            error={errors.phonePrimary?.message}
            label='Numer telefonu'
            placeholder='Podaj numer'
          />
          <TextField
            {...register('phoneSecondary')}
            error={errors.phoneSecondary?.message}
            label='Numer telefonu dodatkowy'
            placeholder='Podaj numer'
          />
        </div>

        <div className='mb-5 grid grid-cols-4 gap-5'>
          <TextField
            {...register('postalCode')}
            error={errors.postalCode?.message}
            label='Kod pocztowy'
            placeholder='XX-XXX'
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const input = e.target.value.replace(/\D/g, '')
              if (input.length > 2) {
                setValue('postalCode', input.slice(0, 2) + '-' + input.slice(2, 5))
              } else {
                setValue('postalCode', input)
              }
            }}
          />
          <TextField
            {...register('city')}
            error={errors.city?.message}
            label='Miejscowość'
            placeholder='Miejscowość'
          />
          <div></div>
        </div>

        <div className='mb-5 grid grid-cols-4 gap-5'>
          <TextField
            {...register('streetName')}
            // error={errors.streetName?.message}
            label='Ulica'
            placeholder='Ulica'
          />
          <TextField
            {...register('houseNr')}
            error={errors.houseNr?.message}
            label='Numer budynku'
            placeholder='Numer budynku'
          />
          <TextField {...register('apartmentNr')} label='Numer lokalu' placeholder='Numer lokalu' />
        </div>

        <div className='mb-5 grid w-3/4 grid-cols-1 gap-5'>
          <TextareaField {...register('locationDescription')} label='Lokalizacja' />
        </div>

        <div>
          <LegalSearch
            isRegion={true}
            defaultOption={document}
            control={control}
            legalData={legalData}
            register={register}
          />
          <p className='mt-2 text-sm text-red-600'>{errors?.document?.message ? 'Wybierz' : ''}</p>
        </div>

        <div className='mt-5 flex justify-center'>
          <div className='mr-5'>
            <Button label='Anuluj' variant='secondary' onClick={() => navigate('/region')} />
          </div>
          <Button disabled={isSending} label='Zatwierdź' />
        </div>
      </form>
    </Card>
  )
}
