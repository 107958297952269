import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Table } from 'components/Atoms/Table'
import { path } from 'constants/path'

export const MainManagmentList = () => {
  const navigate = useNavigate()
  const createNewPath = path.statisticalObligation.mainManagment.create

  const data = React.useMemo(
    () => [
      {
        nr: 'Obowiązek 1',
        year: '2017',
        startDate: '2022-03-12',
        endDate: '2023-12-12',
      },
      {
        nr: 'Obowiązek 2',
        year: '2017',
        startDate: '2022-03-12',
        endDate: '2023-12-12',
      },
      {
        nr: 'Obowiązek 3',
        year: '2017',
        startDate: '2022-03-12',
        endDate: '2023-12-12',
      },
    ],
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Numer',
        accessor: 'nr',
      },
      {
        Header: 'Rok',
        accessor: 'year',
      },
      {
        Header: 'Rozpoczęcie',
        accessor: 'startDate',
      },
      {
        Header: 'Zakończenie',
        accessor: 'endDate',
      },
      {
        Header: 'Edytuj',
        accessor: 'edit',
        Cell: (row: any) => {
          return <p>Edytuj</p>
        },
      },
      {
        Header: 'Zobacz statystyki',
        accessor: 'details',
        Cell: (row: any) => <p>Zobacz</p>,
      },
    ],
    [],
  )

  return (
    <Card label='Rozpoczęcie nowego obiowiązku statystycznego'>
      <Button label='Utwórz nowy' onClick={() => navigate(createNewPath)} />
      <Table columns={columns} data={data} />
    </Card>
  )
}
