import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Select } from 'components/Atoms/Select'
import { TextareaField } from 'components/Atoms/TextareaField'
import { TextField } from 'components/Atoms/TextField'
import LegalSearch from 'components/Legal/LegalSearch'
import { createSectionSchemaClub } from 'schemas/sectionSchema'
import { errorRequires } from 'tools/ToastHelpers'
import { IOption } from 'types/form'
import { ILegalHook } from 'types/legal'
import { ICreateSectionClub } from 'types/section'

interface IProps {
  isSending?: boolean
  onSubmit: (data: any) => void
  navigate: (path: string) => void
  data: ICreateSectionClub
  institutionsList: IOption[]
  branchesList?: any[]
  createRoot?: boolean
  legalData: ILegalHook
}

export const SectionFormClub: React.FC<IProps> = ({
  onSubmit,
  navigate,
  data,
  institutionsList,
  branchesList = [],
  createRoot,
  isSending,
  legalData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<ICreateSectionClub>({
    resolver: yupResolver(createSectionSchemaClub),
    defaultValues: data,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'institutions',
  })

  const onValidationFailure = () => errorRequires(errors)

  return (
    <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
      {createRoot && (
        <div className='mb-5 grid grid-cols-4 gap-5'>
          <div>
            <p className='mb-1 block text-sm font-medium text-gray-700'>Wybierz oddział</p>
            <Controller
              name={'branch'}
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  handleSelect={({ value }) => {
                    onChange(value)
                    legalData && legalData.setUnit(String(value))
                  }}
                  options={branchesList}
                  withEmpty
                />
              )}
            />
            <p className='mt-2 text-sm text-red-600'>{errors?.branch?.message}</p>
          </div>
        </div>
      )}
      <div className='mb-5 grid grid-cols-4 gap-5'>
        <TextField
          id='name'
          {...register('name')}
          error={errors.name?.message}
          label='Nazwa ogniska'
          placeholder='Nazwa'
        />
        <TextField
          {...register('phonePrimary')}
          error={errors.phonePrimary?.message}
          label='Numer telefonu'
          placeholder='Podaj numer'
        />
        <TextField
          {...register('phoneSecondary')}
          error={errors.phoneSecondary?.message}
          label='Numer telefonu dodatkowy'
          placeholder='Podaj numer'
        />
        <TextField
          {...register('email')}
          error={errors.email?.message}
          label='E-mail kontaktowy'
          placeholder='Podaj e-mail'
        />
      </div>

      <div className='mb-5'>
        <label htmlFor='' className='mb-1 block text-sm font-medium text-gray-700'>
          Powiązane placówki
        </label>
        <ul>
          {(fields || []).map((item, index) => (
            <li className='mb-5 grid grid-cols-4 gap-5' key={item.id}>
              <Controller
                name={`institutions.${index}`}
                control={control}
                render={({ field: { onChange } }) => {
                  const defaultOption = item?.name
                    ? {
                        label: String(item?.name),
                        value: data?.institutions?.[index]?.id
                          ? data?.institutions?.[index]?.id
                          : '',
                      }
                    : undefined
                  return (
                    <Select
                      defaultOption={defaultOption}
                      handleSelect={({ value }) => onChange(value)}
                      options={institutionsList}
                    />
                  )
                }}
              />
              <Button
                className='ml-5 w-10'
                icon={<MinusCircleIcon className='w-5' />}
                onClick={() => remove(index)}
              />
            </li>
          ))}
        </ul>
        <div className='flex'>
          <Button
            onClick={(e) => {
              e.preventDefault()
              append({ id: '' })
            }}
            label='Dodaj kolejny'
            icon={<PlusCircleIcon className='mr-2 w-5' />}
          />
        </div>
      </div>
      <div className='mb-5 grid w-3/4 grid-cols-1 gap-5'>
        <TextareaField {...register('locationDescription')} label='Lokalizacja' />
      </div>
      <div>
        <LegalSearch control={control} legalData={legalData} register={register} />
        <p className='mt-2 text-sm text-red-600'>{errors?.document?.message ? 'Wybierz' : ''}</p>
      </div>
      <div className='mt-10 flex'>
        <div className='mr-5'>
          <Button variant='secondary' label='Anuluj' onClick={() => navigate('/section')} />
        </div>
        <Button disabled={isSending} label='Zatwierdź' />
      </div>
    </form>
  )
}
