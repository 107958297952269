import { useQuery } from '@tanstack/react-query'

import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import RodoResourceRequests from 'components/Rodo/RodoResourceRequests'
import { queryKeys } from 'constants/queryKeys'
import { getResourceDeclarationCriteria, getResourceDeclarationsList } from 'fetchers/rodoFetchers'
import useAuth from 'hooks/useAuth'
import { getLoadingHandler } from 'tools/queryHelpers'
import { IDict } from 'types/dictionary'

export const ResourceDeclarationsList = () => {
  const { userToken, isAllAdmin, isAllOD } = useAuth()

  const data = useQuery({
    queryKey: [userToken, queryKeys.rodoResourceDeclarations],
    queryFn: () => getResourceDeclarationsList(),
  })

  const criteria = useQuery({
    queryKey: [userToken, queryKeys.rodoResourceDeclarationsCriteria],
    queryFn: () => getResourceDeclarationCriteria(),
  })

  const dataList = data.data?.items || []
  const criteriaItems: IDict[] = criteria.data?.items || []

  const isLoading = data.isPending || criteria.isPending
  const isError = data.error || criteria.error
  const isValid = isAllAdmin() || isAllOD()
  const loadingHandler = getLoadingHandler(isError, isLoading, !isValid)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <div>
      <Typography size='xl' weight='semibold'>
        RODO / Lista zapytań o deklaracje członkowskie
      </Typography>
      <RodoResourceRequests type='declarations' list={dataList} criteria={criteriaItems} />
    </div>
  )
}

export default ResourceDeclarationsList
