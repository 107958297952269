import { useEffect, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Typography } from 'components/Atoms/Typography'
import { SectionFormGroup } from 'components/Section/SectionFormGroup'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { sectionErrors } from 'error-data/section'
import { useArchiveSection } from 'fetchers/hooks/useArchiveSection'
import { getSection, getSectionGroups, updateSectionGroup } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { ICreateSectionGroup } from 'types/section'

export const SectionUpdateGroup = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { id } = useParams()
  const sectionId = id as string
  const legalData = useLegalFetcher()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()

  const {
    data: section,
    error: sectionError,
    isLoading: sectionIsLoading,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.section, sectionId],
    queryFn: () => getSection(sectionId),
    retry: errorQuery,
  })

  const { data: types, isLoading: typesLoading } = useQuery({
    queryKey: [userToken, queryKeys.sectionsTypesList],
    queryFn: () => getSectionGroups(),
  })
  const typesList = (types?.items || []).map((e: { name: string; id: string }) => {
    return { label: e?.name, value: e?.id }
  })

  const mutation = useMutation({
    mutationFn: (data: ICreateSectionGroup) => {
      const { branch, subtype, ...formData } = data
      return updateSectionGroup(sectionId, formData)
    },
    onSuccess: () => {
      successToast('Zaktualizowano ognisko.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      refetch()
      navigate(path.section.list)
    },
    onError: (error: any) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, sectionErrors.update, 'Nie udało się zaktualizować ogniska.')
    },
  })
  const { showModalHandler, ArchiveModal } = useArchiveSection({
    refetch,
    navigatePath: path.section.list,
  })

  // MODAL HANDLER

  const onSubmit = async (data: ICreateSectionGroup) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zaktualizować ogniska.')
    }
  }

  const initData = {
    branch: section?.parent?.id,
    subtype: section?.type,
    phonePrimary: section?.contact?.phone ? section?.contact?.phone : '',
    phoneSecondary: section?.contact?.phoneSecondary ? section?.contact?.phoneSecondary : '',
    email: section?.contact?.email,
    locationDescription: section?.locationDescription ? section?.locationDescription : '',
    document: section?.document ? section?.document : '',
  }

  useEffect(() => {
    if (section?.parent?.id) {
      legalData.setUnit(section.parent.id)
    }
  }, [section])

  const isLoading = typesLoading || sectionIsLoading
  const loadingHandler = getLoadingHandler(sectionError, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <>
      <Typography size='xl' weight='semibold'>
        <Link to='/section'>Ognisko</Link> / Edytuj sekcję
      </Typography>
      <p className='mb-5 mt-1 text-xs font-semibold text-gray-500'>
        {section?.parent?.name} | {section?.name}
      </p>
      <Card
        actionsButton={[
          {
            label: 'Archiwizuj',
            handleClick: () => showModalHandler(section),
          },
        ]}
      >
        <SectionFormGroup
          legalData={legalData}
          isSending={isSending}
          data={initData}
          onSubmit={onSubmit}
          typesList={typesList}
          navigate={navigate}
        />
      </Card>
      <ArchiveModal />
    </>
  )
}
