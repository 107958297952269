import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { cityRegExp, emailRegex, houseNrRegExp, phoneRegExp } from 'constants/regexs'
const postalCodeRegExp = /^[0-9]{2}-[0-9]{3}$/

export const branchForm = yup.object({
  region: yup.string().required(errorMessage.requiredField),
  name: yup.string().required(errorMessage.requiredField).min(3, errorMessage.min3Chars),
  email: yup.string().optional().matches(emailRegex, errorMessage.invalidEmail),
  postalCode: yup
    .string()
    .required(errorMessage.requiredField)
    .matches(postalCodeRegExp, errorMessage.invalidPostalCode),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => {
      // Custom validation function
      return cityRegExp.test(String(value))
    })
    .required(errorMessage.requiredField)
    .min(2, errorMessage.min2Chars),
  streetName: yup.string(),
  houseNr: yup.string().matches(houseNrRegExp, errorMessage.invalidHouseNr),
  apartmentNr: yup.string(),
  locationDescription: yup.string(),
  phonePrimary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  phoneSecondary: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
})
