import { useState } from 'react'

import { EyeIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Typography } from 'components/Atoms/Typography'

interface IProps {
  id: number
  changing: string
  date: string
  changedFiles: string[]
  beforeChange: string
  afterChange: string
  handleVisibleDetails: (visible: boolean) => void
}

export const HistoryList: React.FC<IProps> = ({
  id: historyId,
  changing,
  date,
  changedFiles,
  beforeChange,
  afterChange,
  handleVisibleDetails,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const params = useParams()
  const navigate = useNavigate()

  // console.info({ params, historyId })

  const handleVisibleChange = () => {
    setIsVisible(true)
    handleVisibleDetails(true)
  }

  return (
    <div className='grid grid-cols-7 gap-4'>
      <Typography size='base' weight='semibold'>
        {changing}
      </Typography>
      <Typography size='base' weight='semibold'>
        {date}
      </Typography>
      <Typography size='base' weight='semibold'>
        {date}
      </Typography>
      <Typography size='base' weight='semibold'>
        {changedFiles}
      </Typography>
      {isVisible ? (
        <>
          <Typography size='base' weight='semibold'>
            {beforeChange}
          </Typography>
          <Typography size='base' weight='semibold'>
            {afterChange}
          </Typography>
          <div className='w-5 cursor-pointer'>
            <EyeIcon
              color='#000'
              onClick={() => navigate(`/member/${params.id}/history/${historyId}`)}
            />
          </div>
        </>
      ) : (
        <>
          <div className='flex'>
            <div className='mr-5'>
              <Button label='Szczegóły' variant='secondary' onClick={handleVisibleChange} />
            </div>
          </div>
          <div></div>
        </>
      )}
    </div>
  )
}
