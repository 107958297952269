import { useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Checkbox } from 'components/Atoms/Checkbox'
import LoadingModal from 'components/Atoms/LoadingModal'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { TextareaField } from 'components/Atoms/TextareaField'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { cardErrors } from 'error-data/card'
import { checkCardReq, getCarReqdUpdate } from 'fetchers/cardFetchers'
import useAuth from 'hooks/useAuth'
import { cardReqUpdate } from 'schemas/cardSchema'
import { mutationErrorHandler } from 'tools/errorHandler'
import { fileUpload } from 'tools/fileUpload'
import { getLoadingHandler } from 'tools/queryHelpers'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { ICardReqError, ICardReqSingle, ICardReqTypeDict, IError } from 'types/cardRequest'

interface IList {
  id: string
}

type Props = {
  type: ICardReqTypeDict
  selectedIds: ICardReqSingle[]
  closeModal: () => void
  refetch: () => void
  deselectAll: () => void
}

export const CardReqUpdate = ({ type, closeModal, selectedIds, refetch, deselectAll }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const [printSummary, setPrintSummary] = useState<boolean>(false)
  const [selectedList, setSelectedList] = useState<IList[]>([])
  const { userToken, isAllAdmin, isAllOK, isAllOD } = useAuth()
  const regionAction = isAllAdmin() || isAllOK()
  const branchAction = isAllOD()
  const isAllow = regionAction || branchAction
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver(cardReqUpdate),
  })
  const checkListIds = selectedIds.map((cardReq) => cardReq.id)
  const checkListData = selectedIds.map((cardReq) => ({ id: cardReq.id }))

  // CHECK VALIDIATION
  const {
    data: check,
    isLoading,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.cardReqCheck, type, checkListIds],
    queryFn: () => checkCardReq(checkListData, type),
  })
  const checkList: ICardReqError[] = check?.items || []
  // END CHECK VALIDIATION

  // SEND HANDLER
  const mutation = useMutation({
    mutationFn: (comment: string) => getCarReqdUpdate(type, comment, selectedList),
    onSuccess: (res) => {
      successToast('Zaktualizowano status wniosków')
      refetch()
      if (type === 'printed') {
        fileUpload(res)
        setPrintSummary(true)
        setIsSending(false)
      } else {
        setSelectedList([])
        deselectAll()
        closeModal()
      }
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, cardErrors.reqUpdate, cardErrors.reqUpdate.message)
    },
  })

  const onSubmit = async (data: any) => {
    try {
      setIsSending(true)
      if (selectedList.length === 0) {
        errorToast('Zaznacz przynajmniej jeden wniosek')
        setIsSending(false)
        return
      }
      mutation.mutate(data.comment)
    } catch (error) {
      setIsSending(false)
      console.error('Error', error)
      mutationErrorHandler(null, cardErrors.reqUpdate, cardErrors.reqUpdate.message)
    }
  }
  // END SEND HANDLER

  // SELECT HANDLERS
  const handleSelectAll = () => {
    const allIds = selectedIds.map((cardReq) => cardReq.id)
    setSelectedList(allIds.map((id) => ({ id })))
  }
  const handleDeselectAll = () => {
    setSelectedList([])
  }

  const handleSelect = (event: React.FormEvent<HTMLInputElement>, id: string) => {
    if (event.currentTarget.checked) {
      setSelectedList((prev) => [...prev, { id }])
    } else {
      const updatedList = selectedList.filter((item) => item.id !== id)
      setSelectedList(updatedList)
    }
  }
  // END SELECT HANDLERS

  // DATA LIST
  const list = selectedIds.map((item) => {
    return {
      ...item,
      errors: checkList.find((checkItem) => checkItem.id === item.id)?.errors || null,
    }
  })
  // END DATA LIST

  const closeHandler = () => {
    closeModal()
    deselectAll()
  }

  const cardLabel = {
    new: 'Zmień typ wniosku',
    accepted: 'Generuj legitymacje',
    printed: 'Drukuj legitymacje',
    in_branch: 'Przekaż legitymacje do oddziału',
    issued: 'Potwierdź wydanie legitymacji',
    rejected: 'Odrzuć wnioski o legitymacje',
  }
  const buttonLabel = {
    new: 'Zmień typ',
    accepted: 'Zatwierdź',
    printed: 'Drukuj',
    in_branch: 'Przekaż',
    issued: 'Potwierdź',
    rejected: 'Odrzuć',
  }

  const listData = list.map((item) => {
    return {
      name: `${item?.member?.firstName} ${item?.member?.lastName}`,
      card: item?.member?.card,
      branch: item?.branch?.name,
      status: item?.status,
      comments: item.errors,
      id: item?.id,
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Wybierz',
        accessor: 'select',
        Cell: ({ row }: any) => {
          return (
            <Checkbox
              id={row.original.id}
              checked={selectedList.some((item) => item.id === row.original.id)}
              onChange={(event) => handleSelect(event, row.original.id)}
            />
          )
        },
      },
      {
        Header: 'Imię i nazwisko',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Nr legitymacji',
        accessor: 'card',
      },
      {
        Header: 'Oddział',
        accessor: 'branch',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Walidacja',
        accessor: 'valid',
        Cell: ({ row }: any) => {
          return (
            <span
              className={clsx(
                'border-grey-500 rounded border border-neutral-600 p-1 px-2 text-center text-sm text-neutral-900',
                { 'bg-green-500': !row.original.comments },
                { 'bg-red-500': row.original.comments },
              )}
            >
              {!row.original.comments ? 'OK' : 'Konflikt'}
            </span>
          )
        },
      },
      {
        Header: 'Uwagi',
        accessor: 'comment',
        Cell: ({ row }: any) => {
          return (
            <ul className='list-inside list-disc'>
              {row.original.comments?.map((error: IError, index: number) => (
                <li key={index}>{error.error}</li>
              ))}
            </ul>
          )
        },
      },
    ],
    [selectedList],
  )

  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  const disabled = isSending

  if (printSummary) {
    return (
      <section>
        <Card label='Wydrukowano wnioski'>
          <div className='mt-6 flow-root py-3'>
            <Typography size='xl' weight='semibold'>
              Lista pomyślnie wydrukowanych wniosków:
            </Typography>
            <ul className='mt-3'>
              {selectedIds.map((cardReq) => {
                const memberArray = [`${cardReq?.member?.firstName} ${cardReq?.member?.lastName}`]
                if (cardReq?.member?.card) memberArray.push(cardReq?.member?.card)
                if (cardReq?.branch?.name) memberArray.push(cardReq?.branch?.name)
                const member = memberArray.join(' | ')
                return <li key={cardReq.id}>{member}</li>
              })}
            </ul>
          </div>
          <div className='mt-4 flex justify-center gap-4'>
            <Button label='Powrót' variant='secondary' onClick={closeHandler} className='ml-2' />
          </div>
        </Card>
      </section>
    )
  }
  return (
    <section>
      <Card label={cardLabel[type as ICardReqTypeDict]}>
        <Button
          label='Zaznacz wszystkie'
          onClick={() => {
            handleSelectAll()
            const data = getValues()
          }}
        />
        <Button label='Odznacz wszystkie' onClick={handleDeselectAll} className='ml-2' />
        <Table columns={columns} data={listData} />
        <TextareaField
          placeholder='Komentarz'
          error={errors.comment?.message}
          {...register('comment')}
        />
        <div className='mt-4 flex justify-center gap-4'>
          <Button
            disabled={disabled}
            label={buttonLabel[type]}
            onClick={handleSubmit(onSubmit)}
            className='ml-2'
          />
          <Button label='Anuluj' variant='secondary' onClick={closeHandler} className='ml-2' />
        </div>
      </Card>
    </section>
  )
}

export default CardReqUpdate
