import { Fragment, useEffect, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { MultiSelect } from 'react-multi-select-component'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { Select } from 'components/Atoms/Select'
import { TextField } from 'components/Atoms/TextField'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { documentErrors } from 'error-data/document'
import { getBranchesList } from 'fetchers/branchFetchers'
import { createDocument, getDocumentTemplateList } from 'fetchers/documentFetchers'
import { getInstitutionsListByMe } from 'fetchers/institutionFetchers'
import { getMembersList } from 'fetchers/membersFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getSectionsList } from 'fetchers/sectionFetchers'
import useAuth from 'hooks/useAuth'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'

const DocumentCreate = () => {
  const [selected, setSelected] = useState([])
  const [documentType, setDocumentType] = useState<string>('')
  const [documentEnum, setDocumentEnum] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [customFields, setCustomFields] = useState<
    {
      label: string | undefined
      type: 'number' | 'email' | 'text' | 'password' | undefined
      name: string
      value: string
    }[]
  >([])

  const { userToken } = useAppSelector((state: any) => state.authReducer)

  const { getHighestRoleLevel } = useAuth()
  const currentUserRole = getHighestRoleLevel()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    data: documentListData,
    isPending: isDocumentListDataPending,
    isLoading: isDocumentListDataLoading,
    error: documentListDataError,
    refetch: refetchDocumentListData,
  } = useQuery({
    queryKey: [userToken, queryKeys.documentList],
    queryFn: () => getDocumentTemplateList(1, 100),
    retry: useRetryHandler({
      resourceName: 'documentListData',
      maxRetries: 1,
    }),
  })

  useEffect(() => {
    if (documentType) {
      const selectedDocument = documentListData?.items.find((item: any) => item.id === documentType)
      if (selectedDocument) {
        const fields = selectedDocument.customFields.map((field: any) => ({
          name: field.name,
          value: '',
          label: field.label,
          type: field.type,
        }))
        setCustomFields(fields)
      }
    }
  }, [documentType, documentListData])

  const { data: getRegionsListData, refetch: refetchRegionsList } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 100, []),
    enabled:
      documentEnum === 'region' && (currentUserRole === 'admin' || currentUserRole === 'region'),
    retry: useRetryHandler({
      resourceName: 'getRegionsListData',
      maxRetries: 1,
    }),
  })

  const { data: getBranchesListData, refetch: refetchBranchList } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 100, []),
    enabled: documentEnum === 'branch' && ['admin', 'region', 'branch'].includes(currentUserRole),
    retry: useRetryHandler({
      resourceName: 'getBranchesListData',
      maxRetries: 1,
    }),
  })

  const { data: getSectionsListData, refetch: refetchSectionList } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList],
    queryFn: () => getSectionsList(1, 100, []),
    enabled:
      documentEnum === 'section' &&
      ['admin', 'region', 'branch', 'institution'].includes(currentUserRole),
    retry: useRetryHandler({
      resourceName: 'getSectionsListData',
      maxRetries: 1,
    }),
  })

  const { data: getInstitutionsListByMeData, refetch: refetchInstitutionList } = useQuery({
    queryKey: [userToken, queryKeys.institutionListByMe],
    queryFn: () => getInstitutionsListByMe(1, 100),
    enabled:
      documentEnum === 'institution' &&
      ['admin', 'region', 'branch', 'institution'].includes(currentUserRole),
    retry: useRetryHandler({
      resourceName: 'getInstitutionsListByMeData',
      maxRetries: 1,
    }),
  })

  const { data: membersListData, refetch: refetchMembersList } = useQuery({
    queryKey: [userToken, queryKeys.membersList],
    queryFn: () => getMembersList(1, 100, ''),
    enabled:
      documentEnum === 'member' &&
      ['admin', 'region', 'branch', 'institution'].includes(currentUserRole),
    retry: useRetryHandler({
      resourceName: 'membersListData',
      maxRetries: 1,
    }),
  })

  const handleCustomFieldChange = (index: number, value: string) => {
    const updatedFields = [...customFields]
    updatedFields[index].value = value
    setCustomFields(updatedFields)
  }

  const mutation = useMutation({
    mutationFn: (data: any) => createDocument(data),
    onSuccess: () => {
      successToast('Dokument został utworzony')
      queryClient.invalidateQueries({
        queryKey: [userToken, queryKeys.documentList],
      })
      navigate('/document')
      setSubmitting(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setSubmitting(false)
      mutationErrorHandler(error, documentErrors.create, 'Nie udało się utworzyć dokumentu')
    },
  })

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true)
    e.preventDefault()
    if (!documentType) {
      errorToast('Wybierz typ dokumentu')
      setSubmitting(false)
      return
    }
    if (selected.length === 0) {
      errorToast(`Wybierz co najmniej jedno pole w ${translateDocumentType(documentEnum)}`)
      setSubmitting(false)
      return
    }
    const exportData = {
      template: documentType,
      items: selected.map((item: { value: string }) => item.value),
      customFields: customFields.map((field) => ({
        name: field.name,
        value: field.value,
      })),
    }
    mutation.mutate(exportData)
  }

  const documentListDataOptions = documentListData?.items
    ? documentListData.items.map((item: { id: string; name: string; type: string }) => ({
        value: item.id,
        label: item.name,
        type: item.type,
      }))
    : []

  const options =
    documentEnum === 'institution'
      ? getInstitutionsListByMeData?.items?.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        })) || []
      : documentEnum === 'branch'
        ? getBranchesListData?.items?.map((item: { id: string; name: string }) => ({
            value: item.id,
            label: item.name,
          })) || []
        : documentEnum === 'region'
          ? getRegionsListData?.items?.map((item: { id: string; name: string }) => ({
              value: item.id,
              label: item.name,
            })) || []
          : documentEnum === 'section'
            ? getSectionsListData?.items?.map((item: { id: string; name: string }) => ({
                value: item.id,
                label: item.name,
              })) || []
            : documentEnum === 'member'
              ? membersListData?.items?.map(
                  (item: { id: string; firstName: string; lastName: string }) => ({
                    value: item.id,
                    label: item.firstName + ' ' + item.lastName,
                  }),
                ) || []
              : []

  // function translating document type into polish name
  const translateDocumentType = (type: string) => {
    switch (type) {
      case 'institution':
        return 'Placówka'
      case 'branch':
        return 'Oddział'
      case 'region':
        return 'Okręg'
      case 'section':
        return 'Ognisko'
      case 'member':
        return 'Członek'
      default:
        return ''
    }
  }

  if (isDocumentListDataPending || isDocumentListDataLoading) {
    return (
      <Card label='Tworzenie wzoru dokumentu'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <Card label='Tworzenie dokumentu'>
        <form onSubmit={submitHandler}>
          <div className='grid max-w-4xl grid-cols-1 gap-4 pb-4 sm:grid-cols-2'>
            {documentListDataOptions && (
              <Select
                handleSelect={(e) => {
                  setDocumentType(String(e.value))
                  setDocumentEnum(String(e.type))
                }}
                placeholder=''
                options={documentListDataOptions}
                label='Typ dokumentu do wygenerowania'
              />
            )}
            {documentListDataOptions && (
              <div>
                <p className='mb-1 block text-sm font-medium text-gray-700'>
                  {translateDocumentType(documentEnum) || 'Wybierz'}
                </p>
                <MultiSelect
                  options={options}
                  value={selected}
                  className='text-sm'
                  onChange={setSelected}
                  overrideStrings={{
                    selectSomeItems: 'Wybierz co najmniej jedno pole',
                    search: 'Wyszukaj',
                    selectAll: 'Zaznacz wszystkie',
                    allItemsAreSelected: 'Wszystkie są zaznaczone',
                  }}
                  labelledBy='Select'
                />
              </div>
            )}
            {customFields.map((field, index) => (
              <div key={index} className='relative flex flex-col gap-2 rounded-md bg-slate-100 p-3'>
                <TextField
                  onChange={(e) => handleCustomFieldChange(index, e.target.value)}
                  label={field?.label}
                  placeholder={field?.label}
                  name={field?.name}
                  type={field?.type}
                  value={field?.value}
                />
              </div>
            ))}
          </div>
          <hr className='my-4' />
          <div className='mt-4 flex justify-center'>
            <Button
              label={
                submitting ? (
                  <span className='flex items-center'>
                    <span className='loader'></span> Czekaj...
                  </span>
                ) : (
                  'Utwórz dokument'
                )
              }
              disabled={submitting}
            />
          </div>
        </form>
      </Card>
      <div className='my-4 flex justify-center gap-4'>
        <Button
          variant='secondary'
          label='Powrót'
          onClick={(e) => {
            e.preventDefault()
            navigate(path.document.list)
          }}
        />
      </div>
    </>
  )
}

export default DocumentCreate
