import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { wipMessage } from 'tools/ToastHelpers'
import { IBranchList } from 'types/branch'
interface IProps {
  data: IBranchList
}
export const RegionBranches: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate()
  const branchesList = data?.items ? data?.items : []
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa oddziału',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Lokalizacja oddziału',
        accessor: 'location',
      },
      {
        Header: 'Adres oddziału',
        accessor: 'address',
      },
      {
        Header: 'Prezes',
        accessor: 'president',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(`/branch/${row.original.id}`)}
              />
              <HoverIcon iconName='TrashIcon' title='Usuń' onClick={() => wipMessage()} />
            </div>
          )
        },
      },
    ],
    [],
  )

  if (branchesList.length === 0) {
    return (
      <Card label='Powiązane oddziały'>
        <p>Brak powiązanych oddziałów</p>
      </Card>
    )
  }

  return (
    <Card label='Powiązane oddziały'>
      <Table data={branchesList} columns={columns} />
    </Card>
  )
}
