import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Select } from 'components/Atoms/Select'
import { Spinner } from 'components/Atoms/Spinner'
import { Table } from 'components/Atoms/Table'
import { translateCardStatus } from 'tools/cardStatus'
import { wipMessage } from 'tools/ToastHelpers'
import { IMemberData } from 'views/Branch/BranchMembers'

interface IOption {
  label: string
  value: number | string
}

interface IProps {
  data: IMemberData[] // Updated to use IMemberData interface
  lastPage: number
  withArchived: boolean
  currentPage: number
  isLoading: boolean
  toggleArchivedFunctions: () => void
  onPageChange: (currentPage: number) => void
  onItemsPerPageChange: (selectItem: IOption) => void
}

export const BranchPerformedFunctions: React.FC<IProps> = ({
  data,
  withArchived,
  isLoading,
  lastPage,
  currentPage,
  onPageChange,
  toggleArchivedFunctions,
  onItemsPerPageChange,
}) => {
  const navigate = useNavigate()

  const columns = React.useMemo(
    () => [
      {
        Header: 'Imię i nazwisko członka',
        accessor: (row: IMemberData) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: 'Pełnione funkcje',
        accessor: 'functions',
      },
      {
        Header: 'Akcje',
        Cell: ({ row }: { row: { original: IMemberData } }) => (
          <div className="flex gap-2">
            <HoverIcon
              iconName="EyeIcon"
              title="Zobacz"
              onClick={() => navigate(`/member/${row.original.id}`)}
            />
            <HoverIcon iconName="TrashIcon" title="Usuń" onClick={() => wipMessage()} />
          </div>
        ),
      },
    ],
    [withArchived]
  )

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-8 text-gray-500">
      <p className="text-lg">Brak przypisanych funkcji w oddziale</p>
    </div>
  )

  return (
    <Card label="Funkcje przypisane w oddziale">
      {isLoading ? (
        <Spinner />
      ) : data.length === 0 ? (
        <EmptyState />
      ) : (
        <Table
          data={data}
          columns={columns}
          pagination={{
            currentPage,
            lastPage,
            onPageChange,
          }}
        />
      )}
      <div className="mt-2 flex justify-center">
        <Button
          type="button"
          label={withArchived ? 'Ukryj archiwalne' : 'Pokaż archiwalne'}
          onClick={toggleArchivedFunctions}
        />
      </div>
    </Card>
  )
}
