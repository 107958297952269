import React, { Fragment, useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { Pagination } from 'components/Atoms/Pagination'
import { Select } from 'components/Atoms/Select'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { documentArchiveErrors } from 'error-data/document'
import { archiveDocument, getDocumentList } from 'fetchers/documentFetchers'
import { getUsersList } from 'fetchers/userFetchers'
import useAuth from 'hooks/useAuth'
import { useLastPage } from 'hooks/useLastPage'
import useSearch from 'hooks/useSearch'
import { mutationErrorHandler } from 'tools/errorHandler'
import { errorQuery } from 'tools/queryHelpers'
import { successToast } from 'tools/ToastHelpers'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption } from 'types/form'

const searchParams = {}

const DocumentList = () => {
  const { unitContext } = useAuth()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const { filters, perPage, page, setLimit, changePage } = useSearch(searchParams)
  const navigate = useNavigate()

  const [isModalOpen, setModalOpen] = useState(false)
  const [documentId, setDocumentId] = useState<string>('')
  const queryClient = useQueryClient()

  const id = unitContext?.id

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const {
    data: documentListData,
    isPending: isDocumentListDataPending,
    isLoading: isDocumentListDataLoading,
    error: documentListDataError,
    refetch: refetchDocumentListData,
  } = useQuery({
    queryKey: [userToken, queryKeys.documentList, filters, perPage, page, id],
    queryFn: () => getDocumentList(page, perPage, filters),
  })

  const onLimitHandler = (e: IOption) => setLimit(e.value)
  const lastPage = useLastPage(documentListData?.pagination, isDocumentListDataLoading)

  const generatedDocumentsList = documentListData?.items || []

  const archiveDocumentMutation = useMutation({
    mutationFn: (documentId: string) => archiveDocument(documentId),
    onSuccess: () => {
      successToast('Zarchiwizowano dokument.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.documentArchive] })
      refetchDocumentListData()
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      const errorMessages = error.response?.data.errors
        .map((item: any) => `${item.property}: ${item.error}`)
        .join('\n')
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(errorMessages)
      })
      mutationErrorHandler(error, documentArchiveErrors.archive, errorMessages)
    },
  })

  const confirmArchiveDocumentHandler = () => {
    archiveDocumentMutation.mutate(documentId)
    closeModal()
  }

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'completed':
        return 'Gotowy do pobrania'
      case 'error':
        return 'Konflikt'
      default:
        return ''
    }
  }

  const { data: userData, isLoading: userDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.usersList],
    queryFn: () => getUsersList(1, 100),
    retry: errorQuery,
  })

  const getUserEmailById = (userId: string) => {
    if (!userData) return 'Wczytuję...'
    const user = userData?.items?.find((user: any) => user.id === userId)
    return user?.email || user?.displayName || user?.firstName + ' ' + user?.lastName || 'Nieznany'
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa dokumentu',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.template?.name
        },
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          return row.original?.createdAt
        },
      },
      {
        Header: 'Utworzony przez',
        accessor: 'createdBy',
        Cell: ({ row }: any) => {
          return getUserEmailById(row.original?.createdBy)
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          return getStatusText(row?.original?.status)
        },
      },
      {
        Header: 'Liczba dokumentów',
        accessor: 'items',
        Cell: ({ row }: any) => {
          return row.original?.itemsCnt
        },
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex gap-2'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz szczegóły dokumentu'
                onClick={() => navigate(`/document/${row.original?.id}/show`)}
              />
              <HoverIcon
                iconName='TrashIcon'
                title='Archiwizuj szkolenie'
                onClick={() => {
                  setDocumentId(row.original?.id)
                  openModal()
                  refetchDocumentListData()
                }}
              />
            </div>
          )
        },
      },
    ],
    [],
  )

  if (documentListDataError) return <div>Wystąpił błąd: {documentListDataError.message}</div> // Wrapped in a <div>

  if (isDocumentListDataLoading) {
    return (
      <Card label='Lista dokumentów'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  return (
    <>
      <Card
        label='Lista dokumentów'
        actionsButton={[
          {
            label: 'Utwórz dokument',
            handleClick: () => navigate(path.document.create),
          },
        ]}
      >
        <div className='flex items-center justify-between gap-4'>
          {lastPage && (
            <>
              <div className='flex flex-col justify-end gap-4 md:flex-row'>
                <div className='flex items-center gap-4'>
                  <Typography weight='semibold' className='text-sm'>
                    Liczba wierszy na stronę
                  </Typography>
                  <Select
                    handleSelect={onLimitHandler}
                    options={[
                      { label: '10', value: 10 },
                      { label: '20 ', value: 20 },
                    ]}
                  />
                </div>
              </div>

              <span className='flex flex-col items-center gap-2 md:flex-row md:gap-4'>
                {lastPage > 1 && (
                  <Pagination
                    lastPage={lastPage}
                    currentPage={page}
                    handlePageChange={changePage}
                  />
                )}
              </span>
            </>
          )}
        </div>
        {!isDocumentListDataPending && (
          <Table maxColumnWidth='300px' columns={columns} data={generatedDocumentsList || []} />
        )}
        {isModalOpen && (
          <div className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 flex items-center justify-center bg-black/80'>
            <div className='rounded bg-white p-6 shadow-lg'>
              <h2 className='mb-4 text-xl'>Potwierdzenie archiwizacji</h2>
              <p>Czy na pewno chcesz zarchiwizować ten dokument?</p>
              <div className='mt-4 flex justify-end space-x-4'>
                <button onClick={closeModal} className='rounded bg-gray-300 px-4 py-2'>
                  Anuluj
                </button>
                <button
                  onClick={confirmArchiveDocumentHandler}
                  className='rounded bg-red-500 px-4 py-2 text-white'
                >
                  Archiwizuj
                </button>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  )
}

export default DocumentList
