import * as yup from 'yup'

import { errorMessage } from 'constants/errorMessage'
import { emailRegex, houseNrRegExp, phoneRegExp, postalCodeRegExp } from 'constants/regexs'

const { requiredField } = errorMessage

export const createMemberSchema = yup
  .object({
    firstName: yup.string().required(requiredField),
    secondName: yup.string(),
    lastName: yup.string().required(requiredField),
    pesel: yup.string().required(requiredField),
    sex: yup.string().required(requiredField),
    phoneNumber: yup.string().required(requiredField),
    email: yup.string(),
    zipCode: yup.string().required(requiredField),
    city: yup
      .string()
      .test('no-numbers', errorMessage.invalidCity, (value) => {
        // Custom validation function
        return !/\d/.test(String(value))
      })
      .required(requiredField),
    street: yup.string().required(requiredField),
    hauseNr: yup.string().required(requiredField),
    apartmentNr: yup.string(),
    education: yup.string(),
    primaryJob: yup.string().required(requiredField),
    position: yup.string(),
    file: yup.string(),
  })
  .required()

export const editMemberSchema = yup.object({
  firstName: yup.string().required(requiredField),
  secondName: yup.string(),
  lastName: yup.string().required(requiredField),
  lastSecondName: yup.string(),
})

export const copyMemberSchema = yup.object({})

export const personalMemberSchema = yup.object({
  phone: yup.string().optional().matches(phoneRegExp, errorMessage.invalidPhone),
  email: yup.string().optional().matches(emailRegex, errorMessage.invalidEmail),
  emailSecondary: yup.string().optional().matches(emailRegex, errorMessage.invalidEmail),
})

export const sensitivePersonalSchema = yup.object({
  apartmentNr: yup.string(),
  city: yup
    .string()
    .test('no-numbers', errorMessage.invalidCity, (value) => {
      // Custom validation function
      return !/\d/.test(String(value))
    })
    .required(requiredField),
  houseNr: yup.string().matches(houseNrRegExp, errorMessage.invalidHouseNr),
  postalCode: yup
    .string()
    .required(errorMessage.requiredField)
    .matches(postalCodeRegExp, errorMessage.invalidPostalCode),
  streetName: yup.string().required(requiredField),
})

export const updateMemberEmploymentSchema = yup.object({
  institution: yup.string().required(errorMessage.requiredField),
  position: yup.string().required(errorMessage.requiredField),
  isMain: yup.boolean(),
})
