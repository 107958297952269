import { useState } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { BranchForm } from 'components/Branch/BranchForm'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { branchErrors } from 'error-data/branch'
import { createBranch } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import useLegalFetcher from 'hooks/useLegalFetcher'
import { useRetryHandler } from 'hooks/useRetryHandler'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { ICreateBranch } from 'types/branch'

export const BranchCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const queryClient = useQueryClient()
  const legalData = useLegalFetcher()

  const { data: regions, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 100),
    retry: useRetryHandler({
      resourceName: 'BranchCreate regions',
      maxRetries: 1,
    }),
  })

  const mutation = useMutation({
    mutationFn: (data: ICreateBranch) => createBranch(data),
    onSuccess: () => {
      successToast('Utworzono oddział.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.branchesList] })
      navigate(path.branch.list)
    },
    onError: (error: AxiosError) => {
      console.error(error)
      setIsSending(false)
      mutationErrorHandler(error, branchErrors.create, 'Nie udało się utworzyć oddziału')
    },
  })

  const onSubmit = async (data: ICreateBranch) => {
    const { id, ...formData } = data
    try {
      setIsSending(true)
      mutation.mutate(formData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się utworzyć oddziału')
    }
  }

  const regionsList = regions?.items
    ? regions?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const initData = {
    id: '',
    region: '',
    name: '',
    email: '',
    phonePrimary: '',
    phoneSecondary: '',
    postalCode: '',
    city: '',
    streetName: '',
    houseNr: '',
    apartmentNr: '',
    locationDescription: '',
    document: '',
  }

  if (isLoading) return <LoadingSection />

  return (
    <BranchForm
      legalData={legalData}
      regions={regionsList}
      data={initData}
      isCreate
      isSending={isSending}
      onSubmit={onSubmit}
    />
  )
}
