import React, { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { Pagination } from 'components/Atoms/Pagination'
import { Table } from 'components/Atoms/Table'
import { TableName } from 'components/Atoms/TableName'
import { TextField } from 'components/Atoms/TextField'
import { Typography } from 'components/Atoms/Typography'
import { path } from 'constants/path'
import { queryKeys } from 'constants/queryKeys'
import { getRegionsList } from 'fetchers/regionFetchers'
import useAuth from 'hooks/useAuth'
import useSearchModule from 'hooks/useSearchModule'
import { errorForbiddenHandler, errorQuery, getLastPage } from 'tools/queryHelpers'

export const RegionList = () => {
  const { filters, currentPage, search, setSearch, onPageChange, onSearchHandler } =
    useSearchModule({ archive: false })
  const navigate = useNavigate()
  const { userToken, isAllAdmin } = useAuth()
  const adminAuth = isAllAdmin()
  const {
    data: regions,
    isLoading,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.regionList, currentPage, filters],
    queryFn: () => getRegionsList(currentPage, 20, filters),
    retry: errorQuery,
  })
  const { lastPage } = getLastPage(regions?.pagination)

  const data = useMemo(() => {
    return (regions?.items || [])?.filter((item: any) => {
      return item?.name?.toLowerCase().includes(search.toLowerCase())
    })
  }, [regions, adminAuth])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa okręgu',
        accessor: 'name',
        Cell: (row: any) => <TableName name={row.row.original.name} />,
      },
      {
        Header: 'Prezes okręgu',
        accessor: 'president',
        Cell: () => <p>-</p>,
      },
      {
        Header: 'Oddziały',
        accessor: 'childrenCnt',
      },
      {
        Header: 'Akcje',
        accessor: 'action',
        Cell: ({ row }: any) => {
          return (
            <div className='flex'>
              <HoverIcon
                iconName='EyeIcon'
                title='Zobacz'
                onClick={() => navigate(row.original.id)}
              />
              {adminAuth && (
                <HoverIcon
                  iconName='PencilIcon'
                  title='Edytuj'
                  onClick={() => navigate(`${path.region.update}${row.original.id}`)}
                />
              )}
            </div>
          )
        },
      },
    ],
    [adminAuth],
  )
  if (errorForbiddenHandler(error)) return <ErrorRoleModal />
  if (isLoading) return <LoadingSection />
  return (
    <div>
      <div className='flex justify-between'>
        <Typography size='xl' weight='semibold'>
          Lista okręgów
        </Typography>
      </div>
      <Card label='Szukaj'>
        <form onSubmit={onSearchHandler}>
          <div className='mb-5 grid w-3/4 grid-cols-2 gap-4'>
            <TextField label='Nazwa' type='text' value={search} onChange={setSearch} />
            <div className='flex items-end'>
              <Button label='Szukaj' />
            </div>
          </div>
        </form>
      </Card>
      <div className='mt-5'>
        <div className='mt-5 flex justify-between'>
          {adminAuth && (
            <Button label='Utwórz okręg' onClick={() => navigate(path.region.create)} />
          )}
        </div>
        <Table columns={columns} data={data} />
        <div className='flex justify-end'>
          <Pagination
            lastPage={lastPage}
            currentPage={currentPage}
            handlePageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  )
}
